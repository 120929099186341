import { ActivityIndicator } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, useColor, useLayout } from 'core'

export const StatisticsLoadingTemplate = observer(function StatisticsLoadingTemplate() {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView margin={{ top: spacing[5] }} hAlign="center">
      <ActivityIndicator color={color['content-2']} />
    </BlockView>
  )
})
