import 'regenerator-runtime/runtime'
import 'react-native-reanimated'
import 'react-native-gesture-handler'
import { Platform } from 'react-native'
import { useFonts } from 'expo-font'

import { AppNavigator } from './src/navigation'

if (Platform.OS === 'web') {
  // @ts-ignore Moti requires this for the Web.
  global._frameTimestamp = null
}

export default function App() {
  const [loaded, error] = useFonts({
    GridularRegular: require('./assets/fonts/Gridular-Regular.otf'),
    RobotoBold: require('./assets/fonts/Roboto-Bold.ttf'),
    RobotoRegular: require('./assets/fonts/Roboto-Regular.ttf'),
    RobotoMonoMedium: require('./assets/fonts/RobotoMono-Medium.ttf'),
  })

  /**
   * @TODO - Display a nice Splash screen here.
   */
  if (!loaded || !!error) {
    return null
  }

  return <AppNavigator />
}
