import { observer } from 'mobx-react-lite'

import { BlockView, ButtonPrimary, ButtonPrimaryProps, useColor, useLayout } from 'core'

type MyAccountActionProps = {
  buttons: ButtonPrimaryProps[]
}

export const MyAccountAction = observer(function MyAccountAction({ buttons }: MyAccountActionProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView margin={{ top: spacing[5] }} padding={{ bottom: spacing[4] }}>
      {/* Divider. */}
      <BlockView margin={{ bottom: spacing[4] }}>
        <BlockView size={{ width: '100%', height: 1 }} backgroundColor={color['utility-border-1']} />
      </BlockView>

      <BlockView direction="row" hAlign="flex-end" vAlign="center">
        {buttons.map((action, index) => {
          const lastItem = buttons.length - 1 === index
          return (
            <BlockView key={`modal-2-action-${index}`} margin={{ right: lastItem ? 0 : 10 }}>
              <ButtonPrimary size="button-2" {...action} />
            </BlockView>
          )
        })}
      </BlockView>
    </BlockView>
  )
})
