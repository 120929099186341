import { useCallback } from 'react'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { useQuery } from 'react-query'

import { BlockView, ButtonIcon, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { getBenchmarkQuestion } from 'api/data-train/get-benchmark-question'

import { NoSecondBestTemplate } from './Template'
import { NoSecondBestLoadingTemplate } from './Template/Loading'
import { NoSecondBestErrorTemplate } from './Template/Error'

type ContributeNsbAppProps = CompositeScreenProps<
  CompositeScreenProps<
    NativeStackScreenProps<ContributeAppRoutesProps, ContributeRoutes.NoSecondBest>,
    DrawerScreenProps<ContributeAppRoutesProps>
  >,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ContributeNoSecondBest = ({ navigation }: ContributeNsbAppProps) => {
  /** States. */
  const { screenSize, breakPoints } = useLayout()
  const isDesktop = screenSize.width >= breakPoints.lg

  /** Get Question Query. */
  const { isLoading, isRefetching, isError, data, refetch } = useQuery({
    queryKey: 'get-benchmark-question',
    queryFn: getBenchmarkQuestion,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({
        headerRight: () =>
          isDesktop ? null : (
            <BlockView margin={{ horizontal: 11 }}>
              <ButtonIcon
                kind="minimal"
                iconName="question-mark"
                onPress={() => {
                  navigation.navigate(HowToModalRoutes.HowToNoSecondBest)
                }}
              />
            </BlockView>
          ),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])
  )

  /** Loading State. */
  if (isLoading) return <NoSecondBestLoadingTemplate />

  /** Error State. */
  if (isError || !data) return <NoSecondBestErrorTemplate refreshing={isRefetching} onRefresh={refetch} />

  /** Dataful State. */
  const { id, question, answers } = data

  return (
    <NoSecondBestTemplate
      key={id}
      isRefetching={isRefetching}
      getNextData={refetch}
      benchmarkId={id}
      question={question}
      answers={answers}
    />
  )
}
