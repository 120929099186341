import { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useMutation } from 'react-query'

import { BlockView, useLayout, FormField, InputFieldState, ButtonPrimary } from 'core'
import { AuthenticationRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { emailValidator, removeDisallowedEmailCharacter } from 'utils/validator'
import { emailLoginOrSignup, EmailLoginOrSignupVariables } from 'api/authentication/email-login-or-signup'

export const EmailSignIn = () => {
  /** States. */
  const navigation = useNavigation<NativeStackNavigationProp<RootAppRoutesProps, AuthenticationRoutes.Login>>()
  const { spacing } = useLayout()
  const [email, setEmail] = useState('')
  const [inputState, setInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })

  /** Email Login or Signup Mutation. */
  const emailLoginOrSignupMutation = useMutation(
    (variables: EmailLoginOrSignupVariables) => {
      return emailLoginOrSignup(variables)
    },
    {
      onSuccess: (response) => {
        if (!response.otpId) {
          setInputState({
            state: 'error',
            message: 'Something went wrong. Please try again or a different email address',
          })
          return
        }

        navigation.navigate(AuthenticationRoutes.VerifyCode, {
          otpId: response.otpId,
          emailAddress: email,
        })
      },
      onError: () => {
        setInputState({
          state: 'error',
          message: 'Something went wrong. Please try again or a different email address',
        })
      },
    }
  )

  const onEmailEnter = (text: string) => {
    if (inputState.state !== 'default') {
      setInputState({
        state: 'default',
        message: '',
      })
    }
    setEmail(removeDisallowedEmailCharacter(text))
  }

  const onLoginPress = () => {
    if (!emailValidator(email)) {
      setInputState({
        state: 'error',
        message: 'Please enter a valid email address.',
      })
      return
    }

    emailLoginOrSignupMutation.mutate({ email })
  }

  return (
    <BlockView size={{ width: '100%' }} margin={{ top: spacing[2] }}>
      <FormField
        value={email}
        onValueChange={onEmailEnter}
        placeholderText="Enter your email address"
        otherInputProps={{
          autoCorrect: false,
          autoCapitalize: 'none',
          autoComplete: 'email',
          autoFocus: false,
          onSubmitEditing: () => {
            if (!emailValidator(email)) return
            onLoginPress()
          },
        }}
        inputState={inputState.state}
        supportLabel={inputState.message}
      />

      <BlockView margin={{ top: spacing[1] }}>
        <ButtonPrimary
          kind="filled"
          label="Send code"
          onPress={onLoginPress}
          loading={emailLoginOrSignupMutation.isLoading}
          disabled={!emailValidator(email)}
        />
      </BlockView>
    </BlockView>
  )
}
