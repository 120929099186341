import { useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, showSnackbar, useColor, useLayout } from 'core'
import { PaymentRoutes } from 'navigation/routes'
import { PaymentAppModalRoutesProps } from 'navigation/stack/payment'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { getCreditPackages } from 'api/credits/get-credit-packages'

import { AddCreditTemplate } from './Template'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<PaymentAppModalRoutesProps, PaymentRoutes.AddCreditModal>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const PaymentAddCredit = observer(function PaymentAddCredit({ navigation }: ScreenProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const [closeModal, setCloseModal] = useState(false)

  const onError = () => {
    showSnackbar({
      kind: 'error',
      label: `Can't generate your lightning invoice, please try again later.`,
      leftIcon: 'error',
    })
    setCloseModal(true)
    navigation.goBack()
  }

  /** Get Credit Packages query. */
  const {
    isLoading: creditPackagesIsLoading,
    isError: creditPackagesIsError,
    data: creditPackagesData,
  } = useQuery({
    queryKey: `get-credit-packages`,
    queryFn: getCreditPackages,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      if (!response?.length) {
        return onError()
      }
    },
    onError,
  })

  /** Loading packages. */
  if (creditPackagesIsLoading) {
    return (
      <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
        <ActivityIndicator color={color['content-1']} />
      </BlockView>
    )
  }

  if (creditPackagesIsError || !creditPackagesData) {
    return null
  }

  const packages = creditPackagesData.map((creditPackage) => ({
    id: creditPackage.id,
    credits: creditPackage.credits,
    price: creditPackage.price,
  }))

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="add-credit-data" type="from-bottom" duration={250}>
            <AddCreditTemplate onClose={() => setCloseModal(true)} packages={packages} />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
