import { Pressable, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'
import { AnimatePresence, MotiView } from 'moti'

import { useColor } from 'core/v2/color'
import { BlockView, ButtonIcon, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { Icon } from './Icon'
import { ButtonState } from './type'

export type NavItemProps = {
  icon: MaterialIconOutlinedName
  label: string
  disabled?: boolean
  activated?: boolean
  onPress?: () => void

  trailing?: React.ReactNode
}

export const NavItem = observer(function NavItem({
  icon,
  label,
  disabled,
  activated,
  onPress,
  trailing,
}: NavItemProps) {
  /** States. */
  const { color } = useColor()
  const buttonState = useSharedValue(ButtonState.Default)

  const showHoverInState = () => {
    buttonState.value = withTiming(ButtonState.HoverIn, { duration: 150 })
  }
  const showHoverOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }
  const showPressedState = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const showPressedOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = activated ? color['alpha-white-8'] : 'transparent'

    if (disabled) return { backgroundColor: 'transparent' }

    return {
      backgroundColor: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, color['alpha-white-4'], color['alpha-white-2']]
      ),
    }
  })

  const labelAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = activated ? color.accent.brand : color['content-1']

    return {
      color: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, defaultColor, color.accent.brand]
      ),
    }
  })

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showHoverInState}
      onHoverOut={showHoverOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
      disabled={disabled}
    >
      <Animated.View style={[styles.container, containerAnimatedStyle, { opacity: disabled ? 0.24 : 1 }]}>
        {/* Icon. */}
        <Icon activated={activated} disabled={disabled} icon={icon} buttonState={buttonState} />

        {/* Text. */}
        <Text.Roboto
          color={color['content-1']}
          size="14"
          strong
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.label}
        >
          <Animated.Text style={labelAnimatedStyle}>{label}</Animated.Text>
        </Text.Roboto>

        {trailing}
      </Animated.View>
    </Pressable>
  )
})

export type NavItemChatProps = Omit<NavItemProps, 'trailing'> & {
  onEditPress?: () => void
  onDeletePress?: () => void
}
export const NavItemChat = observer(function NavItemChat({
  onEditPress,
  onDeletePress,
  ...navItemProps
}: NavItemChatProps) {
  return (
    <NavItem
      {...navItemProps}
      trailing={
        <AnimatePresence>
          {navItemProps.activated && (
            <MotiView
              from={{ opacity: 0, translateX: 10 }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: 10 }}
            >
              <BlockView direction="row" margin={{ left: 4 }} vAlign="center">
                <ButtonIcon kind="minimal" iconName="edit" onPress={onEditPress} />
                <ButtonIcon kind="minimal" iconName="delete" onPress={onDeletePress} />
              </BlockView>
            </MotiView>
          )}
        </AnimatePresence>
      }
    />
  )
})

type Style = {
  container: ViewStyle
  label: TextStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    minHeight: 44,
    paddingLeft: 10,
    paddingRight: 6,
    paddingVertical: 6,
  },
  label: {
    marginLeft: 11,
    flex: 1,
  },
})
