import { LeaderboardFilter } from 'api/data-train/get-leaderboard'

export const getFilterName = (filter: LeaderboardFilter) => {
  switch (filter) {
    case 'daily':
      return 'Daily'
    case 'weekly':
      return 'Weekly'
    case 'monthly':
      return 'Monthly'
    case 'overall':
      return 'Overall'
    default:
      return 'Filter'
  }
}
