import { useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { Image } from 'expo-image'
import { AnimatePresence } from 'moti'

import { BlockView, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { isSandbox } from 'utils/environment'
import { LeaderboardFilter } from 'api/data-train/get-leaderboard'

import { UserLeaderboardRank } from './Template/UserLeaderboardRank'
import { Leaderboard, LeaderboardOrder } from './Template/Leaderboard'
import { TimeFilter } from './Template/TimeFilter'

type ContributeLeaderboardProps = CompositeScreenProps<
  CompositeScreenProps<
    NativeStackScreenProps<ContributeAppRoutesProps, ContributeRoutes.Leaderboard>,
    DrawerScreenProps<ContributeAppRoutesProps>
  >,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ContributeLeaderboard = observer(function ContributeLeaderboard({
  navigation,
  route,
}: ContributeLeaderboardProps) {
  /** States. */
  const { spacing, screenSize, breakPoints } = useLayout()
  const [selectedFilter, setSelectedFilter] = useState<LeaderboardFilter>('overall')
  const [selectedOrder, setSelectedOrder] = useState<LeaderboardOrder>({
    orderBy: 'totalPoints',
    direction: 'desc',
  })
  const isMobile = screenSize.width < breakPoints.sm

  const displayMobileTimeFilter = (() => {
    if (!isMobile) return false
    return route?.params?.showTimeFilter === true
  })()

  return (
    <>
      <ScrollView
        nestedScrollEnabled
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingHorizontal: isMobile ? spacing[2] : spacing[4],
          paddingTop: isMobile ? spacing[2] : spacing[3],
          paddingBottom: spacing[3],
        }}
      >
        {/* Background Image. */}
        <BlockView size={{ width: screenSize.width, height: screenSize.height }} style={StyleSheet.absoluteFill}>
          <Image
            source={require('assets/leaderboard-bg/satoshi-bg-leaderboard.png')}
            style={{ flex: 1 }}
            contentFit="contain"
            contentPosition="center"
            transition={300}
          />
        </BlockView>

        {/* Ranks. */}
        {/* Uncomment this when we fix Rank! */}
        {isSandbox && <UserLeaderboardRank />}

        {/* Leaderboard. */}
        <Leaderboard
          selectedFilter={selectedFilter}
          onFilterSelect={setSelectedFilter}
          selectedOrder={selectedOrder}
          onOrderSelect={setSelectedOrder}
        />
      </ScrollView>

      <AnimatePresence exitBeforeEnter>
        {displayMobileTimeFilter && (
          <BlockView key="mobile-time-filter" flex style={StyleSheet.absoluteFill} vAlign="flex-end">
            <TimeFilter selectedFilter={selectedFilter} onFilterSelect={setSelectedFilter} />
          </BlockView>
        )}
      </AnimatePresence>
    </>
  )
})
