import { useEffect, useRef, useState } from 'react'
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputChangeEventData,
  TextInputProps,
  TextStyle,
  View,
} from 'react-native'
import { observer } from 'mobx-react-lite'
import * as Device from 'expo-device'

import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { clamp } from 'utils/number'
import { BlockView } from '../BlockView'
import { TextStyles } from '../Text'
import { ButtonIcon } from '../Button'
import { MaterialIconOutlined, MaterialIconOutlinedName } from '../Icon'

export type InputFieldChatProps = {
  inputRef?: React.RefObject<TextInput>
  sendButtonRef?: React.RefObject<View>

  leftIcon?: MaterialIconOutlinedName
  leftIconColor?: string

  value: string
  onValueChange: (value: string) => void
  placeholderText?: string
  disabled?: boolean

  isOnAudioTranscript?: boolean
  showAudioTranscript?: boolean
  onAudioTranscriptPress?: () => void
  onAudioTranscriptStopPress?: () => void

  sendLoading?: boolean
  sendDisabled?: boolean
  onSendPress?: () => void

  otherInputProps?: Omit<TextInputProps, 'value' | 'onChangeText' | 'placeholder'>
}

const TEXT_INPUT_BASE_HEIGHT = TextStyles['text-14'].lineHeight ?? 16

export const InputFieldChat = observer(function InputFieldChat({
  inputRef,
  sendButtonRef,
  leftIcon,
  leftIconColor,

  value,
  onValueChange,
  placeholderText,
  disabled,

  isOnAudioTranscript,
  showAudioTranscript,
  onAudioTranscriptPress,
  onAudioTranscriptStopPress,

  sendLoading,
  sendDisabled,
  onSendPress,

  otherInputProps,
}: InputFieldChatProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [inputFocused, setInputFocused] = useState(false)
  const textInputEl = useRef(null)

  const borderColor = (() => {
    const defaultColor = color['utility-border-2']
    if (isOnAudioTranscript) return color.accent.brand
    if (inputFocused) return color['utility-border-1']
    return defaultColor
  })()

  const adjustInputHeight = (e: LayoutChangeEvent | NativeSyntheticEvent<TextInputChangeEventData>) => {
    if (Platform.OS !== 'web') return
    // @ts-ignore
    const el = e.target || e.nativeEvent?.target
    textInputEl.current = el
    if (el) {
      // @ts-ignore
      el.style.height = 0
      // @ts-ignore
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight
      const clampedHeight = clamp(newHeight, 0, screenSize.height / 2)
      // @ts-ignore
      el.style.height = `${clampedHeight}px`
    }
  }

  useEffect(() => {
    /**
     * On Web, when the message is cleared programmatically TextInput's onLayout and onChange does not get triggered.
     * This results in the height of the TextInput not resizing correctly.
     * So we are manually applying the height if the TextInput's value clears.
     */
    if (!value.length && Platform.OS === 'web' && textInputEl.current) {
      // @ts-ignore
      textInputEl.current.style.height = `${TEXT_INPUT_BASE_HEIGHT}px`
    }
  }, [value])

  return (
    <BlockView
      size={{ width: '100%', minHeight: 56 }}
      direction="row"
      vAlign="center"
      border={{ radius: 16, width: 1, color: borderColor }}
      padding={{ left: spacing[2], vertical: 12, right: 12 }}
      style={{ opacity: disabled ? 0.24 : 1 }}
    >
      {/* Left Icon. */}
      {!!leftIcon && (
        <MaterialIconOutlined
          name={leftIcon}
          color={leftIconColor ?? color['content-2']}
          size={24}
          style={{ marginRight: spacing[1] }}
        />
      )}

      {/* Input. */}
      <TextInput
        ref={inputRef}
        {...otherInputProps}
        value={value}
        onChangeText={onValueChange}
        maxFontSizeMultiplier={1.2}
        placeholder={placeholderText ?? 'Send a message'}
        placeholderTextColor={color['content-2']}
        multiline
        onFocus={(e) => {
          setInputFocused(true)
          otherInputProps?.onFocus?.(e)
        }}
        onBlur={(e) => {
          setInputFocused(false)
          otherInputProps?.onBlur?.(e)
        }}
        onChange={adjustInputHeight}
        onLayout={adjustInputHeight}
        {...(Device.deviceType === Device.DeviceType.DESKTOP && { onSubmitEditing: onSendPress, blurOnSubmit: true })}
        style={[
          // @ts-ignore
          Platform.OS === 'web' && { outlineWidth: 0, outline: 'none' },
          styles.input,
          { color: color['content-1'] },
          otherInputProps?.style,
        ]}
      />

      {/* Buttons. */}
      <BlockView size={{ height: '100%' }} direction="row" margin={{ left: spacing[1] }} vAlign="flex-end">
        {/* Audio  Transcript. */}
        {showAudioTranscript && (
          <BlockView margin={{ right: spacing[1] }}>
            {isOnAudioTranscript ? (
              <ButtonIcon
                kind="minimal"
                iconName="stop-circle"
                iconColor={color.accent.brand}
                onPress={onAudioTranscriptStopPress}
              />
            ) : (
              <ButtonIcon
                kind="minimal"
                iconName="mic"
                iconColor={color['content-2']}
                onPress={onAudioTranscriptPress}
              />
            )}
          </BlockView>
        )}

        {/* Send. */}
        <ButtonIcon
          buttonRef={sendButtonRef}
          kind="filled"
          filledColor={color.accent.brand}
          iconColor={color['content-4']}
          iconName="arrow-upward"
          onPress={onSendPress}
          loading={sendLoading}
          disabled={sendDisabled ?? isOnAudioTranscript}
        />
      </BlockView>
    </BlockView>
  )
})

type Style = {
  input: TextStyle
}

const styles = StyleSheet.create<Style>({
  input: {
    flex: 1,
    ...TextStyles.robotoRegular,
    ...TextStyles['text-14'],
    padding: 0,
    margin: 0,
  },
})
