import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'

import { BlockView, NavDrawerMenuMobile } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { ChatAppModalRoutesProps } from 'navigation/stack/chat'
import { ChatRoutes } from 'navigation/routes'

import { useMenuItems } from './useMenuItems'

type ChatAppMobileMenuProps = CompositeScreenProps<
  NativeStackScreenProps<ChatAppModalRoutesProps, ChatRoutes.ModalMobileMenu>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ChatAppMobileMenu = observer(function ChatAppMobileMenu({ navigation }: ChatAppMobileMenuProps) {
  const { getMenuItems, menuLoading, userCredit, userType } = useMenuItems()

  const menuItems = getMenuItems()

  const onOverlayPress = () => {
    navigation.goBack()
  }

  const onAddCreditPress = () => {}

  if (menuLoading) {
    return null
  }

  return (
    <BlockView flex>
      <NavDrawerMenuMobile
        userCredit={userCredit ?? 0}
        userType={userType ?? 'USER'}
        onAddCreditPress={onAddCreditPress}
        items={menuItems}
        onMenuClose={onOverlayPress}
      />
    </BlockView>
  )
})
