import { useCallback, useState } from 'react'
import { ActivityIndicator, FlatList, ListRenderItem, TouchableOpacity } from 'react-native'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { BlurView } from 'expo-blur'

import {
  BlockView,
  ButtonIcon,
  ButtonPrimary,
  Chip,
  Collapsible,
  MaterialIconOutlined,
  MaterialIconOutlinedName,
  Text,
  useColor,
  useLayout,
} from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { InAppBrowser } from 'utils/browser'
import { LeaderboardItem } from 'api/data-train/get-leaderboard'

import { getFilterName } from './util'
import { ListControlProps } from './type'

type MobileLeaderboardProps = {
  data: LeaderboardItem[]
  totalCount: number
  onEndReached: () => void
  isFetchingNextPage: boolean
} & ListControlProps

type LeaderboardNavProp = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.Leaderboard>,
    DrawerNavigationProp<ContributeAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

// CONSTANTS.
const RANK_MIN_WIDTH = 32
const POINTS_MIN_WIDTH = 88

export const MobileLeaderboard = observer(function MobileLeaderboard({
  data,
  totalCount,
  onEndReached,
  selectedFilter,
  selectedOrder,
  onOrderSelect,
  isFetchingNextPage,
}: MobileLeaderboardProps) {
  /** States. */
  const { color, addColorTransparency } = useColor()
  const { screenSize, spacing } = useLayout()

  const renderItem = useCallback<ListRenderItem<LeaderboardItem>>(
    ({ index, item }) => {
      const { fullName, twitterUsername, totalPoints, totalContributions, dtvPoints, fudBusterPoints, waasPoints } =
        item
      const rank = (() => {
        if (selectedOrder.direction === 'desc') return index + 1
        return totalCount - index
      })()
      const username = fullName ?? 'Anonymous User'

      const openLinkedTwitter = twitterUsername
        ? () => {
            // Remove @ symbol.
            const trimmedHandle = twitterUsername.replace(/@/g, '')
            InAppBrowser.open(`https://twitter.com/${trimmedHandle}`)
          }
        : undefined

      return (
        <ListItem
          index={index}
          rank={rank}
          username={username}
          onUsernamePress={openLinkedTwitter}
          totalPoints={totalPoints}
          contributions={totalContributions}
          dtv={dtvPoints}
          fudBuster={fudBusterPoints}
          waas={waasPoints}
        />
      )
    },
    [totalCount, selectedOrder.direction]
  )

  const extractListKey = useCallback((_: LeaderboardItem, index: number) => {
    return `mobile-leaderboard-item-${index}`
  }, [])

  return (
    <FlatList
      data={data}
      ListHeaderComponent={
        <ListHeader selectedFilter={selectedFilter} selectedOrder={selectedOrder} onOrderSelect={onOrderSelect} />
      }
      ListFooterComponent={
        isFetchingNextPage ? (
          <BlockView hAlign="flex-start" vAlign="center" padding={spacing[2]} direction="row">
            <ActivityIndicator color={color['content-1']} style={{ marginRight: spacing[2] }} />
            <Text.Roboto size="12" color={color['content-2']}>
              Getting more data...
            </Text.Roboto>
          </BlockView>
        ) : null
      }
      keyExtractor={extractListKey}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.8}
      style={{
        borderWidth: 1,
        borderColor: color['utility-border-1'],
        borderRadius: 16,
        backgroundColor: addColorTransparency(color['utility-surface-2'], 72),
        height: '100%',
        maxHeight: screenSize.height * 0.9,
      }}
    />
  )
})

const ListHeader = observer(function ListHeader({
  selectedFilter,
  selectedOrder,
  onOrderSelect,
}: Pick<ListControlProps, 'selectedFilter' | 'selectedOrder' | 'onOrderSelect'>) {
  /** States. */
  const navigation = useNavigation<LeaderboardNavProp>()
  const { color } = useColor()
  const { spacing } = useLayout()

  const filterName = getFilterName(selectedFilter)

  const onFilterPress = () => {
    navigation.setParams({
      showTimeFilter: true,
    })
  }

  return (
    <BlockView>
      {/* Header. */}
      <BlurView intensity={40} tint="dark" style={{ backgroundColor: color['utility-surface-2'] }}>
        <BlockView direction="row" padding={spacing[2]} vAlign="center">
          <Text.Gridular size="28" color={color['content-1']} style={{ flex: 1 }}>
            leaderboard
          </Text.Gridular>

          <ButtonPrimary
            size="button-2"
            kind="filled"
            label={filterName}
            applyMinWidth={false}
            onPress={onFilterPress}
          />
        </BlockView>
      </BlurView>

      {/* Table Header. */}
      <BlurView intensity={100} tint="dark" style={{ backgroundColor: color['alpha-white-2'] }}>
        <BlockView direction="row" padding={spacing[2]} vAlign="center">
          {/* Rank. */}
          <BlockView size={{ minWidth: RANK_MIN_WIDTH }} hAlign="center" vAlign="center" margin={{ right: spacing[1] }}>
            <Text.RobotoMono uppercase size="12" color={color['content-2']}>
              Rank
            </Text.RobotoMono>
          </BlockView>

          {/* User. */}
          <BlockView flex>
            <Text.RobotoMono uppercase size="12" color={color['content-2']}>
              User
            </Text.RobotoMono>
          </BlockView>

          {/* Points. */}
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={() => {
              onOrderSelect({
                orderBy: 'totalPoints',
                direction: (() => {
                  if (selectedOrder.orderBy !== 'totalPoints') return 'desc'
                  return selectedOrder.direction === 'desc' ? 'asc' : 'desc'
                })(),
              })
            }}
          >
            <BlockView
              direction="row"
              size={{ width: POINTS_MIN_WIDTH }}
              hAlign="flex-end"
              vAlign="center"
              margin={{ right: spacing[1] }}
            >
              <Text.RobotoMono uppercase size="12" color={color['content-2']}>
                Points
              </Text.RobotoMono>

              {selectedOrder.orderBy === 'totalPoints' && (
                <MaterialIconOutlined
                  name={selectedOrder.direction === 'desc' ? 'arrow-downward' : 'arrow-upward'}
                  color={color['content-2']}
                  size={16}
                  style={{ marginLeft: 4 }}
                />
              )}
            </BlockView>
          </TouchableOpacity>

          {/* Toggle. */}
          {/* Hiding it fow now. */}
          <BlockView style={{ opacity: 0 }}>
            <ButtonIcon kind="minimal" iconName="expand" iconColor={color['content-1']} />
          </BlockView>
        </BlockView>
      </BlurView>
    </BlockView>
  )
})

type ListItemProps = {
  index: number
  rank: number
  username: string
  onUsernamePress: (() => void) | undefined
  totalPoints: number
  contributions: number
  dtv: number
  fudBuster: number
  waas: number
}

const ListItem = observer(function ListItem({
  index,
  rank,
  username,
  onUsernamePress,
  totalPoints,
  contributions,
  dtv,
  fudBuster,
  waas,
}: ListItemProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const [expanded, setExpanded] = useState(false)

  const useLighterColor = index % 2 === 0

  return (
    <BlockView
      size={{ width: '100%' }}
      padding={spacing[2]}
      backgroundColor={useLighterColor ? color['utility-surface-2'] : color['alpha-white-2']}
    >
      {/* Summary. */}
      <BlockView direction="row" vAlign="center">
        {/* Rank. */}
        <BlockView size={{ minWidth: RANK_MIN_WIDTH }} hAlign="center" vAlign="center" margin={{ right: spacing[1] }}>
          <Text.RobotoMono uppercase size="14" color={color.accent.brand}>
            {rank}
          </Text.RobotoMono>
        </BlockView>

        {/* User. */}
        <BlockView flex vAlign="center" direction="row">
          {/* Avatar. */}
          <BlockView
            size={32}
            border={{ radius: 16 }}
            backgroundColor={color['alpha-white-8']}
            vAlign="center"
            hAlign="center"
            margin={{ right: spacing[1] }}
          >
            <Text.RobotoMono size="16" color={color['content-2']} uppercase>
              {username.charAt(0).toLocaleUpperCase()}
            </Text.RobotoMono>
          </BlockView>

          {/* Name. */}
          <Text.Roboto
            color={color['content-1']}
            strong
            size="14"
            numberOfLines={2}
            onPress={onUsernamePress}
            underline={!!onUsernamePress}
            style={{ flex: 1 }}
          >
            {username}
          </Text.Roboto>
        </BlockView>

        {/* Total Points. */}
        <BlockView size={{ minWidth: POINTS_MIN_WIDTH }} hAlign="flex-end" margin={{ right: spacing[1] }}>
          <Chip kind="outlined" label={totalPoints.toLocaleString()} />
        </BlockView>

        {/* Expand Toggle. */}
        <ButtonIcon
          kind="minimal"
          iconName={expanded ? 'expand-less' : 'expand-more'}
          iconColor={color['content-1']}
          onPress={() => setExpanded((prev) => !prev)}
        />
      </BlockView>

      {/* Detailed. */}
      <Collapsible expanded={expanded} contentContainerStyle={{ width: '100%' }}>
        <ListItemDetailItem iconName="arrow-selector-tool" title="Contributions" point={contributions} />
        <ListItemDetailItem iconName="verified" title={`Don't trust verify`} point={dtv} />
        <ListItemDetailItem iconName="group" title="We are all Satoshi" point={waas} />
        <ListItemDetailItem iconName="skull" title="Fudbuster" point={fudBuster} />
      </Collapsible>
    </BlockView>
  )
})

type ListItemDetailItemProps = {
  iconName: MaterialIconOutlinedName
  title: string
  point: number
}

const ListItemDetailItem = observer(function ListItemDetailItem({ iconName, title, point }: ListItemDetailItemProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView size={{ width: '100%' }} direction="row" vAlign="center" margin={{ top: spacing[2] }}>
      <BlockView size={{ width: RANK_MIN_WIDTH }} hAlign="center" margin={{ right: spacing[1] }}>
        <MaterialIconOutlined name={iconName} size={24} color={color['content-2']} />
      </BlockView>

      <BlockView flex margin={{ right: spacing[1] }}>
        <Text.Roboto size="14" color={color['content-2']}>
          {title}
        </Text.Roboto>
      </BlockView>

      <BlockView size={{ minWidth: POINTS_MIN_WIDTH }} hAlign="flex-end" margin={{ right: spacing[1] }}>
        <Chip kind="outlined" label={point.toLocaleString()} />
      </BlockView>

      <BlockView style={{ opacity: 0 }}>
        <ButtonIcon kind="minimal" iconName="chevron-right" iconColor={color['content-1']} />
      </BlockView>
    </BlockView>
  )
})
