import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'

import { BlockView, List, useLayout } from 'core'
import { AccountModalRoutesProps } from 'navigation/stack/account'
import { AccountRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'

type SecurityTabProps = {
  isEmailConnected: boolean
  connectedEmailAddress: string | null
  isLightningConnected: boolean
  isNostrConnected: boolean
}

type NavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<AccountModalRoutesProps, AccountRoutes.MyAccount>,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const SecurityTab = observer(function SecurityTab({
  isEmailConnected,
  connectedEmailAddress,
  isLightningConnected,
  isNostrConnected,
}: SecurityTabProps) {
  /** States. */
  const navigation = useNavigation<NavigationProps>()
  const { spacing } = useLayout()

  return (
    <BlockView flex size={{ width: '100%' }} padding={{ bottom: spacing[4] }}>
      {/* Email Account. */}

      <List
        kind="action"
        label="Email"
        subtext={(() => {
          if (!isEmailConnected) return `Connect your email`
          return connectedEmailAddress ?? 'Connected'
        })()}
        actionButton={{
          kind: 'outlined',
          applyMinWidth: false,
          label: isEmailConnected ? 'Remove' : 'Connect',
          disabled: isEmailConnected,
          onPress: () => {
            if (isEmailConnected) return
            navigation.navigate(AccountRoutes.ConnectEmail)
          },
        }}
      />

      {/* Lightning Account. */}
      <BlockView margin={{ vertical: spacing[5] }}>
        <List
          kind="action"
          label="Lightning"
          subtext={(() => {
            if (!isLightningConnected) return `Connect with a Lightning Wallet`
            return 'Connected'
          })()}
          actionButton={{
            kind: 'outlined',
            applyMinWidth: false,
            label: isLightningConnected ? 'Remove' : 'Connect',
            disabled: isLightningConnected,
            onPress: () => {
              if (isLightningConnected) return
              navigation.navigate(AccountRoutes.ConnectLightning)
            },
          }}
        />
      </BlockView>

      {/* Nostr Account. */}
      <List
        kind="action"
        label="Nostr"
        subtext={(() => {
          if (!isNostrConnected) return `Connect with Nostr`
          return 'Connected'
        })()}
        actionButton={{
          kind: 'outlined',
          applyMinWidth: false,
          label: isNostrConnected ? 'Remove' : 'Connect',
          disabled: isNostrConnected,
          onPress: () => {
            if (isNostrConnected) return
            navigation.navigate(AccountRoutes.ConnectNostr)
          },
        }}
      />
    </BlockView>
  )
})
