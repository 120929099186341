import { request } from 'api/request'

export type VerifyOtpData = {
  token: string
}

export type VerifyOtpVariables = {
  otpId: string
  code: string
}

export const verifyOtp = ({ otpId, code }: VerifyOtpVariables): Promise<VerifyOtpData> => {
  return request.post('v1/account/verify-otp', {
    data: {
      otpId,
      code,
    },
  })
}
