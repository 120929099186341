import { request } from 'api/request'

/**
 * Only ONE parameter must be provided in a request.
 */
export type SubmitBenchmarkActionVariables = {
  rankedAnswers?: {
    [key: string]: string
  }
  customAnswer?: string
}

export const submitBenchmarkAction = (benchmarkId: string, variables: SubmitBenchmarkActionVariables) => {
  return request.postWithRawResponse(`v1/data-cleanup/benchmark/${benchmarkId}/submit`, {
    data: variables,
  })
}
