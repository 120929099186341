import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import {
  BlockView,
  ButtonPrimary,
  Chip,
  FormField,
  InputFieldState,
  Text,
  showSnackbar,
  useColor,
  useLayout,
} from 'core'
import { AccountModalRoutesProps } from 'navigation/stack/account'
import { AccountRoutes, PaymentRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { InAppBrowser } from 'utils/browser'
import { updateAccount } from 'api/user/update-account'

import { MyAccountAction } from '../Action'

type ProfileTabProps = {
  username: string
  userBalance: number
  isContributor: boolean
}

type UpdateAccountParams = {
  username: string
}

type InputState = {
  state: InputFieldState
  errorMessage: string
}

type NavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<AccountModalRoutesProps, AccountRoutes.MyAccount>,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const ProfileTab = observer(function ProfileTab({ username, isContributor, userBalance }: ProfileTabProps) {
  /** States. */
  const queryClient = useQueryClient()
  const navigation = useNavigation<NavigationProps>()
  const { color } = useColor()
  const { spacing } = useLayout()
  const [usernameValue, setUsernameValue] = useState(username)
  const [usernameInputState, setUsernameInputState] = useState<InputState>({ state: 'default', errorMessage: '' })

  const onUpdateFail = (errorType?: 'ALREADY_EXIST') => {
    if (errorType === 'ALREADY_EXIST') {
      const errorMessage = `That username has been taken. Please choose another.`

      setUsernameInputState({
        errorMessage,
        state: 'error',
      })
      showSnackbar({
        kind: 'error',
        label: errorMessage,
        leftIcon: 'error',
      })
      return
    }

    // Default Error handling.
    showSnackbar({
      kind: 'error',
      label: `Unable to update your profile. Please try again later.`,
      leftIcon: 'error',
    })
  }

  /** Update Account mutation. */
  const updateAccountMutation = useMutation(
    ({ username }: UpdateAccountParams) => {
      return updateAccount({
        fullName: username,
      })
    },
    {
      onSuccess: async (response) => {
        // Succeeded.
        if (response.status === 200) {
          queryClient.refetchQueries({
            queryKey: 'get-account-me',
          })
          showSnackbar({
            kind: 'success',
            label: `Successfully updated profile.`,
            leftIcon: 'check-circle',
          })
          return
        }

        const json = await response.json()
        if ('message' in json && json?.message === 'Username already exists') {
          onUpdateFail('ALREADY_EXIST')
          return
        }

        // Unknown error.
        onUpdateFail()
      },
      onError: onUpdateFail,
    }
  )

  const updatedUsername = !!usernameValue.length && username !== usernameValue

  const onSavePress = () => {
    if (!updatedUsername || updateAccountMutation.isLoading) return

    updateAccountMutation.mutate({
      username: usernameValue,
    })
  }

  return (
    <BlockView flex size={{ width: '100%' }}>
      {/* Username Input. */}
      <FormField
        label="Username"
        value={usernameValue}
        onValueChange={(text) => {
          if (usernameInputState.state !== 'default') {
            setUsernameInputState({
              state: 'default',
              errorMessage: '',
            })
          }
          setUsernameValue(text.trim())
        }}
        placeholderText="Your unique @handle"
        otherInputProps={{
          autoCorrect: false,
          autoCapitalize: 'none',
        }}
        inputState={usernameInputState.state}
        supportLabel={usernameInputState.errorMessage}
      />

      {/* Withdraw or Become Contributor. */}
      <BlockView
        size={{ width: '100%' }}
        border={{ radius: 16, color: color['utility-border-1'], width: 1 }}
        padding={12}
        hAlign="center"
        margin={{ top: spacing[5] }}
      >
        {/* Chip. */}
        {isContributor ? (
          <Chip
            backgroundColor={color['alpha-white-8']}
            leftIcon="electric-bolt"
            leftIconColor={color.accent.brand}
            label={`${userBalance.toLocaleString()} sats`}
            disabled
          />
        ) : (
          <Chip
            backgroundColor={color['alpha-white-8']}
            leftIcon="construction"
            leftIconColor={color.accent.grape}
            label="CONTRIBUTOR"
            labelColor={color.accent.grape}
            disabled
          />
        )}

        {/* Description. */}
        <Text.Roboto color={color['content-2']} size="14" style={{ textAlign: 'center', marginVertical: spacing[2] }}>
          {isContributor
            ? `We’ll generate a LNURL that you can scan with your wallet or click to copy the code.`
            : `Help gather, clean, label and enhance the data used to train Satoshi while earning sats for your contributions!`}
        </Text.Roboto>

        {/* Action. */}
        <BlockView size={{ width: '100%' }}>
          {isContributor ? (
            <ButtonPrimary
              kind="outlined"
              size="button-2"
              label="Withdraw"
              onPress={() => {
                navigation.navigate(PaymentRoutes.WithdrawBalance)
              }}
            />
          ) : (
            <ButtonPrimary
              kind="outlined"
              size="button-2"
              label="Become a Contributor"
              onPress={() => {
                InAppBrowser.open('https://www.spiritofsatoshi.ai/help-us-train-satoshi')
              }}
            />
          )}
        </BlockView>
      </BlockView>

      {/* Save Action. */}
      <MyAccountAction
        buttons={[
          {
            kind: 'filled',
            applyMinWidth: false,
            label: `Save`,
            onPress: onSavePress,
            disabled: !updatedUsername,
            loading: updateAccountMutation.isLoading,
          },
        ]}
      />
    </BlockView>
  )
})
