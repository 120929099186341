import { request } from 'api/request'

export type Conversation = {
  id: string
  title: string
  lastActivityAt: string // date string.
  model: string | null
}

export type GetConversationsData = Conversation[]

export const getConversations = (search?: string | undefined): Promise<GetConversationsData> => {
  return request.get(`v1/chat/conversations${search ? `?search=${search}` : ''}`)
}
