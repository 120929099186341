import { Pressable, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import { MaterialIconOutlined, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

export type NavControlActionProps = {
  leftIcon?: MaterialIconOutlinedName
  label: string
  labelStyle?: StyleProp<TextStyle>
  onPress: () => void

  trailing?: React.ReactNode

  containerStyle?: StyleProp<ViewStyle>
}

enum ButtonState {
  Default = 0,
  HoverIn = 1,
  PressedIn = 2,
}

export const NavControlAction = observer(function NavControlAction({
  leftIcon,
  label,
  labelStyle,
  onPress,

  trailing,
  containerStyle,
}: NavControlActionProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const buttonState = useSharedValue(ButtonState.Default)

  const showHoverInState = () => {
    buttonState.value = withTiming(ButtonState.HoverIn, { duration: 150 })
  }
  const showHoverOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }
  const showPressedState = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const showPressedOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = 'transparent'

    return {
      backgroundColor: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, color['alpha-white-4'], color['alpha-white-2']]
      ),
    }
  })

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showHoverInState}
      onHoverOut={showHoverOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
    >
      <Animated.View
        style={[
          styles.container,
          containerAnimatedStyle,
          { borderWidth: 1, borderColor: color['utility-border-1'] },
          containerStyle,
        ]}
      >
        {!!leftIcon && <MaterialIconOutlined name={leftIcon} size={24} color={color.accent.brand} />}

        <Text.Roboto
          size="14"
          color={color.accent.brand}
          style={[styles.label, { marginLeft: spacing[1] }, labelStyle]}
        >
          {label}
        </Text.Roboto>

        {trailing}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
  label: TextStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    minHeight: 44,
    padding: 10,
  },
  label: {
    flex: 1,
  },
})
