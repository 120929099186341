import { LayoutChangeEvent, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import Animated, { useAnimatedStyle, useSharedValue, useWorkletCallback, withTiming } from 'react-native-reanimated'

export type CollapsibleProps = {
  expanded: boolean
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
  contentContainerStyle?: StyleProp<ViewStyle>
}

export const Collapsible = observer(function Collapsible({
  expanded,
  children,
  style,
  contentContainerStyle,
}: CollapsibleProps) {
  /** States. */
  const animatedHeight = useSharedValue(0)

  const onLayout = useWorkletCallback((event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout

    if (height > 0 && animatedHeight.value !== height) {
      animatedHeight.value = height
    }
  }, [])

  const containerAnimatedStyle = useAnimatedStyle<ViewStyle>(() => {
    return {
      height: expanded ? withTiming(animatedHeight.value) : withTiming(0),
    }
  })

  return (
    <Animated.View style={[containerAnimatedStyle, { overflow: 'hidden' }, style]}>
      <Animated.View onLayout={onLayout} style={[styles.contentContainer, contentContainerStyle]}>
        {children}
      </Animated.View>
    </Animated.View>
  )
})

type Style = {
  contentContainer: ViewStyle
}

const styles = StyleSheet.create<Style>({
  contentContainer: {
    position: 'absolute',
  },
})
