import { useCallback } from 'react'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'

import { BlockView, Modal2, showSnackbar, useColor, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { ChatAppModalRoutesProps } from 'navigation/stack/chat'
import { ChatRoutes } from 'navigation/routes'
import { GetConversationsData } from 'api/chat/get-conversations'
import { deleteConversation } from 'api/chat/delete-conversation'

type ChatAppDeleteConversationProps = CompositeScreenProps<
  NativeStackScreenProps<ChatAppModalRoutesProps, ChatRoutes.ModalDeleteConversation>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ChatAppDeleteConversation = observer(function ChatAppDeleteConversation({
  navigation,
  route,
}: ChatAppDeleteConversationProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { color } = useColor()
  const { spacing } = useLayout()

  useFocusEffect(
    useCallback(() => {
      if (route.params?.conversationId) return

      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.navigate(ChatRoutes.Conversation, { id: 'new-chat' })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  /** Delete Conversation Mutation. */
  const deleteConversationMutation = useMutation(
    (conversationId: string) => {
      return deleteConversation(conversationId)
    },
    {
      onSuccess: (response, conversationId) => {
        if (response.status === 200) {
          const getConversationsData = queryClient.getQueryData<GetConversationsData>('get-conversations')

          if (!getConversationsData) return

          const filteredConversationsData = getConversationsData
            .filter((d) => d.id !== conversationId)
            .sort((a, b) => new Date(b.lastActivityAt).getTime() - new Date(a.lastActivityAt).getTime())

          queryClient.setQueryData<GetConversationsData | undefined>('get-conversations', filteredConversationsData)

          if (!filteredConversationsData.length) {
            return navigation.navigate(ChatRoutes.Conversation, { id: 'new-chat' })
          }
          return navigation.navigate(ChatRoutes.Conversation, { id: filteredConversationsData[0].id })
        }
        showSnackbar({
          kind: 'error',
          label: 'Something went wrong. Please try again later.',
          leftIcon: 'error',
        })
      },
      onError: () => {
        showSnackbar({
          kind: 'error',
          label: 'Something went wrong. Please try again later.',
          leftIcon: 'error',
        })
      },
    }
  )

  const onCancelPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(ChatRoutes.Conversation, { id: 'new-chat' })
    }
  }

  const onDeletePress = () => {
    if (!route.params?.conversationId || deleteConversationMutation.isLoading) return

    return deleteConversationMutation.mutate(route.params.conversationId)
  }

  /** Can't render anything. */
  if (!route.params?.conversationId) {
    return null
  }

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <Modal2
        title="Edit title"
        showTitleClose={false}
        text="Are you sure you want to delete this chat?"
        actions={[
          {
            label: `Cancel`,
            kind: 'outlined',
            onPress: onCancelPress,
            applyMinWidth: false,
          },
          {
            label: `Delete`,
            labelColor: color.state.critical,
            kind: 'outlined',
            onPress: onDeletePress,
            loading: deleteConversationMutation.isLoading,
            applyMinWidth: false,
          },
        ]}
      />
    </BlockView>
  )
})
