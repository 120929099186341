import { request } from 'api/request'

export enum SubmitQuestionActionType {
  AddAnswer = 'ADD_ANSWER',
}

export type SubmitQuestionActionVariables = {
  action: SubmitQuestionActionType
  answer: string
}

export const submitQuestionAction = (questionId: string, variables: SubmitQuestionActionVariables) => {
  return request.postWithRawResponse(`v1/data-cleanup/${questionId}/submit`, {
    data: variables,
  })
}
