import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'

import { BlockView, FormField, showSnackbar } from 'core'
import { updateAccount } from 'api/user/update-account'

import { MyAccountAction } from '../Action'

type SocialTabProps = {
  twitterHandle: string | null
}

type UpdateAccountParams = {
  twitterHandle: string
}

export const SocialTab = observer(function SocialTab({ twitterHandle: originalTwitterHandle }: SocialTabProps) {
  /** States. */
  const queryClient = useQueryClient()
  const [twitterHandle, setTwitterHandle] = useState(originalTwitterHandle ?? '')

  const onUpdateFail = () => {
    showSnackbar({
      kind: 'error',
      label: `Unable to update your profile. Please try again later.`,
      leftIcon: 'error',
    })
  }

  /** Update Account mutation. */
  const updateAccountMutation = useMutation(
    (params: UpdateAccountParams) => {
      let updatedHandle = params.twitterHandle
      if (updatedHandle.charAt(0) !== '@') {
        updatedHandle = `@${updatedHandle}`
      }

      return updateAccount({
        twitterUsername: updatedHandle,
      })
    },
    {
      onSuccess: (response, params) => {
        // Succeeded.
        if (response.status === 200) {
          queryClient.refetchQueries({
            queryKey: 'get-account-me',
          })
          showSnackbar({
            kind: 'success',
            label: `Successfully updated profile.`,
            leftIcon: 'check-circle',
          })

          const handle = (() => {
            if (params.twitterHandle.charAt(0) !== '@') return `@${params.twitterHandle}`
            return params.twitterHandle
          })()
          setTwitterHandle(handle)
          return
        }
        onUpdateFail()
      },
      onError: onUpdateFail,
    }
  )

  const removeAtSymbol = (handle: string) => {
    if (handle.charAt(0) === '@') return handle.substring(1)
    return handle
  }
  const updatedTwitterHandle = (() => {
    if (!twitterHandle.length) return false
    return removeAtSymbol(twitterHandle) !== removeAtSymbol(originalTwitterHandle ?? '')
  })()

  const onSavePress = () => {
    if (!updatedTwitterHandle || updateAccountMutation.isLoading) return
    updateAccountMutation.mutate({ twitterHandle })
  }

  return (
    <BlockView flex size={{ width: '100%' }}>
      {/* Twitter Handle Input. */}
      <FormField
        label="Twitter"
        value={twitterHandle}
        onValueChange={(text) => setTwitterHandle(text.trim().toLocaleLowerCase())}
        placeholderText="Your twitter handle"
        otherInputProps={{
          autoCorrect: false,
          autoCapitalize: 'none',
        }}
      />

      {/* Save Action. */}
      <MyAccountAction
        buttons={[
          {
            kind: 'filled',
            applyMinWidth: false,
            label: `Save`,
            onPress: onSavePress,
            disabled: !updatedTwitterHandle,
            loading: updateAccountMutation.isLoading,
          },
        ]}
      />
    </BlockView>
  )
})
