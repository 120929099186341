import { makeAutoObservable } from 'mobx'
import { makePersistable, isHydrated, clearPersistedStore } from 'mobx-persist-store'

export type ColorThemeType = 'dark' | 'light'
export class UiStore {
  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'UiStore',
      properties: ['colorTheme'],
    })
  }

  colorTheme: ColorThemeType = 'dark'
  setColorTheme = (theme: ColorThemeType) => {
    this.colorTheme = theme
  }

  get isHydrated() {
    return isHydrated(this)
  }

  reset = () => {
    clearPersistedStore(true)
    this.colorTheme = 'dark'
  }
}
