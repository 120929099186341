import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { BlockView, ButtonFab, ButtonPrimary } from 'core/v2/atoms'

export type DtvSubmitActionType = 'DISCARD' | 'KEEP' | 'SKIP'
export type DtvFooterProps = {
  loadingAction?: DtvSubmitActionType | null
  onDiscardPress: () => void
  onKeepPress: () => void
  onSkipPress: () => void
  onHelpPress: () => void
}

export const DtvFooter = observer(function DtvFooter({
  loadingAction,
  onDiscardPress,
  onKeepPress,
  onSkipPress,
  onHelpPress,
}: DtvFooterProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()

  const isDesktop = screenSize.width >= breakPoints.lg

  const buttonInactiveColor = 'transparent'
  const buttonActiveColor = color['alpha-white-2']
  const buttonBackgroundColors = {
    pressedIn: buttonActiveColor,
    pressedOut: buttonInactiveColor,
  }

  return (
    <BlockView
      size={{ width: '100%' }}
      hAlign="center"
      padding={{ horizontal: spacing[3], top: spacing[2], bottom: spacing[3] }}
    >
      {/* Button Group. */}
      <BlockView
        direction="row"
        border={{ width: 1, color: color['utility-border-2'], radius: 64 }}
        padding={spacing[1]}
        margin={{ bottom: spacing[1] }}
      >
        {/* Discard Button. */}
        <ButtonFab
          label="Discard"
          leftIcon="close"
          labelColor={color.state.critical}
          onPress={onDiscardPress}
          applyMinWidth
          animatedBackgroundColor={buttonBackgroundColors}
          buttonStyle={{ width: 160 }}
          loading={loadingAction === 'DISCARD'}
        />

        {/* Keep Button. */}
        <ButtonFab
          label="Keep"
          leftIcon="check"
          labelColor={color.accent.brand}
          onPress={onKeepPress}
          applyMinWidth
          animatedBackgroundColor={buttonBackgroundColors}
          buttonStyle={{ width: 160 }}
          loading={loadingAction === 'KEEP'}
        />
      </BlockView>

      {/* Skip. */}
      <ButtonPrimary
        size="button-3"
        kind="minimal"
        label={`I'm not sure, skip`}
        leftIcon="redo"
        labelColor={color['content-3']}
        onPress={onSkipPress}
        loading={loadingAction === 'SKIP'}
      />

      {/* Help Button. */}
      {isDesktop && (
        <BlockView hAlign="flex-end" position={{ bottom: spacing[3], right: spacing[3] }}>
          <ButtonFab
            leftIcon="question-mark"
            leftIconStyle={{ marginRight: 0 }}
            labelColor={color['content-2']}
            onPress={onHelpPress}
            applyMinWidth={false}
            buttonStyle={{ width: 56, height: 56, borderWidth: 1, borderColor: color['utility-border-3'] }}
          />
        </BlockView>
      )}
    </BlockView>
  )
})
