import { useState } from 'react'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, ButtonIcon, Text, useColor, useLayout } from 'core'
import { HowToAppRoutesProps, RootAppRoutesProps } from 'navigation/stack/root'
import { HowToModalRoutes } from 'navigation/routes'

type ModalProps = CompositeScreenProps<
  NativeStackScreenProps<HowToAppRoutesProps, HowToModalRoutes.HowToFightFud>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const HowToFightFud = observer(function HowToFightFud({ navigation }: ModalProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [closeModal, setCloseModal] = useState(false)

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="dtv-how-to-modal" type="from-bottom" duration={250}>
            <BlockView
              size={{ width: 440, maxWidth: screenSize.width - spacing[4], maxHeight: screenSize.height - spacing[5] }}
              backgroundColor={color['utility-surface-2']}
              border={{ radius: 24, color: color['utility-border-1'], width: 1 }}
              padding={{ horizontal: spacing[4], top: spacing[3], bottom: spacing[4] }}
            >
              {/* Title. */}
              <BlockView direction="row" size={{ width: '100%' }} vAlign="center" margin={{ bottom: spacing[3] }}>
                <Text.Gridular size="24" color={color['content-1']} style={{ flex: 1, marginRight: spacing[1] }}>
                  How to fight the FUD
                </Text.Gridular>

                <ButtonIcon
                  kind="minimal"
                  iconName="close"
                  iconColor={color['content-2']}
                  onPress={() => setCloseModal(true)}
                />
              </BlockView>

              {/* Content. */}
              <Text.Roboto color={color['content-1']} size="15" strong style={{ marginBottom: spacing[1] }}>
                In these tasks you will play the role of Satoshi, you'll see a FUD question from a user and your job is
                to reply as if you were Satoshi.
              </Text.Roboto>

              <Text.Roboto color={color['content-2']} size="14">
                The more detailed and specific the responses can be, the better! Your replies will help train Satoshi
                and make him better at fighting the FUD!
              </Text.Roboto>
            </BlockView>
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
