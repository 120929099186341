import { makeAutoObservable } from 'mobx'
import { makePersistable, isHydrated, clearPersistedStore } from 'mobx-persist-store'

export class UiToggleStore {
  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'UiStore',
      properties: ['outOfCreditNotice'],
    })
  }

  outOfCreditNotice = true
  setOutOfCreditNotice = (show: boolean) => {
    this.outOfCreditNotice = show
  }

  get isHydrated() {
    return isHydrated(this)
  }

  reset = () => {
    clearPersistedStore(true)
    this.outOfCreditNotice = true
  }
}
