import { request } from 'api/request'

export type NostrLoginData = {
  otpId: string
}

export type NostrLoginVariables = {
  nPub: string
  relay: string
}

export const nostrLogin = ({ nPub, relay }: NostrLoginVariables): Promise<NostrLoginData> => {
  return request.post('v1/account/nostr/login', {
    data: {
      nPub,
      relay,
    },
  })
}
