import { useEffect, useRef } from 'react'
import { ActivityIndicator } from 'react-native'
import { useMutation, useQuery } from 'react-query'
import { observer } from 'mobx-react-lite'
import * as Clipboard from 'expo-clipboard'

import { BlockView, Text, useColor, useLayout, showSnackbar, ButtonPrimary, NeedLightningWallet, QRCode } from 'core'
import { useRootStore } from 'store/root.store'
import { lnurlLogin } from 'api/authentication/lnurl-login'
import { LnurlLoginCheckVariables, lnurlLoginCheck } from 'api/authentication/lnurl-login-check'
import { updateAccount } from 'api/user/update-account'

export const LnurlSignIn = observer(function () {
  /** States. */
  const { authStore } = useRootStore()
  const { color } = useColor()
  const { spacing } = useLayout()
  const pollIntervalRef = useRef<NodeJS.Timeout>()

  /** Update User Compliance Accepted. */
  const updateUserMutation = useMutation(() =>
    updateAccount({
      tcAccept: true,
    })
  )

  /** Lnurl Login check Mutation. */
  const lnurlLoginCheckMutation = useMutation(
    ({ lnAddressId }: LnurlLoginCheckVariables) =>
      lnurlLoginCheck({
        lnAddressId,
      }),
    {
      onSuccess: (response) => {
        if (response.token) {
          /**
           * Update user's `tcAcceptedAt` field.
           * We do not need to wait for it.
           */
          updateUserMutation.mutate()
          authStore.setAuthToken(response.token)
        }
      },
    }
  )

  /** Lnurl Login Query.  */
  const { isLoading, isError, data } = useQuery({
    queryKey: 'lnurl-login',
    queryFn: lnurlLogin,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      if (response.lnAddressId) {
        pollIntervalRef.current = setInterval(() => {
          lnurlLoginCheckMutation.mutate({
            lnAddressId: response.lnAddressId,
          })
        }, 3000)
      }
    },
  })

  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current)
      }
    }
  }, [])

  /** Error state. */
  if (isError) {
    return (
      <BlockView size={{ width: '100%' }} margin={{ top: spacing[2] }} hAlign="center">
        <Text.Roboto size="15" color={color['content-1']}>
          There was an error generating your lightning invoice, please reload and try again.
        </Text.Roboto>
      </BlockView>
    )
  }

  const renderLightningAddress = () => {
    if (isLoading || !data?.lnAddress) {
      return (
        <BlockView size={160} vAlign="center" hAlign="center">
          <ActivityIndicator color={color['content-1']} />
        </BlockView>
      )
    }

    const onCopyToClipboard = async () => {
      await Clipboard.setStringAsync(data.lnAddress)
      showSnackbar({
        label: `Copied to clipboard!`,
      })
    }

    return (
      <BlockView size={{ width: '100%' }}>
        <BlockView hAlign="center" padding={{ bottom: spacing[5] }}>
          <Text.Roboto color={color['content-1']} size="15" strong style={{ textAlign: 'center' }}>
            Scan with a lightning wallet
          </Text.Roboto>

          {/* Need Lightning wallet */}
          <NeedLightningWallet margin={{ top: spacing[1], bottom: spacing[6] }} />

          <QRCode value={data.lnAddress} />
        </BlockView>

        <ButtonPrimary
          kind="outlined"
          size="button-2"
          label="Copy to clipboard"
          rightIcon="content-copy"
          onPress={onCopyToClipboard}
        />
      </BlockView>
    )
  }

  return (
    <BlockView size={{ width: '100%' }} margin={{ top: spacing[2] }} hAlign="center">
      {/* Lightning Address. */}
      <BlockView
        size={{ width: '100%' }}
        hAlign="center"
        backgroundColor={color['utility-surface-3']}
        border={{ radius: 16 }}
        padding={{ top: spacing[4], bottom: spacing[2], horizontal: spacing[2] }}
      >
        {renderLightningAddress()}
      </BlockView>
    </BlockView>
  )
})
