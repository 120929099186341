import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { Text } from '../Text'

export type SegmentProps = {
  label: string
  onPress?: () => void
  activated?: boolean
  disabled?: boolean
  wrapperStyle?: StyleProp<ViewStyle>
}

enum ButtonState {
  PressedOut = 0,
  PressedIn = 1,
}

export const Segment = observer(function Segment({ label, onPress, activated, disabled, wrapperStyle }: SegmentProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const buttonState = useSharedValue(ButtonState.PressedOut)
  const activatedState = useDerivedValue(() => (activated ? 1 : 0), [activated])

  const onHoverIn = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const onHoverOut = () => {
    buttonState.value = withTiming(ButtonState.PressedOut, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle<ViewStyle>(() => {
    if (disabled) return { backgroundColor: 'transparent', opacity: 0.24 }
    return {
      opacity: 1,
      backgroundColor: interpolateColor(activatedState.value, [0, 1], ['transparent', color['content-1']]),
    }
  })

  const textAnimatedStyle = useAnimatedStyle(() => {
    if (disabled) return { color: color['content-1'] }
    if (activated) return { color: color['content-4'] }
    return {
      color: interpolateColor(
        buttonState.value,
        [ButtonState.PressedOut, ButtonState.PressedIn],
        [color['content-2'], color['content-1']]
      ),
    }
  })

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPressIn={onHoverIn}
      onPressOut={onHoverOut}
      style={wrapperStyle}
    >
      <Animated.View
        style={[
          containerAnimatedStyle,
          styles.container,
          { paddingHorizontal: spacing[3], paddingVertical: spacing[1] },
        ]}
      >
        <Text.Roboto size="16" strong color={color['content-2']} numberOfLines={1}>
          <Animated.Text style={textAnimatedStyle}>{label}</Animated.Text>
        </Text.Roboto>
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    borderRadius: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
