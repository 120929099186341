import { Pressable, ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'

import {
  AnimateTransition,
  BlockView,
  ButtonIcon,
  Card2,
  ChatInput,
  ChatOutLoading,
  ChatOutput,
  useColor,
  useLayout,
  UserAvatar,
} from 'core'
import { useRootStore } from 'store/root.store'

import { ConversationMessage } from 'api/chat/get-conversation-messages'

import { Image } from 'expo-image'
import { CreditNotice } from './CreditNotice'

type NewConversationProps = {
  isUserOutOfCredit: boolean
  optimisticUserMessage: ConversationMessage | null
  optimisticSosMessage: ConversationMessage | null

  userFullName: string | null
  messageStreamLoading: boolean
  setMessage: (message: string) => void

  selectedModel?: string | null
}

const REGULAR_QUICK_PROMPTS = [
  'Tell me something about Bitcoin',
  'Who created Bitcoin and why?',
  'What do you think about other cryptos?',
  'Is there a second best crypto?',
  "What's wrong with government currencies?",
  "Isn't some inflation good for the economy?",
  'Why is Bitcoin better than the US dollar?',
  'Why is it important to separate money from the State?',
]

const CODE_SATOSHI_QUICK_PROMPTS = [
  'What is a Miniscript policy?',
  'Teach me some Miniscript basics.',
  'Give me an example of multisig code.',
  'What is a hashlock and how can I create one in miniscript?',
  'What is wrong with this Miniscript policy and(pk(key_1), pk(key_2), pk(key_3))',
]

const TECHNICAL_ASSISTANT_QUICK_PROMPTS = [
  'How do I perform a Liquid swap?',
  'Give me technical instructions on how to unblind a Liquid confidential transaction.',
  'How do I get started installing Elements?',
  'What metadata is needed to be linked during asset issuance on Liquid?',
  'Why does my block signing keep failing in Elements?',
]

export const NewConversation = observer(function NewConversation({
  isUserOutOfCredit,
  optimisticUserMessage,
  optimisticSosMessage,

  userFullName,
  messageStreamLoading,
  setMessage,

  selectedModel,
}: NewConversationProps) {
  /** States. */
  const { uiToggleStore } = useRootStore()
  const { screenSize, spacing } = useLayout()
  const { color } = useColor()
  const { modalToggleStore } = useRootStore()

  const bannerCloseStyle: any = {
    position: 'absolute',
    right: '100px',
    'z-index': '1000',
  }

  const onBannerPress = () => {
    // Open link to new tab
    window.open('https://btc-tc.com/collections/collaborative-collection', '_blank')
  }

  const onBannerClosePress = () => {
    modalToggleStore.setBtcTradingCardsBanner(false)
  }

  // Quick Prompts.
  if (!optimisticUserMessage && !optimisticSosMessage) {
    // Define source of prompts
    const quickPrompts = (() => {
      if (selectedModel === 'CODE_SATOSHI') return CODE_SATOSHI_QUICK_PROMPTS

      if (selectedModel === 'TECHNICAL_ASSISTANT') return TECHNICAL_ASSISTANT_QUICK_PROMPTS

      return REGULAR_QUICK_PROMPTS
    })()

    return (
      <BlockView
        flex
        size={{ width: '100%', maxWidth: 745 }}
        vAlign="flex-end"
        padding={{ horizontal: screenSize.width <= 745 ? spacing[2] : 0 }}
      >
        {!!isUserOutOfCredit && uiToggleStore.outOfCreditNotice && (
          <AnimateTransition key="chat-out-of-credit-notice" type="from-bottom" style={{ marginBottom: spacing[2] }}>
            <CreditNotice />
          </AnimateTransition>
        )}

        {modalToggleStore.showBtcTradingCardsBanner && (
          <BlockView flex margin={{ right: spacing[1] }} size={{ maxWidth: '100%' }}>
            <Pressable onPress={onBannerPress}>
              <BlockView style={bannerCloseStyle}>
                <ButtonIcon
                  kind="minimal"
                  iconName="close"
                  iconColor={color['content-2']}
                  onPress={onBannerClosePress}
                />
              </BlockView>
              <Image
                source={require('assets/banners/btc-trading-cards.png')}
                style={{ height: 250, width: '100%' }}
                contentFit="contain"
                contentPosition="center"
                transition={300}
              />
            </Pressable>
          </BlockView>
        )}

        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            alignItems: 'stretch',
            justifyContent: 'center',
          }}
          style={{ flexGrow: 0 }}
        >
          {quickPrompts.map((prompt, index) => {
            const lastItem = quickPrompts.length - 1 === index
            return (
              <BlockView
                key={`quick-prompt-${index}`}
                flex
                margin={{ right: lastItem ? 0 : spacing[1] }}
                size={{ maxWidth: 200 }}
              >
                <Card2
                  kind="outlined"
                  subtitle={prompt}
                  subtitleStrong
                  wrapperStyle={{ flex: 1 }}
                  containerStyle={{ flex: 1 }}
                  onCardPress={() => setMessage(prompt)}
                />
              </BlockView>
            )
          })}
        </ScrollView>
      </BlockView>
    )
  }

  // Optimistic Messages.
  return (
    <BlockView
      flex
      size={{ width: '100%', maxWidth: 745 }}
      padding={{ horizontal: screenSize.width <= 745 ? spacing[2] : 0 }}
    >
      {!!optimisticUserMessage && (
        <AnimateTransition key="new-chat-optimistic-user-message" type="from-bottom">
          <ChatInput message={optimisticUserMessage.text} avatar={<UserAvatar fullName={userFullName} />} />
        </AnimateTransition>
      )}
      {/* Message Loading. */}
      {messageStreamLoading && <ChatOutLoading />}
      {!!optimisticSosMessage && (
        <AnimateTransition key="new-chat-optimistic-sos-message" type="from-bottom">
          <ChatOutput
            conversationId=""
            messageId={optimisticSosMessage.id}
            message={optimisticSosMessage.text}
            showReaction={false}
            reaction={null}
            replySource={null}
            streamText
            showPlayback={false}
            voiceUrl={null}
            selectedModel={selectedModel}
          />
        </AnimateTransition>
      )}
    </BlockView>
  )
})
