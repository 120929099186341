import { useCallback, useMemo, useRef, useState } from 'react'

import { request } from 'api/request'

export type SendMessageVariables = {
  text: string
  model?: string
  conversationId?: string // If not passed in, it will create a new conversation.
  streaming?: boolean
}

type OnStreamParams = {
  text: string
  isStreaming: boolean
}

type OnDoneParams = {
  fromStreaming: boolean
}

type StreamInterceptor = {
  onStream?: (params: OnStreamParams) => void
  onDone?: (params: OnDoneParams) => void
  onError?: (error: any) => void
}

type StreamState = {
  loading: boolean
  streaming: boolean
  data: string | null
  error: Error | null
  done: boolean
}

export const useSendMessageStreaming = (interceptor?: StreamInterceptor) => {
  const controller = useRef(new AbortController())
  const [state, setState] = useState<StreamState>({
    loading: false,
    streaming: false,
    data: null,
    error: null,
    done: false,
  })

  const startStreaming = useCallback(
    async (params: SendMessageVariables) => {
      setState((prev) => ({ ...prev, loading: true }))
      try {
        const response = await request.baseRequest('POST', `v1/chat`, {
          data: { ...params, streaming: true },
        })

        if (!response.ok || !response.body) {
          throw new Error(`Bad request: ${response.statusText}`)
        }

        const responseContentType = response.headers.get('content-type')

        /**
         * If response is in JSON, it's a strict response (not streaming).
         */
        if (responseContentType?.includes('application/json')) {
          try {
            const json = await response.json()
            if ('answer' in json) {
              interceptor?.onStream?.({
                text: json.answer?.text ?? '',
                isStreaming: false,
              })
              interceptor?.onDone?.({
                fromStreaming: false,
              })
              setState((prevState) => ({
                ...prevState,
                loading: false,
                data: json.answer?.text ?? '',
                done: true,
                streaming: false,
              }))
            }
            return
          } catch (error) {
            interceptor?.onError?.(error)
            setState((prevState) => ({
              ...prevState,
              loading: false,
              done: true,
              streaming: false,
            }))
            return
          }
        }

        /**
         * Proceed with Streaming logic.
         */
        setState((prev) => ({ ...prev, loading: false }))

        let text = ''
        const reader = response.body.getReader()

        setState((prev) => ({ ...prev, streaming: true }))
        while (true) {
          const { value, done } = await reader.read()

          if (done) {
            // Streaming done.
            interceptor?.onDone?.({
              fromStreaming: true,
            })
            setState((prevState) => ({ ...prevState, done: true, streaming: false }))
            break
          }

          const data = new TextDecoder().decode(value)
          text += data

          interceptor?.onStream?.({
            text,
            isStreaming: true,
          })
          setState((prevState) => ({ ...prevState, data: prevState.data + data }))
        }
      } catch (error: any) {
        interceptor?.onError?.(error)
        if (error?.name !== 'AbortError') {
          setState((prevState) => ({ ...prevState, error, streaming: false, loading: false }))
        }
      }
    },
    [interceptor]
  )

  return useMemo(
    () => ({
      startStreaming,
      loading: state.loading,
      streaming: state.streaming,
      data: state.data,
      done: state.done,
      abort: () => controller.current && controller.current.abort(),
    }),
    [startStreaming, state.data, state.streaming, state.loading, state.done]
  )
}
