import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, Chip, MaterialIconOutlined, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { TextSize } from 'core/v2/atoms/Text/type'

export type NavControlDisplayProps = {
  leftIcon?: MaterialIconOutlinedName
  label: string
  labelSize?: TextSize

  chipLeftIcon?: MaterialIconOutlinedName
  chipLabel?: string
  onChipPress?: () => void

  containerStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
}

export const NavControlDisplay = observer(function NavControlDisplay({
  leftIcon,
  label,
  labelSize,

  chipLeftIcon,
  chipLabel,
  onChipPress,

  containerStyle,
  labelStyle,
}: NavControlDisplayProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView
      direction="row"
      vAlign="center"
      border={{ radius: 8, width: 1, color: color['utility-border-1'] }}
      size={{ minHeight: 44 }}
      padding={10}
      style={containerStyle}
    >
      {!!leftIcon && (
        <MaterialIconOutlined
          name={leftIcon}
          size={24}
          color={color['content-2']}
          style={{ marginRight: spacing[1] }}
        />
      )}

      <Text.RobotoMono
        size={labelSize ?? '20'}
        color={color['content-1']}
        style={[styles.label, { marginRight: spacing[1] }, labelStyle]}
      >
        {label}
      </Text.RobotoMono>

      {!!chipLabel && <Chip kind="tonal" leftIcon={chipLeftIcon} label={chipLabel} onPress={onChipPress} />}
    </BlockView>
  )
})

type Style = {
  container: ViewStyle
  label: TextStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    minHeight: 44,
    padding: 10,
  },
  label: {
    flex: 1,
  },
})
