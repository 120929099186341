import { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonPrimary, Modal1, Modal1Tab, Text, useColor, useLayout } from 'core'
import { GetAccountMeData } from 'api/user/get-account-me'

import { MyAccountTab } from '../type'
import { ProfileTab } from './Profile'
import { SecurityTab } from './Security'
import { SocialTab } from './Social'

type MyAccountTemplateProps = {
  onClose: () => void
  selectedTab: MyAccountTab
  onTabSelect: (tab: MyAccountTab) => void

  userData: GetAccountMeData | null
  reloading: boolean
  onReload: () => void
}

export const MyAccountTemplate = observer(function MyAccountTemplate({
  onClose,
  selectedTab,
  onTabSelect,
  userData,
  reloading,
  onReload,
}: MyAccountTemplateProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  const tabs = useMemo<Modal1Tab<MyAccountTab>[]>(
    () => [
      {
        id: MyAccountTab.Profile,
        title: `Profile`,
      },
      {
        id: MyAccountTab.Security,
        title: `Security`,
      },
      {
        id: MyAccountTab.Social,
        title: `Social`,
      },
    ],
    []
  )

  const renderTabScreen = useCallback(
    (tab: Modal1Tab<MyAccountTab>) => {
      const renderTab = () => {
        // Error state.
        if (!userData) {
          return (
            <BlockView hAlign="center" padding={{ bottom: spacing[2] }}>
              <Text.Roboto
                size="15"
                color={color['content-1']}
                style={{ textAlign: 'center', marginBottom: spacing[3] }}
              >
                Hodl on there Bitcoiner, there was an issue loading your data. Please reload and try again.
              </Text.Roboto>

              <ButtonPrimary label="Reload" onPress={onReload} loading={reloading} size="button-2" />
            </BlockView>
          )
        }

        const { fullName, balance, type } = userData

        if (tab.id === MyAccountTab.Profile) {
          return (
            <ProfileTab
              isContributor={type === 'CONTRIBUTOR'}
              username={fullName ?? ''}
              userBalance={balance?.total ?? 0}
            />
          )
        }

        if (tab.id === MyAccountTab.Security) {
          const { email, hasLightningAuth, hasNostrAuth } = userData

          return (
            <SecurityTab
              isEmailConnected={!!email}
              connectedEmailAddress={email}
              isLightningConnected={hasLightningAuth}
              isNostrConnected={hasNostrAuth}
            />
          )
        }

        if (tab.id === MyAccountTab.Social) {
          const { twitterUsername } = userData

          return <SocialTab twitterHandle={twitterUsername} />
        }

        return null
      }
      return <BlockView padding={{ horizontal: spacing[4], top: spacing[5] }}>{renderTab()}</BlockView>
    },
    [spacing, userData, color, onReload, reloading]
  )

  return (
    <Modal1
      title="Account"
      showTitleClose
      onTitleClosePress={onClose}
      tabs={tabs}
      selectedTabId={selectedTab}
      onTabSelect={onTabSelect}
      renderTabScreen={renderTabScreen}
    />
  )
})
