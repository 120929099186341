import { useCallback } from 'react'
import { ActivityIndicator, FlatList, ListRenderItem, TouchableOpacity } from 'react-native'
import { observer } from 'mobx-react-lite'
import { BlurView } from 'expo-blur'

import { BlockView, ButtonPrimary, Chip, MaterialIconOutlined, Text, useColor, useLayout } from 'core'
import { InAppBrowser } from 'utils/browser'
import { LeaderboardFilter, LeaderboardItem, LeaderboardOrderBy } from 'api/data-train/get-leaderboard'

import { ListControlProps } from './type'
import { getFilterName } from './util'

type DesktopLeaderboardProps = {
  data: LeaderboardItem[]
  totalCount: number
  onEndReached: () => void
  isFetchingNextPage: boolean
} & ListControlProps

// CONSTANTS.
const RANK_MIN_WIDTH = 32

export const DesktopLeaderboard = observer(function DesktopLeaderboard({
  data,
  totalCount,
  onEndReached,
  selectedFilter,
  selectedOrder,
  onFilterSelect,
  onOrderSelect,
  isFetchingNextPage,
}: DesktopLeaderboardProps) {
  /** States. */
  const { color, addColorTransparency } = useColor()
  const { screenSize, spacing } = useLayout()

  const renderItem = useCallback<ListRenderItem<LeaderboardItem>>(
    ({ index, item }) => {
      const { fullName, twitterUsername, totalPoints, totalContributions, dtvPoints, fudBusterPoints, waasPoints } =
        item
      const rank = (() => {
        if (selectedOrder.direction === 'desc') return index + 1
        return totalCount - index
      })()
      const username = fullName ?? 'Anonymous User'
      const useLighterColor = index % 2 === 0

      const points = [totalPoints, totalContributions, dtvPoints, waasPoints, fudBusterPoints]

      const openLinkedTwitter = twitterUsername
        ? () => {
            // Remove @ symbol.
            const trimmedHandle = twitterUsername.replace(/@/g, '')
            InAppBrowser.open(`https://twitter.com/${trimmedHandle}`)
          }
        : undefined

      return (
        <BlockView
          size={{ width: '100%' }}
          padding={spacing[2]}
          backgroundColor={useLighterColor ? color['utility-surface-2'] : color['alpha-white-2']}
        >
          <BlockView direction="row" vAlign="center">
            {/* Rank. */}
            <BlockView
              size={{ minWidth: RANK_MIN_WIDTH }}
              hAlign="center"
              vAlign="center"
              margin={{ right: spacing[2] }}
            >
              <Text.RobotoMono color={color.accent.brand} size="14" uppercase>
                {rank}
              </Text.RobotoMono>
            </BlockView>

            {/* Username. */}
            <BlockView flex={1} vAlign="center" direction="row">
              {/* Avatar. */}
              <BlockView
                size={32}
                border={{ radius: 16 }}
                backgroundColor={color['alpha-white-8']}
                vAlign="center"
                hAlign="center"
                margin={{ right: spacing[1] }}
              >
                <Text.RobotoMono size="16" color={color['content-2']} uppercase>
                  {username.charAt(0).toLocaleUpperCase()}
                </Text.RobotoMono>
              </BlockView>

              {/* Name. */}
              <Text.Roboto
                color={color['content-1']}
                strong
                size="14"
                numberOfLines={2}
                onPress={openLinkedTwitter}
                underline={!!openLinkedTwitter}
                style={{ flex: 1 }}
              >
                {username}
              </Text.Roboto>
            </BlockView>

            <BlockView flex={2} direction="row" vAlign="center">
              {points.map((point, index) => {
                return (
                  <BlockView key={`desktop-leaderboard-point-${index}`} flex hAlign="center">
                    <Chip kind="outlined" label={point.toLocaleString()} />
                  </BlockView>
                )
              })}
            </BlockView>
          </BlockView>
        </BlockView>
      )
    },
    [color, spacing, selectedOrder.direction, totalCount]
  )

  const extractListKey = useCallback((_: LeaderboardItem, index: number) => {
    return `desktop-leaderboard-item-${index}`
  }, [])

  return (
    <FlatList
      data={data}
      ListHeaderComponent={
        <ListHeader
          selectedFilter={selectedFilter}
          onFilterSelect={onFilterSelect}
          selectedOrder={selectedOrder}
          onOrderSelect={onOrderSelect}
        />
      }
      ListFooterComponent={
        isFetchingNextPage ? (
          <BlockView hAlign="flex-start" vAlign="center" padding={spacing[2]} direction="row">
            <ActivityIndicator color={color['content-1']} style={{ marginRight: spacing[2] }} />
            <Text.Roboto size="12" color={color['content-2']}>
              Getting more data...
            </Text.Roboto>
          </BlockView>
        ) : null
      }
      keyExtractor={extractListKey}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.8}
      style={{
        borderWidth: 1,
        borderColor: color['utility-border-1'],
        borderRadius: 16,
        backgroundColor: addColorTransparency(color['utility-surface-2'], 72),
        height: '100%',
        maxHeight: screenSize.height * 0.9,
      }}
    />
  )
})

const ListHeader = observer(function ListHeader({
  selectedFilter,
  onFilterSelect,
  selectedOrder,
  onOrderSelect,
}: ListControlProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  const filters: LeaderboardFilter[] = ['overall', 'daily', 'weekly', 'monthly']
  const pointsHeader: { id: LeaderboardOrderBy; label: string }[] = [
    {
      id: 'totalPoints',
      label: `Points`,
    },
    {
      id: 'totalContributions',
      label: `Contribution`,
    },
    {
      id: 'dtvPoints',
      label: `DTV`,
    },
    {
      id: 'waasPoints',
      label: `WAAS`,
    },
    {
      id: 'fudBusterPoints',
      label: `FuD`,
    },
  ]

  return (
    <BlockView>
      {/* Header. */}
      <BlurView intensity={40} tint="dark" style={{ backgroundColor: color['utility-surface-2'] }}>
        <BlockView direction="row" vAlign="center" padding={spacing[2]}>
          <Text.Gridular size="28" color={color['content-1']} style={{ flex: 1 }}>
            leaderboard
          </Text.Gridular>

          <BlockView direction="row" vAlign="center" margin={{ left: spacing[3] }}>
            {filters.map((filter, index) => {
              const isSelected = filter === selectedFilter
              const isLast = filters.length - 1 === index
              return (
                <ButtonPrimary
                  key={`desktop-leaderboard-filter-${filter}-${index}`}
                  kind={isSelected ? 'filled' : 'outlined'}
                  size="button-2"
                  label={getFilterName(filter)}
                  onPress={() => onFilterSelect(filter)}
                  applyMinWidth={false}
                  buttonStyle={{ marginRight: isLast ? 0 : spacing[1] }}
                />
              )
            })}
          </BlockView>
        </BlockView>
      </BlurView>

      {/* Table Header. */}
      <BlurView intensity={100} tint="dark" style={{ backgroundColor: color['alpha-white-2'] }}>
        <BlockView direction="row" padding={spacing[2]} vAlign="center">
          {/* Rank. */}
          <BlockView size={{ minWidth: RANK_MIN_WIDTH }} hAlign="center" vAlign="center" margin={{ right: spacing[2] }}>
            <Text.RobotoMono uppercase size="12" color={color['content-2']}>
              Rank
            </Text.RobotoMono>
          </BlockView>

          {/* Username. */}
          <BlockView flex={1}>
            <Text.RobotoMono uppercase size="12" color={color['content-2']}>
              User
            </Text.RobotoMono>
          </BlockView>

          {/* Points. */}
          <BlockView flex={2} direction="row" vAlign="center">
            {pointsHeader.map(({ id, label }) => {
              const isCurrentlySorting = selectedOrder.orderBy === id

              return (
                <TouchableOpacity
                  key={`desktop-leaderboard-point-header-${id}`}
                  activeOpacity={0.8}
                  onPress={() => {
                    onOrderSelect({
                      orderBy: id,
                      direction: (() => {
                        if (!isCurrentlySorting) return 'desc'
                        return selectedOrder.direction === 'desc' ? 'asc' : 'desc'
                      })(),
                    })
                  }}
                  style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}
                >
                  <Text.RobotoMono uppercase size="12" color={color['content-2']}>
                    {label}
                  </Text.RobotoMono>

                  {isCurrentlySorting && (
                    <MaterialIconOutlined
                      name={selectedOrder.direction === 'desc' ? 'arrow-downward' : 'arrow-upward'}
                      color={color['content-2']}
                      size={16}
                      style={{ marginLeft: 4 }}
                    />
                  )}
                </TouchableOpacity>
              )
            })}
          </BlockView>
        </BlockView>
      </BlurView>
    </BlockView>
  )
})
