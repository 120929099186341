import { ActivityIndicator } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, Text, useColor, useLayout } from 'core'

export const ChatConversationLoading = observer(function ChatConversationLoading() {
  /** States, */
  const { spacing } = useLayout()
  const { color } = useColor()

  return (
    <BlockView flex hAlign="center" vAlign="center">
      <ActivityIndicator color={color['content-2']} />
      <Text.Roboto color={color['content-2']} size="16" style={{ textAlign: 'center', marginTop: spacing[1] }}>
        Loading more content...
      </Text.Roboto>
    </BlockView>
  )
})
