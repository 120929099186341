import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { BlockView, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'

export type MenuItem = {
  id: string
  label: string
  labelColor?: string
  subtext?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  onPress?: () => void
  disabled?: boolean
}

export const MENU_ITEM_HEIGHT = 44

export type MenuProps = {
  menuStyle?: StyleProp<ViewStyle>
  items: MenuItem[]
  itemStyle?: StyleProp<ViewStyle>
}

export const Menu = observer(function Menu({ menuStyle, items, itemStyle }: MenuProps) {
  /** States. */
  const { color } = useColor()

  return (
    <BlockView
      size={{ width: '100%' }}
      backgroundColor={color['utility-surface-3']}
      border={{ radius: 8, color: color['utility-border-3'], width: 1 }}
      style={[{ overflow: 'hidden' }, menuStyle]}
    >
      {items.map((menu, index) => {
        const isLast = items.length - 1 === index

        return <Item key={`menu-item-${menu.id}-${index}`} {...menu} isLast={isLast} style={itemStyle} />
      })}
    </BlockView>
  )
})

type ItemProps = MenuItem & {
  isLast: boolean
  style?: StyleProp<ViewStyle>
}

enum ButtonState {
  Default = 0,
  HoverIn = 1,
  PressedIn = 2,
}

const Item = observer(function Item({
  label,
  labelColor,
  subtext,
  iconLeft,
  iconRight,
  disabled,
  onPress,
  isLast,
  style,
}: ItemProps) {
  const { color } = useColor()
  const buttonState = useSharedValue(ButtonState.Default)

  const showHoverInState = () => {
    buttonState.value = withTiming(ButtonState.HoverIn, { duration: 150 })
  }
  const showHoverOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }
  const showPressedState = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const showPressedOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = 'transparent'

    if (disabled) return { backgroundColor: defaultColor }

    return {
      backgroundColor: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, color['alpha-white-4'], color['alpha-white-2']]
      ),
    }
  })

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showHoverInState}
      onHoverOut={showHoverOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
      disabled={disabled}
    >
      <Animated.View
        style={[
          styles.container,
          containerAnimatedStyle,
          { opacity: disabled ? 0.24 : 1 },
          !isLast && { borderBottomColor: color['utility-border-2'], borderBottomWidth: 1 },
          style,
        ]}
      >
        {/* Left Icon. */}
        {!!iconLeft && <BlockView margin={{ right: 4 }}>{iconLeft}</BlockView>}

        {/* Label. */}
        <Text.Roboto
          color={labelColor ?? color['content-1']}
          size="14"
          strong
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{ flex: 1 }}
        >
          {label}
        </Text.Roboto>

        {/* Subtext. */}
        {!!subtext && (
          <Text.Roboto color={color['content-2']} size="14">
            {subtext}
          </Text.Roboto>
        )}

        {/* Right Icon. */}
        {!!iconRight && <BlockView margin={{ left: 4 }}>{iconRight}</BlockView>}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: 'row',
    height: MENU_ITEM_HEIGHT,
    alignItems: 'center',
  },
})
