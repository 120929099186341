import { observer } from 'mobx-react-lite'

import {
  BlockView,
  ButtonPrimary,
  ButtonPrimaryProps,
  MaterialIconOutlined,
  MaterialIconOutlinedName,
  Text,
} from 'core/v2/atoms'
import { useColor } from 'core/v2/color'

export type PopoverProps = {
  kind?: 'default'
  width?: number
  leftIcon?: MaterialIconOutlinedName
  label: string
  labelColor?: string
  actionButton?: ButtonPrimaryProps
}

export const Popover = observer(function Popover({
  kind = 'default',
  width,
  leftIcon,
  label,
  labelColor,
  actionButton,
}: PopoverProps) {
  /** States. */
  const { color } = useColor()

  const _labelColor = (() => {
    return color['content-4']
  })()
  const mainColor = labelColor ?? _labelColor

  const backgroundColor = (() => {
    return color['content-1']
  })()

  return (
    <BlockView
      direction="row"
      vAlign="center"
      padding={6}
      backgroundColor={backgroundColor}
      size={{ width: width ?? 250 }}
      border={{ radius: 10, width: 1, color: color['utility-border-2'] }}
    >
      {/* Left Icon. */}
      {!!leftIcon && <MaterialIconOutlined name={leftIcon} color={mainColor} size={24} style={{ marginRight: 6 }} />}

      {/* Label. */}
      <Text.Roboto color={mainColor} size="13" strong style={{ flex: 1, marginRight: 6 }}>
        {label}
      </Text.Roboto>

      {/* Action. */}
      {!!actionButton && <ButtonPrimary applyMinWidth={false} size="button-3" {...actionButton} />}
    </BlockView>
  )
})
