import { request } from 'api/request'

export enum SubmitTrainDataActionType {
  Keep = 'KEEP',
  Discard = 'DISCARD',
}

export type SubmitTrainDataActionVariables = {
  action: SubmitTrainDataActionType
  contentEdit?: string
  answerEdit?: string
}

export const submitTrainDataAction = (dataId: string, variables: SubmitTrainDataActionVariables) => {
  return request.postWithRawResponse(`v1/data-cleanup/${dataId}/submit`, {
    data: variables,
  })
}
