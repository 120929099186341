import { useCallback } from 'react'
import { ImageRequireSource } from 'react-native'

type LightningWallet = {
  name: string
  urlScheme: string
  icon: ImageRequireSource
}

const lightningWallets: LightningWallet[] = [
  {
    name: 'Amber',
    urlScheme: 'getamber',
    icon: require('assets/lightning-wallet-icons/amber.png'),
  },
  {
    name: `Wallet of Satoshi`,
    urlScheme: 'walletofsatoshi',
    icon: require('assets/lightning-wallet-icons/wallet-of-satoshi.png'),
  },
  {
    name: 'Blue Wallet',
    urlScheme: 'bluewallet:lightning',
    icon: require('assets/lightning-wallet-icons/blue-wallet.png'),
  },
  {
    name: 'Blixt',
    urlScheme: 'blixt',
    icon: require('assets/lightning-wallet-icons/blixt.png'),
  },
  {
    name: 'Breez',
    urlScheme: 'breez',
    icon: require('assets/lightning-wallet-icons/breez.png'),
  },
  {
    name: 'Cash App',
    urlScheme: 'cashme',
    icon: require('assets/lightning-wallet-icons/cash-app.png'),
  },
  {
    name: 'Muun',
    urlScheme: 'muun',
    icon: require('assets/lightning-wallet-icons/muun.png'),
  },
  {
    name: 'Open Bitcoin Wallet',
    urlScheme: 'blw',
    icon: require('assets/lightning-wallet-icons/obw.png'),
  },
  {
    name: 'Phoenix',
    urlScheme: 'phoenix',
    icon: require('assets/lightning-wallet-icons/phoenix.png'),
  },
]

export const useLightningWallets = () => {
  const getLightningWalletLinks = useCallback((lnAddress: string) => {
    return lightningWallets.map((wallet) => ({
      name: wallet.name,
      link: `${wallet.urlScheme}:${lnAddress}`,
      icon: wallet.icon,
    }))
  }, [])

  return [getLightningWalletLinks]
}
