import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'

import { useColor } from 'core'
import {
  AccountRoutes,
  AuthenticationRoutes,
  CampaignRoutes,
  ChatRoutes,
  ContributeRoutes,
  HowToModalRoutes,
  PaymentRoutes,
} from 'navigation/routes'
import { useRootStore } from 'store/root.store'
import { getAccountMe } from 'api/user/get-account-me'

/** Routes. */
// Authentication Routes.
import { AuthenticationSignIn } from 'routes/Authentication/SignIn'
import { AuthMethod } from 'routes/Authentication/SignIn/type'
import { AuthenticationVerifyCode } from 'routes/Authentication/VerifyCode'
// Contributor Tool Routes.
import { DiscardDTVPromptModal } from 'routes/Contribute/DiscardDTVPromptModal'
import { HowStatisticsWork } from 'routes/HowTo/HowStatisticsWork'
import { HowToCleanupData } from 'routes/HowTo/HowToCleanupData'
import { HowToFightFud } from 'routes/HowTo/HowToFightFud'
import { HowToReplyAsSatoshi } from 'routes/HowTo/HowToReplyAsSatoshi'
import { HowToNoSecondBest } from 'routes/HowTo/HowToNoSecondBest'
// Chat Routes.
import { ChatAppMobileMenu } from 'routes/Chat/MobileMenu'
import { ChatAppUpdateConversation } from 'routes/Chat/UpdateConversation'
import { ChatAppDeleteConversation } from 'routes/Chat/DeleteConversation'
// Payment Routes.
import { PaymentAddCredit } from 'routes/Payment/AddCredit'
import { PaymentWithdrawBalance } from 'routes/Payment/WithdrawBalance'
import { PaymentGetDonation } from 'routes/Payment/GetDonation'
// Account Routes.
import { MyAccount } from 'routes/Account/MyAccount'
import { AccountConnectEmail } from 'routes/Account/ConnectEmail'
import { AccountConnectLightning } from 'routes/Account/ConnectLightning'
import { AccountConnectNostr } from 'routes/Account/ConnectNostr'
import { AccountVerifyCode } from 'routes/Account/VerifyCode'
// Campaign Routes.
import { CampaignGeyser } from 'routes/Campaign/Geyser'

/** Navigation Stacks. */
import { ChatApp, ChatAppModalRoutesProps, ChatAppRoutesProps } from 'navigation/stack/chat'
import { ContributeApp, ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { PaymentAppModalRoutesProps } from 'navigation/stack/payment'
import { AccountModalRoutesProps } from 'navigation/stack/account'

export type RootAppRoutesProps = {
  [AuthenticationRoutes.Login]?: {
    method?: AuthMethod
  }
  [AuthenticationRoutes.VerifyCode]: {
    otpId: string
    emailAddress?: string
    isNostr?: boolean

    fromCodeResent?: 'true' // If the screen was refreshed from Resending Code.
  }
} & ChatAppRoutesProps &
  ChatAppModalRoutesProps &
  ContributeAppRoutesProps &
  HowToAppRoutesProps &
  PaymentAppModalRoutesProps &
  AccountModalRoutesProps &
  CampaignAppRoutesProps

export type HowToAppRoutesProps = {
  [HowToModalRoutes.HowStatisticsWork]?: undefined
  [HowToModalRoutes.HowToCleanupData]?: undefined
  [HowToModalRoutes.HowToFightFud]?: undefined
  [HowToModalRoutes.HowToReplyAsSatoshi]?: undefined
  [HowToModalRoutes.HowToNoSecondBest]?: undefined
}

export type CampaignAppRoutesProps = {
  [CampaignRoutes.Geyser]?: undefined
}

export const RootAppStack = createNativeStackNavigator<RootAppRoutesProps>()

export const RootApp = observer(function RooApp() {
  /** States. */
  const { color } = useColor()
  const { authStore } = useRootStore()

  /** Get account me Query. */
  const userAccountQuery = useQuery({
    enabled: !!authStore.authToken,
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const renderNavigator = () => {
    /** User is not authenticated. */
    if (!authStore.authToken) {
      // Show Login screen.
      return (
        <>
          <RootAppStack.Screen
            name={AuthenticationRoutes.Login}
            component={AuthenticationSignIn}
            options={{ title: `Spirit of Satoshi - Sign in` }}
          />
          <RootAppStack.Group
            screenOptions={{
              presentation: 'transparentModal',
              contentStyle: {
                backgroundColor: color['alpha-black-64'],
              },
            }}
          >
            <RootAppStack.Screen
              name={AuthenticationRoutes.VerifyCode}
              component={AuthenticationVerifyCode}
              options={{
                title: `Spirit of Satoshi - Verify code`,
              }}
            />
          </RootAppStack.Group>
        </>
      )
    }

    const isUserContributor = userAccountQuery.data?.type === 'CONTRIBUTOR'

    return (
      <>
        {/* Chat App. */}
        <RootAppStack.Screen name={ChatRoutes.Root} component={ChatApp} />
        <RootAppStack.Group
          screenOptions={{
            presentation: 'transparentModal',
            contentStyle: {
              backgroundColor: color['alpha-black-64'],
            },
          }}
        >
          <RootAppStack.Screen
            name={ChatRoutes.ModalMobileMenu}
            component={ChatAppMobileMenu}
            options={{ title: 'Chat menu' }}
          />
          <RootAppStack.Screen
            name={ChatRoutes.ModalUpdateConversation}
            component={ChatAppUpdateConversation}
            options={{ title: 'Update conversation' }}
          />
          <RootAppStack.Screen
            name={ChatRoutes.ModalDeleteConversation}
            component={ChatAppDeleteConversation}
            options={{ title: 'Delete conversation' }}
          />
        </RootAppStack.Group>

        {/* Contribute App. */}
        {isUserContributor && (
          <>
            <RootAppStack.Screen name={ContributeRoutes.Root} component={ContributeApp} />
            <RootAppStack.Group>
              <RootAppStack.Screen
                name={ContributeRoutes.ModalDiscardDTVPrompt}
                component={DiscardDTVPromptModal}
                options={{
                  title: 'Discard Confirm',
                  presentation: 'transparentModal',
                  contentStyle: {
                    backgroundColor: color['alpha-black-64'],
                  },
                }}
              />
            </RootAppStack.Group>
          </>
        )}

        {/* How To Modals. */}
        <RootAppStack.Group
          screenOptions={{
            presentation: 'transparentModal',
            contentStyle: {
              backgroundColor: color['alpha-black-64'],
            },
          }}
        >
          <RootAppStack.Screen
            name={HowToModalRoutes.HowStatisticsWork}
            component={HowStatisticsWork}
            options={{ title: `How Statistics Work` }}
          />
          <RootAppStack.Screen
            name={HowToModalRoutes.HowToCleanupData}
            component={HowToCleanupData}
            options={{ title: `How to Cleanup Data` }}
          />
          <RootAppStack.Screen
            name={HowToModalRoutes.HowToFightFud}
            component={HowToFightFud}
            options={{ title: `How to Fight Fud` }}
          />
          <RootAppStack.Screen
            name={HowToModalRoutes.HowToReplyAsSatoshi}
            component={HowToReplyAsSatoshi}
            options={{ title: `How to Reply as Satoshi` }}
          />
          <RootAppStack.Screen
            name={HowToModalRoutes.HowToNoSecondBest}
            component={HowToNoSecondBest}
            options={{ title: `There is no second best` }}
          />

          {/* Payment App Modals. */}
          <RootAppStack.Screen
            name={PaymentRoutes.AddCreditModal}
            component={PaymentAddCredit}
            options={{ title: `Add credit` }}
          />
          <RootAppStack.Screen
            name={PaymentRoutes.WithdrawBalance}
            component={PaymentWithdrawBalance}
            options={{ title: `Withdraw` }}
          />
          <RootAppStack.Screen
            name={PaymentRoutes.GetDonationModal}
            component={PaymentGetDonation}
            options={{ title: `Support Satoshi` }}
          />
        </RootAppStack.Group>

        {/* Account App Modals. */}
        <RootAppStack.Group
          screenOptions={{
            presentation: 'transparentModal',
            contentStyle: {
              backgroundColor: color['alpha-black-64'],
            },
          }}
        >
          <RootAppStack.Screen name={AccountRoutes.MyAccount} component={MyAccount} options={{ title: `My Account` }} />
          <RootAppStack.Screen
            name={AccountRoutes.ConnectEmail}
            component={AccountConnectEmail}
            options={{ title: `My Account - Connect Email` }}
          />
          <RootAppStack.Screen
            name={AccountRoutes.ConnectLightning}
            component={AccountConnectLightning}
            options={{ title: `My Account - Connect Lightning` }}
          />
          <RootAppStack.Screen
            name={AccountRoutes.ConnectNostr}
            component={AccountConnectNostr}
            options={{ title: `My Account - Connect Nostr` }}
          />
          <RootAppStack.Screen
            name={AccountRoutes.VerifyCode}
            component={AccountVerifyCode}
            options={{ title: `My Account - Verify Code` }}
          />
        </RootAppStack.Group>

        {/* Campaign Modals. */}
        <RootAppStack.Group
          screenOptions={{
            presentation: 'transparentModal',
            contentStyle: {
              backgroundColor: color['alpha-black-64'],
            },
          }}
        >
          <RootAppStack.Screen
            name={CampaignRoutes.Geyser}
            component={CampaignGeyser}
            options={{ title: `Geyser Campaign` }}
          />
        </RootAppStack.Group>
      </>
    )
  }

  return (
    <RootAppStack.Navigator
      screenOptions={{
        headerShown: false,
        headerShadowVisible: false,
        contentStyle: {
          backgroundColor: color['utility-surface-2'],
        },
        headerStyle: {
          backgroundColor: color['utility-surface-2'],
        },
        headerTintColor: color['content-1'],
      }}
    >
      {renderNavigator()}
    </RootAppStack.Navigator>
  )
})
