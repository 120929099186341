import { RefObject } from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { Image } from 'expo-image'

import { BlockView } from 'core/v2/atoms'
import { ButtonIcon } from 'core/v2/atoms/Button'
import { useLayout } from 'core/v2/layout'

import DefaultLogo from 'assets/logo/default-logo/default-logo.svg'
import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'

export type NavHeaderProps = {
  kind?: 'default' | 'minimal'
  showAppButton?: boolean
  onAppButtonPress?: () => void
  showAppButtonRef?: RefObject<View>
}

export const NavHeader = observer(function NavHeader({
  kind = 'default',
  showAppButton = false,
  onAppButtonPress,
  showAppButtonRef,
}: NavHeaderProps) {
  const { spacing } = useLayout()

  const iconSource = kind === 'minimal' ? MinimalLogo : DefaultLogo

  return (
    <BlockView direction="row" size={{ width: '100%', minHeight: 32 }} vAlign="center" margin={{ bottom: spacing[1] }}>
      <BlockView flex margin={{ right: spacing[2] }}>
        <Image source={iconSource} contentFit="contain" style={{ width: 123, height: 18 }} />
      </BlockView>

      {!!showAppButton && (
        <ButtonIcon buttonRef={showAppButtonRef} kind="minimal" iconName="apps" onPress={onAppButtonPress} />
      )}
    </BlockView>
  )
})
