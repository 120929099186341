import { useCallback, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, useColor, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AccountModalRoutesProps } from 'navigation/stack/account'
import { AccountRoutes } from 'navigation/routes'
import { getAccountMe } from 'api/user/get-account-me'

import { MyAccountTab } from './type'
import { MyAccountTemplate } from './Template'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<AccountModalRoutesProps, AccountRoutes.MyAccount>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const MyAccount = observer(function MyAccount({ navigation, route }: ScreenProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState<MyAccountTab>(route?.params?.tab ?? MyAccountTab.Profile)

  /** User Account Me Query. */
  const { isLoading, data, isRefetching, refetch } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const onTabSelect = useCallback(
    (tab: MyAccountTab) => {
      setSelectedTab(tab)
      navigation.setParams({ tab })
    },
    [navigation]
  )

  /** Loading State. */
  if (isLoading) {
    return (
      <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
        <ActivityIndicator color={color['content-1']} />
      </BlockView>
    )
  }

  return (
    <BlockView
      flex
      hAlign="center"
      vAlign="center"
      padding={{ horizontal: spacing[2] }}
      style={{ position: 'relative' }}
    >
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="my-account-data" type="from-bottom" duration={250}>
            <MyAccountTemplate
              selectedTab={selectedTab}
              onTabSelect={onTabSelect}
              onClose={() => setCloseModal(true)}
              userData={data ?? null}
              reloading={isRefetching}
              onReload={refetch}
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
