import { ColorTheme, addColorTransparency } from './shared'

export const DarkColor: ColorTheme = {
  'utility-overlay': addColorTransparency('#101010', 80),
  'utility-surface-1': '#0A0A0A',
  'utility-surface-2': '#101010',
  'utility-surface-3': '#181818',
  'utility-surface-4': '#1E1E1E',
  'utility-surface-5': '#242424',
  'utility-border-1': addColorTransparency('#F3F5F7', 16),
  'utility-border-2': addColorTransparency('#F3F5F7', 8),
  'utility-border-3': addColorTransparency('#F3F5F7', 4),

  'content-1': '#F3F5F7',
  'content-2': '#777777',
  'content-3': '#444444',
  'content-4': '#101010',

  'always-black': '#000000',
  'alpha-black-2': addColorTransparency('#000000', 2),
  'alpha-black-4': addColorTransparency('#000000', 4),
  'alpha-black-8': addColorTransparency('#000000', 8),
  'alpha-black-16': addColorTransparency('#000000', 16),
  'alpha-black-32': addColorTransparency('#000000', 32),
  'alpha-black-64': addColorTransparency('#000000', 64),

  'always-white': '#FFFFFF',
  'alpha-white-2': addColorTransparency('#FFFFFF', 2),
  'alpha-white-4': addColorTransparency('#FFFFFF', 4),
  'alpha-white-8': addColorTransparency('#FFFFFF', 8),
  'alpha-white-16': addColorTransparency('#FFFFFF', 16),
  'alpha-white-32': addColorTransparency('#FFFFFF', 32),
  'alpha-white-64': addColorTransparency('#FFFFFF', 64),

  accent: {
    brand: '#51DA4C',
    blueberry: '#4971FC',
    cherry: '#F3425F',
    grape: '#9360F7',
    lemon: '#F7B928',
    lime: '#45BD62',
    lychee: '#FF66BF',
    water: '#54C7EC',
    date: '#B79773',
    peach: '#FB724B',
    papaya: '#FF9831',
  },

  state: {
    neutral: '#2E89FF',
    positive: '#31A24C',
    attention: '#F7B928',
    critical: '#FF3040',
  },
}
