import { createIconSet } from '@expo/vector-icons'

// @ts-ignore
import font from '../../../../../assets/custom-icon/material-symbols-outlined/MaterialSymbolsOutlined.ttf'
import glyphMap from '../../../../../assets/custom-icon/material-symbols-outlined/MaterialSymbolsOutlined.json'

const MaterialIconOutlined = createIconSet(glyphMap, 'material-outlined', font)

type MaterialIconOutlinedName = keyof typeof glyphMap

export { MaterialIconOutlined, MaterialIconOutlinedName }
