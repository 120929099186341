import { useCallback } from 'react'
import { Pressable } from 'react-native'
import { observer } from 'mobx-react-lite'
import { DragEndParams, NestableDraggableFlatList, RenderItem } from 'react-native-draggable-flatlist'
import { Image } from 'expo-image'

import { BlockView, Text, useColor, useLayout } from 'core'
import { BenchmarkAnswer } from 'api/data-train/get-benchmark-question'

import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'

type AnswerProps = {
  answers: BenchmarkAnswer[]
  onAnswerOrderChange: (answers: BenchmarkAnswer[]) => void
}

export const Answer = observer(function Answer({ answers, onAnswerOrderChange }: AnswerProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  const onDragEnd = useCallback(
    ({ data }: DragEndParams<BenchmarkAnswer>) => {
      onAnswerOrderChange(data)
    },
    [onAnswerOrderChange]
  )

  const renderItem = useCallback<RenderItem<BenchmarkAnswer>>(
    ({ item, drag, getIndex, isActive }) => {
      const index = getIndex()
      const currentOrder = index !== undefined ? index + 1 : ''

      return (
        <Pressable
          onPressIn={drag}
          disabled={isActive}
          style={{
            flex: 1,
          }}
        >
          <BlockView
            size={{ width: '100%' }}
            direction="row"
            vAlign="flex-start"
            padding={spacing[2]}
            backgroundColor={color['alpha-white-4']}
            border={{ radius: 16 }}
          >
            {/* Satoshi Logo. */}
            <BlockView
              size={40}
              border={{ radius: 20 }}
              backgroundColor={color['utility-surface-1']}
              vAlign="center"
              hAlign="center"
              margin={{ right: spacing[1] }}
            >
              <Image source={MinimalLogo} style={{ width: 24, height: 12 }} />
            </BlockView>

            <BlockView flex padding={10} margin={{ right: spacing[1] }}>
              <Text.RobotoMono color={color['content-2']} size="12" uppercase style={{ marginBottom: spacing[1] }}>
                {`Response #${currentOrder}`}
              </Text.RobotoMono>

              <Text.Roboto size="16" color={color['content-1']}>
                {item.text}
              </Text.Roboto>
            </BlockView>
          </BlockView>
        </Pressable>
      )
    },
    [color, spacing]
  )

  return (
    <BlockView margin={{ top: spacing[1] }} size={{ width: '100%' }}>
      <NestableDraggableFlatList
        data={answers}
        renderItem={renderItem}
        keyExtractor={(item) => `benchmark-answer-${item.id}`}
        onDragEnd={onDragEnd}
        ItemSeparatorComponent={() => <BlockView backgroundColor="transparent" size={{ height: spacing[1] }} />}
      />
    </BlockView>
  )
})
