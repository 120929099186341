import { request } from 'api/request'

export type CreditPackage = {
  id: string
  price: number // number of sats.
  credits: number // credit purchase amount
  name: string
  saving?: string | null // eg 20%
}

export type GetCreditPackagesData = CreditPackage[]

export const getCreditPackages = (): Promise<GetCreditPackagesData> => {
  return request.get(`v1/credits`)
}
