import { observer } from 'mobx-react-lite'
import { ActivityIndicator } from 'react-native'

import { BlockView, Text, useColor, useLayout } from 'core'

export const FudBusterLoadingTemplate = observer(function FudBusterLoadingTemplate() {
  /** States, */
  const { spacing } = useLayout()
  const { color } = useColor()

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ top: spacing[8] }}>
      <ActivityIndicator color={color['content-1']} />
      <Text.Roboto size="12" color={color['content-2']} style={{ textAlign: 'center', marginTop: spacing[2] }}>
        Loading more content...
      </Text.Roboto>
    </BlockView>
  )
})
