import { request } from 'api/request'

export type ChatModels = {
  id: string
  name: string
  description: string
  enabled: boolean
  icon?: any
}

export type GetUserChatModelsData = ChatModels[]

export const getUserChatModels = (): Promise<GetUserChatModelsData> => {
  return request.get('v1/chat/models')
}
