import React from 'react'
import { observer } from 'mobx-react-lite'

import { BlockView, Text } from 'core/v2/atoms'
import { useLayout } from 'core/v2/layout'
import { useColor } from 'core/v2/color'

export type NavTitleProps = {
  title: string
}

export const NavTitle = observer(function NavTitle({ title }: NavTitleProps) {
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView padding={{ horizontal: 10, bottom: 6, top: spacing[2] }}>
      <Text.RobotoMono color={color['content-2']} size="12" uppercase>
        {title}
      </Text.RobotoMono>
    </BlockView>
  )
})
