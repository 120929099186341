import { request } from 'api/request'

export type GetAccountStatisticsData = {
  totalSatsEarned: number
  totalPointsEarned: number
  currentConversionRate: number
  dtv: {
    totalPointsEarned: number
    keepCount: number
    discardCount: number
    editCount: number
    consensusPercentage: number
  }
  waas: {
    totalPointsEarned: number
    answersCount: number
    keptAnswersCount: number
    discardedAnswersCount: number
  }
  fudBuster: {
    totalPointsEarned: number
    answersCount: number
    keptAnswersCount: number
    discardedAnswersCount: number
  }
  nsb: {
    totalPointsEarned: number
    rankingsCount: number
  }
}

export const getAccountStatistics = (): Promise<GetAccountStatisticsData> => {
  return request.get('v1/account/me/stats')
}
