import { useState } from 'react'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, ButtonIcon, Text, useColor, useLayout } from 'core'
import { HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps, HowToAppRoutesProps } from 'navigation/stack/root'

type ModalProps = CompositeScreenProps<
  NativeStackScreenProps<HowToAppRoutesProps, HowToModalRoutes.HowStatisticsWork>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const HowStatisticsWork = observer(function HowStatisticsWork({ navigation }: ModalProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [closeModal, setCloseModal] = useState(false)

  const instructions = [
    'You get points for keeping good data or discarding junk data.',
    'You lose points for keeping junk data or discarding good data.',
    'You get extra points for producing good data (whether through edits or new content).',
    'You lose extra points for producing junk data (whether through edits or new content).',
  ]

  const qna = [
    {
      question: `Q: Who decides what's data worth keeping (good) or discarding (junk)?`,
      answer: `A: You do! Our community works together to review items. The consensus model that runs in the background is a function of a specific threshold of what particular piece of data is either “kept” or “discarded” by multiple contributors.`,
    },
    {
      question: `Q: So how do I find out how many sats I get for what action?`,
      answer: `A: Your profile contains statistics for your actions, and if you really, really wanted to, you could probably reverse engineer specifics on how many points you’re getting for what actions. However, your time is better spent interacting with app honestly, and you will watch your points rise.`,
    },
    {
      question: `Q: Why don't you make the points structure transparent?`,
      answer: `A: We don't make that explicit because it would make it easier for bad actors to game the system and potentially fill the model with bad data.`,
    },
  ]

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="dtv-how-to-modal" type="from-bottom" duration={250}>
            <BlockView
              size={{
                width: 440,
                maxWidth: screenSize.width - spacing[4],
                maxHeight: screenSize.height - spacing[5],
              }}
              backgroundColor={color['utility-surface-2']}
              border={{ radius: 24, color: color['utility-border-1'], width: 1 }}
              padding={{ horizontal: spacing[4], top: spacing[3], bottom: spacing[4] }}
            >
              {/* Title. */}
              <BlockView direction="row" size={{ width: '100%' }} vAlign="center" margin={{ bottom: spacing[3] }}>
                <Text.Gridular size="24" color={color['content-1']} style={{ flex: 1, marginRight: spacing[1] }}>
                  How statistics work
                </Text.Gridular>

                <ButtonIcon
                  kind="minimal"
                  iconName="close"
                  iconColor={color['content-2']}
                  onPress={() => setCloseModal(true)}
                />
              </BlockView>

              {/* Content. */}
              {instructions.map((instruction, index) => (
                <BlockView
                  key={`how-statistics-work-instruction-${index}`}
                  direction="row"
                  margin={{ bottom: spacing[2] }}
                >
                  <Text.Roboto color={color['content-1']} size="14" strong style={{ marginRight: spacing[1] }}>
                    •
                  </Text.Roboto>
                  <Text.Roboto color={color['content-1']} size="14">
                    {instruction}
                  </Text.Roboto>
                </BlockView>
              ))}

              <BlockView margin={{ top: spacing[2] }}>
                {qna.map(({ question, answer }, index) => {
                  const isLast = qna.length - 1 === index
                  return (
                    <BlockView key={`how-statistics-work-qna-${index}`} margin={{ bottom: isLast ? 0 : spacing[3] }}>
                      <Text.Roboto size="14" color={color['content-1']} strong style={{ marginBottom: 4 }}>
                        {question}
                      </Text.Roboto>
                      <Text.Roboto size="14" color={color['content-2']}>
                        {answer}
                      </Text.Roboto>
                    </BlockView>
                  )
                })}
              </BlockView>
            </BlockView>
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
