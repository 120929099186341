import { request } from 'api/request'

export enum TrainDataType {
  QA = 'QA',
  ContextChunk = 'CONTEXT_CHUNK',
}

export type GetRandomTrainDataData = {
  id: string
  type: TrainDataType
  /**
   * TrainDataType.QA -> content = question
   * TrainDataType.ContextChunk -> content = book/transcription context chunk
   */
  content: string
  /**
   * Only available for TrainDataType.QA type.
   */
  answer?: string | null
}

export const getRandomTrainData = (): Promise<GetRandomTrainDataData> => {
  return request.get('v1/data-cleanup/random')
}
