import { useDrawerStatus } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonIcon } from 'core'

type DrawerToggleProps = {
  onToggle: () => void
}

export const DrawerToggle = observer(function DrawerToggle({ onToggle }: DrawerToggleProps) {
  const isOpen = useDrawerStatus() === 'open'

  return (
    <BlockView margin={{ left: 11 }}>
      <ButtonIcon kind="minimal" iconName={isOpen ? 'close' : 'side-navigation'} onPress={onToggle} />
    </BlockView>
  )
})
