import { observer } from 'mobx-react-lite'
import QRCodeSvg, { QRCodeProps as QRCodeSvgProps } from 'react-native-qrcode-svg'

import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { BlockView } from 'core/v2/atoms'

export type QRCodeProps = {
  size?: number
} & QRCodeSvgProps

export const QRCode = observer(function QRCode({ size = 200, ecl = 'L', ...qrCodeProps }: QRCodeProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView backgroundColor={color['always-white']} padding={spacing[1]} border={{ radius: 8 }}>
      <QRCodeSvg size={size} ecl={ecl} {...qrCodeProps} />
    </BlockView>
  )
})
