import { createContext, useContext } from 'react'
import { Platform } from 'react-native'
import { configurePersistable } from 'mobx-persist-store'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { reactQueryClient } from 'api/query-client'
import { AuthStore } from './auth.store'
import { UiStore } from './ui.store'
import { ModalToggleStore } from './modal-toggle.store'
import { UiToggleStore } from './ui-toggle.store'

configurePersistable({
  storage: Platform.OS === 'web' ? (typeof window !== 'undefined' ? window.localStorage : undefined) : AsyncStorage,
})

export class RootStore {
  authStore = new AuthStore()
  uiStore = new UiStore()
  modalToggleStore = new ModalToggleStore()
  uiToggleStore = new UiToggleStore()

  get storeHydrated() {
    return (
      this.authStore.isHydrated &&
      this.uiStore.isHydrated &&
      this.modalToggleStore.isHydrated &&
      this.uiToggleStore.isHydrated
    )
  }

  reset = () => {
    reactQueryClient.clear()
    this.authStore.reset()
    this.uiStore.reset()
    this.modalToggleStore.reset()
    this.uiToggleStore.reset()
  }
}

export const rootStore = new RootStore()
export const RootStoreContext = createContext(rootStore)
export const useRootStore = () => useContext(RootStoreContext)
