import { observer } from 'mobx-react-lite'

import { BlockView, ButtonPrimary, Text, useLayout, useColor } from 'core'

type AppErrorTemplateProps = {
  refreshing: boolean
  onRefresh: () => void
}

export const AppErrorTemplate = observer(function AppErrorTemplate({ refreshing, onRefresh }: AppErrorTemplateProps) {
  /** States, */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView flex margin={{ top: spacing[5] }} hAlign="center" padding={{ horizontal: spacing[2] }}>
      <Text.Roboto size="18" color={color['content-1']} strong>
        Data unavailable
      </Text.Roboto>
      <Text.Roboto
        size="15"
        color={color['content-1']}
        style={{ textAlign: 'center', marginTop: spacing[2], marginBottom: spacing[3] }}
      >
        Hodl on there Bitcoiner, there was an issue loading your data. Please reload and try again.
      </Text.Roboto>

      <ButtonPrimary kind="outlined" label="Reload" onPress={onRefresh} loading={refreshing} />
    </BlockView>
  )
})
