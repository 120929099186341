import { StyleSheet, ViewStyle } from 'react-native'
import Animated, { Extrapolate, SharedValue, interpolate, useAnimatedStyle } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { BlockView, MaterialIconOutlined, MaterialIconOutlinedName } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'

import { ButtonState } from './type'

type IconProps = {
  activated?: boolean
  icon: MaterialIconOutlinedName
  disabled?: boolean
  buttonState: SharedValue<ButtonState>
}

export const Icon = observer(function Icon({ activated, icon, disabled, buttonState }: IconProps) {
  /** States. */
  const { color } = useColor()

  const defaultIconAnimatedStyle = useAnimatedStyle<ViewStyle>(() => {
    if (activated) return { opacity: 0 }
    return {
      opacity: interpolate(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [1, 1, 0],
        Extrapolate.CLAMP
      ),
    }
  })
  const activatedIconAnimatedStyle = useAnimatedStyle<ViewStyle>(() => {
    if (activated) return { opacity: 1 }
    return {
      opacity: interpolate(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [0, 0, 1],
        Extrapolate.CLAMP
      ),
    }
  })

  /**
   * On Web, `tintColor` cannot be animated so as an alternative I'm using `opacity` to
   * "toggle" between default and activated icon to simulate the color change.
   */
  return (
    <BlockView size={24} hAlign="center" vAlign="center" style={{ position: 'relative' }}>
      {/* Default / Disabled Icon. */}
      <Animated.View style={[styles.container, defaultIconAnimatedStyle]}>
        <MaterialIconOutlined name={icon} color={disabled ? color['content-1'] : color['content-2']} size={24} />
      </Animated.View>

      {/* Activated Icon. */}
      <Animated.View style={[styles.container, activatedIconAnimatedStyle]}>
        <MaterialIconOutlined name={icon} color={color.accent.brand} size={24} />
      </Animated.View>
    </BlockView>
  )
})

type Style = {
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    width: 24,
    height: 24,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
