import { StyleSheet, TextStyle } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, MaterialIconOutlined, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { NavControlAction } from 'core/v2/molecules'
import { InAppBrowser } from 'utils/browser'

export type HelpSatoshiProps = {
  onBecomeContributorPress: () => void
}

export const HelpSatoshi = observer(function HelpSatoshi({ onBecomeContributorPress }: HelpSatoshiProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView size={{ width: '100%' }} hAlign="center" padding={{ top: spacing[1] }}>
      <MaterialIconOutlined
        name="construction"
        color={color['content-2']}
        size={48}
        style={{ marginBottom: spacing[1] }}
      />

      <Text.Roboto color={color['content-1']} size="20" style={[styles.text, { marginBottom: spacing[1] }]}>
        Help us build Satoshi
      </Text.Roboto>

      <Text.Roboto color={color['content-2']} size="14" style={[styles.text, { marginBottom: spacing[2] }]}>
        Help gather, clean, label and enhance the data used to train Satoshi while earning sats for your contributions!
      </Text.Roboto>

      <BlockView size={{ width: '100%' }}>
        <NavControlAction
          leftIcon="add"
          label="Become a Contributor"
          onPress={() => {
            onBecomeContributorPress()
            InAppBrowser.open('https://www.spiritofsatoshi.ai/help-us-train-satoshi')
          }}
          // @ts-ignore
          labelStyle={{ flex: 'unset' }}
          containerStyle={{ justifyContent: 'center', width: '100%' }}
        />
      </BlockView>
    </BlockView>
  )
})

type Style = {
  text: TextStyle
}

const styles = StyleSheet.create<Style>({
  text: {
    textAlign: 'center',
  },
})
