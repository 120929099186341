import { request } from 'api/request'

export type UserLeaderboardRankData = {
  overall: number | null
  daily: number | null
  weekly: number | null
  monthly: number | null
}

export const getUserLeaderboardRank = (): Promise<UserLeaderboardRankData> => {
  return request.get(`v1/data-cleanup/leaderboard/ranking/current`)
}
