import { useEffect, useRef } from 'react'
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputChangeEventData,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { observer } from 'mobx-react-lite'
import { MotiView } from 'moti'

import { BlockView, Text, useColor, useLayout, TextStyles, Constants, UserAvatar } from 'core'
import { clamp } from 'utils/number'

type ResponseProps = {
  userFullName?: string | null
  response: string
  onResponseChange: (response: string) => void
}

const TEXT_INPUT_BASE_HEIGHT = TextStyles['text-16'].lineHeight ?? 20

export const Response = observer(function Response({ userFullName, response, onResponseChange }: ResponseProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const textInputEl = useRef(null)

  const currentCharacterLength = response.length
  const showCharacterLimit = currentCharacterLength > 2050

  const adjustInputHeight = (e: LayoutChangeEvent | NativeSyntheticEvent<TextInputChangeEventData>) => {
    if (Platform.OS !== 'web') return
    // @ts-ignore
    const el = e.target || e.nativeEvent?.target
    textInputEl.current = el
    if (el) {
      // @ts-ignore
      el.style.height = 0
      // @ts-ignore
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight
      const clampedHeight = clamp(newHeight, 0, screenSize.height / 2)
      // @ts-ignore
      el.style.height = `${clampedHeight}px`
    }
  }

  useEffect(() => {
    /**
     * On Web, when the message is cleared programmatically TextInput's onLayout and onChange does not get triggered.
     * This results in the height of the TextInput not resizing correctly.
     * So we are manually applying the height if the TextInput's value clears.
     */
    if (!response.length && Platform.OS === 'web' && textInputEl.current) {
      // @ts-ignore
      textInputEl.current.style.height = `${TEXT_INPUT_BASE_HEIGHT}px`
    }
  }, [response])

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="flex-start"
      padding={spacing[2]}
      backgroundColor={color['alpha-white-4']}
      border={{ radius: 16 }}
    >
      {/* Satoshi Logo. */}
      <UserAvatar fullName={userFullName ?? null} />

      <MotiView
        from={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={[styles.questionContainer, { marginLeft: spacing[1] }]}
      >
        <BlockView flex border={{ radius: 8, width: 1, color: color['utility-border-1'] }} padding={10}>
          <Text.RobotoMono color={color['content-2']} size="12" uppercase style={{ marginBottom: spacing[1] }}>
            Response
          </Text.RobotoMono>

          <TextInput
            value={response}
            onChangeText={onResponseChange}
            maxFontSizeMultiplier={1.2}
            placeholder="Enter the question"
            placeholderTextColor={color['content-2']}
            multiline
            onChange={adjustInputHeight}
            onLayout={adjustInputHeight}
            autoFocus
            style={[
              // @ts-ignore
              Platform.OS === 'web' && { outlineWidth: 0, outline: 'none' },
              styles.input,
              { color: color['content-1'] },
            ]}
          />
        </BlockView>

        {showCharacterLimit && (
          <BlockView margin={{ top: spacing[1] }} hAlign="flex-end">
            <Text.Roboto
              size="14"
              color={(() => {
                if (currentCharacterLength < 2100) return color.state.attention
                return color.state.critical
              })()}
            >{`${currentCharacterLength} / ${Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH}`}</Text.Roboto>
          </BlockView>
        )}
      </MotiView>
    </BlockView>
  )
})

type Style = {
  questionContainer: ViewStyle
  input: TextStyle
}

const styles = StyleSheet.create<Style>({
  questionContainer: {
    flex: 1,
    width: '100%',
  },
  input: {
    ...TextStyles.robotoRegular,
    ...TextStyles['text-16'],
  },
})
