import { Switch } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonIcon, ButtonPrimary, ButtonPrimaryProps, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

export type ListProps = {
  kind: 'action' | 'toggle'
  label: string
  labelStrong?: boolean
  subtext?: string

  rightIcon?: MaterialIconOutlinedName
  onRightIconPress?: () => void

  actionButton?: ButtonPrimaryProps
  toggle?: {
    value: boolean
    onValueChange: (value: boolean) => void
  }
}

export const List = observer(function List({
  kind,
  label,
  labelStrong = true,
  subtext,
  rightIcon,
  onRightIconPress,
  actionButton,
  toggle,
}: ListProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView size={{ width: '100%' }} direction="row" vAlign="center">
      {/* Content. */}
      <BlockView flex margin={{ right: spacing[1] }}>
        <Text.Roboto color={color['content-1']} size="14" strong={labelStrong}>
          {label}
        </Text.Roboto>

        {!!subtext && (
          <Text.Roboto color={color['content-2']} size="14" style={{ marginTop: 4 }}>
            {subtext}
          </Text.Roboto>
        )}
      </BlockView>

      {/* Right Icon. */}
      {!!rightIcon && (
        <BlockView vAlign="center" margin={{ right: spacing[1] }}>
          <ButtonIcon kind="minimal" iconName={rightIcon} onPress={onRightIconPress} />{' '}
        </BlockView>
      )}

      {/* Action Button. */}
      {kind === 'action' && !!actionButton && <ButtonPrimary {...actionButton} />}

      {/* Toggle. */}
      {kind === 'toggle' && !!toggle && (
        <Switch
          value={toggle.value}
          onValueChange={toggle.onValueChange}
          thumbColor={color['always-white']}
          // @ts-ignore WEB_ONLY
          activeThumbColor={color.accent.brand}
          trackColor={{
            false: color['alpha-white-16'],
            true: color['alpha-white-32'],
          }}
        />
      )}
    </BlockView>
  )
})
