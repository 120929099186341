import { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { runInAction } from 'mobx'
import { useQuery } from 'react-query'

import { useRootStore } from 'store/root.store'
import { getAccountMe } from 'api/user/get-account-me'
import { CampaignRoutes } from 'navigation/routes'

export const useCampaignModal = () => {
  /** States. */
  const { modalToggleStore } = useRootStore()
  const { navigate } = useNavigation<any>()

  /** Get account me Query. */
  const { data } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  //const geyserCampaign = !!data?.featureFlags?.DISPLAY_GEYSER_POPUP?.enabled
  const geyserCampaign = false

  useEffect(() => {
    if (geyserCampaign && modalToggleStore.showGeyserCampaign) {
      runInAction(() => {
        modalToggleStore.setShowGeyserCampaign(false)
      })
      navigate(CampaignRoutes.Geyser)
    }
  }, [geyserCampaign, modalToggleStore, modalToggleStore.showGeyserCampaign, navigate])
}
