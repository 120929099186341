import { useState } from 'react'
import { ActivityIndicator, Pressable, StyleSheet, ViewStyle } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { AnimatePresence, MotiView } from 'moti'
import { LinearGradient } from 'expo-linear-gradient'

import { useColor } from 'core/v2/color'
import { BlockView, Chip, MaterialIconOutlined, Text } from 'core/v2/atoms'
import { useLayout } from 'core/v2/layout'
import { useRootStore } from 'store/root.store'
import { formatToShortNumber } from 'utils/number'
import { getAccountMe } from 'api/user/get-account-me'
import { MENU_ITEM_HEIGHT, Menu, MenuItem } from '../Menu'

enum ButtonState {
  Default = 0,
  HoverIn = 1,
  PressedIn = 2,
}

export type NavProfileProps = {
  fromKey: string // This is to make sure no duplicate key is used for the component
  onMyAccountPress: () => void
  onAddCreditPress: () => void
  onDonationPress: () => void
}
export const NavProfile = observer(function NavProfile({
  fromKey,
  onMyAccountPress,
  onAddCreditPress,
  onDonationPress,
}: NavProfileProps) {
  /** States. */
  const { reset } = useRootStore()
  const { color } = useColor()
  const { spacing } = useLayout()
  const buttonState = useSharedValue(ButtonState.Default)
  const [showMenu, setShowMenu] = useState(false)

  /** User Account Me Query. */
  const { isLoading, isError, data } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const showHoverInState = () => {
    buttonState.value = withTiming(ButtonState.HoverIn, { duration: 150 })
  }
  const showHoverOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }
  const showPressedState = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const showPressedOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = showMenu ? color['alpha-white-8'] : 'transparent'

    return {
      backgroundColor: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, color['alpha-white-4'], color['alpha-white-2']]
      ),
    }
  })

  const menuItems: MenuItem[] = [
    {
      id: 'my-account',
      label: `My account`,
      onPress: () => {
        setShowMenu(false)
        onMyAccountPress()
      },
    },
    {
      id: 'add-credits',
      label: `Add credits`,
      iconRight: (
        <Chip
          kind="outlined"
          size="chip-2"
          label={`${data?.credits ?? 0} credits`}
          onPress={() => {
            setShowMenu(false)
            onAddCreditPress()
          }}
        />
      ),
      onPress: () => {
        setShowMenu(false)
        onAddCreditPress()
      },
    },
    {
      id: 'give-donation',
      label: `Support Satoshi`,
      onPress: () => {
        setShowMenu(false)
        onDonationPress()
      },
    },
    {
      id: 'log-out',
      label: `Log out`,
      labelColor: color.state.critical,
      onPress: () => reset(),
    },
  ]

  const renderAvatar = () => {
    if (isLoading || isError || !data?.fullName?.length) {
      return (
        <BlockView
          size={40}
          backgroundColor={color['alpha-white-8']}
          border={{ radius: 20 }}
          vAlign="center"
          hAlign="center"
        >
          <MaterialIconOutlined name="group" size={24} color={color['content-1']} />
        </BlockView>
      )
    }

    // When we implement user profile picture, do  it here.
    return (
      <BlockView
        size={40}
        backgroundColor={color['alpha-white-8']}
        border={{ radius: 20 }}
        vAlign="center"
        hAlign="center"
      >
        <Text.RobotoMono color={color['content-2']} uppercase size="20">
          {data.fullName.charAt(0)}
        </Text.RobotoMono>
      </BlockView>
    )
  }

  const renderDetails = () => {
    if (isLoading || isError || !data) {
      return <ActivityIndicator color={color['content-2']} />
    }

    const { type, fullName, balance } = data
    const { formatted, isFormatted } = formatToShortNumber(balance.total, 2)

    const balanceText = (() => {
      if (isFormatted) return formatted
      if (typeof formatted === 'number') {
        return formatted.toLocaleString()
      }
      return formatted
    })()

    return (
      <>
        <Text.Roboto strong size="14" color={color['content-1']} style={{ marginBottom: 4 }}>
          {fullName}
        </Text.Roboto>

        {type === 'CONTRIBUTOR' && (
          <Text.RobotoMono color={color.accent.brand} size="12">
            {`${balanceText} sats`}
          </Text.RobotoMono>
        )}
      </>
    )
  }

  return (
    <AnimatePresence>
      {!showMenu && (
        <LinearGradient
          key={`${fromKey}-gradient-overlay`}
          colors={['transparent', color['utility-surface-2']]}
          pointerEvents="none"
          style={{ width: '100%', height: 80, position: 'absolute', top: -80 }}
        />
      )}

      {showMenu && (
        <MotiView
          key={`${fromKey}-nav-profile-menu`}
          from={{ opacity: 0, translateY: 10 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: 10 }}
          style={{
            position: 'absolute',
            width: '100%',
            top: -(MENU_ITEM_HEIGHT * menuItems.length) - spacing[1],
            backgroundColor: color['utility-surface-3'],
          }}
        >
          <Menu items={menuItems} />
        </MotiView>
      )}

      <Pressable
        onHoverIn={showHoverInState}
        onHoverOut={showHoverOutState}
        onPressIn={showPressedState}
        onPressOut={showPressedOutState}
        onPress={() => setShowMenu((prev) => !prev)}
        style={[styles.wrapper, { backgroundColor: color['utility-surface-2'] }]}
      >
        <Animated.View style={[styles.container, containerAnimatedStyle]}>
          {/* Avatar. */}
          {renderAvatar()}

          {/* Name & Balance. */}
          <BlockView flex hAlign="flex-start" margin={{ horizontal: spacing[1] }}>
            {renderDetails()}
          </BlockView>

          {/* Icon Button. */}
          <MaterialIconOutlined name="unfold-more" size={24} color={color['content-2']} />
        </Animated.View>
      </Pressable>
    </AnimatePresence>
  )
})

type Style = {
  wrapper: ViewStyle
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  wrapper: {
    width: '100%',
    minHeight: 56,
    borderRadius: 8,
  },
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
    alignItems: 'center',
    minHeight: 56,
  },
})
