import { observer } from 'mobx-react-lite'

import { BlockView, Text, useColor, useLayout } from 'core'
import { InAppBrowser } from 'utils/browser'

const termsAndConditionsLink = 'https://spirit-of-satoshi-prod.s3.amazonaws.com/public/tc.pdf'
const privacyPolicyLink = ''

export const Compliance = observer(function Compliance() {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  const openTermsConditions = () => {
    InAppBrowser.open(termsAndConditionsLink)
  }

  const openPrivacyPolicy = () => {
    InAppBrowser.open(privacyPolicyLink)
  }

  return (
    <BlockView hAlign="center" vAlign="center" margin={{ top: spacing[2] }}>
      <Text.Roboto color={color['content-3']} size="12">
        By signing up you agree to our
      </Text.Roboto>
      <Text.Roboto color={color['content-3']} size="12">
        <Text.Roboto underline color={color['content-3']} size="12" onPress={openTermsConditions}>
          Terms & Conditions
        </Text.Roboto>
        {/* and{' '}
        <Text.Roboto underline color={color['content-3']} size="12" onPress={openPrivacyPolicy}>
          Privacy Policy
        </Text.Roboto> */}
      </Text.Roboto>
    </BlockView>
  )
})
