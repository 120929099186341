import { useCallback, useEffect, useState } from 'react'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useQueryClient } from 'react-query'

import { showSnackbar, Constants } from 'core'
import { ChatAppRoutesProps } from 'navigation/stack/chat'
import { ChatRoutes, PaymentRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { clampLeft } from 'utils/number'

import { SendMessageVariables, useSendMessageStreaming } from 'api/chat/send-message-streaming'
import {
  ConversationMessage,
  GetConversationMessages,
  getConversationMessages,
} from 'api/chat/get-conversation-messages'
import { GetAccountMeData } from 'api/user/get-account-me'

import { getConversations } from 'api/chat/get-conversations'

type NavigationProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ChatAppRoutesProps, ChatRoutes.Conversation>,
    DrawerNavigationProp<ChatAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

type MessageStreamParams = {
  conversationId: string | null
  isNewConversation: boolean
  isUserOutOfCredit: boolean
}

const OPTIMISTIC_RESPONSE_ID = {
  USER: `user-optimistic-message`,
  SOS: `sos-optimistic-message`,
}

export const useMessageStream = ({ conversationId, isNewConversation, isUserOutOfCredit }: MessageStreamParams) => {
  /** States. */
  const navigation = useNavigation<NavigationProps>()
  const queryClient = useQueryClient()

  // Common States.
  const [streamDoneCallbackLoading, setStreamDoneCallbackLoading] = useState(false)
  /**
   * When onStreamDone callback is called, it is passed with a variable that says whether the message was sent
   * as a stream or "strict". This state can be used in a conjunction with `streamDoneCallbackLoading`.
   *
   * This state is created to display a response loading UI while the app is fetching the latest
   * conversation and new conversation messages because the message was sent as JSON (not streaming).
   */
  const [streamDoneFromStreaming, setStreamDoneFromStreaming] = useState(false)
  const [message, setMessage] = useState('')
  const [chatModel, setChatModel] = useState<string | null>(null)

  // New Conversation States.
  const [optimisticUserMessage, setOptimisticUserMessage] = useState<ConversationMessage | null>(null)
  const [optimisticSosMessage, setOptimisticSosMessage] = useState<ConversationMessage | null>(null)

  /** Stream Message. */
  const {
    startStreaming,
    loading: messageStreamLoading,
    streaming: messageStreaming,
    abort: abortMessageStream,
  } = useSendMessageStreaming({
    onStream: ({ text, isStreaming }) => {
      // If not streaming, no need to actually stream.
      if (!isStreaming) return

      // New conversation flow.
      if (isNewConversation) {
        return setOptimisticSosMessage({
          id: OPTIMISTIC_RESPONSE_ID.SOS,
          isFromUser: false,
          isFromMentor: true,
          text,
          type: 'TEXT',
          createdAt: new Date().toISOString(),
        })
      }

      if (!conversationId) return

      // Existing conversation flow.
      const messageCacheData = queryClient.getQueryData<GetConversationMessages>(
        `get-conversation-messages-${conversationId}`
      )
      if (!messageCacheData) return

      const messageIndex = messageCacheData.findIndex((message) => message.id === OPTIMISTIC_RESPONSE_ID.SOS)

      const optimisticResponse = (() => {
        if (messageIndex >= 0) {
          return messageCacheData.map((d) => {
            if (d.id !== OPTIMISTIC_RESPONSE_ID.SOS) return d
            return {
              ...d,
              text,
            }
          })
        }

        const optimisticSosMessage: ConversationMessage = {
          id: OPTIMISTIC_RESPONSE_ID.SOS,
          isFromUser: false,
          isFromMentor: true,
          text,
          type: 'TEXT',
          createdAt: new Date().toISOString(),
        }
        return [...messageCacheData, optimisticSosMessage]
      })()

      queryClient.setQueryData(`get-conversation-messages-${conversationId}`, optimisticResponse)
    },
    onDone: async ({ fromStreaming }) => {
      setStreamDoneCallbackLoading(true)
      setStreamDoneFromStreaming(fromStreaming)

      // Update user credit.
      queryClient.setQueryData<GetAccountMeData | null>('get-account-me', (old) => {
        if (!old) return null
        return {
          ...old,
          credits: clampLeft(old.credits - 1, 0),
        }
      })

      // New conversation flow.
      if (isNewConversation) {
        // Fetch conversations and get the latest conversationId.
        await queryClient.invalidateQueries({ queryKey: 'get-conversations' })
        const conversations = await queryClient.fetchQuery({
          queryKey: 'get-conversations',
          queryFn: () => getConversations(),
        })
        const createdConversationId = conversations[0].id
        // Pre-fetching conversation message as well to remove loading time.
        await queryClient.fetchQuery({
          queryKey: `get-conversation-messages-${createdConversationId}`,
          queryFn: () => getConversationMessages(createdConversationId),
        })

        setStreamDoneCallbackLoading(false)

        navigation.setParams({
          id: createdConversationId,
          fromNewChat: true,
          fromStrict: !fromStreaming, // This will add typewriter effect if true.
        })
        return
      }

      // Existing conversation flow.
      // Just refetch the conversations.
      await queryClient.refetchQueries({
        queryKey: `get-conversation-messages-${conversationId}`,
      })
      setStreamDoneCallbackLoading(false)
    },
    onError: () => {
      setMessage('')

      showSnackbar({
        kind: 'error',
        label: 'Something went wrong. Please try again later.',
        leftIcon: 'error',
      })

      // New conversation flow.
      if (isNewConversation) {
        setOptimisticUserMessage(null)
        setOptimisticSosMessage(null)
        return
      }

      // Remove all optimistic responses if any.
      const messageCacheData = queryClient.getQueryData<GetConversationMessages>(
        `get-conversation-messages-${conversationId}`
      )

      if (!messageCacheData) return

      const updatedMessages = messageCacheData.filter(
        (message) => message.id !== OPTIMISTIC_RESPONSE_ID.USER && message.id !== OPTIMISTIC_RESPONSE_ID.SOS
      )
      queryClient.setQueryData(`get-conversation-messages-${conversationId}`, updatedMessages)
    },
  })

  useEffect(() => {
    return () => {
      abortMessageStream()
    }
  }, [abortMessageStream])

  const onSendMessage = useCallback(() => {
    if (!message.length || messageStreamLoading || messageStreaming || streamDoneCallbackLoading) {
      return
    }

    const messageToSend = message.substring(0, Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH)

    // If user is out of credits.
    if (isUserOutOfCredit) {
      showSnackbar({
        label: `You're out of credits`,
        leftIcon: 'info',
        actionText: 'Add credits',
        onActionPress: () => navigation.navigate(PaymentRoutes.AddCreditModal),
      })
      return
    }

    let messageParams: SendMessageVariables = {
      text: messageToSend,
      streaming: true,
    }

    // If existing conversation with no conversation id, it's not normal.
    if (!isNewConversation && !conversationId) return

    if (conversationId) {
      messageParams = {
        ...messageParams,
        conversationId,
      }
    }

    if (chatModel !== null) {
      messageParams = {
        ...messageParams,
        model: chatModel,
      }
    }

    const optimisticUserMessage: ConversationMessage = {
      id: OPTIMISTIC_RESPONSE_ID.USER,
      isFromUser: true,
      isFromMentor: false,
      text: messageToSend,
      type: 'TEXT',
      createdAt: new Date().toISOString(),
    }

    // New conversation flow.
    if (isNewConversation) {
      setOptimisticUserMessage(optimisticUserMessage)
    }
    // Existing conversation flow.
    else {
      const messageCacheData = queryClient.getQueryData<GetConversationMessages>(
        `get-conversation-messages-${conversationId}`
      )
      if (messageCacheData) {
        const optimisticUserMessage: ConversationMessage = {
          id: OPTIMISTIC_RESPONSE_ID.USER,
          isFromUser: true,
          isFromMentor: false,
          text: messageToSend,
          type: 'TEXT',
          createdAt: new Date().toISOString(),
        }
        const optimisticResponse = [...messageCacheData, optimisticUserMessage]
        queryClient.setQueryData(`get-conversation-messages-${conversationId}`, optimisticResponse)
      }
    }

    startStreaming(messageParams)
    setMessage('')
  }, [
    chatModel,
    conversationId,
    isNewConversation,
    isUserOutOfCredit,
    message,
    messageStreamLoading,
    messageStreaming,
    navigation,
    queryClient,
    startStreaming,
    streamDoneCallbackLoading,
  ])

  return {
    // Streaming message states.
    onSendMessage,
    messageStreamLoading,
    messageStreaming,
    streamDoneCallbackLoading,
    streamDoneFromStreaming,

    // Common states.
    chatModel,
    setChatModel,
    message,
    setMessage,

    // New conversation states.
    optimisticUserMessage,
    optimisticSosMessage,
  }
}
