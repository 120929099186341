import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import {
  BlockView,
  ButtonIcon,
  ButtonPrimary,
  ButtonPrimaryProps,
  MaterialIconOutlined,
  MaterialIconOutlinedName,
  Text,
} from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { TextSize } from 'core/v2/atoms/Text/type'

export type Card2Props = {
  kind?: 'filled' | 'outlined'

  title?: string
  titleSize?: TextSize
  titleColor?: string

  subtitle?: string
  subtitleStrong?: boolean

  text?: string
  textStrong?: boolean

  icon?: MaterialIconOutlinedName
  controlIcon?: MaterialIconOutlinedName
  onControlIconPress?: () => void

  actionButtons?: ButtonPrimaryProps[]

  wrapperStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  onCardPress?: () => void
}

enum ButtonState {
  PressedOut = 0,
  PressedIn = 1,
}

export const Card2 = observer(function Card2({
  kind = 'filled',
  title,
  titleSize,
  titleColor,

  subtitle,
  subtitleStrong,

  text,
  textStrong,

  icon,
  controlIcon,
  onControlIconPress,
  actionButtons,

  wrapperStyle,
  containerStyle,
  onCardPress,
}: Card2Props) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const buttonState = useSharedValue(ButtonState.PressedOut)

  const onHoverIn = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const onHoverOut = () => {
    buttonState.value = withTiming(ButtonState.PressedOut, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle((): ViewStyle => {
    if (kind === 'filled') {
      return {
        backgroundColor: color['utility-surface-3'],
        opacity: interpolate(buttonState.value, [ButtonState.PressedOut, ButtonState.PressedIn], [1, 0.7]),
      }
    }

    return {
      backgroundColor: interpolateColor(
        buttonState.value,
        [ButtonState.PressedOut, ButtonState.PressedIn],
        [color['utility-surface-2'], color['utility-surface-3']]
      ),
      borderWidth: 1,
      borderColor: color['utility-border-1'],
    }
  })

  return (
    <Pressable
      onPress={onCardPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPressIn={onHoverIn}
      onPressOut={onHoverOut}
      style={wrapperStyle}
    >
      <Animated.View style={[styles.container, containerAnimatedStyle, containerStyle]}>
        {/* Icon and Control. */}
        {(!!icon || !!controlIcon) && (
          <BlockView direction="row" hAlign="space-between" margin={{ bottom: spacing[2] }}>
            {icon ? (
              <MaterialIconOutlined name={icon} size={24} color={color['content-2']} />
            ) : (
              // Need a dummy icon for alignment.
              <BlockView backgroundColor="transparent" size={24} />
            )}

            {controlIcon ? (
              <ButtonIcon
                kind="minimal"
                iconName={controlIcon}
                iconColor={color['content-2']}
                onPress={onControlIconPress}
              />
            ) : (
              // Need a dummy icon for alignment.
              <BlockView backgroundColor="transparent" size={24} />
            )}
          </BlockView>
        )}

        {/* Content. */}
        {!!title && (
          <Text.RobotoMono
            color={titleColor ?? color['content-1']}
            size={titleSize ?? '20'}
            style={{ marginBottom: !!subtitle || !!text ? 4 : 0 }} // Only add margin bottom if there is another content underneath.
          >
            {title}
          </Text.RobotoMono>
        )}

        {!!subtitle && (
          <Text.Roboto
            color={color['content-2']}
            size="14"
            strong={subtitleStrong}
            style={{ marginBottom: text ? 4 : 0 }} // Only add margin bottom if there is another content underneath.
          >
            {subtitle}
          </Text.Roboto>
        )}

        {!!text && (
          <Text.Roboto color={color['content-2']} size="14" strong={textStrong}>
            {text}
          </Text.Roboto>
        )}

        {!!actionButtons?.length && (
          <BlockView direction="row" hAlign="flex-end" margin={{ top: spacing[2] }}>
            {actionButtons.map((actionButton, index) => (
              <BlockView key={`card-action-button-${index}`} margin={{ left: spacing[1] }}>
                <ButtonPrimary size="button-2" {...actionButton} />
              </BlockView>
            ))}
          </BlockView>
        )}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    minWidth: 168,
    borderRadius: 16,
    padding: 12,
  },
})
