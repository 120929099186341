import { useCallback, useEffect } from 'react'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { AnimatePresence } from 'moti'

import { AnimateTransition } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { ChatAppRoutesProps } from 'navigation/stack/chat'
import { ChatRoutes } from 'navigation/routes'
import { Conversation, getConversations } from 'api/chat/get-conversations'
import { getConversationMessages } from 'api/chat/get-conversation-messages'

import { ChatConversationLoading } from './Template/Loading'
import { ChatConversationError } from './Template/Error'
import { ConversationTemplate } from './Template'

type ChatConversationAppProps = CompositeScreenProps<
  CompositeScreenProps<
    NativeStackScreenProps<ChatAppRoutesProps, ChatRoutes.Conversation>,
    DrawerScreenProps<ChatAppRoutesProps>
  >,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ChatConversation = observer(function ChatConversation({ navigation, route }: ChatConversationAppProps) {
  /** Get Conversations. */
  const { data: conversationsData } = useQuery({
    queryKey: 'get-conversations',
    queryFn: () => getConversations(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      if (!response || !route.params?.id) return
      const targetConversation = response.find((d) => d.id === route.params.id)
      navigation.setOptions({
        headerTitle: targetConversation?.title ?? 'Conversation',
      })
    },
  })

  /** Get Conversation Messages. */
  const { isLoading, isError, data, isRefetching, refetch } = useQuery({
    enabled: !!route.params?.id,
    queryKey: `get-conversation-messages-${route.params?.id}`,
    queryFn: () => {
      if (!route.params?.id) return []
      if (route.params.id === 'new-chat') return []
      return getConversationMessages(route.params.id)
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  // If no conversation id is passed, treat it as starting a new chat.
  useFocusEffect(
    useCallback(() => {
      if (!route.params) {
        navigation.setParams({
          id: 'new-chat',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  /**
   * Set the header title.
   * "New chat": if `id` === 'new-chat'
   * {conversation title}: if target conversation can be found using the id passed in.
   * "Conversation": if all above failed.
   */
  useEffect(() => {
    if (route.params?.id === 'new-chat') {
      navigation.setOptions({
        headerTitle: `New chat`,
      })
      return
    }

    if (!conversationsData || !route.params?.id) return

    const targetConversation = conversationsData.find((d) => d.id === route.params.id)
    navigation.setOptions({
      headerTitle: targetConversation?.title ?? 'Conversation',
    })
  }, [conversationsData, route.params?.id, navigation])

  const renderContent = () => {
    if (isLoading) {
      return (
        <AnimateTransition key="chat-conversation-loading" type="from-bottom" style={{ flex: 1 }}>
          <ChatConversationLoading />
        </AnimateTransition>
      )
    }

    if (isError || !data) {
      return (
        <AnimateTransition key="chat-conversation-error" type="from-bottom" style={{ flex: 1 }}>
          <ChatConversationError refreshing={isRefetching} onRefresh={() => refetch()} />
        </AnimateTransition>
      )
    }

    const isNewConversation = !route?.params?.id || route?.params?.id === 'new-chat'
    const conversationId = (() => {
      if (isNewConversation) return null
      return route.params.id
    })()
    const isFromNewConversation = (() => {
      if (!route?.params) return false
      if (typeof route.params?.fromNewChat === 'string') return route.params.fromNewChat === 'true'
      return !!route.params?.fromNewChat
    })()
    const isFromStrictMessage = (() => {
      if (!route?.params) return false
      if (typeof route.params?.fromStrict === 'string') return route.params.fromStrict === 'true'
      return !!route.params?.fromStrict
    })()

    // Try to find model from route params
    const routeModel = route.params?.model?.replace(/\//g, '');

    // Define the model
    const conversationModel = (() => {
      // Try to find existing conversation model
      const targetConversation = conversationsData?.find((d) => d.id === conversationId)
      if (targetConversation) return targetConversation.model

      if (routeModel) return routeModel

      // If none found, return null
      return null
    })()

    // Define the model
    const recentModel = (() => {
      // Try to get recent conversation
      const recentConvo = conversationsData?.[0]

      return recentConvo?.model
    })()

    return (
      <ConversationTemplate
        conversationId={conversationId}
        isNewConversation={isNewConversation}
        isFromNewConversation={isFromNewConversation}
        isFromStrictMessage={isFromStrictMessage}
        messages={data ?? []}
        model={conversationModel}
        routeModel={routeModel}
        recentModel={recentModel}
      />
    )
  }

  return <AnimatePresence exitBeforeEnter>{renderContent()}</AnimatePresence>
})
