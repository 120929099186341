import { useNavigation } from '@react-navigation/native'
import { useQuery } from 'react-query'

import { NavDrawerMenuItem } from 'core'
import { ChatRoutes, ContributeRoutes } from 'navigation/routes'
import { getAccountMe } from 'api/user/get-account-me'

export const useMenuItems = () => {
  const navigation = useNavigation()

  /** Get account me Query. */
  const { isLoading, isError, data } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const getMenuItems = () => {
    if (isLoading) return []

    const userType = data?.type ?? 'USER'

    const basicMenuItems: NavDrawerMenuItem[] = [
      {
        icon: 'chat-bubble',
        label: 'Chat',
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ChatRoutes.Root, { screen: ChatRoutes.Conversation, params: { id: 'new-chat' } })
        },
      },
    ]

    const contributorMenuItems: NavDrawerMenuItem[] = [
      {
        icon: 'verified',
        label: `Don't trust, verify`,
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ContributeRoutes.Root, { screen: ContributeRoutes.DTV })
        },
      },
      {
        icon: 'group',
        label: `We are all Satoshi`,
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ContributeRoutes.Root, { screen: ContributeRoutes.WeAreAllSatoshi })
        },
      },
      {
        icon: 'skull',
        label: `Fudbuster`,
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ContributeRoutes.Root, { screen: ContributeRoutes.FudBuster })
        },
      },
      {
        icon: 'low-priority',
        label: `No second Best`,
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ContributeRoutes.Root, { screen: ContributeRoutes.NoSecondBest })
        },
      },
      {
        icon: 'bar-chart',
        label: `Statistics`,
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ContributeRoutes.Root, { screen: ContributeRoutes.Statistics })
        },
      },
      {
        icon: 'trophy',
        label: `Leaderboard`,
        onPress: () => {
          // @ts-ignore
          navigation.navigate(ContributeRoutes.Root, { screen: ContributeRoutes.Leaderboard })
        },
      },
    ]

    // If error, just return Chat.
    if (isError) {
      return basicMenuItems
    }

    return userType === 'CONTRIBUTOR' ? [...basicMenuItems, ...contributorMenuItems] : basicMenuItems
  }

  return {
    menuLoading: isLoading,
    userCredit: data?.credits,
    userType: data?.type,
    getMenuItems,
  }
}
