import { useState } from 'react'
import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'
import { QueryObserverResult, useMutation } from 'react-query'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'

import { GetQuestionData } from 'api/data-train/get-question'

import { BlockView, Constants, WaaSFooter, WaaSSubmitActionType, showSnackbar, useColor, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { SubmitQuestionActionType, submitQuestionAction } from 'api/data-train/submit-question-action'

import { Question } from './Question'
import { Response } from './Response'

type WeAreAllSatoshiTemplateProps = {
  isRefetching: boolean
  getNextData: () => Promise<QueryObserverResult<GetQuestionData>>
  questionId: string
  content: string
  userFullName?: string | null
}

type NavigationProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.DTV>,
    DrawerNavigationProp<ContributeAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const WeAreAllSatoshiTemplate = observer(function WeAreAllSatoshiTemplate({
  isRefetching,
  getNextData,
  questionId,
  content,
}: WeAreAllSatoshiTemplateProps) {
  /** States. */
  const { navigate } = useNavigation<NavigationProps>()
  const { color } = useColor()
  const { spacing } = useLayout()
  const [userAnswer, setUserAnswer] = useState('')
  const [loadingActionType, setLoadingActionType] = useState<WaaSSubmitActionType | null>(null)

  const resetStates = () => {
    setUserAnswer('')
    setLoadingActionType(null)
  }

  /** Submit Question */
  const submitQuestionActionMutation = useMutation(
    () =>
      submitQuestionAction(questionId, {
        action: SubmitQuestionActionType.AddAnswer,
        answer: userAnswer,
      }),
    {
      onSuccess: (response) => {
        // Succeeded.
        if (response.status === 200) {
          getNextData().finally(resetStates)
          return
        }

        // Did not succeed.
        resetStates()
        showSnackbar({
          kind: 'error',
          label: `Your submission was not successful. Please try again.`,
          leftIcon: 'error',
        })
      },
    }
  )

  const onSkipPress = () => {
    if (isRefetching) return

    setLoadingActionType('SKIP')
    getNextData().finally(resetStates)
  }

  const onSubmitPress = () => {
    if (isRefetching) return

    setLoadingActionType('SUBMIT')
    return submitQuestionActionMutation.mutate()
  }

  const submitActive = userAnswer.length <= Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH && userAnswer.length >= 50

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: color['utility-surface-2'],
          paddingHorizontal: spacing[2],
          alignItems: 'center',
        }}
        style={{ backgroundColor: color['utility-surface-2'] }}
      >
        <BlockView flex size={{ width: '100%', maxWidth: 745 }}>
          <Question content={content} />

          <Response response={userAnswer} onResponseChange={setUserAnswer} />
        </BlockView>
      </ScrollView>

      <WaaSFooter
        submitDisabled={!submitActive}
        onHelpPress={() => {
          navigate(HowToModalRoutes.HowToReplyAsSatoshi)
        }}
        onSkipPress={onSkipPress}
        onSubmitPress={onSubmitPress}
        loadingAction={loadingActionType}
      />
    </>
  )
})
