import { Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser'

export const InAppBrowser = {
  open: (url: string, params?: WebBrowser.WebBrowserOpenOptions) => {
    if (Platform.OS === 'web') {
      return window.open(url, '_blank')
    }

    return WebBrowser.openBrowserAsync(url, params)
  },
}
