import { useMemo, useState } from 'react'
import { Pressable, StyleSheet, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, MaterialIconOutlinedName } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { NavBadge, NavControlDisplay } from 'core/v2/molecules'
import { clampLeftWorklet } from 'utils/number'

import { useMenuItems } from 'routes/Chat/MobileMenu/useMenuItems'
import { splitMenuItems } from './util'
import { HelpSatoshi } from './HelpSatoshi'

export type NavDrawerMenuItem = {
  icon: MaterialIconOutlinedName
  label: string
  onPress: () => void
}

export type NavDrawerMenuProps = {
  onMenuClose: () => void
}
export const NavDrawerMenu = observer(function NavDrawerMenu({ onMenuClose }: NavDrawerMenuProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  const { getMenuItems, menuLoading, userType } = useMenuItems()
  const menuItems = getMenuItems()

  if (menuLoading || !userType) {
    return null
  }

  const isContributor = userType === 'CONTRIBUTOR'

  if (!isContributor) {
    return (
      <AnimateTransition key="chat-menu-handle" type="from-bottom">
        <BlockView
          size={{ width: 312 }}
          padding={{
            horizontal: spacing[1],
            bottom: spacing[1],
            top: spacing[2],
          }}
          backgroundColor={color['utility-surface-3']}
          border={{ radius: 16 }}
        >
          <HelpSatoshi
            onBecomeContributorPress={() => {
              onMenuClose()
            }}
          />
        </BlockView>
      </AnimateTransition>
    )
  }

  const menuSplit = splitMenuItems(menuItems)

  return (
    <AnimateTransition key="chat-menu-handle" type="from-bottom">
      <BlockView padding={spacing[1]} backgroundColor={color['utility-surface-3']} border={{ radius: 16 }}>
        {menuSplit.map((menuRow, rowIndex) => (
          <BlockView
            key={`chat-menu-row-${rowIndex}`}
            size={{ width: '100%' }}
            direction="row"
            vAlign="center"
            margin={{ top: rowIndex === 0 ? 0 : spacing[1] }}
          >
            {menuRow.map((menuItem, columnIndex) => (
              <BlockView
                key={`chat-menu-column-${columnIndex}`}
                size={{ width: '50%' }}
                margin={{ right: columnIndex === 0 ? spacing[1] : 0 }}
              >
                <NavBadge
                  icon={menuItem.icon}
                  label={menuItem.label}
                  activated={menuItem.label === 'Chat'}
                  onPress={() => {
                    if (menuItem.label === 'Chat') {
                      onMenuClose()
                      return
                    }
                    menuItem.onPress()
                    onMenuClose()
                  }}
                />
              </BlockView>
            ))}
          </BlockView>
        ))}
      </BlockView>
    </AnimateTransition>
  )
})

export type NavDrawerMenuMobileProps = {
  userCredit: number
  onAddCreditPress: () => void
  userType?: 'USER' | 'CONTRIBUTOR'
  items: NavDrawerMenuItem[]
  onMenuClose: () => void
}

export const NavDrawerMenuMobile = observer(function NavDrawerMenuMobile({
  userCredit,
  onAddCreditPress,
  userType,
  items,
  onMenuClose,
}: NavDrawerMenuMobileProps) {
  /** States. */
  const { color } = useColor()
  const safeAreaInset = useSafeAreaInsets()
  const { screenSize, spacing } = useLayout()
  const [hideMenu, setHideMenu] = useState(false)
  const menuTranslateY = useSharedValue(0)

  const isContributor = userType === 'CONTRIBUTOR'

  const menuSplit = useMemo(() => splitMenuItems(items), [items])

  const screenHeight = screenSize.height
  const PanGesture = Gesture.Pan()
    .onUpdate((e) => {
      menuTranslateY.value = clampLeftWorklet(e.translationY, 0)
    })
    .onEnd((e) => {
      const isClosed = screenHeight - 100 < e.absoluteY

      menuTranslateY.value = withTiming(isClosed ? screenHeight : 0, { duration: isClosed ? 100 : 300 }, () => {
        if (isClosed) {
          runOnJS(onMenuClose)()
        }
      })
    })

  const menuContainerAnimatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: menuTranslateY.value }],
  }))

  const renderContent = () => {
    if (!isContributor) {
      return (
        <BlockView margin={{ bottom: spacing[3] }}>
          <HelpSatoshi
            onBecomeContributorPress={() => {
              setHideMenu(true)
            }}
          />
        </BlockView>
      )
    }

    return (
      <>
        {/* Menu Items. */}
        {menuSplit.map((menuRow, rowIndex) => (
          <BlockView
            key={`chat-mobile-menu-row-${rowIndex}`}
            size={{ width: '100%' }}
            direction="row"
            vAlign="center"
            margin={{ top: rowIndex === 0 ? 0 : spacing[1] }}
          >
            {menuRow.map((menuItem, columnIndex) => (
              <BlockView
                key={`chat-mobile-menu-column-${columnIndex}`}
                size={{ width: '50%' }}
                margin={{ right: columnIndex === 0 ? spacing[1] : 0 }}
              >
                <NavBadge
                  icon={menuItem.icon}
                  label={menuItem.label}
                  activated={menuItem.label === 'Chat'}
                  onPress={() => {
                    if (menuItem.label === 'Chat') {
                      setHideMenu(true)
                      return
                    }

                    menuItem.onPress()
                  }}
                />
              </BlockView>
            ))}
          </BlockView>
        ))}

        {/* Credit. */}
        <BlockView size={{ width: '100%' }} margin={{ top: spacing[2], bottom: spacing[3] }}>
          <NavControlDisplay
            leftIcon="add-circle"
            label={`${userCredit}`}
            chipLeftIcon="add"
            chipLabel="Add credits"
            onChipPress={onAddCreditPress}
            containerStyle={{ width: '100%', maxWidth: 370, justifyContent: 'center' }}
            labelStyle={{ flex: 0 }}
          />
        </BlockView>
      </>
    )
  }

  return (
    <BlockView flex vAlign="flex-end">
      <AnimatePresence exitBeforeEnter onExitComplete={onMenuClose}>
        <Pressable
          onPress={() => setHideMenu(true)}
          style={[
            StyleSheet.absoluteFill,
            { width: screenSize.width, height: screenSize.height, backgroundColor: 'transparent' },
          ]}
        />

        {!hideMenu && (
          <AnimateTransition key="chat-mobile-menu-handle" type="from-bottom" duration={200}>
            <GestureDetector gesture={PanGesture}>
              <Animated.View
                style={[
                  styles.menuContainer,
                  {
                    backgroundColor: color['utility-surface-2'],
                    paddingHorizontal: spacing[2],
                    paddingBottom: safeAreaInset.bottom,
                  },
                  menuContainerAnimatedStyle,
                ]}
              >
                {/* Grab bar. */}
                <BlockView size={{ width: '100%' }} hAlign="center" margin={{ top: 12, bottom: spacing[2] }}>
                  <BlockView
                    size={{ width: 37, height: 4 }}
                    backgroundColor={color['alpha-white-16']}
                    border={{ radius: 4 }}
                  />
                </BlockView>

                {renderContent()}
              </Animated.View>
            </GestureDetector>
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})

type Style = {
  menuContainer: ViewStyle
}

const styles = StyleSheet.create<Style>({
  menuContainer: {
    width: '100%',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    alignItems: 'center',
  },
})
