import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonPrimary, Text, useColor, useLayout } from 'core'
import { AccountRoutes, ContributeRoutes } from 'navigation/routes'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'

import { Card } from './Card'

type LifetimeRewardsProps = {
  satsEarned: number
  pointsEarned: number
  conversionRate: number
}

export const LifetimeRewards = observer(function LifetimeRewards({
  satsEarned,
  pointsEarned,
  conversionRate,
}: LifetimeRewardsProps) {
  /** States. */
  const { navigate } = useNavigation<NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.Statistics>>()
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()

  const isTablet = screenSize.width < breakPoints.md

  const onViewBalancePress = () => {
    // @ts-ignore
    navigate(AccountRoutes.MyAccount)
  }

  return (
    <Card highlight size={isTablet ? undefined : { minWidth: 340 }}>
      <Text.Roboto size="18" color={color['content-1']} strong style={{ marginBottom: spacing[1] }}>
        Lifetime rewards
      </Text.Roboto>

      <Text.Roboto size="32" strong color={color['content-1']}>
        {satsEarned.toLocaleString()}
        <Text.Roboto size="32" strong color={color['content-2']}>
          {' '}
          sats
        </Text.Roboto>
      </Text.Roboto>

      <BlockView margin={{ top: spacing[5] }} direction="row" hAlign="space-between" vAlign="center">
        <Text.Roboto size="14" color={color['content-2']}>
          Lifetime points:
        </Text.Roboto>
        <Text.Roboto size="14" color={color['content-1']}>
          {pointsEarned.toLocaleString()}
        </Text.Roboto>
      </BlockView>

      <BlockView
        margin={{ top: spacing[1], bottom: spacing[4] }}
        direction="row"
        hAlign="space-between"
        vAlign="center"
      >
        <Text.Roboto size="14" color={color['content-2']}>
          Sats per point:
        </Text.Roboto>
        <Text.Roboto size="14" color={color['content-1']}>
          {conversionRate.toLocaleString()}
        </Text.Roboto>
      </BlockView>

      <ButtonPrimary
        kind="filled"
        label="View Balance"
        onPress={onViewBalancePress}
        buttonStyle={{ backgroundColor: color.accent.brand }}
      />
    </Card>
  )
})
