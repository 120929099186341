import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import jwtDecode from 'jwt-decode'

import { useRootStore } from 'store/root.store'

type AuthProviderProps = {
  children?: React.ReactNode
}

export const AuthProvider = observer(function AuthProvider({ children }: AuthProviderProps) {
  /** States. */
  const { authStore, reset } = useRootStore()

  useEffect(() => {
    if (!authStore.authToken) {
      runInAction(() => reset())
      return
    }

    // Verify Auth Token.
    try {
      const jwt = jwtDecode<{ exp?: number }>(authStore.authToken)

      if (!jwt?.exp || jwt.exp * 1000 < Date.now()) {
        runInAction(() => reset())
      }
    } catch {
      runInAction(() => reset())
    }
  }, [authStore.authToken, reset])

  return children
})
