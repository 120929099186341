import { ActivityIndicator, StyleProp, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { BlurView } from 'expo-blur'

import { BlockView, Text, useColor, useLayout } from 'core'
import { getUserLeaderboardRank } from 'api/data-train/get-user-leaderboard-rank'

export const UserLeaderboardRank = observer(function UserLeaderboardRank() {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()
  const isMobile = screenSize.width < breakPoints.sm

  /** Get User Leaderboard Rank Query. */
  const { isLoading, isError, data } = useQuery({
    queryKey: 'get-user-leaderboard-rank',
    queryFn: getUserLeaderboardRank,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  /** Loading state. */
  if (isLoading) {
    return (
      <Container>
        <ActivityIndicator color={color['content-1']} />
      </Container>
    )
  }

  /** Error state. */
  if (isError || !data) {
    return null
  }

  const { daily, weekly, monthly, overall } = data
  const dailyRank = daily?.toLocaleString() ?? 'N/A'
  const weeklyRank = weekly?.toLocaleString() ?? 'N/A'
  const monthlyRank = monthly?.toLocaleString() ?? 'N/A'
  const overallRank = overall?.toLocaleString() ?? 'N/A'

  const renderRank = () => {
    if (isMobile) {
      return (
        <>
          <BlockView direction="row" margin={{ bottom: spacing[1] }}>
            <RankCard
              rank={overallRank}
              rankColor={color.accent.brand}
              title="Your overall rank"
              style={{ marginRight: spacing[1] }}
            />
            <RankCard rank={dailyRank} title="Your daily rank" />
          </BlockView>

          <BlockView direction="row">
            <RankCard rank={weeklyRank} title="Your weekly rank" style={{ marginRight: spacing[1] }} />
            <RankCard rank={monthlyRank} title="Your monthly rank" />
          </BlockView>
        </>
      )
    }

    return (
      <>
        <RankCard
          rank={overallRank}
          rankColor={color.accent.brand}
          title="Your overall rank"
          style={{ marginRight: spacing[1] }}
        />
        <RankCard rank={dailyRank} title="Your daily rank" style={{ marginRight: spacing[1] }} />
        <RankCard rank={weeklyRank} title="Your weekly rank" style={{ marginRight: spacing[1] }} />
        <RankCard rank={monthlyRank} title="Your monthly rank" />
      </>
    )
  }

  return <Container>{renderRank()}</Container>
})

const Container = ({ children }: { children: React.ReactNode }) => {
  /** States. */
  const { spacing, screenSize, breakPoints } = useLayout()
  const isMobile = screenSize.width < breakPoints.sm

  return (
    <BlockView
      size={{ width: '100%', minHeight: 88 }}
      margin={{ bottom: isMobile ? spacing[2] : spacing[3] }}
      direction={isMobile ? 'column' : 'row'}
      vAlign="center"
    >
      {children}
    </BlockView>
  )
}

type RankCardProps = {
  rank: string
  rankColor?: string
  title: string
  style?: StyleProp<ViewStyle>
}
const RankCard = observer(function RankCard({ rank, rankColor, title, style }: RankCardProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlurView
      intensity={80}
      tint="dark"
      style={[
        {
          flex: 1,
          padding: spacing[2],
          backgroundColor: color['utility-surface-2'],
          borderRadius: 16,
          borderWidth: 1,
          borderColor: color['utility-border-1'],
        },
        style,
      ]}
    >
      <Text.RobotoMono size="30" uppercase color={rankColor ?? color['content-1']} style={{ marginBottom: 4 }}>
        {rank}
      </Text.RobotoMono>

      <Text.Roboto size="14" color={color['content-2']}>
        {title}
      </Text.Roboto>
    </BlurView>
  )
})
