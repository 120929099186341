import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, useLayout } from 'core'
import { Title } from './Title'
import { TextCard } from './Card'

type WaasProps = {
  totalPointsEarned: number
  answersProvided: number
  answersAccepted: number
  answersRejected: number
}

export const Waas = observer(function Waas({
  totalPointsEarned,
  answersProvided,
  answersAccepted,
  answersRejected,
}: WaasProps) {
  /** States. */
  const { spacing, screenSize, breakPoints } = useLayout()

  const isTablet = screenSize.width < breakPoints.lg
  const screenPaddingHorizontal = isTablet ? spacing[2] : 60

  const waasStats = [
    {
      highlight: true,
      title: `Total points earned`,
      content: totalPointsEarned.toLocaleString(),
    },
    {
      highlight: false,
      title: `Answers provided`,
      content: answersProvided.toLocaleString(),
    },
    {
      highlight: false,
      title: `Answers accepted`,
      content: answersAccepted.toLocaleString(),
    },
    {
      highlight: false,
      title: `Answers rejected`,
      content: answersRejected.toLocaleString(),
    },
  ]
  return (
    <BlockView size={{ width: '100%' }} margin={{ top: spacing[5] }}>
      <BlockView padding={{ horizontal: screenPaddingHorizontal }}>
        <Title text="We Are All Satoshi" icon="group" />
      </BlockView>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: screenPaddingHorizontal }}
        style={{ marginTop: spacing[3] }}
      >
        {waasStats.map(({ highlight, title, content }, index) => {
          const isLast = waasStats.length - 1 === index
          return (
            <TextCard
              key={`waas-stat-${title}-${index}`}
              highlight={highlight}
              title={title}
              content={content}
              margin={{ right: isTablet ? 0 : isLast ? 0 : spacing[2] }}
            />
          )
        })}
      </ScrollView>
    </BlockView>
  )
})
