import { request } from 'api/request'

export type LnurlLoginData = {
  lnAddress: string
  lnAddressId: string
}

export const lnurlLogin = (): Promise<LnurlLoginData> => {
  return request.get('v1/account/lnurl/login')
}
