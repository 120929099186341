import { useState } from 'react'
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputChangeEventData,
  TextInputProps,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { MaterialIconOutlined, MaterialIconOutlinedName } from '../Icon'
import { TextStyles } from '../Text'
import { BlockView } from '../BlockView'

export type InputFieldState = 'default' | 'success' | 'error'

export type InputFieldProps = {
  inputRef?: React.RefObject<TextInput>

  size?: 'input-field-1' | 'input-field-2' | 'input-field-3'
  applyMinHeight?: boolean
  inputState?: InputFieldState

  leftIcon?: MaterialIconOutlinedName
  leftIconColor?: string
  rightIcon?: MaterialIconOutlinedName
  rightIconColor?: string

  value: string
  onValueChange: (value: string) => void
  placeholderText?: string
  disabled?: boolean

  otherInputProps?: Omit<TextInputProps, 'value' | 'onChangeText' | 'placeholder'>
}

export const InputField = observer(function InputField({
  inputRef,

  size = 'input-field-1',
  applyMinHeight,
  inputState = 'default',

  leftIcon,
  leftIconColor,
  rightIcon,
  rightIconColor,

  value,
  onValueChange,
  placeholderText,
  disabled,

  otherInputProps,
}: InputFieldProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const [inputFocused, setInputFocused] = useState(false)

  const adjustInputHeight = (e: LayoutChangeEvent | NativeSyntheticEvent<TextInputChangeEventData>) => {
    if (Platform.OS !== 'web') return
    if (!otherInputProps?.multiline) return

    // @ts-ignore
    const el = e.target || e.nativeEvent?.target
    if (el) {
      // @ts-ignore
      el.style.height = 0
      // @ts-ignore
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight
      // @ts-ignore
      el.style.height = `${newHeight}px`
    }
  }

  const backgroundColor = (() => {
    if (disabled) return color['utility-surface-3']
    return color['utility-surface-4']
  })()
  const defaultContainerStyle = (() => {
    if (size === 'input-field-1') return styles.inputField1Container
    if (size === 'input-field-2') return styles.inputField2Container
    return styles.inputField3Container
  })()
  const minHeightStyle = (() => {
    if (!applyMinHeight) return
    if (size === 'input-field-1') return styles.inputField1ContainerMinHeight
    if (size === 'input-field-2') return styles.inputField2ContainerMinHeight
    return styles.inputField3ContainerMinHeight
  })()
  const borderColor = (() => {
    if (inputState === 'success') return color.state.positive
    if (inputState === 'error') return color.state.critical
    if (disabled) return color['utility-border-2']
    if (inputFocused) return color['utility-border-1']

    return color['utility-border-2']
  })()

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="center"
      backgroundColor={backgroundColor}
      style={[defaultContainerStyle, minHeightStyle, { borderColor, opacity: disabled ? 0.24 : 1 }]}
    >
      {/* Left Icon. */}
      {!!leftIcon && (
        <MaterialIconOutlined
          name={leftIcon}
          size={24}
          color={leftIconColor ?? color['content-2']}
          style={{ marginRight: spacing[1] }}
        />
      )}

      {/* Input. */}
      <TextInput
        ref={inputRef}
        {...otherInputProps}
        value={value}
        onChangeText={onValueChange}
        maxFontSizeMultiplier={1.2}
        placeholder={placeholderText}
        placeholderTextColor={color['content-2']}
        onFocus={(e) => {
          setInputFocused(true)
          otherInputProps?.onFocus?.(e)
        }}
        onBlur={(e) => {
          setInputFocused(false)
          otherInputProps?.onBlur?.(e)
        }}
        onChange={adjustInputHeight}
        onLayout={adjustInputHeight}
        editable={!disabled}
        style={[
          // @ts-ignore
          Platform.OS === 'web' && { outlineWidth: 0, outline: 'none' },
          { color: color['content-1'] },
          otherInputProps?.style,
        ]}
      />

      {/* Right Icon. */}
      {!!rightIcon && (
        <MaterialIconOutlined
          name={rightIcon}
          size={24}
          color={rightIconColor ?? color['content-2']}
          style={{ marginLeft: spacing[1] }}
        />
      )}
    </BlockView>
  )
})

type Style = {
  inputFieldText: TextStyle

  inputField1Container: ViewStyle
  inputField1ContainerMinHeight: ViewStyle

  inputField2Container: ViewStyle
  inputField2ContainerMinHeight: ViewStyle

  inputField3Container: ViewStyle
  inputField3ContainerMinHeight: ViewStyle
}

const styles = StyleSheet.create<Style>({
  inputFieldText: {
    flex: 1,
    ...TextStyles.robotoRegular,
    ...TextStyles['text-14'],
    padding: 0,
    margin: 0,
  },

  inputField1Container: {
    padding: 16,
    borderRadius: 12,
    borderWidth: 1,
  },
  inputField1ContainerMinHeight: {
    minHeight: 56,
  },

  inputField2Container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 10,
    borderWidth: 1,
  },
  inputField2ContainerMinHeight: {
    minHeight: 44,
  },

  inputField3Container: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
    borderWidth: 1,
  },
  inputField3ContainerMinHeight: {
    minHeight: 32,
  },
})
