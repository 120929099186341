import { observer } from 'mobx-react-lite'
import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer'

export { NavTitle } from './NavTitle'

export type NavDrawerProps = {
  children: React.ReactNode
} & DrawerContentComponentProps

export const NavDrawer = observer(function NavDrawer({ children, ...drawerProps }: NavDrawerProps) {
  return (
    <DrawerContentScrollView
      {...drawerProps}
      contentContainerStyle={{ paddingBottom: 120 }}
      showsVerticalScrollIndicator={false}
    >
      {children}
    </DrawerContentScrollView>
  )
})
