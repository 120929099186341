import { useCallback, useMemo } from 'react'
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import { MotiView } from 'moti'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { AnimateTransition, BlockView, MaterialIconOutlined, Menu, MenuItem, Text, useColor, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { clampLeftWorklet } from 'utils/number'
import { LeaderboardFilter } from 'api/data-train/get-leaderboard'

import { getFilterName } from './Leaderboard/util'

type TimeFilterProps = {
  selectedFilter: LeaderboardFilter
  onFilterSelect: (filter: LeaderboardFilter) => void
}

type LeaderboardNavProp = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.Leaderboard>,
    DrawerNavigationProp<ContributeAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const TimeFilter = observer(function TimeFilter({ selectedFilter, onFilterSelect }: TimeFilterProps) {
  /** States. */
  const navigation = useNavigation<LeaderboardNavProp>()
  const safeAreaInset = useSafeAreaInsets()
  const { color } = useColor()
  const { screenSize, spacing } = useLayout()
  const menuTranslateY = useSharedValue(0)

  const closeOverlay = useCallback(() => {
    navigation.setParams({
      showTimeFilter: false,
    })
  }, [navigation])

  const screenHeight = screenSize.height
  const PanGesture = Gesture.Pan()
    .onUpdate((e) => {
      menuTranslateY.value = clampLeftWorklet(e.translationY, 0)
    })
    .onEnd((e) => {
      const isClosed = screenHeight - 100 < e.absoluteY

      menuTranslateY.value = withTiming(isClosed ? screenHeight : 0, { duration: isClosed ? 100 : 300 }, () => {
        if (isClosed) {
          runOnJS(closeOverlay)()
        }
      })
    })

  const menuContainerAnimatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: menuTranslateY.value }],
  }))

  const menuItem = useMemo<MenuItem[]>(() => {
    const menus: LeaderboardFilter[] = ['overall', 'daily', 'weekly', 'monthly']

    return menus.map<MenuItem>((menu) => ({
      id: menu,
      label: getFilterName(menu),
      iconRight:
        menu === selectedFilter ? <MaterialIconOutlined name="check" size={24} color={color['content-1']} /> : null,
      onPress: () => {
        onFilterSelect(menu)
        closeOverlay()
      },
    }))
  }, [color, selectedFilter, onFilterSelect, closeOverlay])

  return (
    <>
      {/* Dark Overlay. */}
      <MotiView
        key="mobile-time-filter-overlay"
        from={{ opacity: 0 }}
        animate={{ opacity: 0.64 }}
        exit={{ opacity: 0 }}
        style={[StyleSheet.absoluteFill, { flex: 1, backgroundColor: color['always-black'] }]}
      >
        <TouchableOpacity activeOpacity={1} onPress={closeOverlay} style={{ flex: 1 }} />
      </MotiView>

      <AnimateTransition key="mobile-time-filter" type="from-bottom" duration={200}>
        <GestureDetector gesture={PanGesture}>
          <Animated.View
            style={[
              styles.menuContainer,
              {
                backgroundColor: color['utility-surface-2'],
                paddingHorizontal: spacing[2],
                paddingBottom: safeAreaInset.bottom + spacing[2],
              },
              menuContainerAnimatedStyle,
            ]}
          >
            {/* Grab bar. */}
            <BlockView size={{ width: '100%' }} hAlign="center" margin={{ top: 12, bottom: spacing[2] }}>
              <BlockView
                size={{ width: 37, height: 4 }}
                backgroundColor={color['alpha-white-16']}
                border={{ radius: 4 }}
              />
            </BlockView>

            {/* Title. */}
            <BlockView
              size={{ width: '100%' }}
              hAlign="center"
              vAlign="center"
              padding={{ vertical: 20, horizontal: 10 }}
            >
              <Text.Roboto color={color['content-1']} size="14" strong>
                Filter by
              </Text.Roboto>
            </BlockView>

            {/* Filter items. */}
            <Menu items={menuItem} menuStyle={styles.menu} itemStyle={styles.menuItem} />
          </Animated.View>
        </GestureDetector>
      </AnimateTransition>
    </>
  )
})

type Style = {
  menuContainer: ViewStyle
  menu: ViewStyle
  menuItem: ViewStyle
}

const styles = StyleSheet.create<Style>({
  menuContainer: {
    width: '100%',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    alignItems: 'center',
  },
  menu: {
    borderWidth: 0,
  },
  menuItem: {
    paddingVertical: 20,
    height: 56,
  },
})
