import { observer } from 'mobx-react-lite'

import { BlockView, MaterialIconOutlined, Text, useColor } from 'core'

export type UserAvatarProps = {
  size?: number
  fullName: string | null
}

export const UserAvatar = observer(function UserAvatar({ size = 40, fullName }: UserAvatarProps) {
  /** States. */
  const { color } = useColor()

  if (!fullName) {
    return (
      <BlockView
        size={size}
        border={{ radius: size / 2 }}
        backgroundColor={color['alpha-white-8']}
        hAlign="center"
        vAlign="center"
      >
        <MaterialIconOutlined name="group" size={24} color={color['content-2']} />
      </BlockView>
    )
  }

  return (
    <BlockView
      size={size}
      border={{ radius: size / 2 }}
      backgroundColor={color['alpha-white-8']}
      hAlign="center"
      vAlign="center"
    >
      <Text.RobotoMono color={color['content-2']} size="20" uppercase>
        {fullName.charAt(0)}
      </Text.RobotoMono>
    </BlockView>
  )
})
