export const splitMenuItems = <P>(items: P[]) => {
  const chunkSize = 2 // Display 2 items per row.

  return items.reduce<P[][]>((acc, cur, i) => {
    const index = Math.floor(i / chunkSize)
    if (!acc[index]) {
      acc[index] = []
    }
    acc[index].push(cur)
    return acc
  }, [])
}
