import { ActivityIndicator, Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { RefObject } from 'react'
import { Text } from '../../Text'
import { TextSize } from '../../Text/type'
import { MaterialIconOutlined, MaterialIconOutlinedName } from '../../Icon'

export type ButtonPrimaryProps = {
  kind?: 'filled' | 'outlined' | 'minimal' // Minimal is only allowed for button-3 size.
  size?: 'button-1' | 'button-2' | 'button-3'
  label: string
  labelColor?: string
  labelSize?: TextSize
  leftIcon?: MaterialIconOutlinedName
  rightIcon?: MaterialIconOutlinedName
  borderColor?: string // Only for "outlined" type.

  loading?: boolean
  disabled?: boolean
  onPress: () => void

  applyMinWidth?: boolean
  buttonStyle?: StyleProp<ViewStyle>
  buttonRef?: RefObject<View>
}

export const ButtonPrimary = observer(function ButtonPrimary({
  kind = 'filled',
  size = 'button-1',
  label,
  labelColor,
  labelSize,
  leftIcon,
  rightIcon,
  borderColor,
  loading,
  disabled,
  onPress,

  applyMinWidth = true,
  buttonStyle,
  buttonRef,
}: ButtonPrimaryProps) {
  /** States. */
  const { color } = useColor()
  const pressedIn = useSharedValue(0)

  const showPressedState = () => {
    pressedIn.value = withTiming(1, { duration: 150 })
  }
  const showPressedOutState = () => {
    pressedIn.value = withTiming(0, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => {
    if (disabled) return { opacity: 0.24 }
    return {
      opacity: interpolate(pressedIn.value, [0, 1], [1, 0.8]),
    }
  })

  const sizeStyle = ((): ViewStyle => {
    if (size === 'button-1') return styles.button1
    if (size === 'button-2') return styles.button2
    return styles.button3
  })()

  const borderStyle = ((): ViewStyle => {
    if (kind === 'outlined') {
      return {
        borderWidth: 1,
        borderColor: borderColor ?? color['utility-border-1'],
      }
    }
    return {}
  })()

  const containerBackgroundStyle = ((): ViewStyle => {
    if (kind === 'filled') return { backgroundColor: color['content-1'] }
    return { backgroundColor: 'transparent' }
  })()

  const _labelColor = (() => {
    if (labelColor) return labelColor
    return kind === 'filled' ? color['content-4'] : color['content-1']
  })()

  const minWidthStyle = (() => {
    if (!applyMinWidth) return
    if (size === 'button-1') return styles.button1MinWidth
    if (size === 'button-2') return styles.button2MinWidth
    return styles.button3MinWidth
  })()

  const renderContent = () => {
    if (loading) {
      return <ActivityIndicator color={_labelColor} />
    }

    return (
      <>
        {/* Left Icon. */}
        {!!leftIcon && (
          <MaterialIconOutlined name={leftIcon} size={24} color={_labelColor} style={{ marginRight: 4 }} />
        )}

        {/* Label. */}
        <Text.Roboto
          color={_labelColor}
          strong={size !== 'button-3'}
          size={(() => {
            if (labelSize) return labelSize
            return size === 'button-3' ? '13' : '15'
          })()}
        >
          {label}
        </Text.Roboto>

        {/* Right Icon. */}
        {!!rightIcon && (
          <MaterialIconOutlined name={rightIcon} size={24} color={_labelColor} style={{ marginLeft: 4 }} />
        )}
      </>
    )
  }

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showPressedState}
      onHoverOut={showPressedOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
      disabled={disabled}
      ref={buttonRef}
    >
      <Animated.View
        style={[
          styles.container,
          sizeStyle,
          borderStyle,
          minWidthStyle,
          containerBackgroundStyle,
          containerAnimatedStyle,
          buttonStyle,
        ]}
      >
        {renderContent()}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
  button1: ViewStyle
  button1MinWidth: ViewStyle

  button2: ViewStyle
  button2MinWidth: ViewStyle

  button3: ViewStyle
  button3MinWidth: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button1: {
    minHeight: 44,
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 10,
  },
  button1MinWidth: {
    minWidth: 144,
  },

  button2: {
    minHeight: 32,
    paddingHorizontal: 16,
    paddingVertical: 4,
    borderRadius: 8,
  },
  button2MinWidth: {
    minWidth: 128,
  },

  button3: {
    minHeight: 28,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 6,
  },
  button3MinWidth: {
    minWidth: 86,
  },
})
