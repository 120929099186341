import React, { useRef, useState } from 'react'
import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'

import { AnimateTransition, ChatInput, ChatOutLoading, ChatOutput, UserAvatar } from 'core'
import { useRootStore } from 'store/root.store'
import { ConversationMessage } from 'api/chat/get-conversation-messages'

import { ScrollContainer } from './Container'
import { CreditNotice } from './CreditNotice'

type ExistingConversationProps = {
  conversationId: string
  messages: ConversationMessage[]
  isFromNewConversation: boolean
  isFromStrictMessage: boolean
  userFullName: string | null
  messageStreamLoading: boolean
  isUserOutOfCredit: boolean
  userCanSelectChatModel: boolean
  userCanUseAudioPlayback: boolean
  selectedModel?: string | null
}

const OPTIMISTIC_RESPONSE_ID = {
  USER: `user-optimistic-message`,
  SOS: `sos-optimistic-message`,
}

export const ExistingConversation = observer(function ExistingConversation({
  conversationId,
  messages,
  isFromNewConversation,
  isFromStrictMessage,
  userFullName,
  messageStreamLoading,
  isUserOutOfCredit,
  userCanSelectChatModel,
  userCanUseAudioPlayback,
  selectedModel,
}: ExistingConversationProps) {
  /** States. */
  const { uiToggleStore } = useRootStore()
  const messageScrollViewRef = useRef<ScrollView>(null)
  const previousConversationId = useRef(conversationId)
  const [lastReadMessageIndex] = useState(messages.length)

  const generateUserMessageKey = (index: number) => `user-${conversationId}-${index}`
  const generateSosMessageKey = (index: number) => `sos-${conversationId}-${index}`

  return (
    <ScrollContainer
      scrollViewRef={messageScrollViewRef}
      onContentSizeChange={() => {
        const isLoadingFromSameConversation = previousConversationId.current === conversationId
        messageScrollViewRef.current?.scrollToEnd?.({ animated: isLoadingFromSameConversation })

        if (!isLoadingFromSameConversation) {
          previousConversationId.current = conversationId
        }
      }}
    >
      {messages.map((message, index) => {
        if (message.isFromUser) {
          return (
            <AnimateTransition key={generateUserMessageKey(index)} enabled={!isFromNewConversation} type="from-bottom">
              <ChatInput message={message.text} avatar={<UserAvatar fullName={userFullName} />} />
            </AnimateTransition>
          )
        }

        if (message.isFromMentor) {
          const shouldStreamText = (() => {
            const isLastMessage = messages.length - 1 === index
            const didNotReadLastMessage = lastReadMessageIndex < messages.length

            /**
             * If the message is from Strict Response, the response is not yet "streamed" to the user.
             * So if it's the last message, we need to "stream" the response.
             */
            if (isFromStrictMessage && isLastMessage) return true
            return didNotReadLastMessage && isLastMessage
          })()
          const isFromOptimisticResponse = message.id === OPTIMISTIC_RESPONSE_ID.SOS

          return (
            <AnimateTransition key={generateSosMessageKey(index)} enabled={!isFromNewConversation} type="from-bottom">
              <ChatOutput
                conversationId={conversationId}
                messageId={message.id}
                message={message.text}
                showReaction={!isFromOptimisticResponse}
                reaction={message.reaction ?? null}
                replySource={userCanSelectChatModel ? message.replySource : null}
                streamText={shouldStreamText}
                showPlayback={!!userCanUseAudioPlayback && !isFromOptimisticResponse}
                voiceUrl={message.voiceUrl}
                metadata={message.replyMetadata}
                replyDetails={message.replyDetails}
                selectedModel={selectedModel}
                isFromModel={message.isFromMentor}
              />
            </AnimateTransition>
          )
        }

        return null
      })}

      {/* Message Loading. */}
      {messageStreamLoading && <ChatOutLoading />}

      {/* Out of Credit notice. */}
      {!!isUserOutOfCredit && uiToggleStore.outOfCreditNotice && (
        <AnimateTransition key="chat-out-of-credit-notice" type="from-bottom">
          <CreditNotice />
        </AnimateTransition>
      )}
    </ScrollContainer>
  )
})
