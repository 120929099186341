import { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { useQuery } from 'react-query'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, ButtonPrimary, MaterialIconOutlined, Text, useColor, useLayout } from 'core'
import { getUserChatModels } from 'api/chat/get-user-chat-models'
import { useNavigation } from '@react-navigation/native'
import { ChatRoutes } from '../../../../navigation/routes'

type ModelSwitcherProps = {
  selectedModel: string | null
  onModelSelect: (model: string | null) => void
  isNewConversation?: boolean
}

export const ModelSwitcher = observer(function ModelSwitcher({
  selectedModel,
  onModelSelect,
  isNewConversation,
}: ModelSwitcherProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [showDropdown, setShowDropdown] = useState(false)
  const [currentSelectedModelId, setCurrentSelectedModelId] = useState('')
  const { navigate } = useNavigation<any>()

  onModelSelect(selectedModel)

  /** User Chat Models. */
  const { isLoading, isError, data, isRefetching } = useQuery({
    queryKey: `get-user-chat-models`,
    queryFn: getUserChatModels,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (result) => {
      // Find first enabled model
      const firstEnabledModel = result.find((r) => !!r.enabled)

      // Normalize selected model value
      const selectedModelNormalized = selectedModel?.toUpperCase()

      // Make sure selected model exists and is enabled
      const selectedModelExists = result.find((r) => r.id === selectedModelNormalized && !!r.enabled)

      // console.log('selectedModelExists', selectedModelExists)

      if (selectedModelExists) {
        setCurrentSelectedModelId(selectedModelExists.id)
        onModelSelect(selectedModelExists.id)
      } else if (firstEnabledModel) {
        setCurrentSelectedModelId(firstEnabledModel.id)
        onModelSelect(firstEnabledModel.id)
      }
    },
  })

  if (isLoading || isRefetching || isError || !data) {
    return null
  }

  const currentChatModel = (() => {
    const item = data.find((d) => d.id === selectedModel?.toUpperCase() ?? d.id === currentSelectedModelId)
    if (item) return item

    // If selected model is not found, find first enabled model
    return data.find((r) => !!r.enabled)
  })()

  // console.log('currentChatModel-----', currentChatModel)

  // If current chat model is not the same as selected model, update selected model
  if (currentChatModel && currentChatModel.id !== selectedModel){
    onModelSelect(currentChatModel.id)
    selectedModel = currentChatModel.id
  }

  const onModelSelectClick = (id: string) => {
    // If it's existing conversation and user is selecting another model, start new chat with that model
    if (id !== selectedModel) {
      onModelSelect(id)
      navigate(ChatRoutes.Conversation, { id: `new-chat`, model: id })
    } else onModelSelect(id)
  }

  return (
    <BlockView
      size={{ width: '100%' }}
      backgroundColor="transparent"
      padding={{ left: spacing[2], top: spacing[2], bottom: spacing[1] }}
      style={{ zIndex: 100 }}
    >
      <BlockView size={{ width: '100%' }} hAlign="flex-start" backgroundColor="transparent">
        {/* Selected model. */}
        <ButtonPrimary
          kind="filled"
          label={currentChatModel?.name ?? ''}
          labelSize="16"
          rightIcon={showDropdown ? 'arrow-drop-down' : 'arrow-right'}
          onPress={() => setShowDropdown((prev) => !prev)}
          buttonStyle={{
            backgroundColor: color['alpha-white-4'],
            paddingLeft: spacing[2],
            paddingRight: spacing[1],
            paddingVertical: spacing[1],
          }}
          labelColor={color['content-1']}
          applyMinWidth={false}
        />
      </BlockView>

      <BlockView>
        <AnimatePresence exitBeforeEnter>
          {showDropdown && (
            <AnimateTransition
              key="chat-model-dropdown"
              type="from-top"
              style={{
                width: 380,
                maxWidth: screenSize.width - spacing[4],
                backgroundColor: color['utility-surface-3'],
                borderRadius: 8,
                borderColor: color['utility-border-3'],
                borderWidth: 1,
                position: 'absolute',
                top: spacing[1],
              }}
            >
              {data.map(({ id, name, description, enabled }, index) => {
                const isLast = data.length - 1 === index
                const isCurrentModel = id === selectedModel

                return (
                  <TouchableOpacity
                    key={`chat-model-dropdown-item-${index}-${id}`}
                    onPress={() => {
                      setShowDropdown(false)
                      onModelSelectClick(id)
                    }}
                    disabled={!enabled}
                    style={{
                      padding: spacing[2],
                      opacity: enabled ? 1 : 0.24,
                      borderBottomColor: color['utility-border-3'],
                      borderBottomWidth: isLast ? 0 : 1,
                    }}
                  >
                    <BlockView direction="row" vAlign="center" margin={{ bottom: 4 }}>
                      <Text.Roboto size="15" color={color['content-1']} strong numberOfLines={1} style={{ flex: 1 }}>
                        {name}
                      </Text.Roboto>

                      {isCurrentModel && (
                        <MaterialIconOutlined name="check-circle" color={color.accent.brand} size={20} />
                      )}
                    </BlockView>

                    <Text.Roboto size="15" color={color['content-2']}>
                      {description}
                    </Text.Roboto>
                  </TouchableOpacity>
                )
              })}
            </AnimateTransition>
          )}
        </AnimatePresence>
      </BlockView>
    </BlockView>
  )
})
