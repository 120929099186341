import { makeAutoObservable } from 'mobx'
import { makePersistable, isHydrated, clearPersistedStore } from 'mobx-persist-store'

export class AuthStore {
  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'AuthStore',
      properties: ['authToken'],
    })
  }

  authToken: string | null = null
  setAuthToken = (token: string | null) => {
    this.authToken = token
  }

  get isHydrated() {
    return isHydrated(this)
  }

  reset = () => {
    clearPersistedStore(true)
    this.authToken = null
  }
}
