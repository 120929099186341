import { request } from 'api/request'

type FeatureFlag = {
  enabled: boolean
}
export type GetAccountMeData = {
  id: string
  fullName: string | null
  email: string | null
  twitterUsername: string | null
  hasLightningAuth: boolean
  hasNostrAuth: boolean
  isAnonymous: boolean
  tcAcceptedAt: string | null
  credits: number
  balance: {
    earning: number
    total: number
  }
  type: 'USER' | 'CONTRIBUTOR'
  featureFlags?: {
    CHAT_SHOW_SELECT_MODEL?: FeatureFlag
    CONTRIBUTION_NO_SECOND_BEST?: FeatureFlag
    CHAT_PLAYBACK_AUDIO?: FeatureFlag
    DISPLAY_GEYSER_POPUP?: FeatureFlag
  } | null
}

export const getAccountMe = (): Promise<GetAccountMeData> => {
  return request.get('v1/account/me')
}
