import { useState } from 'react'
import { Image } from 'expo-image'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, ButtonIcon, ButtonPrimary, Text, useColor, useLayout } from 'core'
import { CampaignRoutes } from 'navigation/routes'
import { RootAppRoutesProps, CampaignAppRoutesProps } from 'navigation/stack/root'
import { InAppBrowser } from '../../../utils/browser'
// import { InAppBrowser } from 'utils/browser'

type ModalProps = CompositeScreenProps<
  NativeStackScreenProps<CampaignAppRoutesProps, CampaignRoutes.Geyser>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const CampaignGeyser = observer(function CampaignGeyser({ navigation }: ModalProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [closeModal, setCloseModal] = useState(false)

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="dtv-how-to-modal" type="from-bottom" duration={250}>
            <BlockView
              size={{ width: 350, maxWidth: screenSize.width - spacing[4] }}
              backgroundColor={color['utility-surface-3']}
              border={{ radius: 16 }}
              style={{ overflow: 'hidden' }}
            >
              {/*
               * @TODO - ADD THE PROPER GEYSER CAMPAIGN IMAGE WHEN AVAILABLE.
               */}
              <Image
                source={require('assets/leaderboard-bg/satoshi-bg-leaderboard.png')}
                style={{ height: 250, width: '100%' }}
                contentFit="contain"
                contentPosition="center"
                transition={300}
              />

              {/* Close Button. */}
              <BlockView position={{ top: spacing[1], right: spacing[1] }}>
                <ButtonIcon
                  kind="minimal"
                  iconName="close"
                  iconColor={color['content-2']}
                  onPress={() => setCloseModal(true)}
                />
              </BlockView>

              {/* Content. */}
              <BlockView padding={spacing[2]}>
                {/* Title. */}
                <Text.Gridular size="24" color={color['content-1']}>
                  We're live on Geyser!
                </Text.Gridular>

                {/* Content. */}
                <Text.Roboto
                  size="16"
                  color={color['content-2']}
                  style={{ marginTop: spacing[1], marginBottom: spacing[2] }}
                >
                  {`Contribute to our Geyser crowdfunding campaign and grab one of the limited edition collectible artworks by Bitcoin Trading Cards, a copy of the new AI book “21 Questions” co-written by Satoshi himself, and exclusive Nostr badges.`}
                  {`\n\nAll proceeds go to contributor rewards to help further train Satoshi!`}
                </Text.Roboto>

                {/* Button. */}
                <BlockView hAlign="flex-start">
                  <ButtonPrimary
                    size="button-2"
                    kind="filled"
                    label="Contribute"
                    leftIcon="electric-bolt"
                    labelColor={color['content-4']}
                    buttonStyle={{ backgroundColor: color.accent.brand }}
                    onPress={() => {
                      InAppBrowser.open(`https://geyser.fund/project/spiritofsatoshi`)
                    }}
                    applyMinWidth={false}
                  />
                </BlockView>
              </BlockView>
            </BlockView>
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
