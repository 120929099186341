import { observer } from 'mobx-react-lite'

import { BlockView, ButtonPrimary, Text, useColor, useLayout } from 'core'

type ChatConversationErrorProps = {
  refreshing: boolean
  onRefresh: () => void
}

export const ChatConversationError = observer(function ChatConversationError({
  onRefresh,
  refreshing,
}: ChatConversationErrorProps) {
  /** States, */
  const { spacing } = useLayout()
  const { color } = useColor()

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <BlockView size={{ width: 300 }}>
        <Text.Roboto color={color['content-2']} size="16" style={{ textAlign: 'center', marginBottom: spacing[2] }}>
          Something went wrong. Please try refreshing the page
        </Text.Roboto>

        <ButtonPrimary kind="outlined" label="Refresh" onPress={onRefresh} loading={refreshing} />
      </BlockView>
    </BlockView>
  )
})
