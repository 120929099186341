import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import PieChart from 'react-native-pie-chart'
import ColorJS from 'color'

import { BlockView, BlockViewProps, Text, useLayout, useColor } from 'core'
import { Card } from './Card'

type PointsOverviewProps = {
  waasPoints: number
  dtvPoints: number
  fudBusterPoints: number
  noSecondBestPoints: number | null // If null, user has no permission to use the feature.
}

type PointData = {
  type: 'waas' | 'dtv' | 'fudbuster' | 'nsb'
  point: number
}

export const PointsOverview = observer(function PointsOverview({
  dtvPoints,
  fudBusterPoints,
  waasPoints,
  noSecondBestPoints,
}: PointsOverviewProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()

  const isTablet = screenSize.width < breakPoints.lg

  const chartData = useMemo(() => {
    let points: PointData[] = [
      {
        type: 'dtv',
        point: dtvPoints,
      },
      {
        type: 'waas',
        point: waasPoints,
      },
      {
        type: 'fudbuster',
        point: fudBusterPoints,
      },
    ]

    if (noSecondBestPoints !== null) {
      points = [
        ...points,
        {
          type: 'nsb',
          point: noSecondBestPoints,
        },
      ]
    }

    const total = points.reduce((prev, curr) => prev + curr.point, 0)
    const calculatePercentage = (points: number) => (points / total) * 100

    return points.map(({ type, point }) => {
      const percentage = calculatePercentage(point)
      return {
        name: (() => {
          switch (type) {
            case 'dtv':
              return `Don't Trust, Verify`
            case 'waas':
              return `We are All Satoshi`
            case 'fudbuster':
              return `FudBuster`
            case 'nsb':
              return `No Second Best`
            default:
              return ''
          }
        })(),
        points: point,
        percentage: Math.round(percentage),
        percentageRaw: percentage,
        color: (() => {
          switch (type) {
            case 'waas':
              return color.accent.brand
            case 'dtv':
              return `rgba(45, 153, 255, 1)`
            case 'fudbuster':
              return `rgba(130, 106, 249, 1)`
            case 'nsb':
              return color.accent.papaya
            default:
              return color['content-2']
          }
        })(),
      }
    })
  }, [color, dtvPoints, fudBusterPoints, waasPoints, noSecondBestPoints])

  const zeroChartData = chartData.every(({ points }) => points <= 0)
  const renderPieChart = () => {
    const pieSize = (() => {
      if (screenSize.width < breakPoints.xs) {
        return 150
      }
      if (screenSize.width >= breakPoints.lg && screenSize.width < 1140) {
        return 130
      }
      return 180
    })()

    // User has no points at all.
    if (zeroChartData) {
      const series = noSecondBestPoints === null ? [2, 5, 4] : [2, 5, 4, 3]
      return (
        <PieChart
          widthAndHeight={pieSize}
          series={series}
          sliceColor={chartData.map((d) => ColorJS(d.color).grayscale().hex())}
        />
      )
    }
    return (
      <PieChart
        widthAndHeight={pieSize}
        series={chartData.map((d) => d.points)}
        sliceColor={chartData.map((d) => d.color)}
      />
    )
  }

  const renderLegend = () => {
    const paddingLeft = (() => {
      if (screenSize.width < 375) {
        return spacing[1]
      }
      if (screenSize.width < breakPoints.lg) {
        return spacing[4]
      }
      if (screenSize.width >= breakPoints.lg && screenSize.width < 1140) {
        return spacing[2]
      }
      return spacing[5]
    })()

    if (zeroChartData) {
      return (
        <BlockView flex padding={{ left: paddingLeft }}>
          {chartData
            .map((d) => ({
              name: d.name,
              color: ColorJS(d.color).grayscale().hex(),
            }))
            .map((d, index) => (
              <Legend
                key={`zero-data-chart-legend-${index}`}
                color={d.color}
                name={d.name}
                margin={{ bottom: chartData.length - 1 === index ? 0 : 14 }}
              />
            ))}
        </BlockView>
      )
    }

    return (
      <BlockView flex padding={{ left: paddingLeft }}>
        {chartData.map(({ name, color, percentage, percentageRaw }, index) => (
          <Legend
            key={`chart-legend-${index}`}
            percentage={percentage}
            percentageRaw={percentageRaw}
            color={color}
            name={name}
            margin={{ bottom: chartData.length - 1 === index ? 0 : 14 }}
          />
        ))}
      </BlockView>
    )
  }

  return (
    <Card highlight flex size={isTablet ? undefined : { maxWidth: 660 }}>
      <Text.Roboto size="18" color={color['content-1']} strong style={{ marginBottom: 4 }}>
        Points Overview
      </Text.Roboto>

      <Text.Roboto size="14" color={color['content-2']}>
        Points earned per tool
      </Text.Roboto>

      <BlockView flex direction="row" vAlign="center" margin={{ top: spacing[2] }}>
        {renderPieChart()}

        {/* Legend. */}
        {renderLegend()}
      </BlockView>
    </Card>
  )
})

type LegendProps = {
  percentage?: number
  percentageRaw?: number
  color: string
  name: string
} & BlockViewProps

const Legend = observer(function Legend({ color, name, percentage, percentageRaw, ...blockViewProps }: LegendProps) {
  /** States. */
  const { color: globalColor } = useColor()
  const percentageText = (() => {
    if (percentageRaw === undefined || percentage === undefined) return null

    if (percentageRaw < 1 && percentageRaw > 0) {
      return `< 1%`
    }
    return `${percentage}%`
  })()

  return (
    <BlockView {...blockViewProps}>
      {/* Color indicator. */}
      <BlockView size={10} border={{ radius: 5 }} backgroundColor={color} margin={{ bottom: 6 }} />

      <Text.Roboto size="11" color={globalColor['content-1']}>
        {!!percentageText && (
          <Text.Roboto size="11" color={globalColor['content-1']} strong>{`${percentageText} `}</Text.Roboto>
        )}
        {name}
      </Text.Roboto>
    </BlockView>
  )
})
