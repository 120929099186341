import { ActivityIndicator, Pressable, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { Text } from '../../Text'
import { MaterialIconOutlined, MaterialIconOutlinedName } from '../../Icon'

export type ButtonFabProps = {
  label?: string
  labelColor?: string
  leftIcon?: MaterialIconOutlinedName
  leftIconStyle?: StyleProp<TextStyle>
  rightIcon?: MaterialIconOutlinedName
  rightIconStyle?: StyleProp<TextStyle>
  borderColor?: string
  backgroundColor?: string
  animatedBackgroundColor?: {
    pressedIn: string
    pressedOut: string
  }

  loading?: boolean
  disabled?: boolean
  onPress: () => void

  applyMinWidth?: boolean
  containerStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
}

export const ButtonFab = observer(function ButtonFab({
  label,
  labelColor,
  leftIcon,
  leftIconStyle,
  rightIcon,
  rightIconStyle,
  borderColor,
  backgroundColor,
  animatedBackgroundColor,
  loading,
  disabled,
  onPress,

  applyMinWidth = true,
  containerStyle,
  buttonStyle,
}: ButtonFabProps) {
  /** States. */
  const { color } = useColor()
  const pressedIn = useSharedValue(0)

  const showPressedState = () => {
    pressedIn.value = withTiming(1, { duration: 150 })
  }
  const showPressedOutState = () => {
    pressedIn.value = withTiming(0, { duration: 150 })
  }

  const containerBackgroundStyle = backgroundColor ?? color['utility-surface-3']
  const containerAnimatedStyle = useAnimatedStyle<ViewStyle>(() => {
    if (disabled)
      return {
        opacity: 0.24,
        backgroundColor: containerBackgroundStyle,
      }

    return {
      opacity: interpolate(pressedIn.value, [0, 1], [1, 0.8]),
      backgroundColor: (() => {
        if (!animatedBackgroundColor) return containerBackgroundStyle
        return interpolateColor(
          pressedIn.value,
          [0, 1],
          [animatedBackgroundColor.pressedOut, animatedBackgroundColor.pressedIn]
        )
      })(),
    }
  })

  const borderStyle = ((): ViewStyle => {
    if (borderColor) {
      return {
        borderWidth: 1,
        borderColor: borderColor ?? color['utility-border-1'],
      }
    }
    return {}
  })()

  const _labelColor = labelColor ?? color['content-1']

  const renderContent = () => {
    if (loading) {
      return <ActivityIndicator color={_labelColor} />
    }

    return (
      <>
        {/* Left Icon. */}
        {!!leftIcon && (
          <MaterialIconOutlined
            name={leftIcon}
            size={24}
            color={_labelColor}
            style={[{ marginRight: 4 }, leftIconStyle]}
          />
        )}

        {/* Label. */}
        {!!label && (
          <Text.Roboto color={_labelColor} strong size="15">
            {label}
          </Text.Roboto>
        )}

        {/* Right Icon. */}
        {!!rightIcon && (
          <MaterialIconOutlined
            name={rightIcon}
            size={24}
            color={_labelColor}
            style={[{ marginLeft: 4 }, rightIconStyle]}
          />
        )}
      </>
    )
  }

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showPressedState}
      onHoverOut={showPressedOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
      disabled={disabled}
      style={containerStyle}
    >
      <Animated.View
        style={[
          styles.container,
          styles.button,
          borderStyle,
          applyMinWidth && styles.buttonMinWidth,
          containerAnimatedStyle,
          buttonStyle,
        ]}
      >
        {renderContent()}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
  button: ViewStyle
  buttonMinWidth: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    minHeight: 56,
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 44,
  },
  buttonMinWidth: {
    minWidth: 144,
  },
})
