import { StyleSheet, TextStyle } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonIcon, ButtonPrimary, ButtonPrimaryProps, Tab, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

export type Modal1Tab<TabShape = string> = {
  id: TabShape
  title: string
}

export type Modal1Props<TabShape = string> = {
  title: string
  showTitleClose?: boolean
  onTitleClosePress?: () => void

  tabs: Modal1Tab<TabShape>[]
  selectedTabId: TabShape
  onTabSelect: (tab: TabShape) => void
  renderTabScreen: (tab: Modal1Tab<TabShape>) => React.ReactNode

  footerActions?: ButtonPrimaryProps[]
  footerCustom?: React.ReactNode
  footerDivider?: boolean
}

export const Modal1 = observer(function Modal1<TabShape = string>({
  title,
  showTitleClose,
  onTitleClosePress,
  tabs,
  selectedTabId,
  onTabSelect,
  renderTabScreen,
  footerActions,
  footerCustom,
  footerDivider,
}: Modal1Props<TabShape>) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()

  const currentTab = tabs.find((tab) => tab.id === selectedTabId)

  const renderFooter = () => {
    if (footerCustom) return footerCustom
    if (footerActions) {
      return (
        <BlockView direction="row" hAlign="flex-end" vAlign="center">
          {footerActions.map((action, index) => {
            const lastItem = footerActions.length - 1 === index
            return (
              <BlockView key={`modal-2-action-${index}`} margin={{ right: lastItem ? 0 : 10 }}>
                <ButtonPrimary size="button-2" {...action} />
              </BlockView>
            )
          })}
        </BlockView>
      )
    }
    return null
  }

  return (
    <BlockView
      size={{ width: 480, maxWidth: screenSize.width - spacing[4] }}
      border={{ radius: 24, width: 1, color: color['utility-border-1'] }}
      backgroundColor={color['utility-surface-2']}
    >
      {/* Title. */}
      <BlockView padding={spacing[3]} direction="row" hAlign="space-between" vAlign="center">
        {/* Dummy element for alignment. */}
        <BlockView size={24} backgroundColor="transparent" style={{ opacity: 0 }} />

        {/* Text. */}
        <Text.Gridular color={color['content-1']} size="24" style={styles.title}>
          {title}
        </Text.Gridular>

        {/* Close. */}
        <BlockView style={{ opacity: showTitleClose ? 1 : 0 }}>
          <ButtonIcon
            kind="minimal"
            iconName="close"
            onPress={onTitleClosePress}
            disabled={!showTitleClose}
            iconColor={color['content-2']}
          />
        </BlockView>
      </BlockView>

      {/* Tabs. */}
      <BlockView size={{ width: '100%' }} direction="row" padding={{ horizontal: spacing[4] }}>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={`${tab.id}-${index}`}
              label={tab.title}
              activated={tab.id === selectedTabId}
              onPress={() => onTabSelect(tab.id)}
            />
          )
        })}
      </BlockView>

      {/* Tab Screens. */}
      <BlockView flex>{!!currentTab && renderTabScreen(currentTab)}</BlockView>

      {/* Actions. */}
      {(!!footerActions || !!footerCustom) && (
        <BlockView padding={{ horizontal: spacing[4], bottom: spacing[4] }}>
          {/* Divider. */}
          <BlockView margin={{ bottom: spacing[4] }}>
            {!!footerDivider && (
              <BlockView size={{ width: '100%', height: 1 }} backgroundColor={color['utility-border-1']} />
            )}
          </BlockView>
          {renderFooter()}
        </BlockView>
      )}
    </BlockView>
  )
})

type Style = {
  title: TextStyle
}

const styles = StyleSheet.create<Style>({
  title: {
    flex: 1,
    textAlign: 'center',
  },
})
