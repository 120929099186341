import { request } from 'api/request'

export type BenchmarkAnswer = {
  id: string
  text: string
}

export type GetBenchmarkQuestionData = {
  id: string
  question: string
  answers: BenchmarkAnswer[]
}

export const getBenchmarkQuestion = (): Promise<GetBenchmarkQuestionData> => {
  return request.get('v1/data-cleanup/benchmark')
}
