import { observer } from 'mobx-react-lite'

import { BlockView, InputField, InputFieldProps, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'

export type FormFieldProps = {
  label?: string
  labelStrong?: boolean
  supportLabel?: string
} & InputFieldProps

export const FormField = observer(function FormField({
  label,
  labelStrong = true,
  supportLabel,
  ...inputFieldProps
}: FormFieldProps) {
  /** States. */
  const { color } = useColor()

  const supportLabelColor = (() => {
    if (inputFieldProps.inputState === 'success') return color.state.positive
    if (inputFieldProps.inputState === 'error') return color.state.critical
    if (inputFieldProps.disabled) return color['content-2']
    return color['content-2']
  })()

  return (
    <BlockView size={{ width: '100%' }}>
      {/* Label. */}
      {!!label && (
        <Text.Roboto
          color={color['content-1']}
          strong={labelStrong}
          size="14"
          style={{ marginBottom: 6, opacity: inputFieldProps.disabled ? 0.24 : 1 }}
        >
          {label}
        </Text.Roboto>
      )}

      {/* Input. */}
      <InputField {...inputFieldProps} otherInputProps={{ style: { flex: 1 }, ...inputFieldProps?.otherInputProps }} />

      {/* Support Label. */}
      {!!supportLabel && (
        <Text.Roboto
          color={supportLabelColor}
          size="12"
          style={{ marginTop: 6, opacity: inputFieldProps.disabled ? 0.24 : 1 }}
        >
          {supportLabel}
        </Text.Roboto>
      )}
    </BlockView>
  )
})
