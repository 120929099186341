import { useCallback, useState } from 'react'
import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'
import * as Clipboard from 'expo-clipboard'

import { BlockView, ButtonIcon, Chip, Mermaid, Text, TextStyles, TypeWriter } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'
import { sendFeedback } from 'api/chat/send-feedback'
import { ConversationReaction, GetConversationMessages, ReplyDetails } from 'api/chat/get-conversation-messages'

import { MotiView } from 'moti'
import { showSnackbar } from '../Snackbar'
import { StreamPlayback } from './StreamPlayback'
import { Playback } from './Playback'
import { ChatOutLoading } from './Loading'
import DisplayCodeSnippet from './DisplayCodeSnippet'

export type ChatOutputProps = {
  conversationId: string
  messageId: string
  message: string
  showReaction?: boolean
  reaction: ConversationReaction | null
  replySource?: string | null
  streamText?: boolean
  isFromModel?: boolean

  showPlayback?: boolean
  voiceUrl?: string | null
  metadata?: string | null
  replyDetails?: ReplyDetails | null
  selectedModel?: string | null
}

export { ChatOutLoading }
export const ChatOutput = observer(function ChatOutput({
  conversationId,
  messageId,
  message,
  showReaction = true,
  reaction,
  replySource,
  streamText,
  isFromModel,

  showPlayback,
  voiceUrl,
  metadata,
  replyDetails,
  selectedModel,
}: ChatOutputProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { color } = useColor()
  const { spacing } = useLayout()
  const [streamingComplete, setStreamingComplete] = useState(false)

  const onTypeWriterComplete = useCallback(() => setStreamingComplete(true), [])

  /** Send Feedback Mutation. */
  const sendFeedbackMutation = useMutation(
    (updatedReaction: ConversationReaction) => {
      return sendFeedback(messageId, updatedReaction)
    },
    {
      onSuccess: (response, updatedReaction) => {
        if (response.status === 200) {
          const conversationMessagesData = queryClient.getQueryData<GetConversationMessages>(
            `get-conversation-messages-${conversationId}`
          )
          if (!conversationMessagesData) {
            return
          }

          queryClient.setQueryData<GetConversationMessages>(
            `get-conversation-messages-${conversationId}`,
            (oldData) => {
              return (
                oldData?.map((message) => {
                  if (message.id !== messageId) return message
                  return {
                    ...message,
                    reaction: updatedReaction,
                  }
                }) ?? []
              )
            }
          )
        }
      },
      onError: () => {
        showSnackbar({
          kind: 'error',
          label: 'Something went wrong. Please try again later.',
          leftIcon: 'error',
        })
      },
    }
  )

  // Define if message needs to print conde snippet(miniscript policy for now) or not
  const hasCodeSnippet = /```miniscript_policy\n([\s\S]*?)```/g.test(message)

  const renderMermaidChart = () => {
    /**
     * @TODO - Add some validation to distinguish between different possible metadata.
     */

    // Define mermaid code to print
    const mermaidCode = replyDetails?.mermaidCodes?.[0]

    if (!mermaidCode || mermaidCode.toLowerCase().includes('error while converting miniscript policy to mermaid chart'))
      return null

    // If message has code snippet, mark typing as complete
    if (hasCodeSnippet && !streamingComplete) {
      onTypeWriterComplete()
    }

    if (streamText && !streamingComplete) return null

    return (
      <BlockView margin={{ top: spacing[2] }} hAlign="center">
        <MotiView from={{ opacity: 0, translateX: 5 }} animate={{ opacity: 1, translateX: 0 }}>
          <Mermaid chart={mermaidCode} />
        </MotiView>
      </BlockView>
    )
  }

  const onReactPress = (reaction: ConversationReaction) => {
    if (sendFeedbackMutation.isLoading) return
    sendFeedbackMutation.mutate(reaction)
  }

  const onCopyToClipboard = async () => {
    await Clipboard.setStringAsync(message)
    showSnackbar({
      label: 'Message copied to clipboard!',
    })
  }

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="flex-start"
      padding={spacing[2]}
      backgroundColor={color['alpha-white-4']}
      border={{ radius: 16 }}
    >
      <BlockView
        size={40}
        border={{ radius: 20 }}
        backgroundColor={color['utility-surface-1']}
        vAlign="center"
        hAlign="center"
      >
        <Image source={MinimalLogo} style={{ width: 24, height: 12 }} />
      </BlockView>

      <BlockView flex>
        {/* Message. */}
        <BlockView flex padding={10} style={{ paddingTop: 0 }}>
          {streamText &&
          !hasCodeSnippet &&
          selectedModel &&
          !['TECHNICAL_ASSISTANT', 'CODE_SATOSHI', 'BLINK_BOT'].includes(selectedModel) ? (
            <TypeWriter
              text={message}
              minDelay={3}
              maxDelay={10}
              style={[
                TextStyles['text-16'],
                TextStyles.robotoRegular,
                { marginLeft: spacing[1], color: color['content-1'] },
              ]}
              onDone={onTypeWriterComplete}
            />
          ) : (
            <Text.Roboto color={color['content-1']} size="16" style={{ marginLeft: spacing[1] }}>
              <DisplayCodeSnippet text={message} replyDetails={replyDetails} />
            </Text.Roboto>
          )}

          {renderMermaidChart()}
        </BlockView>

        {/* Control. */}
        <BlockView direction="row" hAlign="space-between" vAlign="center" margin={{ top: spacing[1] }}>
          <BlockView flex direction="row" vAlign="center">
            {/* Like/Dislike & Copy. */}
            {!!showReaction && (
              <BlockView direction="row" vAlign="center">
                <ButtonIcon
                  kind="minimal"
                  iconName="thumb-up"
                  iconColor={reaction === 'THUMBS_UP' ? color.state.positive : color['content-2']}
                  onPress={() => onReactPress('THUMBS_UP')}
                />
                <ButtonIcon
                  kind="minimal"
                  iconName="thumb-down"
                  iconColor={reaction === 'THUMBS_DOWN' ? color.state.critical : color['content-2']}
                  onPress={() => onReactPress('THUMBS_DOWN')}
                />
                <ButtonIcon
                  kind="minimal"
                  iconName="content-copy"
                  iconColor={color['content-2']}
                  onPress={onCopyToClipboard}
                />
              </BlockView>
            )}

            {!!replySource && (
              <BlockView margin={{ left: spacing[1] }}>
                <Chip
                  kind="outlined"
                  size="chip-2"
                  label={`MODEL: ${replySource}`}
                  disabled
                  labelColor={color['content-2']}
                />
              </BlockView>
            )}
          </BlockView>

          {/* Voice Play. */}
          {!!showPlayback && (
            <BlockView direction="row" vAlign="center">
              {voiceUrl ? <Playback audioUrl={voiceUrl} /> : <StreamPlayback messageId={messageId} />}
            </BlockView>
          )}
        </BlockView>
      </BlockView>
    </BlockView>
  )
})
