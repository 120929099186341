import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMutation, useQuery } from 'react-query'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, showSnackbar, useLayout } from 'core'
import { PaymentAppModalRoutesProps } from 'navigation/stack/payment'
import { PaymentRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { getAccountMe } from 'api/user/get-account-me'
import {
  GetTransactionConfirmationVariables,
  getTransactionConfirmation,
} from 'api/pay-out/get-transaction-confirmation'
import { getWithdrawEarnings } from 'api/pay-out/get-withdraw-earnings'

import { WithdrawBalanceTemplate } from './Template'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<PaymentAppModalRoutesProps, PaymentRoutes.WithdrawBalance>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const PaymentWithdrawBalance = observer(function PaymentWithdrawBalance({ navigation }: ScreenProps) {
  /** States. */
  const { spacing } = useLayout()
  const pollIntervalRef = useRef<NodeJS.Timeout>()
  const [closeModal, setCloseModal] = useState(false)

  const onError = (errorMessage: string) => {
    showSnackbar({
      kind: 'error',
      label: errorMessage,
      leftIcon: 'error',
    })
    setCloseModal(true)
  }

  /** User Account Me Query. */
  const accountMeQuery = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      if (!response) onError(`Can't load account details. Please try again later.`)
    },
    onError: () => {
      onError(`Can't load account details. Please try again later.`)
    },
  })

  /** Get Transaction Confirmation Mutation. */
  const getTransactionConfirmationMutation = useMutation(
    (variables: GetTransactionConfirmationVariables) => getTransactionConfirmation(variables),
    {
      onSuccess: (response) => {
        if (response.confirmed) {
          accountMeQuery.refetch()
          showSnackbar({
            kind: 'success',
            label: `Successfully withdrew your earned sats!`,
            leftIcon: 'check-circle',
          })
          setCloseModal(true)
        }
      },
    }
  )

  /** Get Withdraw Earnings Query. */
  const withdrawEarningsQuery = useQuery({
    queryKey: 'my-account-withdraw-earnings',
    queryFn: getWithdrawEarnings,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if ('message' in response) {
        if ((response.message as string).toLocaleLowerCase().includes('you need to earn at least')) {
          onError('Minimum withdrawal amount is 1,000 sats.')
          return
        }
      }

      if (response.transactionId) {
        pollIntervalRef.current = setInterval(() => {
          getTransactionConfirmationMutation.mutate({
            transactionId: response.transactionId,
          })
        }, 3000)
      }
    },
    onError: () => {
      onError(`Can't load withdrawal details. Please try again later.`)
    },
  })

  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current)
      }
    }
  }, [])

  const queryLoading = accountMeQuery.isLoading || withdrawEarningsQuery.isLoading
  const queryError =
    accountMeQuery.isError || !accountMeQuery.data || withdrawEarningsQuery.isError || !withdrawEarningsQuery.data

  const userEarning = accountMeQuery.data?.balance.earning ?? 0
  const lnurlAddress = withdrawEarningsQuery.data?.lnAddress ?? null

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="withdraw-balance-data" type="from-bottom" duration={250}>
            <WithdrawBalanceTemplate
              loading={queryLoading}
              error={queryError}
              onClose={() => setCloseModal(true)}
              userEarning={userEarning}
              lnurlAddress={lnurlAddress}
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
