import { request } from 'api/request'

export type GetWithdrawEarningsData = {
  lnAddress: string
  transactionId: string
}

export const getWithdrawEarnings = (): Promise<GetWithdrawEarningsData> => {
  return request.get('v1/transaction/withdraw-earnings')
}
