import { useCallback } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'

import { BlockView, ButtonIcon, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { getAccountMe } from 'api/user/get-account-me'
import { TrainDataType, getRandomTrainData } from 'api/data-train/get-random-train-data'

import { AppTemplate } from './Template'
import { AppLoadingTemplate } from './Template/Loading'
import { AppErrorTemplate } from './Template/Error'

type ContributeDTVAppProps = CompositeScreenProps<
  CompositeScreenProps<
    NativeStackScreenProps<ContributeAppRoutesProps, ContributeRoutes.DTV>,
    DrawerScreenProps<ContributeAppRoutesProps>
  >,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ContributeDTV = observer(function ContributeDTV({ navigation }: ContributeDTVAppProps) {
  /** States. */
  const { screenSize, breakPoints } = useLayout()
  const isDesktop = screenSize.width >= breakPoints.lg

  /** Get Account me. */
  const { data: accountMeData } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  /** Get Random Train Data Query. */
  const { isLoading, isRefetching, isError, data, refetch } = useQuery({
    queryKey: 'get-random-train-data',
    queryFn: getRandomTrainData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      /**
       * @TODO - Handle other data type as well. But for now, just train QA type.
       */
      if (response.type !== TrainDataType.QA) {
        refetch()
      }
    },
  })

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({
        headerRight: () =>
          isDesktop ? null : (
            <BlockView margin={{ horizontal: 11 }}>
              <ButtonIcon
                kind="minimal"
                iconName="question-mark"
                onPress={() => {
                  navigation.navigate(HowToModalRoutes.HowToCleanupData)
                }}
              />
            </BlockView>
          ),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])
  )

  if (isLoading) {
    return <AppLoadingTemplate />
  }

  /** Error State. */
  if (isError || !data) {
    return <AppErrorTemplate refreshing={isRefetching} onRefresh={refetch} />
  }

  /** Dataful State. */
  const { id, content, answer, type } = data
  return (
    <AppTemplate
      isRefetching={isRefetching}
      getNextData={refetch}
      dataId={id}
      content={content}
      answer={answer}
      dataType={type}
      userFullName={accountMeData?.fullName}
    />
  )
})
