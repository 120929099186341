import { useCallback } from 'react'
import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'

import { BlockView, ButtonFab, ButtonIcon, useColor, useLayout } from 'core'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { getAccountMe } from 'api/user/get-account-me'
import { getAccountStatistics } from 'api/user/get-account-statistics'

import { StatisticsLoadingTemplate } from './Template/Loading'
import { StatisticsErrorTemplate } from './Template/Error'
import { LifetimeRewards } from './Template/LifetimeRewards'
import { PointsOverview } from './Template/PointsOverview'
import { Dtv } from './Template/Dtv'
import { Waas } from './Template/Waas'
import { FudBuster } from './Template/FudBuster'
import { NoSecondBest } from './Template/NoSecondBest'

type ScreenProps = CompositeScreenProps<
  CompositeScreenProps<
    NativeStackScreenProps<ContributeAppRoutesProps, ContributeRoutes.Statistics>,
    DrawerScreenProps<ContributeAppRoutesProps>
  >,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ContributeStatistics = observer(function ContributeStatistics({ navigation }: ScreenProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()

  const isTablet = screenSize.width < breakPoints.md
  const isDesktop = screenSize.width >= breakPoints.lg
  const screenPaddingHorizontal = isDesktop ? 60 : spacing[2]

  /** Get account me Query. */
  const { data: accountMeData } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const benchmarkingToolFeatureFlag = !!accountMeData?.featureFlags?.CONTRIBUTION_NO_SECOND_BEST?.enabled

  /** User Account Statistics Query. */
  const { isLoading, isError, data, isRefetching, refetch } = useQuery({
    queryKey: 'get-account-statistics',
    queryFn: getAccountStatistics,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({
        headerRight: () =>
          isDesktop ? null : (
            <BlockView margin={{ horizontal: 11 }}>
              <ButtonIcon
                kind="minimal"
                iconName="question-mark"
                onPress={() => {
                  navigation.navigate(HowToModalRoutes.HowStatisticsWork)
                }}
              />
            </BlockView>
          ),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])
  )

  const renderApp = () => {
    /** Loading state. */
    if (isLoading) {
      return <StatisticsLoadingTemplate />
    }

    /** Error state. */
    if (isError || !data) {
      return <StatisticsErrorTemplate refreshing={isRefetching} onRefresh={() => refetch()} />
    }

    const { totalPointsEarned, totalSatsEarned, currentConversionRate, dtv, fudBuster, waas, nsb } = data

    const displayNoSecondBest = (() => {
      // If user has non-zero stats for NSB, display them.
      if (nsb.rankingsCount + nsb.totalPointsEarned > 0) return true
      return benchmarkingToolFeatureFlag
    })()

    return (
      <>
        <BlockView direction={isTablet ? 'column' : 'row'} padding={{ horizontal: screenPaddingHorizontal }}>
          <LifetimeRewards
            satsEarned={totalSatsEarned}
            pointsEarned={totalPointsEarned}
            conversionRate={currentConversionRate}
          />

          <BlockView flex margin={isTablet ? { top: spacing[2] } : { left: spacing[2] }}>
            <PointsOverview
              dtvPoints={dtv.totalPointsEarned}
              fudBusterPoints={fudBuster.totalPointsEarned}
              waasPoints={waas.totalPointsEarned}
              noSecondBestPoints={displayNoSecondBest ? nsb.totalPointsEarned : null}
            />
          </BlockView>
        </BlockView>

        <Dtv
          totalPointsEarned={dtv.totalPointsEarned}
          acceptedCount={dtv.keepCount}
          rejectedCount={dtv.discardCount}
          editedCount={dtv.editCount}
          consensusPercentage={dtv.consensusPercentage}
        />

        <Waas
          totalPointsEarned={waas.totalPointsEarned}
          answersProvided={waas.answersCount}
          answersAccepted={waas.keptAnswersCount}
          answersRejected={waas.discardedAnswersCount}
        />

        <FudBuster
          totalPointsEarned={fudBuster.totalPointsEarned}
          answersProvided={fudBuster.answersCount}
          answersAccepted={fudBuster.keptAnswersCount}
          answersRejected={fudBuster.discardedAnswersCount}
        />

        {displayNoSecondBest && (
          <NoSecondBest totalPointsEarned={nsb.totalPointsEarned} answersProvided={nsb.rankingsCount} />
        )}
      </>
    )
  }

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: color['utility-surface-2'],
          paddingHorizontal: spacing[2],
          paddingTop: spacing[1],
          paddingBottom: spacing[5],
        }}
        style={{ backgroundColor: color['utility-surface-2'] }}
      >
        {renderApp()}
      </ScrollView>

      {/* Help Button. */}
      {isDesktop && (
        <BlockView hAlign="flex-end" position={{ bottom: spacing[3], right: spacing[3] }}>
          <ButtonFab
            leftIcon="question-mark"
            leftIconStyle={{ marginRight: 0 }}
            labelColor={color['content-2']}
            onPress={() => navigation.navigate(HowToModalRoutes.HowStatisticsWork)}
            applyMinWidth={false}
            buttonStyle={{ width: 56, height: 56, borderWidth: 1, borderColor: color['utility-border-3'] }}
          />
        </BlockView>
      )}
    </>
  )
})
