import { makeAutoObservable } from 'mobx'
import { makePersistable, isHydrated, clearPersistedStore } from 'mobx-persist-store'

export class ModalToggleStore {
  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'ModalToggleStore',
      properties: ['showDiscardTrainDataPrompt'],
    })

    makePersistable(this, {
      name: 'ModalToggleStoreBanner',
      properties: ['showBtcTradingCardsBanner'],
    })
  }

  showDiscardTrainDataPrompt = true
  setShowDiscardTrainDataPrompt = (show: boolean) => {
    this.showDiscardTrainDataPrompt = show
  }

  showGeyserCampaign = true
  setShowGeyserCampaign = (show: boolean) => {
    this.showGeyserCampaign = show
  }

  showBtcTradingCardsBanner = true
  setBtcTradingCardsBanner = (show: boolean) => {
    this.showBtcTradingCardsBanner = show
  }

  get isHydrated() {
    return isHydrated(this)
  }

  reset = () => {
    clearPersistedStore(true)
    this.showDiscardTrainDataPrompt = true
    this.showGeyserCampaign = true
    this.showBtcTradingCardsBanner = true
  }
}
