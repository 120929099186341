import { request } from 'api/request'

export type LeaderboardItem = {
  fullName: string
  twitterUsername: string | null
  totalPoints: number
  totalContributions: number
  dtvPoints: number
  waasPoints: number
  fudBusterPoints: number
}

export type GetLeaderboardData = {
  items: LeaderboardItem[]
  totalCount: number
  limit: string
  offset: string
  numberOfPages: number
}

export type LeaderboardFilter = 'daily' | 'weekly' | 'monthly' | 'overall'
export type LeaderboardOrderBy = 'totalPoints' | 'totalContributions' | 'dtvPoints' | 'waasPoints' | 'fudBusterPoints'
export type LeaderboardDirection = 'asc' | 'desc'

export type GetLeaderboardVariables = {
  limit?: number
  offset?: number
  filter?: LeaderboardFilter
  direction?: LeaderboardDirection
  order?: LeaderboardOrderBy
}

export const getLeaderboard = ({
  limit = 10,
  offset = 0,
  filter = 'overall',
  direction = 'desc',
  order = 'totalPoints',
}: GetLeaderboardVariables): Promise<GetLeaderboardData> => {
  return request.get(
    `v1/data-cleanup/leaderboard?limit=${limit}&offset=${offset}&filter=${filter}&direction=${direction}&order=${order}`
  )
}
