import React, { useMemo } from 'react'
import { ViewStyle, StyleProp, ScrollViewProps, ScrollView, View } from 'react-native'
import { MotiView } from 'moti'
import { observer } from 'mobx-react-lite'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import Animated, { useAnimatedKeyboard, useAnimatedStyle } from 'react-native-reanimated'

import { useLayout } from 'core/v2/layout'
import { useColor } from 'core/v2/color'

import { BlockView, BlockViewProps } from '../BlockView'
import {
  FROM_BOTTOM_ANIMATION,
  FROM_LEFT_ANIMATION,
  FROM_RIGHT_ANIMATION,
  FROM_TOP_ANIMATION,
} from './animation-config'

export type BackgroundProps = {
  children?: React.ReactNode
} & BlockViewProps

export const Background = observer(function Background({ children, backgroundColor, ...props }: BackgroundProps) {
  const { color } = useColor()

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: color['utility-surface-2'] }}>
      <BlockView flex backgroundColor={backgroundColor ?? color['utility-surface-2']} {...props}>
        {children}
      </BlockView>
    </SafeAreaView>
  )
})

type KeyboardAwareScrollBackgroundProps = {
  children?: React.ReactNode
  addDefaultPadding?: boolean
  innerViewStyle?: StyleProp<ViewStyle>
} & ScrollViewProps

export const KeyboardAwareScrollBackground = observer(function KeyboardAwareScrollBackground({
  children,
  addDefaultPadding = true,
  contentContainerStyle,
  style,
  innerViewStyle,
  ...scrollViewProps
}: KeyboardAwareScrollBackgroundProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const safeAreaInset = useSafeAreaInsets()
  const keyboard = useAnimatedKeyboard()
  const backgroundColor = color['utility-surface-2']

  const containerAnimatedStyle = useAnimatedStyle(() => ({
    paddingBottom: keyboard.height.value,
  }))

  return (
    <ScrollView
      contentContainerStyle={[
        {
          backgroundColor,
          padding: addDefaultPadding ? spacing[2] : undefined,
          paddingBottom: addDefaultPadding ? safeAreaInset.bottom + spacing[2] : undefined,
        },
        contentContainerStyle,
      ]}
      style={[{ backgroundColor }, style]}
      {...scrollViewProps}
    >
      <Animated.View style={[innerViewStyle, { flex: 1, backgroundColor }, containerAnimatedStyle]}>
        {children}
      </Animated.View>
    </ScrollView>
  )
})

type AnimateTransitionProps = {
  // If false, it will just render with a regular <View />.
  enabled?: boolean
  children?: React.ReactNode
  duration?: number
  delay?: number
  style?: StyleProp<ViewStyle>
  type?: 'from-top' | 'from-right' | 'from-bottom' | 'from-left' // Default: 'from-right'
}

export const AnimateTransition = ({
  enabled = true,
  children,
  duration,
  delay,
  style,
  type = 'from-right',
}: AnimateTransitionProps) => {
  const animationConfig = useMemo(() => {
    switch (type) {
      case 'from-bottom':
        return FROM_BOTTOM_ANIMATION
      case 'from-left':
        return FROM_LEFT_ANIMATION
      case 'from-right':
        return FROM_RIGHT_ANIMATION
      case 'from-top':
        return FROM_TOP_ANIMATION
      default:
        return FROM_RIGHT_ANIMATION
    }
  }, [type])

  if (!enabled) {
    return <View style={style}>{children}</View>
  }

  return (
    <MotiView {...animationConfig} transition={{ duration: duration ?? 500, delay, type: 'timing' }} style={style}>
      {children}
    </MotiView>
  )
}
