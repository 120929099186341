import { request } from 'api/request'

export type UpdateConversationVariables = {
  title: string
}

export const updateConversation = (conversationId: string, params: UpdateConversationVariables) => {
  return request.putWithRawResponse(`v1/chat/conversation/${conversationId}`, {
    data: params,
  })
}
