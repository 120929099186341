import { useCallback, useRef, useState } from 'react'
import { TextInput } from 'react-native'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { useMutation } from 'react-query'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, FormField, InputFieldState, Modal2, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AccountModalRoutesProps } from 'navigation/stack/account'
import { AccountRoutes, ChatRoutes } from 'navigation/routes'
import { NostrLoginVariables, nostrLogin } from 'api/authentication/nostr-login'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<AccountModalRoutesProps, AccountRoutes.ConnectNostr>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const AccountConnectNostr = observer(function AccountConnectNostr({ navigation }: ScreenProps) {
  /** States. */
  const { spacing } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const [nPub, setNPub] = useState('')
  const [relay, setRelay] = useState('')
  const [nPubInputState, setNPubInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })
  const [relayInputState, setRelayInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })
  const nPubInputRef = useRef<TextInput>(null)
  const relayInputRef = useRef<TextInput>(null)

  useFocusEffect(
    useCallback(() => {
      if (!navigation.canGoBack()) {
        // @ts-ignore
        navigation.navigate(ChatRoutes.Root, { screen: ChatRoutes.Conversation, params: { id: `new-chat` } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  const onErrorFallback = () => {
    setNPubInputState({
      state: 'error',
      message: '',
    })
    setRelayInputState({
      state: 'error',
      message: 'Something went wrong. Please try again',
    })
  }

  /** Nostr Login Mutation. */
  const nostrLoginMutation = useMutation(
    (variables: NostrLoginVariables) => {
      return nostrLogin(variables)
    },
    {
      onSuccess: (response) => {
        if (!response.otpId) {
          onErrorFallback()
          return
        }

        navigation.goBack()
        navigation.navigate(AccountRoutes.VerifyCode, {
          otpId: response.otpId,
          isNostr: true,
        })
      },
      onError: () => {
        onErrorFallback()
      },
    }
  )

  const onSubmit = () => {
    if (nostrLoginMutation.isLoading) return
    const testNPub = /^npub/.test(nPub)
    const testRelay = /^wss:\/\//.test(relay)

    if (!testNPub) {
      setNPubInputState({
        state: 'error',
        message: 'Please provide a valid npub',
      })
      return
    }
    if (!testRelay) {
      setRelayInputState({
        state: 'error',
        message: 'Please provide a valid relay',
      })
    }

    nostrLoginMutation.mutate({ nPub, relay })
  }

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="account-connect-nostr" type="from-bottom" duration={250}>
            <Modal2
              title="Connect Nostr"
              text={
                <>
                  <FormField
                    inputRef={nPubInputRef}
                    value={nPub}
                    onValueChange={(text) => {
                      if (nPubInputState.state !== 'default') setNPubInputState({ state: 'default', message: '' })
                      setNPub(text)
                    }}
                    placeholderText="npub"
                    otherInputProps={{
                      onSubmitEditing: () => relayInputRef.current?.focus(),
                      autoCapitalize: 'none',
                      autoCorrect: false,
                    }}
                    inputState={nPubInputState.state}
                    supportLabel={nPubInputState.message}
                  />

                  <BlockView margin={{ top: spacing[1] }}>
                    <FormField
                      inputRef={relayInputRef}
                      value={relay}
                      onValueChange={(text) => {
                        if (relayInputState.state !== 'default') setRelayInputState({ state: 'default', message: '' })
                        setRelay(text)
                      }}
                      placeholderText="relay (eg: wss://relay.damus.io)"
                      otherInputProps={{
                        onSubmitEditing: onSubmit,
                        autoCapitalize: 'none',
                        autoCorrect: false,
                      }}
                      inputState={relayInputState.state}
                      supportLabel={relayInputState.message}
                    />
                  </BlockView>
                </>
              }
              actions={[
                {
                  kind: 'outlined',
                  label: 'Cancel',
                  applyMinWidth: false,
                  onPress: () => setCloseModal(true),
                },
                {
                  kind: 'filled',
                  label: 'Continue',
                  applyMinWidth: false,
                  onPress: onSubmit,
                  loading: nostrLoginMutation.isLoading,
                },
              ]}
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
