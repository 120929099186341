import { StyleSheet, Text as RNText, TextStyle, TextProps as RNTextProps } from 'react-native'

import { TextSize } from './type'

export type TextProps = {
  children?: React.ReactNode
  color: string
  size: TextSize

  strong?: boolean
  uppercase?: boolean
  underline?: boolean
} & RNTextProps

export const Text = {
  Gridular: (props: Omit<TextProps, 'strong' | 'underline' | 'uppercase'>) => createText('Gridular', props),
  Roboto: (props: TextProps) => createText('Roboto', props),
  RobotoMono: (props: Omit<TextProps, 'strong'>) => createText('RobotoMono', props),
}

const createText = (font: 'Gridular' | 'Roboto' | 'RobotoMono', props: TextProps) => {
  const { children, style, color, size, strong = false, uppercase = false, underline = false, ...textProps } = props

  const fontStyle = (() => {
    if (font === 'Gridular') return TextStyles.gridular
    if (font === 'RobotoMono') return TextStyles.robotoMonoMedium
    if (font === 'Roboto' && !!strong) return TextStyles.robotoBold
    return TextStyles.robotoRegular
  })()

  return (
    <RNText
      maxFontSizeMultiplier={1.2}
      selectable
      style={[
        fontStyle,
        uppercase && TextStyles.uppercase,
        underline && TextStyles.underline,
        { color },
        TextStyles[`text-${size}`],
        style,
      ]}
      {...textProps}
    >
      {children}
    </RNText>
  )
}

type Style = {
  gridular: TextStyle
  robotoRegular: TextStyle
  robotoBold: TextStyle
  robotoMonoMedium: TextStyle
  underline: TextStyle
  uppercase: TextStyle

  'text-56': TextStyle
  'text-48': TextStyle
  'text-40': TextStyle
  'text-36': TextStyle
  'text-32': TextStyle
  'text-30': TextStyle
  'text-28': TextStyle
  'text-26': TextStyle
  'text-24': TextStyle
  'text-22': TextStyle
  'text-20': TextStyle
  'text-18': TextStyle
  'text-16': TextStyle
  'text-15': TextStyle
  'text-14': TextStyle
  'text-13': TextStyle
  'text-12': TextStyle
  'text-11': TextStyle
  'text-10': TextStyle
}

export const TextStyles = StyleSheet.create<Style>({
  gridular: {
    fontFamily: 'GridularRegular',
  },
  robotoRegular: {
    fontFamily: 'RobotoRegular',
  },
  robotoBold: {
    fontFamily: 'RobotoBold',
  },
  robotoMonoMedium: {
    fontFamily: 'RobotoMonoMedium',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  uppercase: {
    textTransform: 'uppercase',
  },

  'text-56': {
    fontSize: 56,
    lineHeight: 64,
  },
  'text-48': {
    fontSize: 48,
    lineHeight: 56,
  },
  'text-40': {
    fontSize: 40,
    lineHeight: 48,
  },
  'text-36': {
    fontSize: 36,
    lineHeight: 44,
  },
  'text-32': {
    fontSize: 32,
    lineHeight: 40,
  },
  'text-30': {
    fontSize: 30,
    lineHeight: 36,
  },
  'text-28': {
    fontSize: 28,
    lineHeight: 32,
  },
  'text-26': {
    fontSize: 26,
    lineHeight: 30,
  },
  'text-24': {
    fontSize: 24,
    lineHeight: 28,
  },
  'text-22': {
    fontSize: 22,
    lineHeight: 26,
  },
  'text-20': {
    fontSize: 20,
    lineHeight: 24,
  },
  'text-18': {
    fontSize: 18,
    lineHeight: 22,
  },
  'text-16': {
    fontSize: 16,
    lineHeight: 20,
  },
  'text-15': {
    fontSize: 15,
    lineHeight: 17,
  },
  'text-14': {
    fontSize: 14,
    lineHeight: 16,
  },
  'text-13': {
    fontSize: 13,
    lineHeight: 15,
  },
  'text-12': {
    fontSize: 12,
    lineHeight: 14,
  },
  'text-11': {
    fontSize: 11,
    lineHeight: 13,
  },
  'text-10': {
    fontSize: 10,
    lineHeight: 12,
  },
})
