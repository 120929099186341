export const FROM_RIGHT_ANIMATION = {
  from: {
    opacity: 0,
    transform: [{ translateX: 10 }],
  },
  animate: {
    opacity: 1,
    transform: [{ translateX: 0 }],
  },
  exit: {
    opacity: 0,
    transform: [{ translateX: 10 }],
  },
}

export const FROM_LEFT_ANIMATION = {
  from: {
    opacity: 0,
    transform: [{ translateX: -10 }],
  },
  animate: {
    opacity: 1,
    transform: [{ translateX: 0 }],
  },
  exit: {
    opacity: 0,
    transform: [{ translateX: -10 }],
  },
}

export const FROM_TOP_ANIMATION = {
  from: {
    opacity: 0,
    transform: [{ translateY: -10 }],
  },
  animate: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
  exit: {
    opacity: 0,
    transform: [{ translateY: -10 }],
  },
}

export const FROM_BOTTOM_ANIMATION = {
  from: {
    opacity: 0,
    transform: [{ translateY: 10 }],
  },
  animate: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
  exit: {
    opacity: 0,
    transform: [{ translateY: 10 }],
  },
}
