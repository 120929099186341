import { useCallback } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { useQuery } from 'react-query'

import { BlockView, ButtonIcon, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { getAccountMe } from 'api/user/get-account-me'
import { getQuestion } from 'api/data-train/get-question'

import { WeAreAllSatoshiTemplate } from './Template'
import { WeAreAllSatoshiLoadingTemplate } from './Template/Loading'
import { WeAreAllSatoshiErrorTemplate } from './Template/Error'

type ScreenProps = CompositeScreenProps<
  CompositeScreenProps<
    NativeStackScreenProps<ContributeAppRoutesProps, ContributeRoutes.WeAreAllSatoshi>,
    DrawerScreenProps<ContributeAppRoutesProps>
  >,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ContributeWeAreAllSatoshi = ({ navigation }: ScreenProps) => {
  /** States. */
  const { screenSize, breakPoints } = useLayout()
  const isDesktop = screenSize.width >= breakPoints.lg

  /** Get Account me. */
  const { data: accountMeData } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  /** Get Question Query. */
  const { isLoading, isRefetching, isError, data, refetch } = useQuery({
    queryKey: 'get-question',
    queryFn: getQuestion,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({
        headerRight: () =>
          isDesktop ? null : (
            <BlockView margin={{ horizontal: 11 }}>
              <ButtonIcon
                kind="minimal"
                iconName="question-mark"
                onPress={() => {
                  navigation.navigate(HowToModalRoutes.HowToReplyAsSatoshi)
                }}
              />
            </BlockView>
          ),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])
  )

  /** Loading State. */
  if (isLoading) return <WeAreAllSatoshiLoadingTemplate />

  /** Error State. */
  if (isError || !data) return <WeAreAllSatoshiErrorTemplate refreshing={isRefetching} onRefresh={refetch} />

  /** Dataful State. */
  const { id, content } = data

  return (
    <WeAreAllSatoshiTemplate
      isRefetching={isRefetching}
      getNextData={refetch}
      questionId={id}
      content={content}
      userFullName={accountMeData?.fullName}
    />
  )
}
