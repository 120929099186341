import { useEffect, useState } from 'react'
import { Audio } from 'expo-av'
import { observer } from 'mobx-react-lite'

import { ButtonIcon } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'

type PlaybackProps = {
  audioUrl: string
}

export const Playback = observer(function Playback({ audioUrl }: PlaybackProps) {
  /** States. */
  const { color } = useColor()
  const [playback, setPlayback] = useState<Audio.Sound | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    async function createAudio() {
      const { sound } = await Audio.Sound.createAsync({ uri: audioUrl }, { shouldPlay: false }, (status) => {
        if (status.isLoaded) {
          setIsPlaying(status.isPlaying)
        }
      })

      setPlayback(sound)
    }

    createAudio()
  }, [audioUrl])

  useEffect(() => {
    return playback
      ? () => {
          playback.unloadAsync()
        }
      : undefined
  }, [playback])

  const onPlayAudio = async () => {
    playback?.playAsync()
  }

  const onPauseAudio = () => {
    playback?.pauseAsync()
  }

  const onRewindAudio = () => {
    playback?.replayAsync()
  }

  return (
    <>
      <ButtonIcon
        kind="minimal"
        iconName="replay"
        iconColor={color['content-2']}
        onPress={onRewindAudio}
        disabled={!playback}
      />
      <ButtonIcon
        kind="minimal"
        iconName={isPlaying ? 'pause-circle' : 'play-circle'}
        iconColor={color.accent.brand}
        onPress={isPlaying ? onPauseAudio : onPlayAudio}
        disabled={!playback}
      />
    </>
  )
})
