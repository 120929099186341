import { KeyboardTypeOptions, Platform, TextStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import {
  CodeField,
  RenderCellOptions,
  useBlurOnFulfill,
  useClearByFocusCell,
  Cursor,
} from 'react-native-confirmation-code-field'

import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'
import { BlockView, InputFieldState, Text } from 'core/v2/atoms'

export type VerificationCodeProps = {
  numberOfCodes?: number
  code: string
  onCodeEnter: (code: string) => void
  state?: InputFieldState
  keyboardType?: KeyboardTypeOptions
}

export const VerificationCode = observer(function ({
  code,
  onCodeEnter,
  keyboardType,
  numberOfCodes = 6,
  state,
}: VerificationCodeProps) {
  /** States. */
  const inputRef = useBlurOnFulfill({ value: code, cellCount: numberOfCodes })
  const [codeFieldProps, getCellOnLayout] = useClearByFocusCell({ value: code, setValue: onCodeEnter })
  const { color } = useColor()
  const { spacing } = useLayout()

  const borderColor = (() => {
    if (state === 'error') return color.state.critical
    if (state === 'success') return color.state.positive
    return color['utility-border-1']
  })()

  const renderCell = ({ index, isFocused, symbol }: RenderCellOptions) => {
    return (
      <BlockView
        key={`Verify-code-cell-${index}`}
        flex
        vAlign="center"
        hAlign="center"
        size={{ maxWidth: 55, height: 56 }}
        backgroundColor={color['utility-surface-4']}
        border={{ radius: 12, color: borderColor, width: 1 }}
        margin={{ right: numberOfCodes - 1 === index ? 0 : spacing[1] }}
      >
        <Text.Roboto
          color={color['content-1']}
          size="18"
          onLayout={getCellOnLayout(index)}
          adjustsFontSizeToFit
          numberOfLines={1}
        >
          {symbol || (isFocused ? <Cursor /> : null)}
        </Text.Roboto>
      </BlockView>
    )
  }

  return (
    <CodeField
      {...codeFieldProps}
      caretHidden={false}
      ref={inputRef}
      renderCell={renderCell}
      autoFocus
      value={code}
      onChangeText={onCodeEnter}
      cellCount={numberOfCodes}
      keyboardType={keyboardType ?? 'number-pad'}
      maxLength={numberOfCodes}
      rootStyle={{
        width: '100%',
        maxWidth: 55 * numberOfCodes + spacing[2] * (numberOfCodes - 1),
      }}
      textInputStyle={{
        ...Platform.select<TextStyle>({
          web: {
            // @ts-ignore
            outline: 'none',
            // @ts-ignore
            outlineWidth: 0,
          },
        }),
      }}
    />
  )
})
