import { useRootStore } from 'store/root.store'

import { DarkColor } from './dark'
import { LightColor } from './light'
import { addColorTransparency } from './shared'

export const useColor = () => {
  const { uiStore } = useRootStore()

  return {
    color: uiStore.colorTheme === 'dark' ? DarkColor : LightColor,
    addColorTransparency,
  }
}
