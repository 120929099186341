import { RefObject } from 'react'
import { ActivityIndicator, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useRootStore } from 'store/root.store'
import { useColor } from '../../../color'
import { MaterialIconOutlined, MaterialIconOutlinedName } from '../../Icon'

export type ButtonIconProps = {
  buttonRef?: RefObject<View>
  kind: 'filled' | 'minimal'
  iconName?: MaterialIconOutlinedName
  onPress?: () => void | Promise<any>

  disabled?: boolean
  loading?: boolean
  activated?: boolean

  filledColor?: string
  iconColor?: string
}

enum ButtonAction {
  PressedIn = 1,
  PressedOut = 0,
}

export const ButtonIcon = observer(function ButtonIcon({
  buttonRef,
  kind,
  iconName,
  onPress,
  disabled = false,
  loading = false,
  activated = false,

  filledColor,
  iconColor,
}: ButtonIconProps) {
  /** States. */
  const { uiStore } = useRootStore()
  const { color, addColorTransparency } = useColor()
  const pressedIn = useSharedValue(ButtonAction.PressedOut)

  const showPressedState = () => {
    pressedIn.value = withTiming(1, { duration: 150 })
  }
  const showPressedOutState = () => {
    pressedIn.value = withTiming(0, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle<ViewStyle>(() => {
    const filledBackgroundColor = filledColor ?? color['content-1']

    if (disabled) {
      return {
        backgroundColor: filledColor ? filledColor : 'transparent',
      }
    }

    if (kind === 'minimal') {
      return {
        backgroundColor: interpolateColor(
          pressedIn.value,
          [ButtonAction.PressedOut, ButtonAction.PressedIn],
          ['transparent', color['alpha-white-4']]
        ),
      }
    }

    return {
      backgroundColor: filledBackgroundColor,
      opacity: interpolate(pressedIn.value, [ButtonAction.PressedOut, ButtonAction.PressedIn], [1, 0.8]),
    }
  })

  const mainIconColor = (() => {
    const defaultColor = (() => {
      if (iconColor) return iconColor
      return uiStore.colorTheme === 'dark' ? color['content-2'] : color['content-4']
    })()

    if (disabled) return addColorTransparency(defaultColor, 32)
    if (activated) return iconColor ?? color['content-1']
    return defaultColor
  })()

  return (
    <Pressable
      ref={buttonRef}
      onPress={onPress}
      onHoverIn={showPressedState}
      onHoverOut={showPressedOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
      disabled={disabled}
    >
      <Animated.View
        style={[styles.container, disabled && { opacity: filledColor ? 0.24 : 1 }, containerAnimatedStyle]}
      >
        {loading ? (
          <ActivityIndicator color={mainIconColor} />
        ) : (
          <MaterialIconOutlined name={iconName} color={mainIconColor} size={24} />
        )}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    borderRadius: 8,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    width: 32,
  },
})
