import { MaterialIconOutlinedName, useColor } from 'core'

type Content = {
  iconName: MaterialIconOutlinedName
  iconColor: string
  title: string
  content: string
}

export const useHowToContent = (): Content[] => {
  /** States. */
  const { color } = useColor()

  return [
    {
      iconName: 'check',
      iconColor: color.accent.brand,
      title: `Keep`,
      content: `Select when the item(s) presented is good and should be submitted for inclusion in the data set to help train Satoshi!`,
    },
    {
      iconName: 'close',
      iconColor: color.state.critical,
      title: `Discard`,
      content: `Select when the item(s) displayed has no relevance to Bitcoin or related fields and should be removed from the data set entirely.`,
    },
    {
      iconName: `edit`,
      iconColor: color['content-2'],
      title: 'Edit',
      content: `If the item(s) needs improving, simply tap on the item(s) you wish to edit. After you have completed your edits, you can submit the improved version by selecting "Keep".`,
    },
    {
      iconName: 'redo',
      iconColor: color['content-2'],
      title: `Skip`,
      content: `Select if you are unable to assess the accuracy or quality of the item.`,
    },
  ]
}
