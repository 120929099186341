import { request } from 'api/request'

export type EmaiLLoginOrSignupData = {
  otpId: string
}

export type EmailLoginOrSignupVariables = {
  email: string
}

export const emailLoginOrSignup = ({ email }: EmailLoginOrSignupVariables): Promise<EmaiLLoginOrSignupData> => {
  return request.post('v1/account/email/login-or-signup', {
    data: { email },
  })
}
