import { Pressable, StyleSheet, ViewStyle } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { BlockView, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { useLayout } from 'core/v2/layout'

import { Icon } from './Icon'
import { ButtonState } from './type'

export type NavBadgeProps = {
  icon: MaterialIconOutlinedName
  rightIcon?: MaterialIconOutlinedName
  label: string
  disabled?: boolean
  activated?: boolean
  onPress?: () => void
}

export const NavBadge = observer(function NavBadge({
  icon,
  rightIcon,
  label,
  disabled,
  activated,
  onPress,
}: NavBadgeProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const buttonState = useSharedValue(ButtonState.Default)

  const showHoverInState = () => {
    buttonState.value = withTiming(ButtonState.HoverIn, { duration: 150 })
  }
  const showHoverOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }
  const showPressedState = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const showPressedOutState = () => {
    buttonState.value = withTiming(ButtonState.Default, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = activated ? color['alpha-white-8'] : 'transparent'

    if (disabled) return { backgroundColor: 'transparent' }

    return {
      backgroundColor: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, color['alpha-white-4'], color['alpha-white-2']]
      ),
    }
  })

  const labelAnimatedStyle = useAnimatedStyle(() => {
    const defaultColor = activated ? color.accent.brand : color['content-1']

    return {
      color: interpolateColor(
        buttonState.value,
        [ButtonState.Default, ButtonState.HoverIn, ButtonState.PressedIn],
        [defaultColor, defaultColor, color.accent.brand]
      ),
    }
  })

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showHoverInState}
      onHoverOut={showHoverOutState}
      onPressIn={showPressedState}
      onPressOut={showPressedOutState}
      disabled={disabled}
    >
      <Animated.View style={[styles.container, containerAnimatedStyle, { opacity: disabled ? 0.24 : 1 }]}>
        {/* Icon. */}
        <BlockView margin={{ bottom: spacing[1] }}>
          <Icon activated={activated} disabled={disabled} icon={icon} buttonState={buttonState} />
        </BlockView>

        {/* Label. */}
        <Text.Roboto strong size="14" color={color['content-1']}>
          <Animated.Text style={labelAnimatedStyle}>{label}</Animated.Text>
        </Text.Roboto>

        {/* Right Icon. */}
        {!!rightIcon && (
          <BlockView position={{ top: 0, right: 0 }}>
            <Icon activated={activated} disabled={disabled} icon={rightIcon} buttonState={buttonState} />
          </BlockView>
        )}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    minWidth: 144,
    minHeight: 72,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
})
