import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonIcon, ButtonPrimary, ButtonPrimaryProps, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { useLayout } from 'core/v2/layout'
import { useColor } from 'core/v2/color'

import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'

export type ChatNoticeProps = {
  label: string

  rightIcon?: MaterialIconOutlinedName
  onRightIconPress?: () => void

  actionButton?: ButtonPrimaryProps
}

export const ChatNotice = observer(function ChatNotice({
  label,
  rightIcon,
  onRightIconPress,
  actionButton,
}: ChatNoticeProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="flex-start"
      padding={spacing[2]}
      style={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: color['utility-border-1'],
        borderRadius: 16,
      }}
    >
      {/* SOS Icon. */}
      <BlockView
        size={40}
        border={{ radius: 20 }}
        backgroundColor={color['utility-surface-1']}
        vAlign="center"
        hAlign="center"
        margin={{ right: spacing[1] }}
      >
        <Image source={MinimalLogo} style={{ width: 24, height: 12 }} />
      </BlockView>

      {/* Content. */}
      <BlockView flex hAlign="flex-start">
        <Text.Roboto color={color['content-1']} size="16" style={{ padding: 10 }}>
          {label}
        </Text.Roboto>

        {/* Actions. */}
        {!!actionButton && (
          <BlockView margin={{ top: spacing[1] }}>
            <ButtonPrimary size="button-2" {...actionButton} />
          </BlockView>
        )}
      </BlockView>

      {/* Right Icon. */}
      {!!rightIcon && (
        <BlockView margin={{ left: spacing[1] }}>
          <ButtonIcon kind="minimal" iconName={rightIcon} onPress={onRightIconPress} />
        </BlockView>
      )}
    </BlockView>
  )
})
