import ColorJS from 'color'

/**
 * @param baseColor - Base color to apply transparency.
 * @param transparency  - Transparency in percentage. eg 8% -> rgba(r, g, b, 0.08)
 * @returns rgba color string
 */
export const addColorTransparency = (baseColor: string, transparency: number) => {
  const rgb = ColorJS(baseColor).rgb()
  return `rgba(${rgb.red()}, ${rgb.green()}, ${rgb.blue()}, ${transparency / 100})`
}

export type ColorTheme = {
  'utility-overlay': string
  'utility-surface-1': string
  'utility-surface-2': string
  'utility-surface-3': string
  'utility-surface-4': string
  'utility-surface-5': string
  'utility-border-1': string
  'utility-border-2': string
  'utility-border-3': string

  'content-1': string
  'content-2': string
  'content-3': string
  'content-4': string

  'always-black': string
  'alpha-black-2': string
  'alpha-black-4': string
  'alpha-black-8': string
  'alpha-black-16': string
  'alpha-black-32': string
  'alpha-black-64': string

  'always-white': string
  'alpha-white-2': string
  'alpha-white-4': string
  'alpha-white-8': string
  'alpha-white-16': string
  'alpha-white-32': string
  'alpha-white-64': string

  accent: {
    brand: string
    blueberry: string
    cherry: string
    grape: string
    lemon: string
    lime: string
    lychee: string
    water: string
    date: string
    peach: string
    papaya: string
  }

  state: {
    neutral: string
    positive: string
    attention: string
    critical: string
  }
}
