import { useCallback, useEffect, useRef, useState } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'
import { useMutation } from 'react-query'

import {
  AnimateTransition,
  BlockView,
  ButtonPrimary,
  InputFieldState,
  Modal2,
  Text,
  VerificationCode,
  showSnackbar,
  useColor,
  useLayout,
} from 'core'
import { useRootStore } from 'store/root.store'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AuthenticationRoutes, ChatRoutes } from 'navigation/routes'
import { removeNonNumberText } from 'utils/validator'
import { EmailLoginOrSignupVariables, emailLoginOrSignup } from 'api/authentication/email-login-or-signup'
import { VerifyOtpVariables, verifyOtp } from 'api/authentication/verify-otp'
import { updateAccount } from 'api/user/update-account'

type ScreenProps = NativeStackScreenProps<RootAppRoutesProps, AuthenticationRoutes.VerifyCode>

export const AuthenticationVerifyCode = observer(function AuthenticationVerifyCode({ navigation, route }: ScreenProps) {
  /** States. */
  const { authStore } = useRootStore()
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [inputState, setInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })
  const [verifyAttempt, setVerifyAttempt] = useState(0)
  const [codeResent, setCodeResent] = useState(route.params?.fromCodeResent === 'true')
  const resetCodeResentTimeout = useRef<NodeJS.Timeout | null>(null)

  useFocusEffect(
    useCallback(() => {
      const isPresented = navigation.canGoBack()

      if (!isPresented || !route?.params?.otpId) {
        setCloseModal(true)
        if (authStore.authToken) {
          // @ts-ignore
          return navigation.navigate(ChatRoutes.Root, { screen: ChatRoutes.Conversation, params: { id: `new-chat` } })
        }
        return navigation.navigate(AuthenticationRoutes.Login)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  /**
   * Timer to reset "code resent" status for connecting email.
   */
  useEffect(() => {
    if (codeResent) {
      resetCodeResentTimeout.current = setTimeout(() => {
        setCodeResent(false)
      }, 5000)
    }
    return () => {
      if (resetCodeResentTimeout.current) {
        clearTimeout(resetCodeResentTimeout.current)
      }
    }
  }, [codeResent])

  /**
   * Close the modal if user has attempted more than 3 times.
   */
  useEffect(() => {
    if (verifyAttempt > 2) {
      showSnackbar({
        kind: 'error',
        label: `Too many wrong attempts. Please try again.`,
        leftIcon: 'error',
      })
      setCloseModal(true)
    }
  }, [verifyAttempt])

  /** Update User Compliance Accepted. */
  const updateUserMutation = useMutation(() =>
    updateAccount({
      tcAccept: true,
    })
  )

  /** Email Login or Signup Mutation. Used to resend the code. */
  const emailLoginOrSignupMutation = useMutation(
    (variables: EmailLoginOrSignupVariables) => {
      return emailLoginOrSignup(variables)
    },
    {
      onSuccess: (response) => {
        if (!response.otpId || !route.params?.emailAddress) {
          setCloseModal(true)
          return
        }
        navigation.setParams({
          otpId: response.otpId,
          emailAddress: route.params.emailAddress,
          fromCodeResent: 'true',
        })

        setCodeResent(true)
      },
      onError: () => {
        setCloseModal(true)
      },
    }
  )

  /** Verify Otp Mutation. */
  const verifyOtpMutation = useMutation((variables: VerifyOtpVariables) => verifyOtp(variables), {
    onSuccess: (response) => {
      if (!response.token) {
        setVerifyAttempt((prev) => (prev += 1))
        setInputState({
          state: 'error',
          message: 'The verification code is not correct. Please try again.',
        })
        return
      }

      /**
       * Update user's `tcAcceptedAt` field.
       * We do not need to wait for it.
       */
      updateUserMutation.mutate()

      // Successful.
      setInputState({
        state: 'success',
        message: ``,
      })
      authStore.setAuthToken(response.token)
    },
    onError: () => {
      setVerifyAttempt((prev) => (prev += 1))
      setInputState({
        state: 'error',
        message: 'The verification code is not correct. Please try again.',
      })
    },
  })

  const onVerificationCodeEnter = (code: string) => {
    if (verifyOtpMutation.isLoading || !route?.params?.otpId) return
    if (code.length >= 6) {
      setVerificationCode(removeNonNumberText(code.substring(0, 6)))
      return verifyOtpMutation.mutate({
        otpId: route.params.otpId,
        code: code.substring(0, 6),
      })
    }

    if (inputState.state !== 'default') setInputState({ state: 'default', message: '' })
    setVerificationCode(removeNonNumberText(code))
  }

  if (!route?.params?.otpId) {
    return null
  }

  const { emailAddress, isNostr } = route.params

  const onBackPress = () => {
    setCloseModal(true)
  }

  const titleText = (() => {
    if (emailAddress) return `Verify email`
    if (isNostr) return `Verify Nostr`
    return `Verify code`
  })()

  const contentText = (() => {
    if (emailAddress) {
      return `Enter the code sent to ${emailAddress}`
    }
    if (isNostr) {
      return `Enter the code sent to your via DM on Nostr. You may need to check your Message Requests inbox.`
    }
    return `Enter the code sent to you`
  })()

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="account-verify-code" type="from-bottom" duration={250}>
            <Modal2
              width={410}
              leftIcon="arrow-back"
              onLeftIconPress={onBackPress}
              title={titleText}
              centerTitle
              text={
                <BlockView>
                  {/* Content. */}
                  <Text.Roboto color={color['content-2']} size="15" style={{ textAlign: 'center' }}>
                    {contentText}
                  </Text.Roboto>

                  {/* Verification Code. */}
                  <BlockView size={{ width: '100%' }} margin={{ vertical: spacing[4] }}>
                    <VerificationCode
                      code={verificationCode}
                      onCodeEnter={onVerificationCodeEnter}
                      state={inputState.state}
                    />
                    {!!inputState.message && (
                      <Text.Roboto color={color.state.critical} size="12" style={{ marginTop: 6 }}>
                        {inputState.message}
                      </Text.Roboto>
                    )}
                  </BlockView>

                  {/* Resend Button. */}
                  {!!emailAddress && (
                    <BlockView size={{ width: '100%' }} hAlign="center" vAlign="center">
                      {codeResent ? (
                        <ButtonPrimary
                          kind="outlined"
                          size="button-2"
                          leftIcon="check"
                          label="Resent"
                          applyMinWidth={false}
                          disabled
                          onPress={() => {}}
                        />
                      ) : (
                        <ButtonPrimary
                          kind="outlined"
                          size="button-2"
                          label="Resend code"
                          applyMinWidth={false}
                          onPress={() => {
                            if (emailLoginOrSignupMutation.isLoading) return
                            return emailLoginOrSignupMutation.mutate({ email: emailAddress })
                          }}
                          loading={emailLoginOrSignupMutation.isLoading}
                        />
                      )}
                    </BlockView>
                  )}
                </BlockView>
              }
            />
          </AnimateTransition>
        )}
      </AnimatePresence>

      {verifyOtpMutation.isLoading && (
        <BlockView
          // key="verify-otp-loading"
          size={{ width: screenSize.width, height: screenSize.height }}
          hAlign="center"
          vAlign="center"
          style={StyleSheet.absoluteFill}
        >
          <ActivityIndicator color={color['content-1']} />
        </BlockView>
      )}
    </BlockView>
  )
})
