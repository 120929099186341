import { useCallback, useEffect, useRef, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as Clipboard from 'expo-clipboard'

import {
  AnimateTransition,
  BlockView,
  ButtonPrimary,
  Modal2,
  Text,
  showSnackbar,
  useColor,
  useLayout,
  QRCode,
} from 'core'
import { useRootStore } from 'store/root.store'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AccountModalRoutesProps } from 'navigation/stack/account'
import { AccountRoutes, ChatRoutes } from 'navigation/routes'
import { LnurlLoginCheckVariables, lnurlLoginCheck } from 'api/authentication/lnurl-login-check'
import { lnurlLogin } from 'api/authentication/lnurl-login'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<AccountModalRoutesProps, AccountRoutes.ConnectLightning>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const AccountConnectLightning = observer(function AccountConnectLightning({ navigation }: ScreenProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { authStore } = useRootStore()
  const { color } = useColor()
  const { spacing } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const pollIntervalRef = useRef<NodeJS.Timeout>()

  useFocusEffect(
    useCallback(() => {
      if (!navigation.canGoBack()) {
        // @ts-ignore
        navigation.navigate(ChatRoutes.Root, { screen: ChatRoutes.Conversation, params: { id: `new-chat` } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current)
      }
    }
  }, [])

  const onLoadError = () => {
    showSnackbar({
      kind: 'error',
      label: `Can't generate your lightning invoice, please try again later.`,
      leftIcon: 'error',
    })
    setCloseModal(true)
    navigation.goBack()
  }

  /** Lnurl Login check Mutation. */
  const lnurlLoginCheckMutation = useMutation(
    ({ lnAddressId }: LnurlLoginCheckVariables) =>
      lnurlLoginCheck({
        lnAddressId,
      }),
    {
      onSuccess: (response) => {
        if (response.token) {
          authStore.setAuthToken(response.token)
          queryClient.refetchQueries({
            queryKey: 'get-account-me',
          })

          showSnackbar({
            kind: 'success',
            label: `Successfully connected a lightning wallet!`,
            leftIcon: 'check-circle',
          })
          setCloseModal(true)
        }
      },
    }
  )

  /** Lnurl Login Query.  */
  const { isLoading, isError, data } = useQuery({
    queryKey: 'my-account-lnurl-login',
    queryFn: lnurlLogin,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      if (response.lnAddressId) {
        pollIntervalRef.current = setInterval(() => {
          lnurlLoginCheckMutation.mutate({
            lnAddressId: response.lnAddressId,
          })
        }, 3000)
        return
      }

      onLoadError()
    },
    onError: onLoadError,
  })

  if (isError) {
    return null
  }

  const renderLnurlAddress = () => {
    if (isLoading || !data?.lnAddress) {
      return (
        <BlockView size={160} vAlign="center" hAlign="center">
          <ActivityIndicator color={color['content-1']} />
        </BlockView>
      )
    }

    const onCopyToClipboard = async () => {
      await Clipboard.setStringAsync(data.lnAddress)
      showSnackbar({
        label: `Copied to clipboard!`,
      })
    }

    return (
      <BlockView size={{ width: '100%' }}>
        <BlockView flex hAlign="center" vAlign="center" padding={{ top: spacing[2], bottom: spacing[5] }}>
          <Text.Roboto
            color={color['content-1']}
            size="15"
            strong
            style={{ textAlign: 'center', marginBottom: spacing[3] }}
          >
            Scan with a lightning wallet
          </Text.Roboto>

          <QRCode value={data.lnAddress} />
        </BlockView>

        <ButtonPrimary
          kind="outlined"
          size="button-2"
          label="Copy to clipboard"
          rightIcon="content-copy"
          onPress={onCopyToClipboard}
        />
      </BlockView>
    )
  }

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="account-connect-lightning" type="from-bottom" duration={250}>
            <Modal2
              title="Connect lightning"
              centerTitle
              text={
                <BlockView size={{ width: '100%' }} hAlign="center">
                  {renderLnurlAddress()}
                </BlockView>
              }
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
