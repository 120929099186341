import { Pressable, StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import { useColor } from 'core/v2/color'
import { TextStyles } from '../Text'

export type TabProps = {
  label: string
  activated?: boolean
  disabled?: boolean
  onPress?: () => void

  wrapperStyle?: StyleProp<ViewStyle>
}

enum PressedState {
  PressedOut = 0,
  PressedIn = 1,
}

export const Tab = observer(function Tab({ label, activated, disabled, onPress, wrapperStyle }: TabProps) {
  /** States. */
  const { color, addColorTransparency } = useColor()
  const pressedState = useSharedValue(PressedState.PressedOut)

  const onHoverIn = () => {
    pressedState.value = withTiming(PressedState.PressedIn, { duration: 150 })
  }
  const onHoverOut = () => {
    pressedState.value = withTiming(PressedState.PressedOut, { duration: 150 })
  }

  const labelAnimatedStyle = useAnimatedStyle<TextStyle>(() => {
    if (activated) return { color: color.accent.brand }
    if (disabled) return { color: addColorTransparency(color['content-1'], 24) }

    return {
      color: interpolateColor(
        pressedState.value,
        [PressedState.PressedOut, PressedState.PressedIn],
        [color['content-2'], color['content-1']]
      ),
    }
  })

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPressIn={onHoverIn}
      onPressOut={onHoverOut}
      style={[
        styles.button,
        {
          borderBottomColor: activated ? color.accent.brand : color['utility-border-1'],
        },
      ]}
    >
      <Animated.Text style={[styles.text, labelAnimatedStyle]}>{label}</Animated.Text>
    </Pressable>
  )
})

type Style = {
  button: ViewStyle
  text: TextStyle
}

const styles = StyleSheet.create<Style>({
  button: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 10,
    borderBottomWidth: 1,
  },
  text: {
    ...TextStyles['text-15'],
    ...TextStyles.robotoBold,
    textAlign: 'center',
    paddingVertical: 13,
  },
})
