import { observer } from 'mobx-react-lite'

import { BlockView, Text, MaterialIconOutlined, MaterialIconOutlinedName, useColor, useLayout } from 'core'

type TitleProps = {
  icon: MaterialIconOutlinedName
  text: string
}

export const Title = observer(function Title({ icon, text }: TitleProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView direction="row" vAlign="center">
      <MaterialIconOutlined name={icon} size={24} color={color['content-1']} style={{ marginRight: spacing[1] }} />
      <Text.Roboto size="20" color={color['content-1']} strong>
        {text}
      </Text.Roboto>
    </BlockView>
  )
})
