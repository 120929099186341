import { useRef, useState } from 'react'
import { TextInput } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useMutation } from 'react-query'

import { BlockView, ButtonPrimary, FormField, InputFieldState, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AuthenticationRoutes } from 'navigation/routes'
import { NostrLoginVariables, nostrLogin } from 'api/authentication/nostr-login'

export const NostrSignIn = () => {
  /** States. */
  const navigation = useNavigation<NativeStackNavigationProp<RootAppRoutesProps, AuthenticationRoutes.Login>>()
  const { spacing } = useLayout()
  const [nPub, setNPub] = useState('')
  const [relay, setRelay] = useState('')
  const [nPubInputState, setNPubInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })
  const [relayInputState, setRelayInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })
  const nPubInputRef = useRef<TextInput>(null)
  const relayInputRef = useRef<TextInput>(null)

  const onErrorFallback = () => {
    setNPubInputState({
      state: 'error',
      message: '',
    })
    setRelayInputState({
      state: 'error',
      message: 'Something went wrong. Please try again',
    })
  }

  /** Nostr Login Mutation. */
  const nostrLoginMutation = useMutation(
    (variables: NostrLoginVariables) => {
      return nostrLogin(variables)
    },
    {
      onSuccess: (response) => {
        if (!response.otpId) {
          onErrorFallback()
          return
        }

        navigation.navigate(AuthenticationRoutes.VerifyCode, {
          otpId: response.otpId,
          isNostr: true,
        })
      },
      onError: () => {
        onErrorFallback()
      },
    }
  )

  const submitActive = (() => {
    const testNPub = /^npub/.test(nPub)
    const testRelay = /^wss:\/\//.test(relay)

    return testNPub && testRelay
  })()

  const onSubmit = () => {
    if (nostrLoginMutation.isLoading) return
    const testNPub = /^npub/.test(nPub)
    const testRelay = /^wss:\/\//.test(relay)

    if (!testNPub) {
      setNPubInputState({
        state: 'error',
        message: 'Please provide a valid npub',
      })
      return
    }
    if (!testRelay) {
      setRelayInputState({
        state: 'error',
        message: 'Please provide a valid relay',
      })
    }

    nostrLoginMutation.mutate({ nPub, relay })
  }

  return (
    <BlockView size={{ width: '100%' }} margin={{ top: spacing[2] }}>
      <FormField
        inputRef={nPubInputRef}
        value={nPub}
        onValueChange={(text) => {
          if (nPubInputState.state !== 'default') setNPubInputState({ state: 'default', message: '' })
          setNPub(text)
        }}
        placeholderText="npub"
        otherInputProps={{
          onSubmitEditing: () => relayInputRef.current?.focus(),
          autoCapitalize: 'none',
          autoCorrect: false,
        }}
        inputState={nPubInputState.state}
        supportLabel={nPubInputState.message}
      />

      <BlockView margin={{ vertical: spacing[1] }}>
        <FormField
          inputRef={relayInputRef}
          value={relay}
          onValueChange={(text) => {
            if (relayInputState.state !== 'default') setRelayInputState({ state: 'default', message: '' })
            setRelay(text)
          }}
          placeholderText="relay (eg: wss://relay.damus.io)"
          otherInputProps={{
            onSubmitEditing: onSubmit,
            autoCapitalize: 'none',
            autoCorrect: false,
          }}
          inputState={relayInputState.state}
          supportLabel={relayInputState.message}
        />
      </BlockView>

      <ButtonPrimary
        kind="filled"
        label="Send code"
        onPress={onSubmit}
        loading={nostrLoginMutation.isLoading}
        disabled={!submitActive}
      />
    </BlockView>
  )
}
