import { useEffect, useRef, useState } from 'react'
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputChangeEventData,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { observer } from 'mobx-react-lite'
import { AnimatePresence, MotiView } from 'moti'

import {
  BlockView,
  ButtonIcon,
  ButtonPrimary,
  Text,
  useColor,
  useLayout,
  TextStyles,
  Constants,
  UserAvatar,
} from 'core'
import { clamp } from 'utils/number'

type ResponseProps = {
  userFullName?: string | null
  content: string
  onContentUpdate: (newContent: string) => void
}

export const Response = observer(function Response({ userFullName, content, onContentUpdate }: ResponseProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const [inEditMode, setInEditMode] = useState(false)

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="flex-start"
      padding={spacing[2]}
      backgroundColor={color['alpha-white-4']}
      border={{ radius: 16 }}
    >
      {/* Satoshi Logo. */}
      <UserAvatar fullName={userFullName ?? null} />

      <AnimatePresence exitBeforeEnter>
        {!inEditMode ? (
          <MotiView
            key="dtv-resposne-static"
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={[styles.questionContainer, { marginLeft: spacing[1] }]}
          >
            <StaticResponse content={content} onEditPress={() => setInEditMode(true)} />
          </MotiView>
        ) : (
          <MotiView
            key="dtv-response-edit"
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={[styles.questionContainer, { marginLeft: spacing[1] }]}
          >
            <EditResponse
              content={content}
              onCancelPress={() => setInEditMode(false)}
              onSavePress={(updatedContent) => {
                setInEditMode(false)
                onContentUpdate(updatedContent)
              }}
            />
          </MotiView>
        )}
      </AnimatePresence>
    </BlockView>
  )
})

type StaticResponseProps = {
  content: string
  onEditPress: () => void
}

const StaticResponse = observer(function StaticResponse({ content, onEditPress }: StaticResponseProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView flex direction="row">
      <BlockView flex padding={10} margin={{ right: spacing[1] }}>
        <Text.RobotoMono color={color['content-2']} size="12" uppercase style={{ marginBottom: spacing[1] }}>
          Response
        </Text.RobotoMono>

        <Text.Roboto size="16" color={color['content-1']}>
          {content}
        </Text.Roboto>
      </BlockView>

      {/* Edit Button. */}
      <ButtonIcon kind="minimal" iconName="edit" iconColor={color['content-2']} onPress={onEditPress} />
    </BlockView>
  )
})

type EditResponseProps = {
  content: string
  onCancelPress: () => void
  onSavePress: (updatedContent: string) => void
}

const TEXT_INPUT_BASE_HEIGHT = TextStyles['text-16'].lineHeight ?? 20

const EditResponse = observer(function EditResponse({ content, onCancelPress, onSavePress }: EditResponseProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [contentValue, setContentValue] = useState(content)
  const textInputEl = useRef(null)

  const currentCharacterLength = contentValue.length
  const showCharacterLimit = currentCharacterLength > 2050

  const adjustInputHeight = (e: LayoutChangeEvent | NativeSyntheticEvent<TextInputChangeEventData>) => {
    if (Platform.OS !== 'web') return
    // @ts-ignore
    const el = e.target || e.nativeEvent?.target
    textInputEl.current = el
    if (el) {
      // @ts-ignore
      el.style.height = 0
      // @ts-ignore
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight
      const clampedHeight = clamp(newHeight, 0, screenSize.height / 2)
      // @ts-ignore
      el.style.height = `${clampedHeight}px`
    }
  }

  useEffect(() => {
    /**
     * On Web, when the message is cleared programmatically TextInput's onLayout and onChange does not get triggered.
     * This results in the height of the TextInput not resizing correctly.
     * So we are manually applying the height if the TextInput's value clears.
     */
    if (!contentValue.length && Platform.OS === 'web' && textInputEl.current) {
      // @ts-ignore
      textInputEl.current.style.height = `${TEXT_INPUT_BASE_HEIGHT}px`
    }
  }, [contentValue])

  return (
    <BlockView flex>
      <BlockView flex border={{ radius: 8, width: 1, color: color['utility-border-1'] }} padding={10}>
        <Text.RobotoMono color={color['content-2']} size="12" uppercase style={{ marginBottom: spacing[1] }}>
          Response
        </Text.RobotoMono>

        <TextInput
          value={contentValue}
          onChangeText={setContentValue}
          maxFontSizeMultiplier={1.2}
          placeholder="Enter the question"
          placeholderTextColor={color['content-2']}
          multiline
          onChange={adjustInputHeight}
          onLayout={adjustInputHeight}
          autoFocus
          style={[
            // @ts-ignore
            Platform.OS === 'web' && { outlineWidth: 0, outline: 'none' },
            styles.input,
            { color: color['content-1'] },
          ]}
        />
      </BlockView>

      {showCharacterLimit && (
        <BlockView margin={{ top: spacing[1] }} hAlign="flex-end">
          <Text.Roboto
            size="14"
            color={(() => {
              if (currentCharacterLength < 2100) return color.state.attention
              return color.state.critical
            })()}
          >{`${currentCharacterLength} / ${Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH}`}</Text.Roboto>
        </BlockView>
      )}

      {/* Button Group. */}
      <BlockView direction="row" hAlign="flex-end" margin={{ top: spacing[2] }}>
        <ButtonPrimary size="button-2" kind="outlined" label="Cancel" onPress={onCancelPress} applyMinWidth={false} />
        <ButtonPrimary
          size="button-2"
          kind="filled"
          label="Save"
          onPress={() => onSavePress(contentValue)}
          applyMinWidth={false}
          buttonStyle={{ marginLeft: spacing[1] }}
          disabled={!currentCharacterLength || currentCharacterLength > Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH}
        />
      </BlockView>
    </BlockView>
  )
})

type Style = {
  questionContainer: ViewStyle
  input: TextStyle
}

const styles = StyleSheet.create<Style>({
  questionContainer: {
    flex: 1,
    width: '100%',
  },
  input: {
    ...TextStyles.robotoRegular,
    ...TextStyles['text-16'],
  },
})
