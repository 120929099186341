import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'

import { BlockView } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'
import { ActivityIndicator } from 'react-native'

export const ChatOutLoading = observer(function ChatOutLoading() {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="center"
      padding={spacing[2]}
      backgroundColor={color['alpha-white-4']}
      border={{ radius: 16 }}
    >
      {/* Avatar. */}
      <BlockView
        size={40}
        border={{ radius: 20 }}
        backgroundColor={color['utility-surface-1']}
        vAlign="center"
        hAlign="center"
      >
        <Image source={MinimalLogo} style={{ width: 24, height: 12 }} />
      </BlockView>

      <BlockView padding={10}>
        <ActivityIndicator color={color['content-2']} style={{ marginLeft: spacing[1] }} />
      </BlockView>
    </BlockView>
  )
})
