import { request } from 'api/request'

export type ConversationReaction = 'THUMBS_UP' | 'THUMBS_DOWN'

export type ReplyDetails = {
  miniscriptPolicy?: string | string[]
  mermaidCodes?: string[]
  policiesCompiled?: [
    {
      miniscript?: string
      bitcoinScript?: string
      spending?: {
        script?: string
        input?: string
        total?: string
      }
    },
  ]
  ragItems: [
    {
      url?: string
      title?: string
      text?: string
    },
  ]
}

export type ConversationMessage = {
  id: string
  isFromUser: boolean
  isFromMentor: boolean
  text: string
  createdAt: string // Date
  type: 'TEXT' | 'VOICE'
  voiceUrl?: string | null // Only when type === 'VOICE'
  reaction?: ConversationReaction | null
  replySource?: string | null
  replyMetadata?: string | null
  replyDetails?: ReplyDetails | null
}

export type GetConversationMessages = ConversationMessage[]

export const getConversationMessages = (conversationId: string): Promise<GetConversationMessages> => {
  return request.get(`v1/chat/${conversationId}`)
}
