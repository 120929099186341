import { observer } from 'mobx-react-lite'

import { BlockView, Text, BlockViewProps, useColor, useLayout } from 'core'

type CardProps = {
  children?: React.ReactNode
  highlight?: boolean // A flag to highlight the card with the lighter background.
} & BlockViewProps

export const Card = observer(function Card({ children, highlight = false, ...blockViewProps }: CardProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView
      {...blockViewProps}
      backgroundColor={highlight ? color['utility-surface-3'] : 'transparent'}
      border={{ radius: 16 }}
      padding={spacing[3]}
    >
      {children}
    </BlockView>
  )
})

type TextCardProps = {
  title: string
  content: string
  highlight?: boolean
} & BlockViewProps

export const TextCard = observer(function TextCard({
  title,
  content,
  highlight = false,
  ...blockViewProps
}: TextCardProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <Card highlight={highlight} {...blockViewProps}>
      <Text.Roboto
        size="14"
        color={highlight ? color['content-1'] : color['content-2']}
        strong
        style={{ marginBottom: spacing[2] }}
      >
        {title}
      </Text.Roboto>

      <Text.Roboto size="32" color={color['content-1']} strong>
        {content}
      </Text.Roboto>
    </Card>
  )
})
