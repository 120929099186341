import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'

import { BlockView, ChatNotice, useColor, useLayout } from 'core'
import { useRootStore } from 'store/root.store'
import { ChatAppRoutesProps } from 'navigation/stack/chat'
import { ChatRoutes, PaymentRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'

type NavigationProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ChatAppRoutesProps, ChatRoutes.Conversation>,
    DrawerNavigationProp<ChatAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const CreditNotice = observer(function CreditNotice() {
  /** States. */
  const navigation = useNavigation<NavigationProps>()
  const { uiToggleStore } = useRootStore()
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView margin={{ top: spacing[1] }}>
      <ChatNotice
        rightIcon="close"
        onRightIconPress={() => {
          uiToggleStore.setOutOfCreditNotice(false)
        }}
        label={`Just letting you know, you're out of credits. Add more to continue this conversation.`}
        actionButton={{
          kind: 'outlined',
          label: `Add credits`,
          leftIcon: 'add',
          applyMinWidth: false,
          labelColor: color.accent.brand,
          onPress: () => {
            navigation.navigate(PaymentRoutes.AddCreditModal)
          },
        }}
      />
    </BlockView>
  )
})
