import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'
import { AnimatePresence } from 'moti'

import { BlockView, Modal2, showSnackbar, useLayout, useColor, AnimateTransition } from 'core'
import { SubmitQuestionActionType, submitQuestionAction } from 'api/data-train/submit-question-action'

type ConfirmModalProps = {
  questionId: string
  action: SubmitQuestionActionType
  answer: string
  onSubmitSuccess: () => void

  visible: boolean
  onClose: () => void
}

export const ConfirmModal = observer(function ConfirmModal({
  questionId,
  action,
  answer,
  onSubmitSuccess,

  visible,
  onClose,
}: ConfirmModalProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { color } = useColor()
  const { screenSize, spacing } = useLayout()

  /** Submit Question */
  const submitQuestionActionMutation = useMutation(
    () =>
      submitQuestionAction(questionId, {
        action,
        answer,
      }),
    {
      onSuccess: (response) => {
        // Succeeded.
        if (response.status === 200) {
          queryClient.refetchQueries({
            queryKey: 'get-fud-buster-question',
          })
          onClose()
          onSubmitSuccess()
          return
        }

        // Did not succeed.
        onClose()
        showSnackbar({
          kind: 'error',
          label: `Your submission was not successful. Please try again.`,
          leftIcon: 'error',
        })
      },
    }
  )

  return (
    <AnimatePresence exitBeforeEnter onExitComplete={onClose}>
      {visible && (
        <BlockView
          flex
          size={{ width: '100%', height: '100%' }}
          hAlign="center"
          vAlign="center"
          padding={{ horizontal: spacing[2] }}
          position={{ top: 0, left: 0 }}
        >
          <AnimateTransition key="dtv-discard-prompt-modal" type="from-bottom" duration={250}>
            <Modal2
              title="Are you sure?"
              text="Is your answer complete? Your reply should thoroughly and specifically answer the question."
              actions={[
                {
                  kind: 'outlined',
                  label: `Keep editing`,
                  applyMinWidth: false,
                  onPress: onClose,
                },
                {
                  kind: 'outlined',
                  label: `Yes, submit`,
                  labelColor: color.accent.brand,
                  onPress: () => {
                    if (!questionId) return
                    submitQuestionActionMutation.mutate()
                  },
                  loading: submitQuestionActionMutation.isLoading,
                },
              ]}
              width={360}
              style={{
                maxWidth: screenSize.width - spacing[4],
              }}
            />
          </AnimateTransition>
        </BlockView>
      )}
    </AnimatePresence>
  )
})
