export enum AuthenticationRoutes {
  Login = 'authentication/login',
  VerifyCode = 'authentication/verify-code',
}

export enum HowToModalRoutes {
  HowStatisticsWork = 'how-to/how-statistics-work',
  HowToCleanupData = 'how-to/how-to-cleanup-data',
  HowToFightFud = 'how-to/how-to-fight-fud',
  HowToReplyAsSatoshi = 'how-to/how-to-reply-as-satoshi',
  HowToNoSecondBest = 'how-to/how-to-no-second-best',
}

export enum ChatRoutes {
  Root = 'chat/root', // This is a root for the route.
  Conversation = 'chat/conversation',

  ModalRoot = 'chat/modal/root',
  ModalMobileMenu = 'chat/modal/mobile-menu',
  ModalUpdateConversation = 'chat/modal/update-conversation',
  ModalDeleteConversation = 'chat/modal/delete-conversation',
}

export enum ContributeRoutes {
  Root = 'contribute/root', // This is a root for the route.
  DTV = 'contribute/dont-trust-verify',
  WeAreAllSatoshi = 'contribute/we-are-all-satoshi',
  FudBuster = 'contribute/fud-buster',
  NoSecondBest = 'contribute/no-second-best',
  Statistics = 'contribute/statistics',
  Leaderboard = 'contribute/leaderboard',

  // Modals.
  ModalRoot = 'contribute/modal/root',
  ModalDiscardDTVPrompt = 'contribute/modal/discard-dtv-prompt',
}

export enum PaymentRoutes {
  AddCreditModal = 'payment/add-credit',
  WithdrawBalance = 'payment/withdraw-balance',
  GetDonationModal = 'payment/get-donation',
}

export enum AccountRoutes {
  MyAccount = 'account/my-account',
  ConnectEmail = 'account/connect-email',
  ConnectLightning = 'account/connect-lightning',
  ConnectNostr = 'account/connect-nostr',
  VerifyCode = 'account/verify-code',
}

export enum CampaignRoutes {
  Geyser = 'campaign/geyser',
}
