// MiniscriptViewer.tsx
import React, { useState } from 'react'
import { StyleSheet, Text, View, ScrollView, TouchableOpacity, Animated } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'
import { ButtonFab, MaterialIconOutlined, Text as TextCore } from 'core'
import Markdown from 'react-native-markdown-display'
import { ReplyDetails } from '../../../../api/chat/get-conversation-messages'
import { InAppBrowser } from '../../../../utils/browser'

interface CodeBlockProps {
  code: string
  pattern: string
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code, pattern }) => {
  const [copiedText, setCopiedText] = useState('')
  const fadeAnim = new Animated.Value(0)

  const copyToClipboard = (code: string) => {
    Clipboard.setString(code)
    setCopiedText(code) // Set the copied text to state

    // Animate the opacity for the "Copied" text
    Animated.sequence([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }),
      Animated.delay(1500),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start(() => {
      setCopiedText('') // Reset the copied text state after the animation
    })
  }

  const randStr = () => Math.random().toString(36).substr(2, 5)

  return (
    <ScrollView style={styles.scrollView}>
      <View key={`code-${randStr}-pattern`} style={styles.codeContainer}>
        <View style={styles.codeHeaderContainer}>
          <Text style={styles.codeHeader}>{pattern}</Text>
          <Text style={styles.copyButtonText}>
            {copiedText ? (
              <View style={[styles.copiedTextContainer]}>
                <MaterialIconOutlined name="check" size={15} style={{ marginRight: 4 }} />
                <Text style={styles.copiedText}>Copied!</Text>
              </View>
            ) : (
              <TouchableOpacity style={styles.copyButton} onPress={() => copyToClipboard(code.trim())}>
                <View style={[styles.copyButtonText]}>
                  <MaterialIconOutlined name="file-copy" size={14} style={{ marginRight: 4 }} />
                  <Text>Copy code</Text>
                </View>
              </TouchableOpacity>
            )}
          </Text>
        </View>
        <Text style={styles.codeBlock}>{code.trim()}</Text>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  copiedTextContainer: {
    zIndex: 1,
    color: 'white',
    alignItems: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: 5,
    paddingLeft: 0,
  },
  copiedText: {
    color: 'white',
    fontWeight: 'bold',
  },
  scrollView: {
    // marginHorizontal: 20,
  },
  codeContainer: {
    marginBottom: 15,
  },
  codeHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#21252b',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    height: 32,
  },
  codeHeader: {
    fontSize: 12.5,
    fontWeight: 'bold',
    color: 'rgba(217,217,227,var(--tw-text-opacity))',
  },
  copyButton: {
    backgroundColor: '#21252b',
    borderRadius: 5,
    padding: 5,
    paddingLeft: 0,
  },
  copyButtonText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'rgba(217,217,227,var(--tw-text-opacity))',
    alignItems: 'center',
    flexDirection: 'row',
  },
  codeBlock: {
    fontFamily: 'monospace',
    backgroundColor: 'black',
    color: '#F2A900',
    padding: 10,
    fontSize: 14,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    overflowWrap: 'anywhere',
  },
})

export default CodeBlock
