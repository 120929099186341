import { useWindowDimensions } from 'react-native'

const BASE_SPACING = 8

export const useLayout = () => {
  const { width, height } = useWindowDimensions()

  return {
    screenSize: {
      width,
      height,
    },

    spacing: {
      '1': BASE_SPACING * 1,
      '2': BASE_SPACING * 2,
      '3': BASE_SPACING * 3,
      '4': BASE_SPACING * 4,
      '5': BASE_SPACING * 5,
      '6': BASE_SPACING * 6,
      '8': BASE_SPACING * 8,
      '10': BASE_SPACING * 10,
      '12': BASE_SPACING * 12,
      '16': BASE_SPACING * 16,
    },

    breakPoints: {
      '2xs': 320,
      xs: 420,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    },
  }
}
