import { ScrollView, StyleProp, ViewStyle } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, ButtonIcon, ButtonPrimary, ButtonPrimaryProps, MaterialIconOutlinedName, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

export type Modal2Props = {
  width?: number
  height?: number

  title: string
  centerTitle?: boolean
  showTitleClose?: boolean
  onTitleClosePress?: () => void
  leftIcon?: MaterialIconOutlinedName
  onLeftIconPress?: () => void

  text: string | React.ReactNode

  actions?: ButtonPrimaryProps[]

  useScroll?: boolean
  style?: StyleProp<ViewStyle>
}

export const Modal2 = observer(function Modal2({
  width,
  height,
  title,
  centerTitle,
  showTitleClose,
  onTitleClosePress,
  leftIcon,
  onLeftIconPress,
  text,
  actions,
  useScroll,
  style,
}: Modal2Props) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  const renderLeft = () => {
    if (leftIcon) {
      return <ButtonIcon kind="minimal" iconName={leftIcon} iconColor={color['content-1']} onPress={onLeftIconPress} />
    }
    if (centerTitle) {
      return <BlockView size={24} backgroundColor="transparent" style={{ opacity: 0 }} />
    }
    return null
  }

  const renderContent = () => {
    if (typeof text === 'string') {
      return (
        <Text.Roboto color={color['content-2']} size="14">
          {text}
        </Text.Roboto>
      )
    }

    return text
  }

  return (
    <Container useScroll={useScroll} width={width} height={height} style={style}>
      {/* Title. */}
      <BlockView
        padding={{ top: spacing[3], horizontal: spacing[3], bottom: spacing[1] }}
        direction="row"
        hAlign="space-between"
        vAlign="center"
      >
        {/* Dummy element for centered title. */}
        {renderLeft()}

        <Text.Roboto color={color['content-1']} size="18" strong>
          {title}
        </Text.Roboto>

        <BlockView style={{ opacity: showTitleClose ? 1 : 0 }}>
          <ButtonIcon kind="minimal" iconName="close" onPress={onTitleClosePress} />
        </BlockView>
      </BlockView>

      {/* Content. */}
      <BlockView padding={{ top: spacing[1], bottom: spacing[3], horizontal: spacing[3] }}>{renderContent()}</BlockView>

      {/* Actions. */}
      {!!actions && (
        <BlockView padding={{ horizontal: spacing[3], bottom: spacing[3] }} direction="row" hAlign="flex-end">
          {actions.map((action, index) => {
            const lastItem = actions.length - 1 === index
            return (
              <BlockView key={`modal-2-action-${index}`} margin={{ right: lastItem ? 0 : 10 }}>
                <ButtonPrimary size="button-2" {...action} />
              </BlockView>
            )
          })}
        </BlockView>
      )}
    </Container>
  )
})

type ContainerProps = {
  children: React.ReactNode
} & Pick<Modal2Props, 'width' | 'height' | 'style' | 'useScroll'>

const Container = observer(function Container({ children, width, height, style, useScroll }: ContainerProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()

  if (useScroll) {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: color['utility-surface-2'],
        }}
        style={[
          {
            width: width ?? 360,
            maxWidth: screenSize.width - spacing[4],
            height: height ? height : undefined,
            maxHeight: screenSize.height - spacing[5],
            borderRadius: 16,
            borderWidth: 1,
            borderColor: color['utility-border-1'],
            backgroundColor: color['utility-surface-2'],
          },
          style,
        ]}
      >
        {children}
      </ScrollView>
    )
  }

  return (
    <BlockView
      size={{
        width: width ?? 360,
        maxWidth: screenSize.width - spacing[4],
        height: height ? height : undefined,
        maxHeight: screenSize.height - spacing[5],
      }}
      border={{ radius: 16, width: 1, color: color['utility-border-1'] }}
      backgroundColor={color['utility-surface-2']}
      style={style}
    >
      {children}
    </BlockView>
  )
})
