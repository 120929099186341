import { TouchableOpacity } from 'react-native'
import Toast, { BaseToast, ToastConfig, ToastConfigParams } from 'react-native-toast-message'

import { MaterialIconOutlined, MaterialIconOutlinedName } from 'core/v2/atoms'
import { Text, TextStyles } from 'core/v2/atoms/Text'
import { useColor } from 'core/v2/color'

export type SnackbarKind = 'default' | 'error' | 'success'

export type SnackbarProps = {
  kind?: SnackbarKind
  leftIcon?: MaterialIconOutlinedName
  label: string
  labelStrong?: boolean

  actionText?: string
  onActionPress?: () => void
}

export const useSnackbarConfig = (): ToastConfig => {
  /** States. */
  const { color } = useColor()

  const generateToastConfig = (toastProps: ToastConfigParams<SnackbarProps>, type: SnackbarKind) => {
    const backgroundColor = (() => {
      if (type === 'error') return color.state.critical
      if (type === 'success') return color.state.positive
      return color['content-1']
    })()
    const mainLabelColor = (() => {
      if (type === 'error') return color['always-white']
      if (type === 'success') return color['always-white']
      return color['content-4']
    })()

    const { label, labelStrong, leftIcon, actionText, onActionPress } = toastProps.props

    return (
      <BaseToast
        {...toastProps}
        style={{
          backgroundColor,
          borderRadius: 8,
          alignItems: 'center',
          paddingHorizontal: 16,
          paddingVertical: 10,
          borderWidth: 0,
          minHeight: 44,
          height: 'auto',
        }}
        contentContainerStyle={{
          padding: 0,
        }}
        text1={label}
        text1Style={[
          labelStrong ? TextStyles.robotoBold : TextStyles.robotoRegular,
          TextStyles['text-15'],
          { color: mainLabelColor },
        ]}
        text1NumberOfLines={2}
        renderLeadingIcon={() => {
          if (!leftIcon) return
          return <MaterialIconOutlined name={leftIcon} color={mainLabelColor} size={24} style={{ marginRight: 4 }} />
        }}
        renderTrailingIcon={() => {
          if (!actionText) return
          return (
            <TouchableOpacity onPress={onActionPress} activeOpacity={0.7} style={{ marginLeft: 4 }}>
              <Text.Roboto color={mainLabelColor} underline size="15">
                {actionText}
              </Text.Roboto>
            </TouchableOpacity>
          )
        }}
      />
    )
  }

  return {
    default: (toastProps) => generateToastConfig(toastProps, 'default'),
    error: (toastProps) => generateToastConfig(toastProps, 'error'),
    success: (toastProps) => generateToastConfig(toastProps, 'success'),
  }
}

export const showSnackbar = ({
  kind = 'default',
  label,
  labelStrong,
  leftIcon,
  actionText,
  onActionPress,
}: SnackbarProps) => {
  return Toast.show({
    type: kind,
    visibilityTime: 3000,
    props: {
      kind,
      label,
      labelStrong,
      leftIcon,
      actionText,
      onActionPress,
    },
  })
}
