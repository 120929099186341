import { useEffect, useRef, useState } from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'
import { observer } from 'mobx-react-lite'
import Animated, { useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'

export type TypeWriterProps = {
  minDelay?: number
  maxDelay?: number
  text: string
  style?: StyleProp<TextStyle>

  onDone?: () => void
}

export const TypeWriter = observer(function TypeWriter({
  minDelay = 20,
  maxDelay = 100,
  text,
  style,
  onDone,
}: TypeWriterProps) {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const timeout = useRef<NodeJS.Timeout>()

  const showCursor = currentIndex < text.length

  useEffect(() => {
    const randomTimeout = Math.round(Math.random() * (maxDelay - minDelay) + minDelay)

    if (currentIndex < text.length) {
      timeout.current = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, randomTimeout)
    } else {
      onDone?.()
    }

    return () => clearTimeout(timeout.current)
  }, [minDelay, maxDelay, currentIndex, text, onDone])

  return (
    <Text selectable style={style}>
      {currentText}
      {showCursor && <Cursor style={style} />}
    </Text>
  )
})

type CursorProps = {
  style?: StyleProp<TextStyle>
}
const Cursor = observer(function Cursor({ style }: CursorProps) {
  const opacity = useSharedValue(0)

  useEffect(() => {
    opacity.value = withRepeat(withTiming(1, { duration: 500 }), -1, true)
  }, [opacity])

  const animatedStyle = useAnimatedStyle(() => ({ opacity: opacity.value }))

  return <Animated.Text style={[style, animatedStyle]}>|</Animated.Text>
})
