import { useState } from 'react'
import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'
import { QueryObserverResult } from 'react-query'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'

import { GetQuestionData } from 'api/data-train/get-question'

import { BlockView, Constants, FudbusterFooter, FudbusterSubmitActionType, useColor, useLayout } from 'core'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { SubmitQuestionActionType } from 'api/data-train/submit-question-action'

import { Question } from './Question'
import { Response } from './Response'
import { ConfirmModal } from './ConfirmModal'

type FudBusterTemplateProps = {
  isRefetching: boolean
  getNextData: () => Promise<QueryObserverResult<GetQuestionData>>
  questionId: string
  content: string
}

type AppProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.FudBuster>,
    DrawerNavigationProp<ContributeAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const FudBusterTemplate = observer(function FudBusterTemplate({
  isRefetching,
  getNextData,
  questionId,
  content,
}: FudBusterTemplateProps) {
  /** States. */
  const { navigate } = useNavigation<AppProps>()
  const { color } = useColor()
  const { spacing } = useLayout()
  const [userAnswer, setUserAnswer] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loadingActionType, setLoadingActionType] = useState<FudbusterSubmitActionType | null>(null)

  const resetStates = () => {
    setUserAnswer('')
    setLoadingActionType(null)
  }

  const onSkipPress = () => {
    if (isRefetching) return

    setLoadingActionType('SKIP')
    getNextData().finally(resetStates)
  }

  const onSubmitPress = () => {
    if (isRefetching) return

    setShowConfirmModal(true)
  }

  const submitActive = userAnswer.length <= Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH && userAnswer.length >= 50

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: color['utility-surface-2'],
          paddingHorizontal: spacing[2],
          alignItems: 'center',
        }}
        style={{ backgroundColor: color['utility-surface-2'] }}
      >
        <BlockView flex size={{ width: '100%', maxWidth: 745 }}>
          <Question content={content} />

          <Response response={userAnswer} onResponseChange={setUserAnswer} />
        </BlockView>
      </ScrollView>

      <FudbusterFooter
        submitDisabled={!submitActive}
        onHelpPress={() => {
          navigate(HowToModalRoutes.HowToFightFud)
        }}
        onSkipPress={onSkipPress}
        onSubmitPress={onSubmitPress}
        loadingAction={loadingActionType}
      />

      <ConfirmModal
        visible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        questionId={questionId}
        answer={userAnswer}
        action={SubmitQuestionActionType.AddAnswer}
        onSubmitSuccess={resetStates}
      />
    </>
  )
})
