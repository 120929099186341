import { request } from 'api/request'

export type GetTransactionConfirmationData = {
  confirmed: boolean
}

export type GetTransactionConfirmationVariables = {
  transactionId: string
}

export const getTransactionConfirmation = ({
  transactionId,
}: GetTransactionConfirmationVariables): Promise<GetTransactionConfirmationData> => {
  return request.get(`v1/transaction/${transactionId}/confirmation`)
}
