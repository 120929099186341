import { useCallback, useEffect, useState } from 'react'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'
import { AnimatePresence } from 'moti'

import { BlockView, Modal2, showSnackbar, useLayout, useColor, AnimateTransition } from 'core'
import { ContributeAppModalRoutesProps, ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes } from 'navigation/routes'
import { useRootStore } from 'store/root.store'
import {
  SubmitTrainDataActionType,
  SubmitTrainDataActionVariables,
  submitTrainDataAction,
} from 'api/data-train/submit-train-data-action'

export type DiscardPromptParams = {
  dataId: string
  contentEdit?: string
  answerEdit?: string
}

type ModalProps = CompositeScreenProps<
  NativeStackScreenProps<ContributeAppModalRoutesProps, ContributeRoutes.ModalDiscardDTVPrompt>,
  DrawerScreenProps<ContributeAppRoutesProps>
>

export const DiscardDTVPromptModal = observer(function DiscardDTVPromptModal({ route, navigation }: ModalProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { modalToggleStore } = useRootStore()
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [closeModal, setCloseModal] = useState(false)

  const dismissModal = () => {
    if (navigation.canGoBack()) {
      return navigation.goBack()
    }
    return navigation.navigate(ContributeRoutes.DTV)
  }

  useFocusEffect(
    useCallback(() => {
      if (!route.params?.dataId) {
        dismissModal()
      }
      // We only want this to run once.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  useEffect(() => {
    modalToggleStore.setShowDiscardTrainDataPrompt(false)
  }, [modalToggleStore])

  /** Submit Train Data Action Mutation. */
  const submitTrainDataMutation = useMutation(
    (data: ContributeAppModalRoutesProps[ContributeRoutes.ModalDiscardDTVPrompt]) => {
      let variables: SubmitTrainDataActionVariables = {
        action: SubmitTrainDataActionType.Discard,
      }

      const { dataId, contentEdit, answerEdit } = data

      // User has edited content.
      if (contentEdit) {
        variables = { ...variables, contentEdit }
      }
      // Answer was originally provided && user edited the answer.
      if (answerEdit) {
        variables = { ...variables, answerEdit }
      }

      return submitTrainDataAction(dataId, variables)
    },
    {
      onSuccess: (response) => {
        // Succeeded.
        if (response.status === 200) {
          // Get the next training date.
          queryClient.refetchQueries({
            queryKey: 'get-random-train-data',
          })
          setCloseModal(true)
          return
        }

        // Did not succeed.
        setCloseModal(true)
        showSnackbar({
          kind: 'error',
          label: `Your submission was not successful. Please try again.`,
          leftIcon: 'error',
        })
      },
    }
  )

  if (!route.params) {
    return null
  }

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="dtv-discard-prompt-modal" type="from-bottom" duration={250}>
            <Modal2
              title="Are you sure?"
              text="Only discard if this item if it has absolutely no relevance to Bitcoin or related fields. Otherwise please edit the item and improve the data instead."
              actions={[
                {
                  kind: 'outlined',
                  label: `Cancel`,
                  applyMinWidth: false,
                  onPress: () => setCloseModal(true),
                },
                {
                  kind: 'outlined',
                  label: `Discard`,
                  labelColor: color.state.critical,
                  onPress: () => {
                    if (!route.params?.dataId) return
                    submitTrainDataMutation.mutate(route.params)
                  },
                  loading: submitTrainDataMutation.isLoading,
                },
              ]}
              width={440}
              style={{
                maxWidth: screenSize.width - spacing[4],
              }}
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
