import { useMemo } from 'react'
import { ActivityIndicator, Image, ImageStyle, Linking, StyleSheet } from 'react-native'
import * as Device from 'expo-device'
import { observer } from 'mobx-react-lite'
import * as Clipboard from 'expo-clipboard'

import {
  BlockView,
  ButtonPrimary,
  Chip,
  Modal2,
  NeedLightningWallet,
  Text,
  showSnackbar,
  useColor,
  useLayout,
  QRCode,
} from 'core'
import { useLightningWallets } from '../useLightningWallets'

type WithdrawBalanceTemplateProps = {
  loading: boolean
  error: boolean
  onClose: () => void

  userEarning: number
  lnurlAddress: string | null
}

export const WithdrawBalanceTemplate = observer(function WithdrawBalanceTemplate({
  loading,
  error,
  onClose,

  userEarning,
  lnurlAddress,
}: WithdrawBalanceTemplateProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const isDesktopDevice = Device.deviceType === Device.DeviceType.DESKTOP
  const [getLightningWallets] = useLightningWallets()

  const renderedContent = useMemo(() => {
    if (loading) {
      return (
        <BlockView hAlign="center" vAlign="center">
          <ActivityIndicator color={color['content-1']} />
        </BlockView>
      )
    }

    if (error || !lnurlAddress) {
      return null
    }

    const onCopyToClipboard = async () => {
      await Clipboard.setStringAsync(lnurlAddress)
      showSnackbar({
        label: `Copied to clipboard!`,
      })
    }

    const renderWalletOptions = () => {
      if (isDesktopDevice) {
        return <NeedLightningWallet />
      }

      const lightningWallets = getLightningWallets(lnurlAddress)

      return (
        <>
          <Text.Roboto
            size="14"
            color={color['content-1']}
            strong
            style={{ textAlign: 'center', marginTop: spacing[1], marginBottom: spacing[2] }}
          >
            Install a lightning wallet before withdrawing
          </Text.Roboto>

          {lightningWallets.map(({ name, link, icon }, index) => (
            <BlockView
              key={`lighting-wallet-${name}-${index}`}
              direction="row"
              size={{ width: '100%' }}
              vAlign="center"
              margin={{ bottom: spacing[3] }}
            >
              <BlockView flex direction="row" vAlign="center" margin={{ right: spacing[1] }}>
                <Image source={icon} style={[styles.lightningWalletIcon, { marginRight: spacing[1] }]} />
                <Text.Roboto size="18" color={color['content-1']} strong>
                  {name}
                </Text.Roboto>
              </BlockView>

              <ButtonPrimary
                kind="filled"
                size="button-2"
                label="Withdraw"
                onPress={() => {
                  Linking.openURL(link)
                }}
                applyMinWidth={false}
              />
            </BlockView>
          ))}
        </>
      )
    }

    return (
      <>
        {/* Balance. */}
        <BlockView hAlign="center">
          <Chip
            leftIcon="electric-bolt"
            label={`${userEarning.toLocaleString()} sats`}
            backgroundColor={color['alpha-white-8']}
            disabled
          />
          <Text.Roboto size="15" color={color['content-1']} style={{ textAlign: 'center', marginTop: spacing[1] }}>
            Scan this lightning invoice or copy the code
          </Text.Roboto>
        </BlockView>

        {/* QR Code. */}
        <BlockView padding={{ vertical: spacing[5] }} hAlign="center" vAlign="center">
          <QRCode value={lnurlAddress} />
        </BlockView>

        {/* Copy to Clipboard. */}
        <BlockView hAlign="center">
          <ButtonPrimary
            kind="outlined"
            size="button-2"
            label="Copy to clipboard"
            rightIcon="content-copy"
            onPress={onCopyToClipboard}
          />
        </BlockView>

        {/* Wallet Options. */}
        <BlockView size={{ width: '100%' }} hAlign="center" margin={{ top: spacing[2] }}>
          {renderWalletOptions()}
        </BlockView>
      </>
    )
  }, [loading, error, color, spacing, userEarning, lnurlAddress, getLightningWallets, isDesktopDevice])

  return (
    <Modal2
      centerTitle
      width={410}
      title="Withdraw"
      showTitleClose
      onTitleClosePress={onClose}
      text={<BlockView padding={{ vertical: spacing[2] }}>{renderedContent}</BlockView>}
      useScroll
    />
  )
})

type Style = {
  lightningWalletIcon: ImageStyle
}

const styles = StyleSheet.create<Style>({
  lightningWalletIcon: {
    width: 48,
    height: 48,
    borderRadius: 8,
  },
})
