import { BlurView } from 'expo-blur'
import { observer } from 'mobx-react-lite'

import { ButtonPrimary, Text, useLayout, useColor } from 'core'

type LeaderboardErrorProps = {
  refreshing: boolean
  onRefresh: () => void
}

export const LeaderboardError = observer(function LeaderboardError({ refreshing, onRefresh }: LeaderboardErrorProps) {
  /** States, */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlurView
      tint="dark"
      intensity={70}
      style={{
        backgroundColor: color['utility-surface-2'],
        padding: spacing[4],
        alignItems: 'center',
        borderRadius: 16,
      }}
    >
      <Text.Roboto size="20" color={color['content-1']}>
        Data Unavailable
      </Text.Roboto>
      <Text.Roboto
        size="14"
        color={color['content-1']}
        style={{ textAlign: 'center', marginTop: spacing[2], marginBottom: spacing[3] }}
      >
        Hodl on there Bitcoiner, there was an issue loading your data. Please reload and try again.
      </Text.Roboto>

      <ButtonPrimary kind="filled" size="button-2" label="Reload" onPress={onRefresh} loading={refreshing} />
    </BlurView>
  )
})
