import { request } from 'api/request'

export type GetQuestionData = {
  id: string
  content: string
}

export const getQuestion = (): Promise<GetQuestionData> => {
  return request.get('v1/data-cleanup/question')
}
