import { createDrawerNavigator } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'

import { useColor, useLayout } from 'core'
import { ContributeRoutes } from 'navigation/routes'
import { getAccountMe } from 'api/user/get-account-me'

import { ContributeDTV } from 'routes/Contribute/DTV'
import { ContributeWeAreAllSatoshi } from 'routes/Contribute/WeAreAllSatoshi'
import { ContributeFudBuster } from 'routes/Contribute/FudBuster'
import { ContributeNoSecondBest } from 'routes/Contribute/NoSecondBest'
import { ContributeLeaderboard } from 'routes/Contribute/Leaderboard'
import { ContributeStatistics } from 'routes/Contribute/Statistics'

import { AppSideBar } from './SideBar'
import { DrawerToggle } from './SideBar/DrawerToggle'

export type ContributeAppRoutesProps = {
  [ContributeRoutes.Root]?: undefined
  [ContributeRoutes.DTV]?: undefined
  [ContributeRoutes.FudBuster]?: undefined
  [ContributeRoutes.WeAreAllSatoshi]?: undefined
  [ContributeRoutes.NoSecondBest]?: undefined
  [ContributeRoutes.Leaderboard]?: {
    showTimeFilter?: boolean
  }
  [ContributeRoutes.Statistics]?: undefined
} & ContributeAppModalRoutesProps

export type ContributeAppModalRoutesProps = {
  [ContributeRoutes.ModalRoot]?: undefined
  [ContributeRoutes.ModalDiscardDTVPrompt]: {
    dataId: string
    contentEdit?: string
    answerEdit?: string
  }
}

const ContributeAppStack = createDrawerNavigator<ContributeAppRoutesProps>()

export const ContributeApp = observer(function ContributeApp() {
  /** States. */
  const { color } = useColor()
  const { screenSize, breakPoints, spacing } = useLayout()

  /** Get account me Query. */
  const userAccountQuery = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const isDesktop = screenSize.width >= breakPoints.lg

  const benchmarkingToolFeatureFlag = !!userAccountQuery.data?.featureFlags?.CONTRIBUTION_NO_SECOND_BEST?.enabled

  return (
    <ContributeAppStack.Navigator
      initialRouteName={ContributeRoutes.DTV}
      screenOptions={({ navigation }) => ({
        headerShown: true,
        headerStyle: {
          backgroundColor: color['utility-surface-2'],
        },
        headerTintColor: color['content-1'],
        headerTitleAlign: 'center',
        headerShadowVisible: false,

        headerLeft: () => (!isDesktop ? <DrawerToggle onToggle={navigation.toggleDrawer} /> : null),

        drawerType: isDesktop ? 'permanent' : 'slide',
        drawerStyle: {
          width: isDesktop ? 280 : 320,
          backgroundColor: color['utility-surface-2'],
          borderRightColor: color['utility-border-1'],
          padding: spacing[2],
        },
        drawerContentStyle: {
          backgroundColor: color['utility-surface-2'],
        },
        sceneContainerStyle: {
          backgroundColor: color['utility-surface-2'],
        },
      })}
      drawerContent={(props) => <AppSideBar {...props} />}
    >
      {/* DTV. */}
      <ContributeAppStack.Screen
        name={ContributeRoutes.DTV}
        component={ContributeDTV}
        options={{
          title: `Contribute - Don't trust, verify`,
          headerTitle: `Don't Trust, Verify`,
        }}
      />

      {/* We are All Satoshi */}
      <ContributeAppStack.Screen
        name={ContributeRoutes.WeAreAllSatoshi}
        component={ContributeWeAreAllSatoshi}
        options={{
          title: `Contribute - We are all Satoshi`,
          headerTitle: `We are all Satoshi`,
        }}
      />

      {/* Fud Buster */}
      <ContributeAppStack.Screen
        name={ContributeRoutes.FudBuster}
        component={ContributeFudBuster}
        options={{
          title: `Contribute - Fudbuster`,
          headerTitle: `Fudbuster`,
        }}
      />

      {/* No Second Best. */}
      {benchmarkingToolFeatureFlag && (
        <ContributeAppStack.Screen
          name={ContributeRoutes.NoSecondBest}
          component={ContributeNoSecondBest}
          options={{
            title: `Contribute - No Second Best`,
            headerTitle: `No Second Best`,
          }}
        />
      )}

      {/* Statistics. */}
      <ContributeAppStack.Screen
        name={ContributeRoutes.Statistics}
        component={ContributeStatistics}
        options={{
          title: `Contribute - Statistics`,
          headerTitle: `Statistics`,
        }}
      />

      {/* Leaderboard. */}
      <ContributeAppStack.Screen
        name={ContributeRoutes.Leaderboard}
        component={ContributeLeaderboard}
        options={{
          title: `Contribute - Leaderboard`,
          headerTitle: `Leaderboard`,
        }}
      />
    </ContributeAppStack.Navigator>
  )
})
