export const emailValidator = (email: string): boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const isEmailValid = regex.test(email)
  return isEmailValid
}

export const removeNonNumberText = (text: string, allowWhiteSpace?: boolean) => {
  return allowWhiteSpace ? text.replace(/[^0-9\s]+/g, '') : text.replace(/[^0-9]+/g, '')
}

export const removeDisallowedEmailCharacter = (email: string) => {
  return email.replace(/[^A-Za-z0-9~!@#$%^&*()_+=\-{}|.]/g, '')
}
