import { useQuery } from 'react-query'
import { observer } from 'mobx-react-lite'

import { BlockView, useLayout } from 'core'
import { ConversationMessage } from 'api/chat/get-conversation-messages'
import { getAccountMe } from 'api/user/get-account-me'

import { useMessageStream } from './useMessageStream'
import { ModelSwitcher } from './ModelSwitcher'
import { NewConversation } from './NewConversation'
import { ExistingConversation } from './ExistingConversation'
import { Container } from './Container'
import { PageChat } from './PageChat'

type ConversationTemplateProps = {
  conversationId: string | null
  isNewConversation: boolean // If user is creating a new conversation.
  isFromNewConversation: boolean // If user "created" a new conversation and was directed.
  isFromStrictMessage: boolean // If the message is from Strict response and require typewriter effect.
  messages: ConversationMessage[]
  model: string | null
  routeModel?: string | null
  recentModel?: string | null
}

export const ConversationTemplate = observer(function ConversationTemplate({
  conversationId,
  isNewConversation,
  isFromNewConversation,
  isFromStrictMessage,
  messages,
  model,
  recentModel,
  routeModel,
}: ConversationTemplateProps) {
  /** States. */
  const { spacing } = useLayout()

  /** Get Account me. */
  const { data: accountMeData } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const isUserOutOfCredit = (() => {
    if (accountMeData?.credits === undefined) return false
    return accountMeData.credits <= 0
  })()
  const userAllowedToSelectChatModel = !!accountMeData?.featureFlags?.CHAT_SHOW_SELECT_MODEL?.enabled
  const userAllowedToUseAudioPlayback = !!accountMeData?.featureFlags?.CHAT_PLAYBACK_AUDIO?.enabled

  /** Message Streaming. */
  const {
    // Streaming message states.
    onSendMessage,
    messageStreamLoading,
    messageStreaming,
    streamDoneCallbackLoading,
    streamDoneFromStreaming,

    // Common states.
    chatModel,
    setChatModel,
    message,
    setMessage,

    // New conversation states.
    optimisticUserMessage,
    optimisticSosMessage,
  } = useMessageStream({
    conversationId,
    isNewConversation,
    isUserOutOfCredit,
  })

  const modelName = isNewConversation ? routeModel ?? recentModel : model ?? chatModel
  const newConversationModel = isNewConversation ? routeModel?? recentModel : chatModel

  const renderContent = () => {
    if (isNewConversation) {
      return (
        <BlockView flex hAlign="center">
          <NewConversation
            isUserOutOfCredit={isUserOutOfCredit}
            optimisticUserMessage={optimisticUserMessage}
            optimisticSosMessage={optimisticSosMessage}
            userFullName={accountMeData?.fullName ?? null}
            selectedModel={newConversationModel}
            messageStreamLoading={
              messageStreamLoading ||
              /**
               * If the message was NOT sent as a stream, we need to wait until `streamDoneCallbackLoading` is false which
               * is true until the latest conversation and its messages are fetched from the API.
               */
              (!streamDoneFromStreaming && streamDoneCallbackLoading)
            }
            setMessage={setMessage}
          />
        </BlockView>
      )
    }

    // If it's not a new conversation, it MUST have a conversation id.
    if (!conversationId) {
      return null
    }

    return (
      <ExistingConversation
        conversationId={conversationId}
        messages={messages}
        isFromNewConversation={isFromNewConversation}
        isFromStrictMessage={isFromStrictMessage}
        userFullName={accountMeData?.fullName ?? null}
        messageStreamLoading={messageStreamLoading}
        isUserOutOfCredit={isUserOutOfCredit}
        userCanSelectChatModel={userAllowedToSelectChatModel}
        userCanUseAudioPlayback={userAllowedToUseAudioPlayback}
        selectedModel={chatModel}
      />
    )
  }

  return (
    <BlockView flex>
      <ModelSwitcher selectedModel={modelName!} onModelSelect={setChatModel} isNewConversation={isNewConversation} />

      {renderContent()}

      <Container padding={{ top: spacing[2], horizontal: spacing[2] }}>
        <PageChat
          message={message}
          onMessageEnter={setMessage}
          onSendMessage={onSendMessage}
          sendMessageLoading={messageStreamLoading || messageStreaming || streamDoneCallbackLoading}
        />
      </Container>
    </BlockView>
  )
})
