import { request } from 'api/request'

export type LnurlLoginCheckData = {
  token: string
}

export type LnurlLoginCheckVariables = {
  lnAddressId: string
}

export const lnurlLoginCheck = (variables: LnurlLoginCheckVariables): Promise<LnurlLoginCheckData> => {
  return request.post('v1/account/lnurl/login/check', {
    data: variables,
  })
}
