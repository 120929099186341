import { RefObject } from 'react'
import { ScrollView, ScrollViewProps } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, BlockViewProps, useColor, useLayout } from 'core'

type ContainerProps = {
  children: React.ReactNode
} & BlockViewProps

export const CONTAINER_MAX_WIDTH = 745

export const Container = observer(function Container({ children, ...blockViewProps }: ContainerProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView
      hAlign="center"
      backgroundColor={color['utility-surface-2']}
      padding={{ horizontal: spacing[2] }}
      {...blockViewProps}
    >
      <BlockView flex size={{ width: '100%', maxWidth: 745 }}>
        {children}
      </BlockView>
    </BlockView>
  )
})

export const ScrollContainer = observer(function ScrollContainer({
  scrollViewRef,
  children,
  style,
  contentContainerStyle,
  ...scrollViewProps
}: ScrollViewProps & { scrollViewRef?: RefObject<ScrollView> }) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <ScrollView
      ref={scrollViewRef}
      {...scrollViewProps}
      contentContainerStyle={[
        contentContainerStyle,
        { backgroundColor: color['utility-surface-2'], alignItems: 'center', paddingHorizontal: spacing[2] },
      ]}
      style={[style, { backgroundColor: color['utility-surface-2'] }]}
    >
      <BlockView flex size={{ width: '100%', maxWidth: CONTAINER_MAX_WIDTH }}>
        {children}
      </BlockView>
    </ScrollView>
  )
})
