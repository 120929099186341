import { request } from 'api/request'

export type UpdateAccountVariables = {
  fullName?: string
  twitterUsername?: string
  tcAccept?: boolean
}

export const updateAccount = (variables: UpdateAccountVariables) => {
  return request.putWithRawResponse(`v1/account/update`, {
    data: variables,
  })
}
