import { observer } from 'mobx-react-lite'

import { BlockView, BlockViewProps, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { InAppBrowser } from 'utils/browser'

type NeedLightningWalletProps = BlockViewProps

export const NeedLightningWallet = observer(function NeedLightningWallet(blockViewProps: NeedLightningWalletProps) {
  /** States. */
  const { color } = useColor()

  return (
    <BlockView hAlign="center" vAlign="center" {...blockViewProps}>
      <Text.Roboto size="13" color={color['content-2']} style={{ textAlign: 'center' }}>
        Need a lightning wallet?
      </Text.Roboto>

      <Text.Roboto size="13" color={color['content-2']} style={{ textAlign: 'center' }}>
        Check out{' '}
        <Text.Roboto
          size="12"
          color={color['content-2']}
          underline
          onPress={() => InAppBrowser.open('https://www.blink.sv/')}
        >
          Blink
        </Text.Roboto>
        ,{' '}
        <Text.Roboto
          size="13"
          color={color['content-2']}
          underline
          onPress={() => InAppBrowser.open('https://phoenix.acinq.co/')}
        >
          Phoenix
        </Text.Roboto>
        ,{' '}
        <Text.Roboto
          size="13"
          color={color['content-2']}
          underline
          onPress={() => InAppBrowser.open('https://bitkit.to/#download')}
        >
          BitKit
        </Text.Roboto>{' '}
        or{' '}
        <Text.Roboto
          size="13"
          color={color['content-2']}
          underline
          onPress={() => InAppBrowser.open('https://www.walletofsatoshi.com/')}
        >
          Wallet of Satoshi
        </Text.Roboto>
      </Text.Roboto>
    </BlockView>
  )
})
