import Constants from 'expo-constants'

import { rootStore } from 'store/root.store'

type RequestParam = {
  data?: Record<string, any>
}

const API_URL = Constants.expoConfig?.extra?.apiUrl

class Request {
  constructor(private apiUrl: string) {
    if (!apiUrl) {
      throw new Error('No API url is provided.')
    }
  }

  commonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  baseRequest = (type: 'GET' | 'PUT' | 'POST' | 'DELETE', endpoint: string, param?: RequestParam) => {
    const headers = (() => {
      if (rootStore.authStore.authToken) {
        return {
          ...this.commonHeaders,
          Authorization: `Bearer ${rootStore.authStore.authToken}`,
        }
      }
      return this.commonHeaders
    })()

    return fetch(`${this.apiUrl}/${endpoint}`, {
      method: type,
      headers,
      body: param?.data ? JSON.stringify(param.data) : undefined,
    })
  }

  get = (endpoint: string, param?: RequestParam) => {
    return this.baseRequest('GET', endpoint, param).then((response) => {
      return response.json()
    })
  }

  post = (endpoint: string, param?: RequestParam) => {
    return this.baseRequest('POST', endpoint, param).then((response) => {
      return response.json()
    })
  }

  postWithRawResponse = (endpoint: string, param?: RequestParam) => {
    return this.baseRequest('POST', endpoint, param)
  }

  putWithRawResponse = (endpoint: string, param?: RequestParam) => {
    return this.baseRequest('PUT', endpoint, param)
  }

  deleteWithRawResponse = (endpoint: string, param?: RequestParam) => {
    return this.baseRequest('DELETE', endpoint, param)
  }
}

export const request = new Request(API_URL)
