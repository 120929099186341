import { useEffect, useRef } from 'react'
import { ActivityIndicator } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as Clipboard from 'expo-clipboard'

import { BlockView, ButtonPrimary, Text, showSnackbar, useColor, useLayout, QRCode } from 'core'
import { getPurchaseConfirmation } from 'api/credits/get-purchase-confirmation'
import { purchaseCredits } from 'api/credits/purchase-credits'

type LnurlPaymentProps = {
  packageId: string
  creditAmount: number
  priceSats: number

  onPurchaseComplete: () => void
  onError: () => void
}

export const LnurlPayment = observer(function LnurlPayment({
  packageId,
  creditAmount,
  priceSats,
  onPurchaseComplete,
  onError,
}: LnurlPaymentProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { color } = useColor()
  const { spacing } = useLayout()
  const pollIntervalRef = useRef<NodeJS.Timeout>()

  /** Get Purchase Confirmation Mutation. */
  const getPurchaseConfirmationMutation = useMutation(
    (purchaseId: string) => {
      return getPurchaseConfirmation(purchaseId)
    },
    {
      onSuccess: (response) => {
        if (response.purchased) {
          queryClient.refetchQueries({
            queryKey: 'get-account-me',
          })

          showSnackbar({
            kind: 'success',
            label: `Successfully added ${creditAmount} credits`,
          })
          onPurchaseComplete()
        }
      },
      onError,
    }
  )

  const onPurchaseCreditsError = () => {
    showSnackbar({
      kind: 'error',
      label: `Something went wrong. Please try again later.`,
      leftIcon: 'error',
    })
    onError()
  }
  /** Purchase Credits Query.  */
  const { isLoading: purchaseCreditsIsLoading, data: purchaseCreditsData } = useQuery({
    queryKey: `purchase-credits-${packageId}`,
    queryFn: () => purchaseCredits(packageId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (response) => {
      if (response.purchaseId) {
        pollIntervalRef.current = setInterval(() => {
          getPurchaseConfirmationMutation.mutate(response.purchaseId)
        }, 3000)
        return
      }

      onPurchaseCreditsError()
    },
    onError: () => {
      onPurchaseCreditsError()
    },
  })

  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current)
      }
    }
  }, [])

  const renderLnurlAddress = () => {
    if (purchaseCreditsIsLoading || !purchaseCreditsData) {
      return (
        <BlockView hAlign="center" vAlign="center" size={{ height: 160 }}>
          <ActivityIndicator color={color['content-2']} style={{ marginRight: 4 }} />
        </BlockView>
      )
    }

    const { lnAddress } = purchaseCreditsData

    const onCopyToClipboardPress = async () => {
      await Clipboard.setStringAsync(lnAddress)
      showSnackbar({
        label: `Copied to clipboard!`,
      })
    }

    return (
      <BlockView flex hAlign="center">
        <BlockView margin={{ bottom: spacing[4] }}>
          <QRCode value={lnAddress} />
        </BlockView>

        {/* Copy. */}
        <ButtonPrimary
          kind="outlined"
          size="button-2"
          label="Copy to clipboard"
          onPress={onCopyToClipboardPress}
          rightIcon="content-copy"
        />
      </BlockView>
    )
  }

  return (
    <BlockView flex padding={spacing[4]} hAlign="center">
      {/* Text. */}
      <BlockView size={{ maxWidth: 300 }} margin={{ bottom: spacing[4] }}>
        <Text.Roboto color={color['content-1']} size="15" style={{ textAlign: 'center' }}>
          Scan this lightning invoice to add{' '}
          <Text.Roboto color="rgb(81, 218, 76)" size="15">
            {creditAmount}
          </Text.Roboto>{' '}
          credits for{' '}
          <Text.Roboto color="#f2a900" size="15">
            {Intl.NumberFormat('en-US').format(priceSats)}
          </Text.Roboto>{' '}
          sats and continue the conversation with Satoshi
        </Text.Roboto>
      </BlockView>
      {renderLnurlAddress()}
    </BlockView>
  )
})
