import { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import mermaid, { MermaidConfig } from 'mermaid'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'

export type MermaidProps = {
  name?: string
  chart: string
  config?: MermaidConfig
}

export const Mermaid = observer(function Mermaid({ name = '', chart, config }: MermaidProps) {
  /** States. */
  const { color } = useColor()

  const DEFAULT_CONFIG: MermaidConfig = useMemo(
    () => ({
      startOnLoad: true,
      fontFamily: 'RobotoRegular',
      theme: 'base',
      themeVariables: {
        primaryColor: color['utility-surface-2'],
        secondaryColor: color['utility-surface-3'],
        tertiaryColor: color['utility-surface-4'],
        primaryTextColor: color['content-1'],
        secondaryTextColor: color['content-2'],
        primaryBorderColor: color['utility-border-1'],
        secondaryBorderColor: color['utility-border-2'],
        lineColor: color['content-2'],
      },
      flowchart: {
        htmlLabels: true,
        curve: 'linear',
      },
    }),
    [color]
  )

  mermaid.initialize({
    ...DEFAULT_CONFIG,
    ...config,
  })

  useEffect(() => {
    mermaid.contentLoaded()
  }, [])

  /**
   * Mermaid is only supported in Web.
   */
  if (Platform.OS !== 'web') {
    return null
  }

  return (
    <div
      className="mermaid"
      // @ts-ignore
      name={name}
    >
      {chart}
    </div>
  )
})
