import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { useQuery } from 'react-query'
import { observer } from 'mobx-react-lite'

import { useLayout, NavDrawer, NavHeader, NavTitle, BlockView, NavItem, NavProfile, NavControlDisplay } from 'core'
import { useCampaignModal } from 'core/v2/hooks/useCampaignModal'
import { AccountRoutes, ChatRoutes, ContributeRoutes, PaymentRoutes } from 'navigation/routes'
import { getAccountMe } from 'api/user/get-account-me'

type AppSideBarProps = DrawerContentComponentProps

export const AppSideBar = observer(function AppSideBar(props: AppSideBarProps) {
  const { navigation, state } = props
  const { closeDrawer, navigate } = navigation

  /** States. */
  const { spacing, screenSize, breakPoints } = useLayout()
  const showDrawerPermanent = screenSize.width >= breakPoints.lg
  const currentRouteName = state.routeNames[state.index]

  /**
   * Campaign Hooks.
   */
  useCampaignModal()

  /** Get account me Query. */
  const { data: accountMeData } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const benchmarkingToolFeatureFlag = !!accountMeData?.featureFlags?.CONTRIBUTION_NO_SECOND_BEST?.enabled

  const closeDrawerIfMobile = () => {
    if (showDrawerPermanent) return
    closeDrawer()
  }

  return (
    <BlockView flex>
      <NavDrawer {...props}>
        <BlockView margin={{ bottom: spacing[1] }}>
          <NavHeader showAppButton={false} />
        </BlockView>

        <NavControlDisplay
          leftIcon="chat-bubble"
          label={`${accountMeData?.credits ?? 0}`}
          labelSize="16"
          chipLeftIcon="add"
          chipLabel="Add credits"
          onChipPress={() => {
            closeDrawerIfMobile()
            navigate(PaymentRoutes.AddCreditModal)
          }}
        />

        {/* General. */}
        <NavTitle title="General" />
        <NavItem
          icon="chat-bubble"
          label="Chat"
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ChatRoutes.Root, {
              screen: ChatRoutes.Conversation,
              params: {
                id: 'new-chat',
              },
            })
          }}
        />

        {/* Tools. */}
        <NavTitle title="Tools" />
        <NavItem
          icon="verified"
          label="Don't trust, verify"
          activated={currentRouteName === ContributeRoutes.DTV}
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ContributeRoutes.DTV)
          }}
        />
        <NavItem
          icon="group"
          label="We are all Satoshi"
          activated={currentRouteName === ContributeRoutes.WeAreAllSatoshi}
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ContributeRoutes.WeAreAllSatoshi)
          }}
        />

        <NavItem
          icon="skull"
          label="Fudbuster"
          activated={currentRouteName === ContributeRoutes.FudBuster}
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ContributeRoutes.FudBuster)
          }}
        />

        {benchmarkingToolFeatureFlag && (
          <NavItem
            icon="low-priority"
            label="No Second Best"
            activated={currentRouteName === ContributeRoutes.NoSecondBest}
            onPress={() => {
              closeDrawerIfMobile()
              navigate(ContributeRoutes.NoSecondBest)
            }}
          />
        )}

        <NavItem
          icon="bar-chart"
          label="Statistics"
          activated={currentRouteName === ContributeRoutes.Statistics}
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ContributeRoutes.Statistics)
          }}
        />

        <NavItem
          icon="trophy"
          label="Leaderboard"
          activated={currentRouteName === ContributeRoutes.Leaderboard}
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ContributeRoutes.Leaderboard)
          }}
        />
      </NavDrawer>

      <BlockView size={{ width: '100%' }} position={{ bottom: 0 }}>
        <NavProfile
          fromKey="contribute"
          onMyAccountPress={() => {
            closeDrawerIfMobile()
            navigate(AccountRoutes.MyAccount)
          }}
          onAddCreditPress={() => {
            closeDrawerIfMobile()
            navigate(PaymentRoutes.AddCreditModal)
          }}
          onDonationPress={() => {
            closeDrawerIfMobile()
            navigate(PaymentRoutes.GetDonationModal)
          }}
        />
      </BlockView>
    </BlockView>
  )
})
