import React from 'react'
import { observer } from 'mobx-react-lite'

import { BlockView, Text } from 'core/v2/atoms'
import { useColor } from 'core/v2/color'
import { useLayout } from 'core/v2/layout'

export type ChatInputProps = {
  avatar: React.ReactNode
  message: string
}

export const ChatInput = observer(function ChatInput({ avatar, message }: ChatInputProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView size={{ width: '100%' }} direction="row" vAlign="flex-start" padding={spacing[2]}>
      {avatar}

      <BlockView flex padding={10}>
        <Text.Roboto color={color['content-1']} size="16" style={{ marginLeft: spacing[1] }}>
          {message}
        </Text.Roboto>
      </BlockView>
    </BlockView>
  )
})
