export const formatToShortNumber = (num: number, precision = 2) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
  ]

  const found = map.find((x) => Math.abs(num) >= x.threshold)

  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix
    return {
      formatted,
      isFormatted: true,
    }
  }

  return {
    formatted: num,
    isFormatted: false,
  }
}

export const clamp = (value: number, left: number, right: number) => {
  if (value < left) {
    return left
  }
  if (value > right) {
    return right
  }
  return value
}
export const clampLeft = (value: number, left: number) => {
  if (value < left) {
    return left
  }
  return value
}

export const clampLeftWorklet = (value: number, left: number) => {
  'worklet'

  if (value < left) {
    return left
  }
  return value
}
