import { useCallback, useState } from 'react'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'
import * as Clipboard from 'expo-clipboard'

import {
  AnimateTransition,
  BlockView,
  ButtonPrimary,
  Modal1,
  Modal1Tab,
  NeedLightningWallet,
  QRCode,
  Text,
  showSnackbar,
  useColor,
  useLayout,
} from 'core'
import { PaymentRoutes } from 'navigation/routes'
import { PaymentAppModalRoutesProps } from 'navigation/stack/payment'
import { RootAppRoutesProps } from 'navigation/stack/root'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<PaymentAppModalRoutesProps, PaymentRoutes.GetDonationModal>,
  NativeStackScreenProps<RootAppRoutesProps>
>

enum DonationTabId {
  OnChain = 'on-chain',
  Lightning = 'lightning',
}

const donationTabs: Modal1Tab<DonationTabId>[] = [
  {
    id: DonationTabId.OnChain,
    title: `On-chain`,
  },
  {
    id: DonationTabId.Lightning,
    title: `Lightning`,
  },
]

const ON_CHAIN_DONATION_ADDRESS = '3Eviowqdejy1gzYWiqoahCVxXjPKPziFkE'
const LNURL_DONATION_ADDRESS = 'laier-two-labs@ln.opennode.com'

export const PaymentGetDonation = observer(function PaymentGetDonation({ navigation }: ScreenProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const [selectedTabId, setSelectedTabId] = useState(DonationTabId.OnChain)

  const renderTabScreen = useCallback(
    (tab: Modal1Tab) => {
      const address = tab.id === DonationTabId.OnChain ? ON_CHAIN_DONATION_ADDRESS : LNURL_DONATION_ADDRESS

      const onCopyToClipboardPress = async () => {
        await Clipboard.setStringAsync(address)
        showSnackbar({
          label: `Copied to clipboard!`,
        })
      }

      return (
        <BlockView flex padding={spacing[4]} hAlign="center">
          {/* Text. */}
          <BlockView size={{ maxWidth: 300 }} margin={{ bottom: spacing[4] }}>
            <Text.Roboto color={color['content-1']} size="15" style={{ textAlign: 'center' }}>
              Make a contribution towards the world's first open-source Bitcoin-Austro-Libertarian focused Large
              Language Model.
            </Text.Roboto>
          </BlockView>

          {/* QR Code. */}
          <BlockView flex hAlign="center">
            <BlockView margin={{ bottom: spacing[4] }}>
              <QRCode value={address} ecl="Q" />
            </BlockView>

            {/* Copy. */}
            <ButtonPrimary
              kind="outlined"
              size="button-2"
              label="Copy to clipboard"
              onPress={onCopyToClipboardPress}
              rightIcon="content-copy"
            />
          </BlockView>
        </BlockView>
      )
    },
    [color, spacing]
  )

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="add-credit-data" type="from-bottom" duration={250}>
            <Modal1
              title="Support Satoshi"
              showTitleClose
              onTitleClosePress={() => setCloseModal(true)}
              tabs={donationTabs}
              selectedTabId={selectedTabId}
              onTabSelect={setSelectedTabId}
              renderTabScreen={renderTabScreen}
              footerDivider
              footerCustom={<NeedLightningWallet />}
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
