import { useCallback, useState } from 'react'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'
import { useMutation } from 'react-query'

import { AnimateTransition, BlockView, FormField, InputFieldState, Modal2, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AccountModalRoutesProps } from 'navigation/stack/account'
import { AccountRoutes, ChatRoutes } from 'navigation/routes'
import { removeDisallowedEmailCharacter } from 'utils/validator'
import { EmailLoginOrSignupVariables, emailLoginOrSignup } from 'api/authentication/email-login-or-signup'

type ScreenProps = CompositeScreenProps<
  NativeStackScreenProps<AccountModalRoutesProps, AccountRoutes.ConnectEmail>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const AccountConnectEmail = observer(function AccountConnectEmail({ navigation, route }: ScreenProps) {
  /** States. */
  const { spacing } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const [email, setEmail] = useState(route?.params?.email ?? '')
  const [inputState, setInputState] = useState<{ state: InputFieldState; message: string }>({
    state: 'default',
    message: '',
  })

  useFocusEffect(
    useCallback(() => {
      if (!navigation.canGoBack()) {
        // @ts-ignore
        navigation.navigate(ChatRoutes.Root, { screen: ChatRoutes.Conversation, params: { id: `new-chat` } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  /** Email Login or Signup Mutation. */
  const emailLoginOrSignupMutation = useMutation(
    (variables: EmailLoginOrSignupVariables) => {
      return emailLoginOrSignup(variables)
    },
    {
      onSuccess: (response) => {
        if (!response?.otpId) {
          setInputState({
            state: 'error',
            message: 'Something went wrong. Please try again or a different email address',
          })
          return
        }

        navigation.goBack()
        navigation.navigate(AccountRoutes.VerifyCode, {
          otpId: response.otpId,
          emailAddress: email,
        })
      },
      onError: () => {
        setInputState({
          state: 'error',
          message: 'Something went wrong. Please try again or a different email address',
        })
      },
    }
  )

  const onEmailEnter = (value: string) => {
    if (inputState.state !== 'default') {
      setInputState({
        state: 'default',
        message: '',
      })
    }
    setEmail(removeDisallowedEmailCharacter(value))
  }

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="account-connect-email" type="from-bottom" duration={250}>
            <Modal2
              title="Connect email"
              text={
                <FormField
                  value={email}
                  onValueChange={onEmailEnter}
                  placeholderText="Enter your email address"
                  otherInputProps={{
                    autoCorrect: false,
                    autoCapitalize: 'none',
                    autoComplete: 'email',
                    autoFocus: false,
                  }}
                  inputState={inputState.state}
                  supportLabel={inputState.message}
                />
              }
              actions={[
                {
                  kind: 'outlined',
                  label: 'Cancel',
                  applyMinWidth: false,
                  onPress: () => setCloseModal(true),
                },
                {
                  kind: 'filled',
                  label: 'Continue',
                  applyMinWidth: false,
                  onPress: () => {
                    if (emailLoginOrSignupMutation.isLoading) return
                    emailLoginOrSignupMutation.mutate({
                      email,
                    })
                  },
                  loading: emailLoginOrSignupMutation.isLoading,
                },
              ]}
            />
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
