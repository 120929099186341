// MiniscriptViewer.tsx
import React, { useState } from 'react'
import { StyleSheet, Text, ScrollView, Animated } from 'react-native'
import { Text as TextCore } from 'core'
import Markdown from 'react-native-markdown-display'
import { ReplyDetails } from '../../../../api/chat/get-conversation-messages'
import { InAppBrowser } from '../../../../utils/browser'
import CodeBlock from './CodeBlock'

interface DisplayCodeSnippetProps {
  text: string
  replyDetails?: ReplyDetails | null
}

const DisplayCodeSnippet: React.FC<DisplayCodeSnippetProps> = ({ text, replyDetails }) => {
  const fadeAnim = new Animated.Value(0)

  const renderTextSections = (inputText: string): JSX.Element[] => {
    const sections: JSX.Element[] = []
    // Find all code blocks in the input text that start and end with ```
    const regex = /```([\s\S]*?)```/g
    let lastIndex = 0
    let loopIndex = 0

    let match
    while ((match = regex.exec(inputText)) !== null) {
      // Extract code and pattern from found match
      const { code, codePattern } = (() => {
        let [fullMatch, code] = match
        // Extract code type
        const firstLineMatch = code.match(/^(.*?)(\r\n|\n|$)([\s\S]*)/)
        const codePattern = firstLineMatch ? firstLineMatch[1] : null
        code = (firstLineMatch ? firstLineMatch[3] : null) ?? code

        // Remove empty spaces in behind new lines
        // code = code.replace(/^\s+/gm, '')

        return {
          code,
          codePattern: codePattern ?? 'code',
        }
      })()

      // Define proper miniscript and bitcoin script values
      const compiled = replyDetails?.policiesCompiled?.[loopIndex]

      // Add regular text before the code block
      let regularText = inputText.substring(lastIndex, match.index)
      if (regularText) {
        regularText = regularText.replace(/`/g, '_').trim()
        sections.push(
          <Text key={`text-${lastIndex}`} style={styles.regularText}>
            <Markdown>{regularText}</Markdown>
          </Text>
        )
      }

      // Add code block with header and copy button
      if (code) {
        sections.push(<CodeBlock code={code} pattern={codePattern} />)
      }

      if (compiled?.miniscript) {
        sections.push(<CodeBlock code={compiled!.miniscript!.trim()} pattern={'miniscript'} />)
      }

      if (compiled?.bitcoinScript) {
        sections.push(<CodeBlock code={compiled!.bitcoinScript!.trim()} pattern={'bitcoins_cript'} />)
      }

      if (compiled?.spending?.script) {
        sections.push(
          <Text key={`text-${lastIndex}`} style={styles.regularText}>
            Spending cost analysis
            <ul>
              <li>Script: {compiled?.spending?.script}</li>
              <li>Input: {compiled?.spending?.input}</li>
              <li>Total: {compiled?.spending?.total}</li>
            </ul>
          </Text>
        )
      }

      lastIndex = regex.lastIndex
      loopIndex++
    }

    // Add any remaining regular text after the last code block
    let remainingText = inputText.substring(lastIndex)
    if (remainingText) {
      remainingText = remainingText.replace(/`/g, '_')
      remainingText = remainingText.replace(/\n\s+/g, '\n').trim()
      sections.push(
        <Text key={`text-${lastIndex}`} style={styles.regularText}>
          <Markdown>{remainingText}</Markdown>
        </Text>
      )
    }

    // Add RAG sources section
    if (replyDetails?.ragItems?.length) {
      // Make sure duplicates are removed
      const uniqueRagItems = replyDetails.ragItems.filter(
        (item, index, self) => index === self.findIndex((t) => t.title === item.title)
      )

      sections.push(
        <Text key={`text-${lastIndex}`} style={styles.regularText}>
          <TextCore.Roboto color="rgb(243, 245, 247)" size="16">
            Sources:
          </TextCore.Roboto>
          <ul>
            {uniqueRagItems.map((item, index) => {
              if (item.url) {
                return (
                  <li key={`rag-item-${index}`}>
                    <TextCore.Roboto
                      color="rgb(0, 112, 224)"
                      size="14"
                      underline
                      style={{ fontStyle: 'italic' }}
                      onPress={() => InAppBrowser.open(item.url!)}
                    >
                      {item.url}
                    </TextCore.Roboto>
                  </li>
                )
              }

              if (item.title) {
                return (
                  <li key={`rag-item-${index}`}>
                    <TextCore.Roboto color="rgb(243, 245, 247)" size="14" style={{ fontStyle: 'italic' }}>
                      {item.title}
                    </TextCore.Roboto>
                  </li>
                )
              }
            })}
          </ul>
        </Text>
      )
    }
    return sections
  }

  return (
    <ScrollView style={styles.scrollView}>
      {renderTextSections(text)}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    // marginHorizontal: 20,
  },
  regularText: {
    fontSize: 16,
    marginVertical: 5,
  },
  regularTextRemaining: {
    fontSize: 16,
    marginVertical: 5,
    whiteSpace: 'normal',
  },
})

export default DisplayCodeSnippet
