import { useCallback, useState } from 'react'
import { CompositeScreenProps, useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { useMutation, useQueryClient } from 'react-query'

import { BlockView, InputField, Modal2, showSnackbar, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { ChatAppModalRoutesProps } from 'navigation/stack/chat'
import { ChatRoutes } from 'navigation/routes'
import { updateConversation } from 'api/chat/update-conversation'
import { GetConversationsData } from 'api/chat/get-conversations'

type ChatAppUpdateConversationProps = CompositeScreenProps<
  NativeStackScreenProps<ChatAppModalRoutesProps, ChatRoutes.ModalUpdateConversation>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const ChatAppUpdateConversation = observer(function ChatAppUpdateConversation({
  navigation,
  route,
}: ChatAppUpdateConversationProps) {
  /** States. */
  const queryClient = useQueryClient()
  const { spacing } = useLayout()
  const [title, setTitle] = useState('')

  useFocusEffect(
    useCallback(() => {
      if (route.params?.conversationId) return

      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.navigate(ChatRoutes.Conversation, { id: 'new-chat' })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  )

  /** Update Conversation Mutation. */
  const updateConversationMutation = useMutation(
    ({ conversationId, updatedTitle }: { conversationId: string; updatedTitle: string }) => {
      return updateConversation(conversationId, {
        title: updatedTitle,
      })
    },
    {
      onSuccess: (response, { conversationId, updatedTitle }) => {
        if (response.status === 200) {
          queryClient.setQueryData<GetConversationsData | undefined>('get-conversations', (old) => {
            if (!old) return
            return old.map((d) => {
              if (d.id !== conversationId) return d
              return {
                ...d,
                title: updatedTitle,
              }
            })
          })
          navigation.goBack()
          return
        }
        showSnackbar({
          kind: 'error',
          label: 'Something went wrong. Please try again later.',
          leftIcon: 'error',
        })
      },
      onError: () => {
        showSnackbar({
          kind: 'error',
          label: 'Something went wrong. Please try again later.',
          leftIcon: 'error',
        })
      },
    }
  )

  const onCancelPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(ChatRoutes.Conversation, { id: 'new-chat' })
    }
  }

  const onSavePress = () => {
    if (!title.length || !route.params?.conversationId || updateConversationMutation.isLoading) return

    return updateConversationMutation.mutate({
      conversationId: route.params.conversationId,
      updatedTitle: title,
    })
  }

  /** Can't render anything. */
  if (!route.params?.conversationId) {
    return null
  }

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <Modal2
        title="Edit title"
        showTitleClose={false}
        text={
          <InputField size="input-field-2" value={title} onValueChange={setTitle} placeholderText="A short title" />
        }
        actions={[
          {
            label: `Cancel`,
            kind: 'outlined',
            onPress: onCancelPress,
            applyMinWidth: false,
          },
          {
            label: `Save`,
            kind: 'filled',
            onPress: onSavePress,
            loading: updateConversationMutation.isLoading,
            applyMinWidth: false,
          },
        ]}
      />
    </BlockView>
  )
})
