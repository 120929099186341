import { useCallback } from 'react'
import { ActivityIndicator } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useInfiniteQuery } from 'react-query'

import { BlockView, useColor, useLayout } from 'core'
import { GetLeaderboardVariables, getLeaderboard } from 'api/data-train/get-leaderboard'

import { ListControlProps, LeaderboardOrder } from './type'
import { MobileLeaderboard } from './Mobile'
import { DesktopLeaderboard } from './Desktop'
import { LeaderboardError } from './Error'

export { LeaderboardOrder }
type LeaderboardProps = ListControlProps

export const Leaderboard = observer(function Leaderboard({
  selectedFilter,
  onFilterSelect,
  selectedOrder,
  onOrderSelect,
}: LeaderboardProps) {
  /** States. */
  const { color } = useColor()
  const { screenSize, breakPoints } = useLayout()
  const isMobile = screenSize.width < breakPoints.sm

  /** Get Leaderboard Query. */
  const { isLoading, isRefetching, isError, refetch, isFetchingNextPage, data, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get-leaderboard', selectedFilter, selectedOrder.direction, selectedOrder.orderBy],
      queryFn: ({ pageParam }) => {
        return getLeaderboard(
          pageParam ?? {
            limit: 20,
            offset: 0,
            filter: selectedFilter,
            order: selectedOrder.orderBy,
            direction: selectedOrder.direction,
          }
        )
      },
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages): GetLeaderboardVariables | null => {
        if (allPages.length >= lastPage.numberOfPages) {
          return null
        }

        return {
          limit: 20,
          offset: +lastPage.offset + 20,
          filter: selectedFilter,
          order: selectedOrder.orderBy,
          direction: selectedOrder.direction,
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    })

  const onListEndReached = useCallback(() => {
    if (!hasNextPage) return
    fetchNextPage()
  }, [hasNextPage, fetchNextPage])

  const leaderboardTotalCount = data?.pages[0].totalCount ?? 0
  const leaderboardData = data?.pages.map((page) => page.items).flat() ?? []

  if (isLoading) {
    return (
      <BlockView hAlign="center">
        <ActivityIndicator color={color['content-1']} />
      </BlockView>
    )
  }

  if (isError) {
    return <LeaderboardError refreshing={isRefetching} onRefresh={() => refetch()} />
  }

  if (isMobile) {
    return (
      <MobileLeaderboard
        data={leaderboardData}
        totalCount={leaderboardTotalCount}
        onEndReached={onListEndReached}
        selectedFilter={selectedFilter}
        onFilterSelect={onFilterSelect}
        selectedOrder={selectedOrder}
        onOrderSelect={onOrderSelect}
        isFetchingNextPage={isFetchingNextPage}
      />
    )
  }
  return (
    <DesktopLeaderboard
      data={leaderboardData}
      totalCount={leaderboardTotalCount}
      onEndReached={onListEndReached}
      selectedFilter={selectedFilter}
      onFilterSelect={onFilterSelect}
      selectedOrder={selectedOrder}
      onOrderSelect={onOrderSelect}
      isFetchingNextPage={isFetchingNextPage}
    />
  )
})
