import { useCallback, useEffect } from 'react'
import { Platform, View } from 'react-native'
import ExpoConstants from 'expo-constants'
import { observer } from 'mobx-react-lite'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'

import { BlockView, InputFieldChat, Text, useColor, useLayout, Constants } from 'core'
import { ChatRoutes, PaymentRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { ChatAppRoutesProps } from 'navigation/stack/chat'
import { InAppBrowser } from 'utils/browser'

const isChrome = (() => {
  if (Platform.OS !== 'web') return false
  if (!navigator?.userAgent) return false
  // @ts-ignore
  if (navigator?.brave) return false
  return navigator.userAgent.match(/chrome|chromium|crios/i)
})()

type PageChatProps = {
  sendButtonRef?: React.RefObject<View>
  message: string
  onMessageEnter: (message: string) => void
  onSendMessage: () => void
  sendMessageLoading: boolean
}

type NavigateProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ChatAppRoutesProps, ChatRoutes.Conversation>,
    DrawerNavigationProp<ChatAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export const PageChat = observer(function PageChat({
  sendButtonRef,
  message,
  onMessageEnter,
  onSendMessage,
  sendMessageLoading,
}: PageChatProps) {
  /** States. */
  const { navigate } = useNavigation<NavigateProps>()
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()
  const showDrawerPermanent = screenSize.width >= breakPoints.lg

  const version = (() => {
    const version = ExpoConstants.expoConfig?.version

    if (!version) return `Spirit of Satoshi`
    return `Spirit of Satoshi v${version}`
  })()

  const currentCharacterLength = message.length
  const showCharacterLimit = currentCharacterLength > 2050

  const onMessageChange = useCallback(
    (message: string) => {
      if (message.length > Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH) {
        onMessageEnter(message.substring(0, Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH))
        return
      }
      onMessageEnter(message)
    },
    [onMessageEnter]
  )

  return (
    <BlockView size={{ width: '100%' }} margin={{ bottom: spacing[2] }}>
      {Platform.OS === 'web' && isChrome ? (
        <WebChatInput
          sendButtonRef={sendButtonRef}
          message={message}
          onMessageEnter={onMessageChange}
          onSendMessage={onSendMessage}
          sendMessageLoading={sendMessageLoading}
        />
      ) : (
        <InputFieldChat
          sendButtonRef={sendButtonRef}
          value={message}
          onValueChange={onMessageChange}
          onSendPress={onSendMessage}
          sendLoading={sendMessageLoading}
          sendDisabled={sendMessageLoading}
          otherInputProps={{
            maxLength: Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH,
          }}
        />
      )}

      {showCharacterLimit && (
        <BlockView margin={{ top: spacing[1] }} hAlign="flex-end">
          <Text.Roboto
            size="14"
            color={(() => {
              if (currentCharacterLength < 2100) return color.state.attention
              return color.state.critical
            })()}
          >{`${currentCharacterLength} / ${Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH}`}</Text.Roboto>
        </BlockView>
      )}

      {showDrawerPermanent && (
        <BlockView margin={{ vertical: spacing[2] }} hAlign="center">
          <Text.Roboto color={color['content-3']} size="11" style={{ textAlign: 'center' }}>
            <Text.Roboto
              color={color['content-3']}
              size="11"
              underline
              onPress={() => InAppBrowser.open('https://www.spiritofsatoshi.ai/')}
            >
              {version}
            </Text.Roboto>
            {`. This tool is made by `}
            <Text.Roboto
              color={color['content-3']}
              size="11"
              underline
              onPress={() => InAppBrowser.open('https://www.laiertwo.com/')}
            >
              Laier Two Labs
            </Text.Roboto>
            {`. If you like it, please consider `}
            <Text.Roboto
              color={color['content-3']}
              size="11"
              underline
              onPress={() => navigate(PaymentRoutes.GetDonationModal)}
            >
              supporting us
            </Text.Roboto>
            .
          </Text.Roboto>
        </BlockView>
      )}
    </BlockView>
  )
})

const WebChatInput = ({ sendButtonRef, message, onMessageEnter, onSendMessage, sendMessageLoading }: PageChatProps) => {
  const { transcript, listening } = useSpeechRecognition()

  useEffect(() => {
    onMessageEnter(transcript)
  }, [onMessageEnter, transcript])

  if (Platform.OS !== 'web') {
    return null
  }

  const onStartPress = () => {
    SpeechRecognition.startListening()
  }

  const onStopPress = () => {
    SpeechRecognition.stopListening()
  }

  return (
    <InputFieldChat
      sendButtonRef={sendButtonRef}
      value={message}
      onValueChange={onMessageEnter}
      onSendPress={onSendMessage}
      sendLoading={sendMessageLoading}
      sendDisabled={sendMessageLoading}
      showAudioTranscript
      isOnAudioTranscript={listening}
      onAudioTranscriptPress={onStartPress}
      onAudioTranscriptStopPress={onStopPress}
      otherInputProps={{
        maxLength: Constants.MAX_CHAT_MESSAGE_CHARACTER_LENGTH,
      }}
    />
  )
}
