import { observer } from 'mobx-react-lite'

import { BlockView, Tab } from 'core'

import { AuthMethod } from '../type'
import { EmailSignIn } from './EmailSignIn'
import { LnurlSignIn } from './LnurlSignIn'
import { NostrSignIn } from './NostrSignIn'

type AuthSelectorProps = {
  selectedAuthId: AuthMethod
  onAuthSelect: (method: AuthMethod) => void
}

const AuthMethods = [
  {
    id: AuthMethod.Email,
    title: `Email`,
  },
  {
    id: AuthMethod.Lightning,
    title: `Lightning`,
  },
  {
    id: AuthMethod.Nostr,
    title: `Nostr`,
  },
]

export const AuthSelector = observer(function AuthSelector({ selectedAuthId, onAuthSelect }: AuthSelectorProps) {
  const renderAuthMethod = () => {
    if (selectedAuthId === AuthMethod.Lightning) {
      return <LnurlSignIn />
    }

    if (selectedAuthId === AuthMethod.Nostr) {
      return <NostrSignIn />
    }

    // Email.
    return <EmailSignIn />
  }

  return (
    <BlockView size={{ width: '100%' }}>
      {/* Tabs. */}
      <BlockView size={{ width: '100%' }} direction="row" vAlign="center">
        {AuthMethods.map((method, index) => (
          <Tab
            key={`${method.id}-=${index}`}
            label={method.title}
            activated={method.id === selectedAuthId}
            onPress={() => onAuthSelect(method.id)}
          />
        ))}
      </BlockView>

      {renderAuthMethod()}
    </BlockView>
  )
})
