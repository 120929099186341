import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Modal1, Modal1Tab, NeedLightningWallet } from 'core'

import { LnurlPayment } from './LnurlPayment'

type Package = {
  id: string
  credits: number
  price: number
}

type AddCreditTemplateProps = {
  onClose: () => void
  packages: Package[]
}

export const AddCreditTemplate = ({ onClose, packages }: AddCreditTemplateProps) => {
  /** States. */
  const queryClient = useQueryClient()
  const [selectedTabId, setSelectedTabId] = useState(packages[0].id)

  const tabs = useMemo<Modal1Tab[]>(
    () =>
      packages.map((p) => ({
        id: p.id,
        title: `${p.credits} replies`,
      })),
    [packages]
  )

  const renderTabScreen = useCallback(
    (tab: Modal1Tab) => {
      const creditAmount = packages.find((p) => p.id === tab.id)?.credits ?? 0
      const creditPrice = packages.find((p) => p.id === tab.id)?.price ?? 0

      return (
        <LnurlPayment packageId={tab.id} creditAmount={creditAmount} priceSats={creditPrice} onPurchaseComplete={onClose} onError={onClose} />
      )
    },
    [packages, onClose]
  )

  useEffect(() => {
    return () => {
      /**
       * We reset the following queries so when this Modal is opened next, it pulls from Network instead of cache.
       */
      packages.forEach(({ id }) => {
        queryClient.resetQueries({
          queryKey: `purchase-credits-${id}`,
        })
      })
    }
  }, [queryClient, packages])

  return (
    <Modal1
      title="Add credits"
      showTitleClose
      onTitleClosePress={onClose}
      tabs={tabs}
      selectedTabId={selectedTabId}
      onTabSelect={setSelectedTabId}
      renderTabScreen={renderTabScreen}
      footerDivider
      footerCustom={<NeedLightningWallet />}
    />
  )
}
