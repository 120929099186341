import { useState } from 'react'
import { StyleSheet } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'

import { Background, BlockView, useColor, useLayout } from 'core'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { AuthenticationRoutes } from 'navigation/routes'

import DefaultLogo from 'assets/logo/default-logo/default-logo.svg'

import { AuthSelector } from './Template/AuthSelector'
import { AuthMethod } from './type'
import { Compliance } from './Template/Compliance'

type ScreenProps = NativeStackScreenProps<RootAppRoutesProps, AuthenticationRoutes.Login>

export const AuthenticationSignIn = observer(function AuthenticationSignIn({ navigation, route }: ScreenProps) {
  /** States. */
  const [currentAuthMethod, setAuthMethod] = useState(route?.params?.method ?? AuthMethod.Email)
  const { color } = useColor()
  const { screenSize, spacing } = useLayout()

  const backgroundImageSource = (() => {
    if (currentAuthMethod === AuthMethod.Email) return require('assets/login-bg/satoshi-bg-email.png')
    if (currentAuthMethod === AuthMethod.Lightning) return require('assets/login-bg/satoshi-bg-lightning.png')
    return require('assets/login-bg/satoshi-bg-nostr.png')
  })()

  const onAuthSelect = (method: AuthMethod) => {
    setAuthMethod(method)
    navigation.setParams({ method })
  }

  return (
    <Background backgroundColor={color['always-black']}>
      <Image
        source={backgroundImageSource}
        style={[StyleSheet.absoluteFill, { flex: 1, width: screenSize.width, height: screenSize.height }]}
        contentFit="cover"
        contentPosition="center"
        transition={300}
      />

      {/* Logo. */}
      <BlockView hAlign="center" vAlign="center" size={{ width: '100%', height: 66 }}>
        <Image source={DefaultLogo} contentFit="contain" style={{ width: 123, height: 18 }} />
      </BlockView>

      <BlockView flex hAlign="center" vAlign="center">
        <BlockView
          size={{ width: 370, maxWidth: screenSize.width - spacing[4] }}
          backgroundColor={color['always-black']}
        >
          <AuthSelector selectedAuthId={currentAuthMethod} onAuthSelect={onAuthSelect} />

          <Compliance />
        </BlockView>
      </BlockView>
    </Background>
  )
})
