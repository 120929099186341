import { useEffect, useRef, useState } from 'react'
import { ActivityIndicator, Pressable, TouchableOpacity, View } from 'react-native'
import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import Popover, { PopoverPlacement } from 'react-native-popover-view'

import {
  useLayout,
  NavDrawer,
  NavHeader,
  NavTitle,
  BlockView,
  NavProfile,
  useColor,
  NavControlAction,
  Text,
  ButtonPrimary,
  NavItemChat,
  NavDrawerMenu,
  Chip,
  InputField,
} from 'core'
import { useCampaignModal } from 'core/v2/hooks/useCampaignModal'
import { AccountRoutes, ChatRoutes, PaymentRoutes } from 'navigation/routes'
import { getConversations } from 'api/chat/get-conversations'
import { getAccountMe } from 'api/user/get-account-me'
import { Image } from 'expo-image'
// Don't remove those, they are dynamically used below
import code_satoshi_icon from 'assets/sidebar-icons/models/code_satoshi.svg'
import technical_assistant_icon from 'assets/sidebar-icons/models/technical_assistant.svg'
import gpt_model_icon from 'assets/sidebar-icons/models/gpt_model.svg'
import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'

import { getUserChatModels } from '../../../../api/chat/get-user-chat-models'

type AppSideBarProps = DrawerContentComponentProps

export const AppSideBar = observer(function AppSideBar(props: AppSideBarProps) {
  const { navigation, state } = props
  const { closeDrawer, navigate } = navigation

  /** States. */
  const { color } = useColor()
  const { spacing, screenSize, breakPoints } = useLayout()
  const showDrawerPermanent = screenSize.width >= breakPoints.lg
  const onMobileDevice = screenSize.width <= breakPoints.sm
  const currentRoute = state.routes[state.index]
  const currentRouteName = state.routeNames[state.index]
  const [showDesktopAppMenu, setShowDesktopAppMenu] = useState(false)
  const [conversationSearch, setConversationSearch] = useState('')
  const showAppMenuButtonRef = useRef<View>(null)

  /**
   * Campaign Hooks.
   */
  useCampaignModal()

  /** Get account me Query. */
  const { data: accountMeData } = useQuery({
    queryKey: 'get-account-me',
    queryFn: getAccountMe,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  /** Get Conversations Query. */
  const { isLoading, isError, data, refetch, isRefetching } = useQuery({
    queryKey: 'get-conversations',
    queryFn: () => getConversations(conversationSearch),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  /** User Chat Models. */
  const {
    isLoading: modelsLoading,
    isError: modelsError,
    data: modelsData,
    isRefetching: modelsReferching,
  } = useQuery({
    queryKey: `get-user-chat-models`,
    queryFn: getUserChatModels,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  // useEffect hook to trigger refetching when searchInput changes
  useEffect(() => {
    if (!conversationSearch) {
      refetch()
    }

    if (conversationSearch.length > 2) {
      refetch()
    }
  }, [conversationSearch, refetch])

  const closeDrawerIfMobile = () => {
    if (showDrawerPermanent) return
    closeDrawer()
  }

  const onUpdateConversationPress = (conversationId: string) => {
    navigation.navigate(ChatRoutes.ModalUpdateConversation, {
      conversationId,
    })
  }

  const onDeleteConversationPress = (conversationId: string) => {
    navigation.navigate(ChatRoutes.ModalDeleteConversation, {
      conversationId,
    })
  }

  const onConversationPress = (conversationId: string) => {
    navigate(ChatRoutes.Conversation, {
      id: conversationId,
    })
  }

  const onModelPress = (id: string) => {
    navigate(ChatRoutes.Conversation, { id: `new-chat`, model: id })
  }

  const renderModels = () => {
    if (modelsLoading || modelsError || !modelsData) {
      return null
    }

    if (!modelsData.length) {
      return null
    }

    // Available models
    const availableModels = modelsData.filter((r) => r.enabled) // && r.id !== 'SATOSHI')

    availableModels.forEach((model) => {
      switch (model.id.toLowerCase()) {
        case 'satoshi':
        case 'satoshi_v2':
          model.icon = MinimalLogo
          break
        case 'code_satoshi':
          model.icon = code_satoshi_icon
          break
        case 'technical_assistant':
          model.icon = technical_assistant_icon
          break
        case 'gpt_model':
          model.icon = gpt_model_icon
          break
        default:
          break
      }
    })

    // console.log(availableModels, 'modelsData')

    return (
      <>
        <BlockView
          direction="column"
          size={{ width: '100%', minHeight: 32 }}
          vAlign="center"
          margin={{ bottom: spacing[1] }}
          style={{ marginTop: 10 }}
        >
          {availableModels.map((model, index) => (
            <TouchableOpacity onPress={() => onModelPress(model.id)}>
              <BlockView
                direction="row"
                flex
                margin={{ right: spacing[2] }}
                key={`side-models-${index}`}
                style={{ marginTop: 0, minHeight: 56, alignItems: 'center' }}
              >
                <Image
                  source={model.icon}
                  contentFit="contain"
                  style={{ width: 40, height: 40 }}
                  key={`side-models-${index}-icon`}
                />
                <Text.Roboto
                  size="15"
                  color={color['content-1']}
                  strong
                  style={{ paddingLeft: 10 }}
                  key={`side-models-${index}-name`}
                >
                  {model.name}
                </Text.Roboto>
              </BlockView>
            </TouchableOpacity>
          ))}
        </BlockView>
      </>
    )
  }

  const renderConversations = () => {
    if (isLoading) {
      return (
        <BlockView flex hAlign="center" margin={{ top: spacing[2] }}>
          <ActivityIndicator color={color['content-1']} />
        </BlockView>
      )
    }

    if (isError || !data) {
      return (
        <BlockView flex hAlign="center" margin={{ top: spacing[4] }}>
          <Text.Roboto color={color['content-2']} size="16" style={{ textAlign: 'center' }}>
            Unable to get conversations. Please refresh the page.
          </Text.Roboto>

          <BlockView margin={{ top: spacing[2] }}>
            <ButtonPrimary kind="outlined" label="Refresh" onPress={() => refetch()} loading={isRefetching} />
          </BlockView>
        </BlockView>
      )
    }

    if (!data.length) {
      return null
    }

    const isOnCurrentConversation = (conversationId: string) => {
      if (currentRouteName !== ChatRoutes.Conversation) return false
      if (!!currentRoute.params && 'id' in currentRoute.params) return conversationId === currentRoute.params.id
      return false
    }

    const descSortedData = data.sort(
      (a, b) => new Date(b.lastActivityAt).getTime() - new Date(a.lastActivityAt).getTime()
    )

    const today = new Date().toDateString()
    const yesterday = dayjs().subtract(1, 'day').toDate().toDateString()

    const isToday = (date: Date) => date.toDateString() === today
    const isYesterday = (date: Date) => date.toDateString() === yesterday

    const todayConversations = descSortedData.filter((data) => isToday(new Date(data.lastActivityAt)))

    const yesterConversations = descSortedData.filter((data) => isYesterday(new Date(data.lastActivityAt)))

    // This excludes Today and Yesterday.
    const previousConversations = descSortedData.filter(
      (data) => !isToday(new Date(data.lastActivityAt)) && !isYesterday(new Date(data.lastActivityAt))
    )

    return (
      <BlockView flex margin={{ top: spacing[1] }}>
        {/* Today. */}
        {!!todayConversations.length && (
          <>
            <NavTitle title="Today" />
            {todayConversations.map((conversation, index) => (
              <NavItemChat
                key={`today-chat-${conversation.id}-${index}`}
                icon="chat-bubble"
                label={conversation.title}
                activated={isOnCurrentConversation(conversation.id)}
                onPress={() => onConversationPress(conversation.id)}
                onEditPress={() => onUpdateConversationPress(conversation.id)}
                onDeletePress={() => onDeleteConversationPress(conversation.id)}
              />
            ))}
          </>
        )}

        {/* Yesterday. */}
        {!!yesterConversations.length && (
          <>
            <NavTitle title="Yesterday" />
            {yesterConversations.map((conversation, index) => (
              <NavItemChat
                key={`yesterday-chat-${conversation.id}-${index}`}
                icon="chat-bubble"
                label={conversation.title}
                activated={isOnCurrentConversation(conversation.id)}
                onPress={() => onConversationPress(conversation.id)}
                onEditPress={() => onUpdateConversationPress(conversation.id)}
                onDeletePress={() => onDeleteConversationPress(conversation.id)}
              />
            ))}
          </>
        )}

        {/* All previous. */}
        {!!previousConversations.length && (
          <>
            <NavTitle title="Previous" />
            {previousConversations.map((conversation, index) => (
              <NavItemChat
                key={`previous-chat-${conversation.id}-${index}`}
                icon="chat-bubble"
                label={conversation.title}
                activated={isOnCurrentConversation(conversation.id)}
                onPress={() => onConversationPress(conversation.id)}
                onEditPress={() => onUpdateConversationPress(conversation.id)}
                onDeletePress={() => onDeleteConversationPress(conversation.id)}
              />
            ))}
          </>
        )}
      </BlockView>
    )
  }

  return (
    <BlockView flex>
      <NavDrawer {...props}>
        <BlockView margin={{ bottom: spacing[1] }}>
          {onMobileDevice ? (
            <NavHeader showAppButton onAppButtonPress={() => navigate(ChatRoutes.ModalMobileMenu)} />
          ) : (
            <>
              <NavHeader
                showAppButtonRef={showAppMenuButtonRef}
                showAppButton
                onAppButtonPress={() => setShowDesktopAppMenu(true)}
              />
              <Popover
                isVisible={showDesktopAppMenu}
                arrowSize={{ height: 0, width: 0 }}
                animationConfig={{
                  duration: 0,
                }}
                placement={PopoverPlacement.BOTTOM}
                from={showAppMenuButtonRef}
                onRequestClose={() => setShowDesktopAppMenu(false)}
                popoverStyle={{
                  backgroundColor: 'transparent',
                }}
                backgroundStyle={{
                  backgroundColor: 'transparent',
                }}
              >
                <NavDrawerMenu onMenuClose={() => setShowDesktopAppMenu(false)} />
              </Popover>
            </>
          )}
        </BlockView>

        <NavControlAction
          leftIcon="add"
          label="New Chat"
          trailing={
            <Chip
              size="chip-2"
              kind="outlined"
              label={`${accountMeData?.credits ?? 0} credits`}
              onPress={() => {
                closeDrawerIfMobile()
                navigate(PaymentRoutes.AddCreditModal)
              }}
            />
          }
          onPress={() => {
            closeDrawerIfMobile()
            navigate(ChatRoutes.Conversation, {
              id: 'new-chat',
            })
          }}
        />

        {renderModels()}

        <BlockView margin={{ top: spacing[1] }}>
          <InputField
            size="input-field-3"
            value={conversationSearch}
            onValueChange={setConversationSearch}
            placeholderText="Search"
            leftIcon="search"
            disabled={isLoading || isError}
          />
        </BlockView>

        {renderConversations()}
      </NavDrawer>

      <BlockView size={{ width: '100%' }} position={{ bottom: 0 }}>
        <NavProfile
          fromKey="chat"
          onMyAccountPress={() => {
            closeDrawerIfMobile()
            navigate(AccountRoutes.MyAccount)
          }}
          onAddCreditPress={() => {
            closeDrawerIfMobile()
            navigate(PaymentRoutes.AddCreditModal)
          }}
          onDonationPress={() => {
            closeDrawerIfMobile()
            navigate(PaymentRoutes.GetDonationModal)
          }}
        />
      </BlockView>
    </BlockView>
  )
})
