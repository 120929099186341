import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { QueryObserverResult, useMutation } from 'react-query'
import { SafeAreaView } from 'react-native-safe-area-context'
import { NestableScrollContainer } from 'react-native-draggable-flatlist'

import { BlockView, NsbFooter, NsbSubmitActionType, showSnackbar, useColor, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { BenchmarkAnswer, GetBenchmarkQuestionData } from 'api/data-train/get-benchmark-question'
import { SubmitBenchmarkActionVariables, submitBenchmarkAction } from 'api/data-train/submit-benchmark-action'

import { Question } from './Question'
import { Answer } from './Answer'

type AppProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.NoSecondBest>,
    DrawerNavigationProp<ContributeAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

type NoSecondBestTemplateProps = {
  isRefetching: boolean
  getNextData: () => Promise<QueryObserverResult<GetBenchmarkQuestionData>>
  benchmarkId: string
  question: string
  answers: BenchmarkAnswer[]
}

export const NoSecondBestTemplate = observer(function NoSecondBestTemplate({
  isRefetching,
  getNextData,
  benchmarkId,
  question,
  answers,
}: NoSecondBestTemplateProps) {
  /** States. */
  const { navigate } = useNavigation<AppProps>()
  const { color } = useColor()
  const { spacing } = useLayout()
  const [answerOrder, setAnswerOrder] = useState(answers)
  const [loadingActionType, setLoadingActionType] = useState<NsbSubmitActionType | null>(null)

  /** Submit Benchmark Action. */
  const submitBenchmarkActionMutation = useMutation(
    (variables: SubmitBenchmarkActionVariables) => submitBenchmarkAction(benchmarkId, variables),
    {
      onSuccess: (response) => {
        // Succeeded.
        if (response.status === 200) {
          getNextData().finally(() => setLoadingActionType(null))
          return
        }

        showSnackbar({
          // Did not succeed.
          kind: 'error',
          label: `Your submission was not successful. Please try again.`,
          leftIcon: 'error',
        })
      },
      onError: () => {
        // Did not succeed.
        showSnackbar({
          kind: 'error',
          label: `Your submission was not successful. Please try again.`,
          leftIcon: 'error',
        })
      },
    }
  )

  const onSkipPress = () => {
    if (isRefetching) return

    setLoadingActionType('SKIP')
    getNextData().finally(() => setLoadingActionType(null))
  }

  const onSubmitPress = () => {
    if (isRefetching) return

    const formattedOrder = answerOrder.reduce(
      (acc, value, index) => ({
        ...acc,
        [`${index + 1}`]: value.id,
      }),
      {}
    )

    setLoadingActionType('SUBMIT')
    submitBenchmarkActionMutation.mutate({
      rankedAnswers: formattedOrder,
    })
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: color['utility-surface-2'] }}>
      <NestableScrollContainer
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          padding: spacing[2],
          backgroundColor: color['utility-surface-2'],
          alignItems: 'center',
        }}
        style={{
          backgroundColor: color['utility-surface-2'],
        }}
      >
        <BlockView flex size={{ width: '100%', maxWidth: 745 }}>
          {/* Question. */}
          <Question content={question} />

          {/* Answer. */}
          <Answer answers={answerOrder} onAnswerOrderChange={setAnswerOrder} />
        </BlockView>
      </NestableScrollContainer>

      <NsbFooter
        onHelpPress={() => {
          navigate(HowToModalRoutes.HowToNoSecondBest)
        }}
        onSkipPress={onSkipPress}
        onSubmitPress={onSubmitPress}
        loadingAction={loadingActionType}
      />
    </SafeAreaView>
  )
})
