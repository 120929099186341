import { QueryCache, QueryClient } from 'react-query'

import { showSnackbar } from 'core'
import { rootStore } from 'store/root.store'

const queryCache = new QueryCache({
  onError: (e) => {
    if (e instanceof Error) {
      if (e.message.includes('Unauthorized')) {
        showSnackbar({
          kind: 'error',
          label: `You have been logged out due to invalid session. Please log in again.`,
        })
        rootStore.reset()
      }
    }
  },
})

export const reactQueryClient = new QueryClient({
  queryCache,
})
