import { request } from 'api/request'

export type PurchaseCreditData = {
  lnAddress: string
  purchaseId: string
}

export const purchaseCredits = (creditPackageId: string): Promise<PurchaseCreditData> => {
  return request.post(`v1/credits/${creditPackageId}/purchase`)
}
