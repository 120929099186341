import { useState } from 'react'
import { CompositeScreenProps } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { observer } from 'mobx-react-lite'
import { AnimatePresence } from 'moti'

import { AnimateTransition, BlockView, ButtonIcon, MaterialIconOutlined, Text, useColor, useLayout } from 'core'
import { HowToAppRoutesProps, RootAppRoutesProps } from 'navigation/stack/root'
import { HowToModalRoutes } from 'navigation/routes'

import { useHowToContent } from './content'

type ModalProps = CompositeScreenProps<
  NativeStackScreenProps<HowToAppRoutesProps, HowToModalRoutes.HowToCleanupData>,
  NativeStackScreenProps<RootAppRoutesProps>
>

export const HowToCleanupData = observer(function HowToCleanupData({ navigation }: ModalProps) {
  /** States. */
  const { color } = useColor()
  const { spacing, screenSize } = useLayout()
  const [closeModal, setCloseModal] = useState(false)
  const howToContent = useHowToContent()

  return (
    <BlockView flex hAlign="center" vAlign="center" padding={{ horizontal: spacing[2] }}>
      <AnimatePresence exitBeforeEnter onExitComplete={navigation.goBack}>
        {!closeModal && (
          <AnimateTransition key="dtv-how-to-modal" type="from-bottom" duration={250}>
            <BlockView
              size={{ width: 400, maxWidth: screenSize.width - spacing[4], maxHeight: screenSize.height - spacing[5] }}
              backgroundColor={color['utility-surface-2']}
              border={{ radius: 24, color: color['utility-border-1'], width: 1 }}
              padding={{ horizontal: spacing[4], top: spacing[3], bottom: spacing[4] }}
            >
              {/* Title. */}
              <BlockView direction="row" size={{ width: '100%' }} vAlign="center" margin={{ bottom: spacing[3] }}>
                <Text.Gridular size="24" color={color['content-1']} style={{ flex: 1, marginRight: spacing[1] }}>
                  How to clean data
                </Text.Gridular>

                <ButtonIcon
                  kind="minimal"
                  iconName="close"
                  iconColor={color['content-2']}
                  onPress={() => setCloseModal(true)}
                />
              </BlockView>

              {/* Content. */}
              {howToContent.map(({ title, iconName, iconColor, content }, index) => {
                return (
                  <BlockView key={`how-to-clean-content-${index}`} margin={{ bottom: spacing[2] }}>
                    <BlockView direction="row" vAlign="center">
                      {/* Icon. */}
                      <MaterialIconOutlined name={iconName} color={iconColor} size={24} />

                      {/* Title. */}
                      <Text.Roboto size="16" strong color={color['content-1']} style={{ marginLeft: spacing[1] }}>
                        {title}
                      </Text.Roboto>
                    </BlockView>

                    {/* Content. */}
                    <BlockView
                      flex
                      margin={{
                        // Icon size + left margin.
                        left: 24 + spacing[1],
                      }}
                    >
                      <Text.Roboto size="16" color={color['content-2']}>
                        {content}
                      </Text.Roboto>
                    </BlockView>
                  </BlockView>
                )
              })}
            </BlockView>
          </AnimateTransition>
        )}
      </AnimatePresence>
    </BlockView>
  )
})
