import { useEffect, useRef, useState } from 'react'
import { ScrollView } from 'react-native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native'
import { QueryObserverResult, useMutation } from 'react-query'
import { observer } from 'mobx-react-lite'

import { showSnackbar, useColor, DtvFooter, DtvSubmitActionType, BlockView, useLayout } from 'core'
import { ContributeAppRoutesProps } from 'navigation/stack/contribute'
import { ContributeRoutes, HowToModalRoutes } from 'navigation/routes'
import { RootAppRoutesProps } from 'navigation/stack/root'
import { useRootStore } from 'store/root.store'
import { GetRandomTrainDataData, TrainDataType } from 'api/data-train/get-random-train-data'
import {
  SubmitTrainDataActionType,
  SubmitTrainDataActionVariables,
  submitTrainDataAction,
} from 'api/data-train/submit-train-data-action'

import { Question } from './Question'
import { Response } from './Response'

type AppProps = CompositeNavigationProp<
  CompositeNavigationProp<
    NativeStackNavigationProp<ContributeAppRoutesProps, ContributeRoutes.DTV>,
    DrawerNavigationProp<ContributeAppRoutesProps>
  >,
  NativeStackNavigationProp<RootAppRoutesProps>
>

export type AppTemplateProps = {
  isRefetching: boolean
  getNextData: () => Promise<QueryObserverResult<GetRandomTrainDataData>>
  dataId: string
  dataType: TrainDataType
  content: string
  answer?: string | null
  userFullName?: string | null
}

export const AppTemplate = observer(function AppTemplate({
  isRefetching,
  getNextData,
  dataId,
  dataType,
  content,
  answer,
  userFullName,
}: AppTemplateProps) {
  /** States. */
  const { navigate } = useNavigation<AppProps>()
  const { modalToggleStore } = useRootStore()
  const { color } = useColor()
  const { spacing } = useLayout()
  const originalData = useRef({ content, answer })
  const [contentValue, setContentValue] = useState(content)
  const [answerValue, setAnswerValue] = useState<string | null>(answer ?? null)
  const [loadingActionType, setLoadingActionType] = useState<DtvSubmitActionType | null>(null)

  /**
   * Manually refresh the content and answer if it is refreshed.
   */
  useEffect(() => {
    if (content !== originalData.current.content) {
      originalData.current.content = content
      setContentValue(content)
    }
    if (answer !== originalData.current.answer) {
      originalData.current.answer = answer
      setAnswerValue(answer ?? null)
    }
  }, [content, answer])

  /** Submit Train Data Action Mutation. */
  const submitTrainDataMutation = useMutation(
    (actionType: SubmitTrainDataActionType) => {
      let variables: SubmitTrainDataActionVariables = {
        action: actionType,
      }

      // User has edited content.
      if (content !== contentValue) {
        variables = { ...variables, contentEdit: contentValue }
      }
      // Answer was originally provided && user edited the answer.
      if (answer !== null && answerValue !== null && answer !== answerValue) {
        variables = { ...variables, answerEdit: answerValue }
      }

      return submitTrainDataAction(dataId, variables)
    },
    {
      onSuccess: (response) => {
        // Succeeded.
        if (response.status === 200) {
          // Get the next training date.
          getNextData().finally(() => setLoadingActionType(null))
          return
        }

        setLoadingActionType(null)
        // Did not succeed.
        showSnackbar({
          kind: 'error',
          label: 'Your submission was not successful. Please try again.',
          leftIcon: 'error',
        })
      },
    }
  )

  const onDiscardPress = () => {
    if (isRefetching) return

    // User has seen Discard Prompt.
    if (!modalToggleStore.showDiscardTrainDataPrompt) {
      setLoadingActionType('DISCARD')
      return submitTrainDataMutation.mutate(SubmitTrainDataActionType.Discard)
    }

    let params = {
      dataId,
    } as ContributeAppRoutesProps[ContributeRoutes.ModalDiscardDTVPrompt]

    if (content !== contentValue) {
      params = { ...params, contentEdit: contentValue }
    }

    if (answer !== null && answerValue !== null && answer !== answerValue) {
      params = { ...params, answerEdit: answerValue }
    }
    navigate(ContributeRoutes.ModalDiscardDTVPrompt, params)
  }

  const onKeepPress = () => {
    if (isRefetching) return

    setLoadingActionType('KEEP')
    return submitTrainDataMutation.mutate(SubmitTrainDataActionType.Keep)
  }

  const onSkipPress = () => {
    if (isRefetching) return

    setLoadingActionType('SKIP')
    return getNextData().finally(() => setLoadingActionType(null))
  }

  const renderContent = () => {
    /** QA Train data. */
    if (dataType === TrainDataType.QA && !!answer) {
      return (
        <>
          {/* Question. */}
          <Question content={contentValue} onContentUpdate={setContentValue} />

          {/* Response. */}
          {answerValue !== null && (
            <BlockView flex size={{ width: '100%' }} margin={{ top: spacing[1] }}>
              <Response userFullName={userFullName} content={answerValue} onContentUpdate={setAnswerValue} />
            </BlockView>
          )}
        </>
      )
    }

    // Other Train type is not supported.
    return null
  }

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: color['utility-surface-2'],
          paddingHorizontal: spacing[2],
          alignItems: 'center',
        }}
        style={{ backgroundColor: color['utility-surface-2'] }}
      >
        <BlockView flex size={{ width: '100%', maxWidth: 745 }}>
          {renderContent()}
        </BlockView>
      </ScrollView>

      <DtvFooter
        onHelpPress={() => {
          navigate(HowToModalRoutes.HowToCleanupData)
        }}
        onDiscardPress={onDiscardPress}
        onKeepPress={onKeepPress}
        onSkipPress={onSkipPress}
        loadingAction={loadingActionType}
      />
    </>
  )
})
