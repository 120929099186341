import { Pressable, StyleSheet, ViewStyle } from 'react-native'
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { observer } from 'mobx-react-lite'

import { useColor } from 'core/v2/color'
import { Text } from '../Text'
import { MaterialIconOutlined, MaterialIconOutlinedName } from '../Icon'

export type ChipProps = {
  kind?: 'tonal' | 'outlined' | 'minimal'
  size?: 'chip-1' | 'chip-2'
  leftIcon?: MaterialIconOutlinedName
  leftIconColor?: string
  rightIcon?: MaterialIconOutlinedName
  rightIconColor?: string
  label: string
  labelColor?: string
  disabled?: boolean
  backgroundColor?: string
  onPress?: () => void
}

enum ButtonState {
  PressedOut = 0,
  PressedIn = 1,
}
export const Chip = observer(function Chip({
  kind = 'tonal',
  size = 'chip-1',
  leftIcon,
  leftIconColor,
  rightIcon,
  rightIconColor,
  label,
  labelColor,
  disabled,
  backgroundColor,
  onPress,
}: ChipProps) {
  /** States. */
  const { color } = useColor()
  const buttonState = useSharedValue(ButtonState.PressedOut)

  const showHoverInState = () => {
    buttonState.value = withTiming(ButtonState.PressedIn, { duration: 150 })
  }
  const showHoverOutState = () => {
    buttonState.value = withTiming(ButtonState.PressedOut, { duration: 150 })
  }

  const containerAnimatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(buttonState.value, [ButtonState.PressedOut, ButtonState.PressedIn], [1, 0.7]),
  }))

  const _labelColor = (() => {
    if (labelColor) return labelColor
    if (kind === 'outlined') return color['content-1']
    return color.accent.brand
  })()

  const containerKindStyle = ((): ViewStyle => {
    if (kind === 'tonal') {
      return {
        backgroundColor: backgroundColor ?? color['utility-surface-4'],
      }
    }

    if (kind === 'outlined') {
      return {
        borderColor: color['utility-border-1'],
        borderWidth: 1,
      }
    }

    return {}
  })()

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={showHoverInState}
      onHoverOut={showHoverOutState}
      onPressIn={showHoverInState}
      onPressOut={showHoverOutState}
      disabled={disabled}
    >
      <Animated.View
        style={[
          styles.container,
          size === 'chip-1' ? styles.chip1 : styles.chip2,
          containerKindStyle,
          containerAnimatedStyle,
        ]}
      >
        {!!leftIcon && (
          <MaterialIconOutlined
            name={leftIcon}
            color={leftIconColor ?? _labelColor}
            size={16}
            style={{ marginRight: 4 }}
          />
        )}

        <Text.RobotoMono uppercase size={size === 'chip-1' ? '12' : '10'} color={_labelColor}>
          {label}
        </Text.RobotoMono>

        {!!rightIcon && (
          <MaterialIconOutlined
            name={rightIcon}
            color={rightIconColor ?? color['content-2']}
            size={16}
            style={{ marginLeft: 4 }}
          />
        )}
      </Animated.View>
    </Pressable>
  )
})

type Style = {
  container: ViewStyle
  chip1: ViewStyle
  chip2: ViewStyle
}

const styles = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 30,
  },
  chip1: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  chip2: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
})
