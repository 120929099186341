import React from 'react'
import { observer } from 'mobx-react-lite'
import { useColor } from 'core/v2/color'
import { BlockView, Segment } from 'core/v2/atoms'

export type NavSegmentBarItem<ItemId = string> = {
  id: ItemId
  label: string
  disabled?: boolean
}

export type NavSegmentBarProps<ItemId = string> = {
  selectedItemId: ItemId
  onItemSelect: (itemId: ItemId) => void
  items: NavSegmentBarItem<ItemId>[]
}

export const NavSegmentBar = observer(function NavSegmentBar<ItemId = string>({
  selectedItemId,
  onItemSelect,
  items,
}: NavSegmentBarProps<ItemId>) {
  /** States. */
  const { color } = useColor()

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      border={{ radius: 32 }}
      backgroundColor={color['alpha-white-8']}
      padding={4}
      vAlign="center"
    >
      {items.map((item, index) => {
        return (
          <Segment
            key={`nav-segment-bar-item-${item.id}-${index}`}
            label={item.label}
            onPress={() => onItemSelect(item.id)}
            activated={selectedItemId === item.id}
            disabled={item.disabled}
            wrapperStyle={{ flex: 1 }}
          />
        )
      })}
    </BlockView>
  )
})
