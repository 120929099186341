import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, useLayout } from 'core'
import { Title } from './Title'
import { TextCard } from './Card'

type NoSecondBestProps = {
  totalPointsEarned: number
  answersProvided: number
}

export const NoSecondBest = observer(function NoSecondBest({ totalPointsEarned, answersProvided }: NoSecondBestProps) {
  /** States. */
  const { spacing, screenSize, breakPoints } = useLayout()

  const isTablet = screenSize.width < breakPoints.lg
  const screenPaddingHorizontal = isTablet ? spacing[2] : 60

  const noSecondBestStats = [
    {
      highlight: true,
      title: `Total points earned`,
      content: totalPointsEarned.toLocaleString(),
    },
    {
      highlight: false,
      title: `Answers provided`,
      content: answersProvided.toLocaleString(),
    },
  ]
  return (
    <BlockView size={{ width: '100%' }} margin={{ top: spacing[5] }}>
      <BlockView padding={{ horizontal: screenPaddingHorizontal }}>
        <Title text="No Second Best" icon="low-priority" />
      </BlockView>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: screenPaddingHorizontal }}
        style={{ marginTop: spacing[3] }}
      >
        {noSecondBestStats.map(({ highlight, title, content }, index) => {
          const isLast = noSecondBestStats.length - 1 === index
          return (
            <TextCard
              key={`no-second-best-stat-${title}-${index}`}
              highlight={highlight}
              title={title}
              content={content}
              margin={{ right: isTablet ? 0 : isLast ? 0 : spacing[2] }}
            />
          )
        })}
      </ScrollView>
    </BlockView>
  )
})
