import { StyleSheet, ViewStyle } from 'react-native'
import { Image } from 'expo-image'
import { observer } from 'mobx-react-lite'
import { MotiView } from 'moti'

import { BlockView, Text, useColor, useLayout } from 'core'

import MinimalLogo from 'assets/logo/minimal-logo/minimal-logo.svg'

type QuestionProps = {
  content: string
}

export const Question = observer(function Question({ content }: QuestionProps) {
  /** States. */
  const { color } = useColor()
  const { spacing } = useLayout()

  return (
    <BlockView
      size={{ width: '100%' }}
      direction="row"
      vAlign="flex-start"
      padding={spacing[2]}
      backgroundColor="transparent"
    >
      {/* Satoshi Logo. */}
      <BlockView
        size={40}
        border={{ radius: 20 }}
        backgroundColor={color['utility-surface-1']}
        vAlign="center"
        hAlign="center"
        margin={{ right: spacing[1] }}
      >
        <Image source={MinimalLogo} style={{ width: 24, height: 12 }} />
      </BlockView>

      <MotiView from={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={styles.questionContainer}>
        <BlockView flex padding={10} margin={{ right: spacing[1] }}>
          <Text.RobotoMono color={color['content-2']} size="12" uppercase style={{ marginBottom: spacing[1] }}>
            Question
          </Text.RobotoMono>

          <Text.Roboto size="16" color={color['content-1']}>
            {content}
          </Text.Roboto>
        </BlockView>
      </MotiView>
    </BlockView>
  )
})

type Style = {
  questionContainer: ViewStyle
}

const styles = StyleSheet.create<Style>({
  questionContainer: {
    flex: 1,
    width: '100%',
  },
})
