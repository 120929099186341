import { ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'

import { BlockView, useLayout } from 'core'
import { Title } from './Title'
import { TextCard } from './Card'

type DtvProps = {
  totalPointsEarned: number
  acceptedCount: number
  rejectedCount: number
  editedCount: number
  consensusPercentage: number
}

export const Dtv = observer(function Dtv({
  totalPointsEarned,
  acceptedCount,
  rejectedCount,
  editedCount,
  consensusPercentage,
}: DtvProps) {
  /** States. */
  const { spacing, screenSize, breakPoints } = useLayout()

  const isTablet = screenSize.width < breakPoints.lg
  const screenPaddingHorizontal = isTablet ? spacing[2] : 60

  const dtvStats = [
    {
      highlight: true,
      title: `Total points earned`,
      content: totalPointsEarned.toLocaleString(),
    },
    {
      highlight: false,
      title: `Accepted count`,
      content: acceptedCount.toLocaleString(),
    },
    {
      highlight: false,
      title: `Rejected count`,
      content: rejectedCount.toLocaleString(),
    },
    {
      highlight: false,
      title: `Edited count`,
      content: editedCount.toLocaleString(),
    },
    {
      highlight: false,
      title: `% in consensus`,
      content: `${Math.round(consensusPercentage)}%`,
    },
  ]
  return (
    <BlockView size={{ width: '100%' }} margin={{ top: spacing[5] }}>
      <BlockView padding={{ horizontal: screenPaddingHorizontal }}>
        <Title text={`Don't Trust, Verify`} icon="verified" />
      </BlockView>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: screenPaddingHorizontal }}
        style={{ marginTop: spacing[3] }}
      >
        {dtvStats.map(({ highlight, title, content }, index) => {
          const isLast = dtvStats.length - 1 === index
          return (
            <TextCard
              key={`dtv-stat-${title}-${index}`}
              highlight={highlight}
              title={title}
              content={content}
              margin={{ right: isTablet ? 0 : isLast ? 0 : spacing[2] }}
            />
          )
        })}
      </ScrollView>
    </BlockView>
  )
})
